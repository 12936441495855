import React, { Component } from "react";
import { Multiselect, DropdownList } from "react-widgets";
import { connect } from "react-redux";
import {
  Container,
  CustomInput,
} from "reactstrap";

import { Authorization } from "./../../utils/Authorization";
import {
  createUsuario,
  getListaUsuarios,
  getUsuario,
} from "./../../redux/actions/usuarios-actions";

import { getNivelesAprobaciones } from "./../../redux/actions/niveles-aprobaciones-actions";

import { getAreasOrganizacionales } from "./../../redux/actions/areas-actions";
import { getRoles } from "./../../redux/actions/roles-action";
import Loading from "../utils/Loading";
import swal from "sweetalert"

class CrearUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = { usuarioExiste: false };
  }
  emailRef = React.createRef();
  firmaRef = false;
  firmaAprobacionPoaRef = false;
  firmaRevisionRef = false;
  firmaAprobacionMonitoreoRef = false;
  passwordRef = React.createRef();
  localRef = React.createRef();
  rolesRef = ["Visualizar Productos", "Visualizar Actividades"];
  nombreRef = React.createRef();
  nivelAprobacionRef = React.createRef();
  usuarioRef = React.createRef();
  emailSeach = "";
  areaOrganizacionalRef = {};

  componentDidMount() {
    this.props.getRoles();
    this.props.getListaUsuarios();
    this.props.getAreasOrganizacionales();
    this.props.getNivelesAprobaciones();
  }

  buscarUsuario = async () => {
    this.getUsuario();
  };
  crearUsuario = async (e) => {
    e.preventDefault();

    if (this.validarCrearUsuario()) {
      let usuario = {
        email: this.emailRef.current.value,
        usuario: this.usuarioRef.current.value,
        password: this.passwordRef.current
          ? this.passwordRef.current.value
          : null,
        local: this.localRef.current ? this.localRef.current.value : false,
        nombre: this.nombreRef.current.value,
        roles: this.rolesRef,
        areaOrganizacionalId: this.areaOrganizacionalRef.areaOrganizacionalId,
        nivelAprobacionId: this.nivelAprobacionRef.current
          ? this.nivelAprobacionRef.current.value
          : null,
        firma: this.firmaRef,
        firmaAprobacionPoa: this.firmaAprobacionPoaRef,
        firmaRevision: this.firmaRevisionRef,
        firmaAprobacionMonitoreo: this.firmaAprobacionMonitoreoRef,
      };
      if (this.state.usuarioExiste) {
        swal("Crear Usuario","No se puede crear nuevamente este usuario","error");
      } else {
       await this.props.createUsuario(usuario);
      this.props.history.push("/admin/usuarios/index");
      }
    }

  };

  validarCrearUsuario = () => {
    if (this.nombreRef.current.value === "") {
      swal("Crear usuario","Buscar el usuario es necesario antes de poder crearlo","error");
      return false;
    }
    if (Object.keys(this.areaOrganizacionalRef).length === 0) {
      swal("Crear usuario","El area organizacional es requerido","error");
      return false;
    }
    if (this.nivelAprobacionRef.current.value === "0") {
      swal("Crear usuario","Debe seleccionar el nivel de aprobación del usuario","error");
      return false;
    }
    if (!this.validarCorreoElectronico(this.emailSeach)) {
      swal("Crear usuario","Debe especificar un correo electronico valido antes de poder buscar el usuario","error");
      return false;
    }
    if (this.rolesRef.length === 0) {
      swal("Crear usuario","Debe especificar los roles del usuario","error");
      return false;
    }
    if (this.usuarioRef.current.value === "") {
      swal("Crear usuario","Debe especificar el correo del usuario","error");
      return false;
    }
    return true;
  };

  valorEmail = (email) => {
    this.emailSeach = email;
  };

  validarCorreoElectronico = (email) => {
    if (/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/.test(email)) {
      return true;
    } else {
      return false;
    }
  };
  buscarUsuario = () => {
    if (
      this.emailSeach !== "" &&
      this.validarCorreoElectronico(this.emailSeach)
    ) {
      this.props.getUsuario(this.emailSeach).then((resultado) => {
        if (this.props.usuario) {
          if (this.props.usuario.email === this.emailSeach) {
            if (this.validarUsuario(this.emailSeach)) {
              swal("Crear usuario","usuario existe no puede crearlo nuevamente","error");
              this.setState({ usuarioExiste: true });
            } else {
              this.setState({ usuarioExiste: false });
            }
            document.getElementById("nombre").value = this.props.usuario.name;
            document.getElementById(
              "usuario"
            ).value = this.props.usuario.user.username;
          } else {
            swal("Crear usuario","Usuario no encontrado favor validar correo electronico","error");
            document.getElementById("nombre").value = "";
          }
        } else {
          swal("Crear usuario","Usuario no encontrado favor validar correo electronico","error");
          document.getElementById("nombre").value = "";
        }
      });
    } else {
      swal("Crear usuario","Debe especificar un correo electronico valido antes de poder buscar el usuario","error");
      document.getElementById("nombre").value = "";
    }
  };
  validarUsuario = (email) => {
    let resultado = [];
    this.props.usuarios.filter((usuario) =>
      usuario.email === email ? resultado.push(usuario) : null
    );

    return resultado.length > 0 ? true : false;
  };

  render() {
    const {
      roles,
      isLoading,
      isLoadingUsuario,
      areaOrganizacional,
      nivelesAprobaciones,
    } = this.props;

    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container>
          <div className="container bg-white border">
            {isLoading ? (
              <Loading></Loading>
            ) : (
              <form
                className="needs-validation p-3 "
                onSubmit={this.crearUsuario}
                noValidate
              >
                <div className="row">
                  <div className="form-group col-md-6 ">
                    <label htmlFor="email">Email</label>
                    <div className="input-group ">
                      <input
                        type="email"
                        className="form-control  text-dark"
                        id="email"
                        placeholder="Correo Electronico"
                        ref={this.emailRef}
                        onBlur={(e) => {
                          this.valorEmail(
                            document.getElementById("email").value
                          );
                        }}
                        required
                      />
                      <div className="input-group-append">
                        {isLoadingUsuario ? (
                          <button
                            className="btn btn-success"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Cargando...
                          </button>
                        ) : (
                          <button
                            className="btn  btn-success"
                            type="button"
                            onClick={() => {
                              this.buscarUsuario();
                            }}
                          >
                            Buscar
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>

                  <div className="form-group col-md-6 ">
                    <label htmlFor="nombre">Nombre</label>

                    <input
                      type="text"
                      className="form-control  text-dark"
                      id="nombre"
                      placeholder="Nombre Empleado"
                      ref={this.nombreRef}
                      required
                    />
                  </div>
                </div>
                {this.props.user.roles.includes("Administrador general") && (this.props.user.email==="miguel.eve@mem.gob.do"|| this.props.user.email==="rrodriguez@mem.gob.do") ? (
                  <React.Fragment>
                    <div className="row">
                      <div className="form-group col-md-6 ">
                        <label htmlFor="email">Contraseña</label>

                        <input
                          type="password"
                          className="form-control  text-dark"
                          id="contrasena"
                          placeholder="Contraseña"
                          ref={this.passwordRef}
                          autoComplete="off"
                        />
                      </div>

                      <div className="form-group col-md-6 ">
                        <label htmlFor="constrasenaConfig">
                          Confirmar Contraseña
                        </label>

                        <input
                          type="password"
                          className="form-control  text-dark"
                          id="constrasenaConfig"
                          placeholder="Contraseña"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="form-group ">
                      <label htmlFor="local">Local</label>
                      <select
                        id="local"
                        className="form-control"
                        ref={this.localRef}
                      >
                        <option value={false}>NO</option>
                        <option value={true}>SI</option>
                      </select>
                    </div>
                  </React.Fragment>
                ) : (
                  <div></div>
                )}
                <div className="row">
                  <div className="form-group col-md-6 ">
                    <label htmlFor="Usuario">Usuario</label>

                    <input
                      type="text"
                      className="form-control  text-dark"
                      id="usuario"
                      placeholder="Usuario"
                      ref={this.usuarioRef}
                      required
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="roles">Roles</label>
                    <Multiselect
                      id="roles"
                      placeholder="--Seleccione los roles del usuario--"
                      data={roles.filter((rol) =>
                        !this.props.user.roles.includes("Administrador general")
                          ? rol.rol !== "Administrador general"
                          : rol
                      )}
                      textField="rol"
                      valueField="rol"
                      defaultValue={this.rolesRef}
                      onChange={(e) => {
                        // this.unidadResponsableRef = e;
                        this.rolesRef = [];
                        e.map((rol) => this.rolesRef.push(rol.rol));
                      }}
                      // caseSensitive={false}
                      minLength={3}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="areaOrganizacional">
                      Area Organizacional
                    </label>
                    <DropdownList
                      filter
                      id="areaOrganizacional"
                      placeholder="--Seleccione el Area Organizacional--"
                      data={areaOrganizacional}
                      allowCreate="onFilter"
                      onCreate={(nombre) => this.handleCreate(nombre)}
                      onChange={(value) => (this.areaOrganizacionalRef = value)}
                      textField="nombre"
                      // caseSensitive={false}
                      minLength={3}
                    />
                  </div>

                  <div className="from-group col-md-6">
                    <label htmlFor="nivelAprobacion">Nivel de Aprobación</label>
                    <select
                      className=" form-control  text-dark "
                      id="nivelAprobacion"
                      ref={this.nivelAprobacionRef}
                    >
                      <option value="0">
                        --Seleccionar el nivel de aprobación--
                      </option>
                      {nivelesAprobaciones.map((niveles) => (
                        <option
                          key={niveles.nivelAprobacionId}
                          value={niveles.nivelAprobacionId}
                        >
                          {niveles.descripcion}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="from-group col-md-3">
                    <label htmlFor="firma">Firma Reportes</label>
                    <CustomInput
                      type="checkbox"
                      id="firma"
                      label="SI"
                      onClick={(e) => {
                        this.firmaRef = e.currentTarget.checked;
                      }}
                    />
                  </div>
                  <div className="from-group col-md-3">
                    <label htmlFor="firmaAprobacionPoa">Aprobación POA</label>
                    <CustomInput
                      type="checkbox"
                      id="firmaAprobacionPoa"
                      label="SI"
                      onClick={(e) => {
                        this.firmaAprobacionPoaRef = e.currentTarget.checked;
                      }}
                    />
                  </div>
                  <div className="from-group col-md-3">
                    <label htmlFor="firmaRevision">Revisión Monitoreo</label>
                    <CustomInput
                      type="checkbox"
                      id="firmaRevision"
                      label="SI"
                      onClick={(e) => {
                        this.firmaRevisionRef = e.currentTarget.checked;
                      }}
                    />
                  </div>
                  <div className="from-group col-md-3">
                    <label htmlFor="firmaAprobacionMonitoreo">
                      Aprobación Monitoreo
                    </label>
                    <CustomInput
                      type="checkbox"
                      id="firmaAprobacionMonitoreo"
                      label="SI"
                      onClick={(e) => {
                        this.firmaAprobacionMonitoreoRef =
                          e.currentTarget.checked;
                      }}
                    />
                  </div>
                </div>

                <br />
                <button className="btn btn-info" type="submit">
                  Guardar
                </button>
              </form>
            )}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.usuarios.usuario,
  isLoading: state.usuarios.isLoading,
  roles: state.roles.roles,
  usuarios: state.usuarios.usuarios,
  isLoadingUsuario: state.usuarios.isLoadingUsuario,
  areaOrganizacional: state.areasOrganizacionales.areasOrganizacionales,
  user: state.auth.signed,
  nivelesAprobaciones: state.nivelesAprobaciones.nivelesAprobaciones,
});

const mapDispatchToProps = (dispatch) => ({
  getUsuario: (email) => dispatch(getUsuario(email)),
  createUsuario: (usuario) => dispatch(createUsuario(usuario)),
  getRoles: () => dispatch(getRoles()),
  getListaUsuarios: () => dispatch(getListaUsuarios()),
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
  getNivelesAprobaciones: () => dispatch(getNivelesAprobaciones()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(CrearUsuario, [
    "Administrador general",
    "Gestor del POA",
    "Analista del POA",
    "MAE",
  ])
);
