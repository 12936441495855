import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import "./assets/css/style.css";

import AdminLayout from "./layouts/Admin.jsx";
import FullLayout from "./layouts/FullBlank.jsx";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store/configureStore";

ReactDOM.render( <
        Provider store = { store } >
        <
        PersistGate loading = { null }
        persistor = { persistor } >
        <
        BrowserRouter >
        <
        Switch >
        <
        Route path = "/admin"
        render = {
            props => < AdminLayout {...props }
            />} / >
            <
            Route path = "/user"
            render = {
                props => < AdminLayout {...props }
                />} / >
                <
                Route
                path = "/auth/login"
                render = {
                    props => < FullLayout {...props }
                    />} / >
                    <
                    Redirect from = "/"
                    to = "/auth/login" / >
                    <
                    /Switch> < /
                    BrowserRouter > <
                    /PersistGate> < /
                    Provider > ,
                    document.getElementById("root")
                );

                // If you want your app to work offline and load faster, you can change
                // unregister() to register() below. Note this comes with some pitfalls.
                // Learn more about service workers: https://bit.ly/CRA-PWA
                serviceWorker.unregister();