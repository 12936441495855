import {
  MOSTRAR_AREAS,
  AREAS_FAILED,
  LOADING_AREAS,
  AREAS_SUCCESS
} from "./../types";
import initialState from "./../initial-state.js";
const { areasOrganizacionales } = initialState;

const areasReducer = (state = areasOrganizacionales, action) => {
  switch (action.type) {
    case MOSTRAR_AREAS:
      return {
        ...state,
        areasOrganizacionales: action.payload
      };
    case LOADING_AREAS:
      return {
        ...state,
        isLoading: true
      };
    case AREAS_SUCCESS:
    case AREAS_FAILED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default areasReducer;
