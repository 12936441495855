import React, { Component } from "react";

import { connect } from "react-redux";
import {
  Container
} from "reactstrap";
import Loading from "./../utils/Loading";
import { getMonitoreosActividad } from "./../../redux/actions/monitoreo-actividad-actions";
import { Authorization } from "./../../utils/Authorization";

class ListaMonitoreosActividades extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }
  componentDidMount() {}
  componentWillReceiveProps(nextProps, nextState) {
    this.props.getMonitoreosActividad(nextProps.actividad.actividadId);
    this.setState({ isLoading: false });
  }
  render() {
    const { isLoading } = this.state;
    return (
      <div className="pb-6 pt-5 pt-md-7">
        {!isLoading ? (
          <Container>
            <h1>ListaMonitoreosActividades</h1>
          </Container>
        ) : (
          <Loading></Loading>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  actividad: state.actividades.actividad,
  producto: state.productos.producto,
  isLoading: state.monitoreosActividad.isLoading,
  user: state.auth.signed
});
const mapDispatchToProps = dispatch => ({
  getMonitoreosActividad: actividadId =>
    dispatch(getMonitoreosActividad(actividadId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(ListaMonitoreosActividades, [
    "Visualizar Productos",
    "Visualizar Actividades",
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Analista del POA",
    "MAE"
  ])
);
