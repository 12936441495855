import {
  MOSTRAR_ROLES,
  LOADING_ROLES,
  ROLES_SUCCEEDED,
  ROLES_FAILD,
} from "./../types";
import { api_base } from "./../../config";
import { getData } from "./../../utils/api-client";
export const getRoles = () => async (dispatch) => {
  let url = api_base + `api/v1/roles`;
  dispatch({ type: LOADING_ROLES });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_ROLES, payload: resultado.data });
      dispatch({ type: ROLES_SUCCEEDED });
    })
    .catch((error) => {
      if (error.response) {
        dispatch({ type: ROLES_FAILD });
      }
    });
};
