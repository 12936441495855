import React, { Component } from "react";
import Loading from "./../utils/Loading";
import {
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";

export const ModalLoading =(props) =>{
    const toggle = () => this.setState({ modal: false });
    const { modal,mensaje } = props;
    
    return (
      <div>
        <Modal isOpen={modal} toggle={toggle} backdrop="static" >
          <ModalHeader toggle={toggle}>Cargando...</ModalHeader>
          <ModalBody>
            <React.Fragment>
            <div className="text-center">
            <h3>{mensaje}</h3>
            </div>
            
            <Loading></Loading>
            </React.Fragment>
          </ModalBody>
        </Modal>
      </div>
    );
}
