import swal from "sweetalert";
import { api_base } from "./../../config";
import {
  EDITAR_USUARIO,
  LOADING_USUARIOS,
  LOADING_USUARIO,
  MOSTRAR_USUARIO,
  MOSTRAR_USUARIOS,
  USUARIOS_SUCCEEDED,
  USUARIOS_FAILED,
  MOSTRAR_USUARIOS_ANALISTAS_ASIGNADOS
} from "./../types";
import { getData } from "../../utils/api-client";

export const getListaUsuarios = () => async (dispatch) => {
  const url = api_base + `api/v1/usuarios`;

  dispatch({ type: LOADING_USUARIOS });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_USUARIOS, payload: resultado.data });
      dispatch({ type: USUARIOS_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({type: USUARIOS_FAILED});
      }
    });
};
export const getUsuario = (email) => async (dispatch) => {
  const url = api_base + `api/v1/usuarios/${email}`;

  dispatch({ type: LOADING_USUARIO });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_USUARIO, payload: JSON.parse(resultado.data) });
      dispatch({ type: USUARIOS_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({type: USUARIOS_FAILED});
      }
    });
};
export const getUsuarioEntity = (usuarioId) => async (dispatch) => {
  const url = `${api_base}api/v1/usuarios/usuarioid/${usuarioId}`;

  dispatch({ type: LOADING_USUARIO });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_USUARIO, payload: resultado.data });
      dispatch({ type: USUARIOS_SUCCEEDED });
    })
    .catch((error) => {
      dispatch({ type: MOSTRAR_USUARIO, payload: {} });
      if (error) {
        dispatch({ type: USUARIOS_FAILED });
      }
    });
};

export const getListaAnalistasAsignados = () => async (dispatch) => {
  const url = api_base + `api/v1/usuarios/analistas/asignados`;

  dispatch({ type: LOADING_USUARIOS });
  await getData
    .get(url)
    .then((resultado) => {

      dispatch({ type: MOSTRAR_USUARIOS_ANALISTAS_ASIGNADOS, payload: resultado.data });
      dispatch({ type: USUARIOS_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({type: USUARIOS_FAILED});
      }
    });
};

export const createUsuario = (usuario) => async (dispatch) => {
  let url = api_base + `api/v1/usuarios/create`;

  dispatch({ type: LOADING_USUARIOS });
  await getData
    .post(url, JSON.stringify(usuario))
    .then((resultado) => {
      swal("Crear Usuario", "Usuario creado","success").then((value)=>{
        dispatch({ type: USUARIOS_SUCCEEDED });
      });
      return resultado.data;
    })
    .catch((error) => {
      if (error) {
        swal("Crear Usuario", "El usuario no pudo ser creado","error").then((value)=>{
          dispatch({ type: USUARIOS_FAILED });
        });
      }
    });
};

export const updateStatusUsuario = (usuario) => async (dispatch) => {
  let usuarios = {
    email: usuario.user.email,
    comentario: !usuario.user.active? "El usuario fue activado":"El usuario fue desactivado",
    estatus: !usuario.user.active,
  };

  let url = api_base + `api/v1/usuarios/activar`;
  dispatch({ type: LOADING_USUARIOS });
  await getData
    .post(url, usuarios)
    .then((resultado) => {
      dispatch({ type: EDITAR_USUARIO, payload: resultado.data });
      swal("Editar Usuario", "Usuario actualizado","success").then((value)=>{
        dispatch({ type: USUARIOS_SUCCEEDED });
      });
    })
    .catch((error) => {
      if (error) {
        swal("Editar Usuario", "El usuario no pudo ser actualizado","error").then((value)=>{
          dispatch({ type: USUARIOS_FAILED });
        });
      }
    });
};

export const updateUsuario = (usuario) => async (dispatch) => {
  let url = api_base + `api/v1/usuarios/editar`;
  dispatch({ type: LOADING_USUARIOS });
  await getData
    .post(url, usuario)
    .then((resultado) => {
      dispatch({ type: EDITAR_USUARIO, payload: resultado.data });
       swal("Editar Usuario", "Usuario actualizado","success").then((value)=>{
        dispatch({ type: USUARIOS_SUCCEEDED });
      });
    })
    .catch((error) => {
      if (error) {
        swal("Editar Usuario", "El usuario no pudo ser actualizado","error").then((value)=>{
          dispatch({ type: USUARIOS_FAILED });
        });
      }
    });
};
