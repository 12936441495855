import {
  MOSTRAR_MONITOREO_ACTIVIDAD,
  MOSTRAR_MONITOREOS_ACTIVIDAD,
  LOADING_MONITOREO_ACTIVIDAD,
  MOSTRAR_ADMIN_MONITOREO_ACTIVIDAD,
  MOSTRAR_REPORTE_MONITOREO_DETALLE,
  MONITOREO_ACTIVIDAD_SUCCEEDED,
  MONITOREO_ACTIVIDAD_FAILED,
  ELIMINAR_MONITOREO_ACTIVIDAD,
  EDITAR_MONITOREO_ACTIVIDAD,
  LOADING_ACTIVIDAD,
  ACTIVIDAD_SUCCEEDED,
  ELIMINAR_MEDIOS_VERIFICACION,
  MOSTRAR_ACTIVIDAD,
  MEDIOS_VERIFICACION_SUCCEEDED,
  MONITOREO_UPDATE_APROBACION_ACTIVIDADES,
  ELIMINAR_EVIDENCIA_NO_CUMPLIMIENTO,
  EVIDENCIA_NO_CUMPLIMIENTO_SUCCEEDED,
  EVIDENCIA_NO_CUMPLIMIENTO_FAILED,

  //NO POA
  MOSTRAR_MONITOREO_NOPOA_ACTIVIDAD,
  MOSTRAR_MONITOREOS_NOPOA_ACTIVIDAD,
  LOADING_MONITOREO_NOPOA_ACTIVIDAD,
  MOSTRAR_ADMIN_MONITOREO_NOPOA_ACTIVIDAD,
  MOSTRAR_REPORTE_MONITOREO_NOPOA_DETALLE,
  MONITOREO_ACTIVIDAD_NOPOA_SUCCEEDED,
  MONITOREO_ACTIVIDAD_NOPOA_FAILED,
  ELIMINAR_MONITOREO_NOPOA_ACTIVIDAD,
  EDITAR_MONITOREO_NOPOA_ACTIVIDAD,
  LOADING_ACTIVIDAD_NOPOA_,
  ACTIVIDAD_NOPOA__SUCCEEDED,
  ELIMINAR_MEDIOS_VERIFICACION_NOPOA_,
  MOSTRAR_ACTIVIDAD_NOPOA_,
  MEDIOS_VERIFICACION_NOPOA_SUCCEEDED,
  MONITOREO_UPDATE_APROBACION_ACTIVIDADES_NOPOA_,
  ELIMINAR_EVIDENCIA_NO_CUMPLIMIENTO_NOPOA,
  EVIDENCIA_NO_CUMPLIMIENTO_NOPOA_SUCCEEDED,
  EVIDENCIA_NO_CUMPLIMIENTO_NOPOA_FAILED,
} from "./../types";
import { api_base } from "./../../config";
import { getData, getInformes, getDocument } from "./../../utils/api-client";
import FileSaver, { saveAs } from "file-saver";
import { api_download_reportes } from "./../../config";
import swal from "sweetalert";

export const getMonitoreoActividad = (monitoreoId) => async (dispatch) => {
  let url = api_base + `api/v1/monitoreoactividad/${monitoreoId}`;
  dispatch({ type: LOADING_MONITOREO_ACTIVIDAD });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_MONITOREO_ACTIVIDAD, payload: resultado.data });
      dispatch({ type: MONITOREO_ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: MONITOREO_ACTIVIDAD_FAILED });
      }
    });
};
export const getAdminMonitoreoActividad = (poaId, area = 0) => async (dispatch) => {
  let url = api_base + `api/v1/adminmonitoreosactividad/poaId/${poaId}`;
  if (area > 0) { url = api_base + `api/v1/adminmonitoreosactividad/poaId/${poaId}/${area}`; }
  dispatch({ type: LOADING_MONITOREO_ACTIVIDAD });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({
        type: MOSTRAR_ADMIN_MONITOREO_ACTIVIDAD,
        payload: resultado.data,
      });
      dispatch({ type: MONITOREO_ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: MONITOREO_ACTIVIDAD_FAILED });
      }
    });
};
export const getMonitoreosActividad = (actividadId) => async (dispatch) => {
  let url = api_base + `api/v1/monitoreosactividad/actividad/${actividadId}`;
  dispatch({ type: LOADING_MONITOREO_ACTIVIDAD });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_MONITOREOS_ACTIVIDAD, payload: resultado.data });
      dispatch({ type: MONITOREO_ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: MONITOREO_ACTIVIDAD_FAILED });
      }
    });
};
export const createMonitoreoActividad = (monitoreoActividad) => async (
  dispatch
) => {
  let url = api_base + `api/v1/monitoreoactividad/crear`;

  dispatch({ type: LOADING_MONITOREO_ACTIVIDAD });
  await getData
    .post(url, monitoreoActividad, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resultado) => {
      dispatch({ type: createMonitoreoActividad, payload: resultado.data });
      dispatch({ type: MONITOREO_ACTIVIDAD_SUCCEEDED });
      swal("Guardar Monitoreo", "Monitoreo guardado", "success");
    })
    .catch((error) => {
      if (error) {
        swal(
          "Error guardando",
          "Error intentando guardar el monitoreo, favor validar",
          "error"
        );
        dispatch({ type: MONITOREO_ACTIVIDAD_FAILED });
      }
    });
};
export const updateMonitoreoActividad = (monitoreoActividad) => async (
  dispatch
) => {
  let url = api_base + `api/v1/monitoreoactividad/editar`;
  dispatch({ type: LOADING_MONITOREO_ACTIVIDAD, payload: true });
  await getData
    .post(url, monitoreoActividad, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resultado) => {
      dispatch({ type: EDITAR_MONITOREO_ACTIVIDAD, payload: resultado.data });
      dispatch({
        type: MONITOREO_UPDATE_APROBACION_ACTIVIDADES,
        payload: resultado.data,
      });
      dispatch({ type: LOADING_MONITOREO_ACTIVIDAD, payload: false });
      swal(
        "Editar Monitoreo",
        "Monitoreo actualizado",
        "success"
      ).then((value) => { });
      dispatch({ type: MONITOREO_ACTIVIDAD_SUCCEEDED });
      swal({
        title: "Enviar Monitoreo!",
        text: "El monitoreo fue enviado con exito!",
        icon: "success",
        button: "Aceptar",
      });
    })
    .catch((error) => {
      if (error) {
        swal(
          "Error actualizando",
          "Error intentando guardar el monitoreo, favor validar",
          "error"
        );
        dispatch({ type: MONITOREO_ACTIVIDAD_FAILED });
        dispatch({ type: LOADING_MONITOREO_ACTIVIDAD, payload: false });
      }
    });
};

export const deletMonitoreoActividad = (monitoreoActividad) => async (
  dispatch
) => {
  let url = api_base + `api/v1/monitoreoactividad/eliminar`;
  dispatch({ type: LOADING_MONITOREO_ACTIVIDAD });
  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(url, JSON.stringify(monitoreoActividad))
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_MONITOREO_ACTIVIDAD,
        payload: resultado.data,
      });
      dispatch({ type: MONITOREO_ACTIVIDAD_SUCCEEDED });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: MONITOREO_ACTIVIDAD_FAILED });
      }
    });
};
export const deletMonitoreoMedioVerificacion = (
  actividadId,
  monitoreoMedioVerificacionId
) => async (dispatch) => {
  let url = api_base + `api/v1/monitoremedioverificacion/eliminar`;

  let medioVerificacion = {
    actividadId,
    monitoreoMedioVerificacionId,
  };
  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(url, JSON.stringify(medioVerificacion))
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_MEDIOS_VERIFICACION,
        payload: monitoreoMedioVerificacionId,
      });
      dispatch({
        type: MOSTRAR_ACTIVIDAD,
        payload: resultado.data,
      });
      dispatch({ type: MEDIOS_VERIFICACION_SUCCEEDED });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: MONITOREO_ACTIVIDAD_FAILED });
      }
    });
};

export const deletMonitoreoEvidencia = (
  actividadId,
  evidenciaId
) => async (dispatch) => {
  let url = api_base + `api/v1/evidenciaNoCumplimiento/eliminar`;

  let medioVerificacion = {
    actividadId,
    evidenciaId,
  };
  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(url, JSON.stringify(medioVerificacion))
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_EVIDENCIA_NO_CUMPLIMIENTO,
        payload: evidenciaId,
      });
      dispatch({
        type: MOSTRAR_ACTIVIDAD,
        payload: resultado.data,
      });
      dispatch({ type: EVIDENCIA_NO_CUMPLIMIENTO_SUCCEEDED });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: EVIDENCIA_NO_CUMPLIMIENTO_FAILED });
      }
    });
};

export const getReporteMonitoreo = (monitoreo) => async (dispatch) => {
  // console.log(monitoreo.trimestre)
  let url =
    api_base +
    `api/v1/reportes/monitoreo/${monitoreo.poaId}/${monitoreo.areaOrganizacionalId}/${monitoreo.trimestre}`;

  await getInformes
    .get(url, {
      headers: {
        "Content-Type": "application/octet-stream",
      }, responseType: 'blob'
    },)
    .then((resultado) => {
      var a = document.createElement("a");
      // console.log("success", resultado.data)
      let blob = new Blob([resultado.data], { type: "octet/stream" }),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Monitoreo_${monitoreo.ano}_${monitoreo.areaOrganizacional.replace(/\s/g, '_')}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      // console.log("Error", error)
      if (error) {
        if (error) {
          dispatch({ type: MONITOREO_ACTIVIDAD_FAILED });
        }
      }
    });
};

export const getReporteEvidenciaNoCumplimiento = (c1, c2, fileName, nombreImagen) => async (dispatch) => {
  let url =
    api_base +
    `api/v1/reportes/evidencias_no_cumplimiento/${c1}/${c2}/${fileName}`;

  await getDocument
    .get(url, {
      responseType: 'blob'
    })
    .then((resultado) => {

      // create file link in browser's memory
      const href = URL.createObjectURL(resultado.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `${nombreImagen}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

    })
    .catch((error) => {
      // console.log("Error", error)
      if (error) {
        if (error) {
          dispatch({ type: MONITOREO_ACTIVIDAD_FAILED });
        }
      }
    });
};

export const getReporteMonitoreoDetalle = (monitoreo, areaFiltro = 0) => async (dispatch) => {
  let url =
    api_base +
    `api/v1/reportes/monitoreo-detalle/${monitoreo.poaId}/${monitoreo.areaOrganizacionalId}`;
  if (areaFiltro > 0) {

    url =
      api_base +
      `api/v1/reportes/monitoreo-detalle/${monitoreo.poaId}/${areaFiltro}`;
  }

  dispatch({ type: LOADING_MONITOREO_ACTIVIDAD });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({
        type: MOSTRAR_REPORTE_MONITOREO_DETALLE,
        payload: resultado.data,
      });
      dispatch({ type: MONITOREO_ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: MONITOREO_ACTIVIDAD_FAILED });
      }
    });
};
