import {
  MOSTRAR_PRESUPUESTOS_ASIGNADOS_INSTITUCION,
  MOSTRAR_PRESUPUESTO_ASIGNADO_INSTITUCION,
  PRESUPUESTO_ASIGNADO_INSTITUCION_FAILED,
  LOADING_PRESUPUESTO_ASIGNADO_INSTITUCION,
  PRESUPUESTO_ASIGNADO_INSTITUCION_SUCCESS,
  CREATE_PRESUPUESTO_ASIGNADO_INSTITUCION,
  DELETE_PRESUPUESTO_ASIGNADO_INSTITUCION,
  UPDATE_PRESUPUESTO_ASIGNADO_INSTITUCION,
  } from "../types";
  import initialState from "../initial-state.js";
  const { presupuestosAsignadosInstitucion } = initialState;
  
  const PresupuestoAsignadoInstitucionReducer = (state = presupuestosAsignadosInstitucion, action) => {
    switch (action.type) {
      case MOSTRAR_PRESUPUESTOS_ASIGNADOS_INSTITUCION:
        // console.log("action.payload",action.payload)
        return {
          ...state,
          presupuestosAsignadosInstitucion: action.payload
        };
      case CREATE_PRESUPUESTO_ASIGNADO_INSTITUCION:
        // console.log("action.payload",action.payload)
        return {
          ...state,
          presupuestosAsignadosInstitucion: [...state.presupuestosAsignadosInstitucion,action.payload ] 
        };
        case UPDATE_PRESUPUESTO_ASIGNADO_INSTITUCION:
          // console.log("action.payload",action.payload)
          return {
            ...state,
            presupuestosAsignadosInstitucion: [...state.presupuestosAsignadosInstitucion.filter(x=> x.presupuestoAsignadoInstitucionId !== action.payload.presupuestoAsignadoId),action.payload ] 
          };
      case DELETE_PRESUPUESTO_ASIGNADO_INSTITUCION:
        // console.log("action.payload",action.payload)
        return {
          ...state,
          presupuestosAsignadosInstitucion: action.payload
        };
      case MOSTRAR_PRESUPUESTO_ASIGNADO_INSTITUCION:
        // console.log("action.payload",action.payload)
        return {
          ...state,
          presupuestoAsignadoInstitucion: action.payload
        };
      case LOADING_PRESUPUESTO_ASIGNADO_INSTITUCION:
        return {
          ...state,
          isLoading: true
        };
      case PRESUPUESTO_ASIGNADO_INSTITUCION_FAILED:
      case PRESUPUESTO_ASIGNADO_INSTITUCION_SUCCESS:
        return {
          ...state,
          isLoading: false
        };
      default:
        return state;
    }
  };
  
  export default PresupuestoAsignadoInstitucionReducer;
  