import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  Table,
  Container,
} from "reactstrap";
import numbro from "numbro";

import { Authorization } from "../../utils/Authorization";
import Loading from "../utils/Loading";

import { getAreasOrganizacionales } from "../../redux/actions/areas-actions"; 
import { getRequerimiento } from "../../redux/actions/requerimientos-actions";
import { habilitarProcesoPlurianual } from "../../utils/habilitarCampos.js"

class EditarExpedientesFisicos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      trimestres: [],
      trimestresEntity: [],
      modalInformeTrimestralStatus: false,
      selectedFile: null,
      file: [],
      fecha: "",
      documento: [],
      trimestreId: "",
      estatusEnviar: false,
      areaid2:0
    };
  }

    // Ref
    procesoPlurianualRef = React.createRef();
    montoTotalRef = React.createRef();
    numeroInternoRef = React.createRef();
    vigenciaRef = React.createRef();
    fechaRemisionRef = React.createRef();
    horaRemisionRef = React.createRef();
    fechaRecepcionRef = React.createRef();
    horaRecepcionRef = React.createRef();

  componentDidMount() {
    this.cargarDatos();
    this.props.getAreasOrganizacionales();
    this.setState({areaid2:this.props.user.areaOrganizacional.areaOrganizacionalId})
  }

  cargarDatos = async () => {
    await this.props.getRequerimiento(this.props.match.params.expedienteFisicoId);
    this.setState({ isLoadingState: false });
  }

  // guardarInformeTrimestral = async () => {
  //   if (this.validarGuardarInforme()) {
  //     this.setState({ isLoadingState: true });
  //     this.activarModalInformeTrimestral();

  //     let informeTrimestral = {
  //       trimestreId: this.state.trimestreId,
  //       nivelAprobacionId: this.props.user.nivelAprobacion.nivelAprobacionId,
  //       nombreImagen: this.state.documento[0],
  //       areaOrganizacionalId: this.props.user.areaOrganizacional
  //         .areaOrganizacionalId,
  //       poaId: this.props.poaActivo.poaId,
  //     };

  //     const fd = new FormData();
  //     if (this.state.file.length > 0) {
  //       for (let i = 0; i < this.state.file.length; i++) {
  //         fd.append(`documento[]`, this.state.file[i], this.state.documento[i]);
  //       }
  //     }

  //     Object.keys(informeTrimestral).forEach(function (item) {
  //       fd.append(item, informeTrimestral[item]);
  //     });

  //     await this.props.createInformeTrimestral(fd);

  //     this.setState({ isLoadingState: false });
  //   }
  // };

  guardarExpedienteFisico = async () => {
    this.setState({ isLoadingState: true });
    let expedienteFisico = {
      requerimientoId: this.props.match.params.expedienteFisicoId,
      procesoPlurianual: document.getElementById("procesoPlurianual").checked,
      montoTotal: this.montoTotalRef.current.value,
      numeroInterno: this.numeroInternoRef.current.value,
      vigencia: this.vigenciaRef.current ? this.vigenciaRef.current.value : "",
      fechaRemision: this.fechaRemisionRef.current ? this.fechaRemisionRef.current.value : "",
      horaRemision: this.horaRemisionRef.current ? this.horaRemisionRef.current.value : "",
      fechaRecepcion: this.fechaRecepcionRef.current ? this.fechaRecepcionRef.current.value : "",
      horaRecepcion: this.horaRecepcionRef.current ? this.horaRecepcionRef.current.value : "",
    }

    // console.log(expedienteFisico);
    this.setState({ isLoadingState: false });
  }

  validar;

  render() {
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (

                <div className="row">

                    <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-4 pb-4 border-right">
                            <h3>Detalles de la compra</h3>
                            <hr style={{ marginTop: '0rem' }}/>
                            <label className="text-left" htmlFor="noRequerimiento">No. Requerimiento</label>
                            <input
                            type="text"
                            className="form-control text-dark"
                            id="noRequerimiento"
                            required
                            disabled={true}
                            value={this.props.requerimiento.numeroSolicitud}
                            />

                            <div className="custom-control custom-checkbox mt-4">
                                <input type="checkbox" className="custom-control-input" id="procesoPlurianual" ref="procesoPlurianualRef" onChange={ () => habilitarProcesoPlurianual() }/>
                                <label className="custom-control-label" htmlFor="procesoPlurianual">¿Es proceso plurianual?</label>
                            </div>

                            <div className="mt-4 procesoPluriannual aplicaProcesoPlurianual">
                                <label className="text-left" htmlFor="montoTotal">Monto Total</label>
                                <div className="input-group">
                                    <input type="number" 
                                        id="montoTotal"
                                        className="form-control" 
                                        aria-label="Amount (to the nearest dollar)" 
                                        placeholder="1000"
                                        ref={this.montoTotalRef}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">$</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 mb-4 border-right">
                            <h3>Contracto</h3>
                            <hr style={{ marginTop: '0rem' }}/>
                            <label className="text-left" htmlFor="numeroInterno">Número interno</label>
                            <input
                                type="text"
                                className="form-control text-dark"
                                id="numeroInterno"
                                ref={this.numeroInternoRef}
                                // defaultValue={descripcion}
                                // readOnly={this.state.actividadEdit}
                                required
                            />

                            <div className="mt-4">
                                <label className="text-left" htmlFor="vigencia">Vigencia</label>
                                <input
                                    type="date"
                                    className="form-control text-dark"
                                    id="vigencia"
                                    ref={this.vigenciaRef}
                                    // defaultValue={descripcion}
                                    // readOnly={this.state.actividadEdit}
                                    required
                                />
                            </div>

                            <div className="mt-4">
                                <label>Documento (PDF)</label>
                                <input
                                id="inputpdf"
                                className="form-control text-dark"
                                type="file" accept=".pdf"
                                name="file[]"
                                onChange= {
                                    (e) => {
                                        e.preventDefault();
                                        ////////////////////////////////////////////////////////////
                                        var fileInput = document.getElementById('inputpdf');
                                        var filePath = fileInput.value;
                                        var allowedExtensions = /(\.pdf|\.PDF)$/i;
                                        if (!allowedExtensions.exec(filePath)) {
                                        swal("Error de tipo de archivo","Debe cargar archivos en formato PDF","error");
                                            fileInput.value = '';
                                            return false;
                                        } 
                                        ///////////////////////////////////////////////
                                        // props.cargarInformeTrimestral(e);
                                    }
                                }
                                />
                            </div>
                           
                        </div>
                        <div className="col-4">
                            <h3>Remisión</h3>
                            <hr style={{ marginTop: '0rem' }}/>
                            <label className="text-left" htmlFor="fechaRemision">Fecha</label>
                            <input
                                type="date"
                                className="form-control text-dark"
                                id="fechaRemision"
                                ref={this.fechaRemisionRef}
                                // defaultValue={descripcion}
                                // readOnly={this.state.actividadEdit}
                                required
                            />

                            <div className="mt-4">
                                <label className="text-left" htmlFor="horaRemision">Hora</label>
                                <input
                                    type="time"
                                    className="form-control text-dark"
                                    id="horaRemision"
                                    ref={this.horaRemisionRef}
                                    // defaultValue={descripcion}
                                    // readOnly={this.state.actividadEdit}
                                    required
                                />
                            </div>

                            <h3 className="mt-5">Recepción</h3>
                            <hr style={{ marginTop: '0rem' }}/>
                            <label className="text-left" htmlFor="fechaRecepcion">Fecha</label>
                            <input
                                type="date"
                                className="form-control text-dark"
                                id="fechaRecepcion"
                                ref={this.fechaRecepcionRef}
                                // defaultValue={descripcion}
                                // readOnly={this.state.actividadEdit}
                                required
                            />

                            <div className="mt-4">
                                <label className="text-left" htmlFor="horaRecepcion">Hora</label>
                                <input
                                    type="time"
                                    className="form-control text-dark"
                                    id="horaRecepcion"
                                    ref={this.horaRecepcionRef}
                                    // defaultValue={descripcion}
                                    // readOnly={this.state.actividadEdit}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                  </div>
                 
                  <div className="col-md-12 mt-5">
                    <h3 className="text-center">Insumos</h3>
                    <Table>
                        <thead>
                          <tr>
                            <th>Sub-clase</th>
                            <th>Cod. Gasto</th>
                            <th>Nombre</th>
                            <th>Precio</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.requerimiento.actividadesArticuloSeguimiento.map(
                              (articulo) =>
                                articulo != null &&
                                articulo != undefined && (
                                  <tr>
                                    <td>{articulo.presupuesto.subclase}</td>
                                    <td>{articulo.presupuesto.cuenta}</td>
                                    <td>{articulo.nombreArticulo}</td>
                                    <td>{'RD$ '+numbro(articulo.presupuesto.totalCosto).format({thousandSeparated:true})}</td>
                                  </tr>
                                )
                            )}
                             <tr>
                               <td></td>
                               <td></td>
                               <td></td>
                               <td>$RD 5,000,000</td>
                               <td></td>
                               </tr>
                        </tbody>
                      </Table>

                      <div className="row pb-3 pt-3">
                        <div className="col-4 m-auto">
                          <button
                            onClick={() => this.props.history.goBack() }
                            className="btn btn-danger"
                          >
                            CANCELAR
                          </button>
                          <button
                            onClick={() => this.guardarExpedienteFisico() }
                            className="btn btn-primary"
                          >
                            GUARDAR
                          </button>
                        </div>
                      </div>
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
  requerimiento: state.requerimientos.requerimiento,
});

const mapDispatchToProps = (dispatch) => ({
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
  getRequerimiento: (requerimientoId) => dispatch(getRequerimiento(requerimientoId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(EditarExpedientesFisicos, [
    "Administrador general",
    "Responsables de compras",
    "Analista Compras"
  ])
);
