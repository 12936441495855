import {
  MOSTRAR_INVOLUCRADOS,
  MOSTRAR_INVOLUCRADO,
  CREAR_INVOLUCRADO,
  EDITAR_INVOLUCRADO,
  ELIMINAR_INVOLUCRADO,
  LOADING_INVOLUCRADO,
  INVOLUCRADO_SUCCEEDED,
  INVOLUCRADO_FAILED,
} from "./../types";
import { api_base } from "../../config";
import { getData } from "./../../utils/api-client";

export const getInvolucrados = () => async (dispatch) => {
  let infoUsuario = JSON.parse(localStorage.getItem("jwt"));
  let url = api_base + "api/v1/involucrados";
  dispatch({ type: LOADING_INVOLUCRADO });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_INVOLUCRADOS, payload: resultado.data });
      dispatch({ type: INVOLUCRADO_SUCCEEDED });
    })
    .catch((error) => {
      if(error) {
        dispatch({ type: INVOLUCRADO_FAILED });
      }
    });
};
export const getInvolucrado = (involucradoId) => async (dispatch) => {
  let url = api_base + `api/v1/involucrado/${involucradoId}`;
  dispatch({ type: LOADING_INVOLUCRADO });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_INVOLUCRADO, payload: resultado.data });
      dispatch({ type: INVOLUCRADO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: INVOLUCRADO_FAILED });
      }
    });
};
export const createInvolucrados = (involucrado) => async (dispatch) => {
  let url = api_base + "api/v1/involucrados/nuevo";

  dispatch({ type: LOADING_INVOLUCRADO });
  await getData
    .post(url, JSON.stringify(involucrado))
    .then((resultado) => {
      dispatch({
        type: CREAR_INVOLUCRADO,
        payload: resultado.data,
      });
      dispatch({ type: INVOLUCRADO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: INVOLUCRADO_FAILED });
      }
    });
};
export const updateInvolucrados = (involucrado) => async (dispatch) => {
  let url = api_base + "api/v1/involucrado/editar";

  dispatch({ type: LOADING_INVOLUCRADO });
  await getData
    .post(url, JSON.stringify(involucrado))
    .then((resultado) => {
      dispatch({
        type: EDITAR_INVOLUCRADO,
        payload: resultado.data,
      });
      dispatch({ type: INVOLUCRADO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: INVOLUCRADO_FAILED });
      }
    });
};
export const deleteInvolucrados = (involucrado) => async (dispatch) => {
  let url = api_base + "api/v1/involucrado/eliminar";

  dispatch({ type: LOADING_INVOLUCRADO });
  await getData
    .post(url, JSON.stringify(involucrado))
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_INVOLUCRADO,
        payload: involucrado.involucradoId,
      });
      dispatch({ type: INVOLUCRADO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: INVOLUCRADO_FAILED });
      }
    });
};
