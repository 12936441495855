import React from "react";
import api from "../../utils/api-client";
import { endpoints, client, api_base } from "../../config";

import reducer from "./index";
import { createStore, applyMiddleware, compose } from "redux";
import axios from "axios";

const prueba = async (store, auth) => {
  localStorage.setItem("token_valid", false);
  let url = api_base + endpoints.login;

  await axios
    .post(url, {
      client_id: client.id,
      client_secret: client.secret,
      scope: "*",
      grant_type: "refresh_token",
      refresh_token: auth.token.refresh_token,
    })
    .then((res) => {
      res.data.expires_at = new Date().getTime() + res.data.expires_in * 1000;
      let token = {
        token: res.data,
        attributes: { name: null, email: null },
      };
      localStorage.removeItem("jwt");
      localStorage.setItem("jwt", JSON.stringify(res.data));
      localStorage.setItem("token_valid", true);
      store.dispatch({ type: "SIGNIN_REQUEST_SUCCEEDED", payload: token });
    })
    .catch((error) => {
      // console.log(error);
    });
};
const requestMiddleware = (store) => (next) => (action) => {
  const refreshThreshold = new Date().getTime() + 300000;
  const auth = store.getState().auth;

  if (
    auth &&
    auth.token !== null &&
    auth.token !== undefined &&
    auth.token.refresh_token &&
    refreshThreshold > auth.token.expires_at
  ) {
    prueba(store, auth);
  } else {
    localStorage.setItem("token_valid", true);
  }

  let result = next(action);
  return result;
};

export default requestMiddleware;

export const store = createStore(reducer, applyMiddleware(requestMiddleware));
