import {
    MOSTRAR_PRESUPUESTOS_ASIGNADOS_INSTITUCION,
    MOSTRAR_PRESUPUESTO_ASIGNADO_INSTITUCION,
    PRESUPUESTO_ASIGNADO_INSTITUCION_FAILED,
    LOADING_PRESUPUESTO_ASIGNADO_INSTITUCION,
    PRESUPUESTO_ASIGNADO_INSTITUCION_SUCCESS,
    CREATE_PRESUPUESTO_ASIGNADO_INSTITUCION,
    DELETE_PRESUPUESTO_ASIGNADO_INSTITUCION,
    UPDATE_PRESUPUESTO_ASIGNADO_INSTITUCION,
  } from "../types";
import { api_base } from "../../config";
import { getData } from "../../utils/api-client";

export const getListaPresupuestosAsignadosInstitucion = () => async dispatch => {
    let url = api_base + "api/v1/presupuestos_asignados/";
    dispatch({ type: LOADING_PRESUPUESTO_ASIGNADO_INSTITUCION });
    await getData
        .get(url)
        .then(resultado => {
            //  console.log("resultado.data",resultado.data)
            dispatch({ type: MOSTRAR_PRESUPUESTOS_ASIGNADOS_INSTITUCION, payload: resultado.data });
            dispatch({ type: PRESUPUESTO_ASIGNADO_INSTITUCION_SUCCESS});
        })
        .catch(error => {
                dispatch({ type: PRESUPUESTO_ASIGNADO_INSTITUCION_FAILED });
        });
};
export const getPresupuestoAsignadoInstitucion = (presupuestoAsignadoInstitucionId) => async dispatch => {
    let url = api_base + "api/v1/presupuestos_asignados/"+presupuestoAsignadoInstitucionId;
    dispatch({ type: LOADING_PRESUPUESTO_ASIGNADO_INSTITUCION });
    await getData
        .get(url)
        .then(resultado => {
            // console.log("resultado.data",resultado.data)
            dispatch({ type: MOSTRAR_PRESUPUESTO_ASIGNADO_INSTITUCION, payload: resultado.data });
            dispatch({ type: PRESUPUESTO_ASIGNADO_INSTITUCION_SUCCESS});
        })
        .catch(error => {
                dispatch({ type: PRESUPUESTO_ASIGNADO_INSTITUCION_FAILED });
        });
};
export const updatePresupuestoAsingadoInstitucion = (presupuestoAsignadoInstitucion) => async dispatch => {
    let url = api_base + "api/v1/presupuestos_asignados/actualizar";
    dispatch({ type: LOADING_PRESUPUESTO_ASIGNADO_INSTITUCION });
    await getData
        .post(url,presupuestoAsignadoInstitucion)
        .then(resultado => {
            // console.log("resultado.data",resultado.data)
            dispatch({ type: UPDATE_PRESUPUESTO_ASIGNADO_INSTITUCION, payload: resultado.data });
            dispatch({ type: PRESUPUESTO_ASIGNADO_INSTITUCION_SUCCESS});
        })
        .catch(error => {
                dispatch({ type: PRESUPUESTO_ASIGNADO_INSTITUCION_FAILED });
        });
};
export const setPresupuestoAsignadoInstitucion= (presupuestoAsignadoInstitucion) => async dispatch => {
    let url = api_base + "api/v1/presupuestos_asignados/crear";
    dispatch({ type: LOADING_PRESUPUESTO_ASIGNADO_INSTITUCION });
    await getData
        .post(url,presupuestoAsignadoInstitucion)
        .then(resultado => {
            // console.log("resultado.data",resultado.data)
            dispatch({ type: CREATE_PRESUPUESTO_ASIGNADO_INSTITUCION, payload: resultado.data });
            dispatch({ type: PRESUPUESTO_ASIGNADO_INSTITUCION_SUCCESS});
        })
        .catch(error => {
                dispatch({ type: PRESUPUESTO_ASIGNADO_INSTITUCION_FAILED });
        });
};

export const deletePresupuestoAsignadoInstitucion = (presupuestoAsignadoInstitucion) => async dispatch => {
    let url = api_base + "api/v1/presupuestos_asignados/eliminar";
    dispatch({ type: LOADING_PRESUPUESTO_ASIGNADO_INSTITUCION });

    await getData
        .post(url,presupuestoAsignadoInstitucion)
        .then(resultado => {
            // console.log("resultado.data",resultado.data)
            dispatch({ type: DELETE_PRESUPUESTO_ASIGNADO_INSTITUCION, payload: resultado.data });
            dispatch({ type: PRESUPUESTO_ASIGNADO_INSTITUCION_SUCCESS});
        })
        .catch(error => {
                dispatch({ type: PRESUPUESTO_ASIGNADO_INSTITUCION_FAILED });
        });
};
