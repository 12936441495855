import React, { Component } from "react";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import {
  Container
} from "reactstrap";

import {
  getReporteMonitoreoDetalle,
  getReporteMonitoreo,
} from "./../../redux/actions/monitoreo-actividad-actions";

import { ModalLoading } from "./../utils/ModalLoading";
import Loading from "./../utils/Loading";
import { Multiselect } from "react-widgets";

import { getAreasOrganizacionales } from "./../../redux/actions/areas-actions"; //me
import {
  getPoaActivo,
  mostrarPoas,
  mostrarPoa,
} from "./../../redux/actions/poa-actions";

class ReporteMonitoreo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      listaProductosState: [],
      listaTrimestreState: [],
      listaProductosOriginalState: [],
      detalleProductosPage: [],
      trimestreActualState: 0,
      modal: false,
      mensaje: false,
      activePage: 1,
      totalPages: 0,
      itemPerPage: 3,
      areaSeleccionanda: 0,
      poaSelected: 0
    };
  }

  componentDidMount() {
    const { poaActivo, user } = this.props;
    this.setState({ poaSelected: poaActivo.poaId, areaSeleccionanda: user.areaOrganizacional.areaOrganizacionalId })
    let monitoreo = {
      poaId: poaActivo.poaId,
      areaOrganizacionalId: user.areaOrganizacional.areaOrganizacionalId,
    };
    this.calcularTrimestreActual();
    this.props.getAreasOrganizacionales();//me
    this.cargarData(monitoreo);
    // this.setState({areaSeleccionanda:this.props.user.areaOrganizacional.areaOrganizacionalId})
    this.props.mostrarPoas();
  }

  validarPaginacion = (page = 1, elementos = 3) => {
    let index = elementos * page - elementos;
    let listaProductos = [];

    for (let i = 0; i < elementos; i++) {
      listaProductos.push(this.state.listaProductosState[index]);
      index++;
    }

    let totalPages = Math.ceil(
      this.state.listaProductosState.length / elementos
    );

    this.setState({
      detalleProductosPage: listaProductos,
      activePage: page,
      totalPages,
      itemPerPage: elementos,
    });
  };
  calcularTrimestreActual = () => {
    let fechaMes = new Date(2023, 11, 1);
    let Mes = fechaMes.getMonth();
    let trimestreTotal = Math.ceil(Mes / 3);
    let listaTrimestre = [];
    for (let i = 1; i <= trimestreTotal; i++) {
      listaTrimestre.push(i);
    }

    let fechatrimestre = new Date();
    let mesTrimestre = fechatrimestre.getMonth();
    let trimestreActual = Math.ceil(mesTrimestre / 3);
    this.setState({
      trimestreActualState: [trimestreActual],
      listaTrimestreState: listaTrimestre,
    });
  };

  cargarData = async (monitoreo, areaFiltro = 0) => {

    if (areaFiltro > 0) {
      monitoreo.areaOrganizacionalId = areaFiltro;


      this.setState({ areaSeleccionanda: areaFiltro });
    }


    await this.props.getReporteMonitoreoDetalle(monitoreo);
    const { productos } = this.props.reporteMonitoreoDetalle;
    let listaProductosOriginal = productos;
    let listaProductos = listaProductosOriginal;

    listaProductos = await this.actualizarSeguimientoTrimestreProductos(
      listaProductos,
      this.state.trimestreActualState
    );

    this.setState(
      {
        isLoadingState: false,
        listaProductosState: listaProductos,
        listaProductosOriginalState: listaProductosOriginal,
      },
      () => this.validarPaginacion()
    );
  };

  truncateString = (str) => {
    let num = 60;

    return str && str.length > num ? str.slice(0, 57) + "..." : str;
  };

  actualizarSeguimientoTrimestreProductos = (listaProductos, trimestre) => {
    listaProductos.map((producto) =>
      producto.actividades.map(
        (actividad) =>
          (actividad = this.calcularSeguimientoTrimestre(trimestre, actividad))
      )
    );
    return listaProductos;
  };

  cambiarSeguimientoPorTrimestre = (trimestre) => {
    
    let trimestreSelected = trimestre;
    this.setState({
      isLoadingState: true,
      trimestreActualState: trimestreSelected,
    });

    const { listaProductosOriginalState } = this.state;
    let listaProductos = this.actualizarSeguimientoTrimestreProductos(
      listaProductosOriginalState,
      trimestreSelected
    );
    this.validarPaginacion();
    this.setState({
      listaProductosState: listaProductos,
    });

    setTimeout(() => {
      this.setState({
        isLoadingState: false,
      });
    }, 1000);
  };

  calcularSeguimientoTrimestre = (trimestre, actividad) => {
    let totalSeguimiento = 0;
    let valorTrimestre = 0;
    let valorTrimestreEjecutado = 0;

    for (let i = 0; i <= trimestre.length-1; i++) {
     
      valorTrimestre += parseFloat(actividad[`t${trimestre[i]}`]);
      valorTrimestreEjecutado += parseFloat(actividad[`t${trimestre[i]}e`]);
    }
    // for (let i = 1; i <= trimestre.length; i++) {

    //   valorTrimestre += parseFloat(actividad[`t${i}`]);
    //   valorTrimestreEjecutado += parseFloat(actividad[`t${i}e`]);
    // }
    
    if (valorTrimestre == 0) {
      actividad.primerSeguimiento = "";
      actividad.segundoSeguimiento = "";
      actividad.tercerSeguimiento = "";
      return actividad;
    }

    if (valorTrimestreEjecutado > valorTrimestre) {
      let igualarTrimestre = valorTrimestreEjecutado - valorTrimestre;
      valorTrimestreEjecutado = valorTrimestreEjecutado - igualarTrimestre;
    }

    let resultado = parseFloat(
      (valorTrimestreEjecutado / valorTrimestre) * 100
    );

    if (resultado < 50) {
      actividad.primerSeguimiento = resultado;
      actividad.segundoSeguimiento = "";
      actividad.tercerSeguimiento = "";
    } else if (resultado >= 50 && resultado < 100) {
      actividad.primerSeguimiento = "";
      actividad.segundoSeguimiento = resultado;
      actividad.tercerSeguimiento = "";
    } else {
      actividad.primerSeguimiento = "";
      actividad.segundoSeguimiento = "";
      actividad.tercerSeguimiento = resultado;
    }

    return actividad;
  };

  reporteMonitoreo = async () => {
    // console.log("reporteMonitoreo")
    // console.log("this.state.trimestreActualState",this.state.trimestreActualState)
    let areaOrganizacional = this.props.areaOrganizacionales.filter(x => x.areaOrganizacionalId == this.state.areaSeleccionanda)[0];
    let poa = this.props.poas.filter(x => x.poaId == this.state.poaSelected)[0];

    let monitoreo = {
      areaOrganizacionalId: areaOrganizacional.areaOrganizacionalId,
      poaId: poa.poaId,
      areaOrganizacional: areaOrganizacional.nombre,
      ano: poa.ano,
      trimestre: this.state.trimestreActualState
    };
    this.setState({ modal: true, mensaje: "Elaborando Reporte de Monitoreo" });
    await this.props.getReporteMonitoreo(monitoreo);

    this.setState({ mensaje: "Reporte descargado" });

    setTimeout(() => {
      this.setState({ modal: false });
    }, 1500);
  };

  render() {
    //const { productos } = this.props.reporteMonitoreoDetalle;
    let timeout;
    const {
      listaProductosState,
      isLoadingState,
      detalleProductosPage,
      styleBlockedNivelEjecucion,
      trimestreActualState,
    } = this.state;

    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <div className="container-fluid bg-white border py-3">
            {isLoadingState ? (
              <Loading></Loading>
            ) : detalleProductosPage.length > 0 ? (
              <React.Fragment>
                <div className="row">

                  <div className="col-md-3 my-2">
                    {/*///////////////////////////////////////////*/}
                    <h3>Filtro Área</h3>
                    <select
                      className="form-control"
                      defaultValue={this.state.areaSeleccionanda}

                      id="areaSelected"
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({ isLoadingState: true });
                        this.setState({ areaSeleccionanda: document.getElementById("areaSelected").value });

                        const { poaActivo, user } = this.props;
                        let monitoreo2 = {
                          poaId: this.state.poaSelected,
                          areaOrganizacionalId: document.getElementById("areaSelected").value,
                        };
                        //alert(monitoreo2.areaOrganizacionalId);
                        this.cargarData(monitoreo2);
                      }}
                    >
                      {this.props.areaOrganizacionales.map((area) => (
                        <option key={area.areaOrganizacionalId} value={area.areaOrganizacionalId}>
                          {area.nombre}
                        </option>

                      ))}
                    </select>
                  </div>
                  {/* ////////////////////////////////////////// */}

                  <div className="col-md-3 my-2">
                    <h3>Trimestre seleccionado</h3>
                    {<Multiselect
                      placeholder="--Seleccione el trimestre--"
                      data={this.state.listaTrimestreState}
                      // id="unidadResponsable"
                      // textField="nombre"
                      // defaultValue={this.state.listaTrimestreState.filter(
                      //   (trimestre) =>
                      //     trimestre ===
                      //     this.state.trimestreActualState
                      // )}
                      defaultValue={this.state.trimestreActualState}
                      onChange={(e) => {
                        this.trimestresRef = [];
                        e.map((trimestre) =>
                          this.trimestresRef.push(
                            trimestre
                          ));
                        if(this.trimestresRef && this.trimestresRef.length>0){
                          if(timeout){
                            clearTimeout(timeout);
                          }
                          timeout= setTimeout(()=>{ this.cambiarSeguimientoPorTrimestre(this.trimestresRef);},2000)
                        }
                       
                      }}
                      minLength={3}
                    />}
                    {/* <select
                      defaultValue={this.state.trimestreActualState}
                      onChange={(e) => {
                        this.cambiarSeguimientoPorTrimestre(e);
                      }}
                      className="form-control"
                    >
                      {this.state.listaTrimestreState.map((trimestre) => (
                        <option key={trimestre}>{trimestre}</option>
                      ))}
                    </select> */}
                  </div>
                  <div className="col-md-1 my-1">
                    <h3>Poa Activo</h3>
                    <select
                      defaultValue={this.state.poaSelected}
                      id="poaSelected"
                      onChange={(e) => {
                        // this.cargarData(this.state.monitoreo,this.state.areaSeleccionanda,e.poaId);

                        e.preventDefault();
                        // console.log(this.props.poas);
                        this.setState({ isLoadingState: true });
                        this.setState({ poaSelected: e.currentTarget.value });

                        // console.log(e.currentTarget.value)
                        // console.log("areaSeleccionanda",this.state.areaSeleccionanda.value)
                        const { poaActivo, user } = this.props;
                        let monitoreo2 = {
                          areaOrganizacionalId: this.state.areaSeleccionanda,
                          poaId: e.currentTarget.value
                        };
                        // alert("monitoreo2",monitoreo2);
                        this.cargarData(monitoreo2);

                      }}
                      className="form-control"
                    >
                      {this.props.poas.map((poa) => (
                        <option key={poa.poaId} value={poa.poaId}>{poa.ano}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4 my-2 float-right">
                    <div className="col-md-6 ">
                      <h3>Productos por paginas</h3>
                      <select
                        className="form-control"
                        onChange={(e) => {
                          e.preventDefault();
                          this.validarPaginacion(1, e.currentTarget.value);
                        }}
                      >
                        <option key="3" value={3}>
                          3
                        </option>
                        <option key="6" value={6}>
                          6
                        </option>
                        <option key="10" value={10}>
                          10
                        </option>
                        <option key="15" value={15}>
                          15
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-1 mt-5 float-right">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        this.reporteMonitoreo();
                      }}
                      className="btn btn-lg btn-success float-right ml-3"
                    >
                      Reporte Monitoreo
                    </button>
                  </div>
                </div>

                <table
                  className="table table-bordered table-sm "
                  style={{ lineHeight: 1 }}
                >
                  <thead>
                    <tr
                      className="text-center"
                      style={{
                        backgroundColor: "#ee2a24",
                        color: "white",
                      }}
                    >
                      <th style={{ width: "20%" }}>1</th>
                      <th style={{ width: "25%" }}>2</th>
                      <th style={{ width: "15%" }}>3</th>
                      <th style={{ width: "" }} colSpan="4">
                        4
                      </th>
                      <th style={{ width: "" }} colSpan="3">
                        5
                      </th>
                    </tr>
                    <tr
                      className="text-center"
                      style={{
                        backgroundColor: "#003876",
                        color: "white",
                      }}
                    >
                      <th style={{ whiteSpace: "normal" }} rowSpan={2}>
                        Producto (s)
                      </th>
                      <th style={{ whiteSpace: "normal" }} rowSpan={2}>
                        Actividad (es)
                      </th>
                      <th style={{ whiteSpace: "normal" }} rowSpan={2}>
                        Medio Verificación
                      </th>

                      <th
                        rowSpan={1}
                        colSpan="4"
                        style={{
                          paddingLeft: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                        }}
                      >
                        Nivel de Ejecución <br />
                        <br />
                        Planificado / Ejecutado
                        <br />
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>E-M</th>
                              <th>A-J</th>
                              <th>J-S</th>
                              <th>O-D</th>
                            </tr>
                          </thead>
                        </table>
                      </th>
                      <th
                        rowSpan={1}
                        colSpan="3"
                        style={{
                          paddingLeft: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                        }}
                      >
                        Seguimiento al trimestre
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>0-49%</th>
                              <th>50-99%</th>
                              <th> 100%</th>
                            </tr>
                          </thead>
                        </table>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {detalleProductosPage.map(
                      (producto) =>
                        producto !== null &&
                        producto !== undefined && (
                          <tr key={producto.producto}>
                            <td
                              rowSpan={producto.colspan}
                              style={{
                                whiteSpace: "normal",
                                verticalAlign: "inherit",
                              }}
                              title={producto.producto.trim()}
                            >
                              {producto.producto.trim()}
                            </td>
                            <td
                              rowSpan={producto.colspan}
                              style={{ padding: 0 }}
                            >
                              <table width="100%">
                                <tbody>
                                  {producto.actividades.map((actividad) => (
                                    <tr key={actividad.actividadId}>
                                      <td
                                        title={actividad.actividad}
                                        style={{
                                          whiteSpace: "normal",
                                          height: 100,
                                          verticalAlign: "inherit",
                                        }}
                                      >
                                        {actividad.actividad}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </td>
                            <td
                              rowSpan={producto.colspan}
                              style={{ padding: 0 }}
                            >
                              <table style={{ tableLayout: "fixed" }}>
                                <tbody>
                                  {producto.actividades.map((actividad) => (
                                    <tr key={actividad.actividadId}>
                                      <td
                                        style={{
                                          whiteSpace: "normal",
                                          height: 100,
                                          width: "100%",
                                          verticalAlign: "inherit",
                                          textAlign: "center",
                                        }}
                                      >
                                        {this.truncateString(
                                          actividad.medioVerificacion
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </td>

                            <td
                              rowSpan={producto.colspan}
                              colSpan="4"
                              style={{ padding: 0 }}
                            >
                              <table width="100%">
                                <tbody>
                                  {producto.actividades.map((actividad) => (
                                    <tr key={actividad.actividadId}>
                                      <td
                                        style={{
                                          whiteSpace: "normal",
                                          height: 100,
                                          width: 45,
                                          verticalAlign: "inherit",
                                          textAlign: "center",
                                        }}
                                      >
                                        {actividad.t1} / {actividad.t1e}
                                      </td>
                                      <td
                                        style={
                                          trimestreActualState < 2
                                            ? {
                                              whiteSpace: "normal",
                                              height: 100,
                                              width: 45,
                                              verticalAlign: "inherit",
                                              textAlign: "center",
                                              backgroundColor: "#ddd6",
                                              cursor: "not-allowed",
                                            }
                                            : {
                                              whiteSpace: "normal",
                                              height: 100,
                                              width: 45,
                                              verticalAlign: "inherit",
                                              textAlign: "center",
                                            }
                                        }
                                      >
                                        {actividad.t2} /{" "}
                                        {trimestreActualState < 2
                                          ? "-"
                                          : actividad.t2e}
                                      </td>
                                      <td
                                        style={
                                          trimestreActualState < 3
                                            ? {
                                              whiteSpace: "normal",
                                              height: 100,
                                              width: 45,
                                              verticalAlign: "inherit",
                                              textAlign: "center",
                                              backgroundColor: "#ddd6",
                                              cursor: "not-allowed",
                                            }
                                            : {
                                              whiteSpace: "normal",
                                              height: 100,
                                              width: 45,
                                              verticalAlign: "inherit",
                                              textAlign: "center",
                                            }
                                        }
                                      >
                                        {actividad.t3} /{" "}
                                        {trimestreActualState < 3
                                          ? "-"
                                          : actividad.t3e}
                                      </td>
                                      <td
                                        style={
                                          trimestreActualState < 4
                                            ? {
                                              whiteSpace: "normal",
                                              height: 100,
                                              width: 45,
                                              verticalAlign: "inherit",
                                              textAlign: "center",
                                              backgroundColor: "#ddd6",
                                              cursor: "not-allowed",
                                            }
                                            : {
                                              whiteSpace: "normal",
                                              height: 100,
                                              width: 45,
                                              verticalAlign: "inherit",
                                              textAlign: "center",
                                            }
                                        }
                                      >
                                        {actividad.t4} /{" "}
                                        {trimestreActualState < 4
                                          ? "-"
                                          : actividad.t4e}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </td>

                            <td
                              rowSpan={producto.colspan}
                              colSpan="3"
                              style={{ padding: 0 }}
                            >
                              <table width="100%">
                                <tbody>
                                  {producto.actividades.map((actividad) => (
                                    <tr key={actividad.actividadId}>
                                      <td
                                        style={{
                                          whiteSpace: "normal",
                                          height: 100,
                                          width: 50,
                                          backgroundColor:
                                            actividad.primerSeguimiento !== ""
                                              ? "#C00000"
                                              : "white",
                                          color: "white",
                                          fontWeight: "bold",
                                          verticalAlign: "inherit",
                                          textAlign: "center",
                                        }}
                                      >
                                        {actividad.primerSeguimiento !== "" &&
                                          Math.round(
                                            (actividad.primerSeguimiento +
                                              Number.EPSILON) *
                                            100
                                          ) /
                                          100 +
                                          "%"}
                                        {/* actividad.primerSeguimiento.toFixed(
                                            2
                                          ) + "%"} */}
                                      </td>
                                      <td
                                        style={{
                                          whiteSpace: "normal",
                                          height: 100,
                                          width: 50,
                                          backgroundColor:
                                            actividad.segundoSeguimiento !== ""
                                              ? "#FFFF00"
                                              : "white",
                                          color: "#666",
                                          fontWeight: "bold",
                                          verticalAlign: "inherit",
                                          textAlign: "center",
                                        }}
                                      >
                                        {actividad.segundoSeguimiento !== "" &&
                                          Math.round(
                                            (actividad.segundoSeguimiento +
                                              Number.EPSILON) *
                                            100
                                          ) /
                                          100 +
                                          "%"}
                                      </td>
                                      <td
                                        style={{
                                          whiteSpace: "normal",
                                          height: 100,
                                          width: 50,
                                          backgroundColor:
                                            actividad.tercerSeguimiento !== ""
                                              ? "#009900"
                                              : "white",
                                          color: "white",
                                          fontWeight: "bold",
                                          verticalAlign: "inherit",
                                          textAlign: "center",
                                        }}
                                      >
                                        {actividad.tercerSeguimiento !== "" &&
                                          Math.round(
                                            (actividad.tercerSeguimiento +
                                              Number.EPSILON) *
                                            100
                                          ) /
                                          100 +
                                          "%"}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
                <ModalLoading
                  modal={this.state.modal}
                  mensaje={this.state.mensaje}
                ></ModalLoading>
                <div className="d-flex justify-content-center">
                  <Pagination
                    prevPageText="ant"
                    nextPageText="sig"
                    firstPageText="prime"
                    lastPageText="ultimo"
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemPerPage}
                    totalItemsCount={this.state.listaProductosState.length}
                    //pageRangeDisplayed={5}
                    onChange={this.validarPaginacion.bind(this)}
                  />
                </div>
              </React.Fragment>
            ) : (
              <h3 className="text-center">
                No tiene productos agregados en el POA {this.state.ano}{" "}
              </h3>
            )}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  poas: state.poas.poas,
  user: state.auth.signed,
  reporteMonitoreoDetalle: state.monitoreosActividad.reporteMonitoreoDetalle,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,//me
});

const mapDispatchToProps = (dispatch) => ({
  getReporteMonitoreoDetalle: (monitoreo) =>
    dispatch(getReporteMonitoreoDetalle(monitoreo)),
  getReporteMonitoreo: (monitoreo) => dispatch(getReporteMonitoreo(monitoreo)),
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),//me
  mostrarPoas: () => dispatch(mostrarPoas()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReporteMonitoreo);
