import {
    CREAR_PROGRAMACION_MONITOREO,
    MOSTRAR_PROGRAMACION_MONITOREO,
    EDITAR_PROGRAMACION_MONITOREO,
    LOADING_PROGRAMACION_MONITOREO,
    PROGRAMACION_MONITOREO_SUCCESS,
    PROGRAMACION_MONITOREO_FAILED
} from "./../types";

import initialState from "./../initial-state";

const { programacionMonitoreos } = initialState;

const programacionMonitoreosReducer = (state = programacionMonitoreos, action) => {

    switch (action.type) {

        case EDITAR_PROGRAMACION_MONITOREO:
        case MOSTRAR_PROGRAMACION_MONITOREO:
        case CREAR_PROGRAMACION_MONITOREO:
            return {
                ...state,
                programacionMonitoreos: action.payload
            }
        case PROGRAMACION_MONITOREO_SUCCESS:
        case PROGRAMACION_MONITOREO_FAILED:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
}
export default programacionMonitoreosReducer;
