import React from "react";

export const EjeEstrategico = props => {
  const { descripcion } = props.EjeEstrategico;
  return (
    <option value={JSON.stringify(props.EjeEstrategico)}>{descripcion}</option>
  );
};

export const ObjetivoEstrategico = props => {
  const { descripcion } = props.objetivoEstrategico;
  return (
    <option value={JSON.stringify(props.objetivoEstrategico)}>
      {descripcion}
    </option>
  );
};
export const Estrategia = props => {
  const { descripcion } = props.estrategia;
  return (
    <option value={JSON.stringify(props.estrategia)}>{descripcion}</option>
  );
};
