import React, { Component } from "react";
import { connect } from "react-redux";
import {updateTipoAsignacion, getTipoAsignacion} from './../../../redux/actions/tipos_asignaciones_action.js'
import Loading from "./../../utils/Loading.jsx";

import { Authorization } from  "./../../../utils/Authorization.js";

import {
  Container
} from "reactstrap";
import tipoAsignaciones from "./index.jsx";

class EditarTipoAsignaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      descripcion: "",
      estatus: false,
      nombre:""
    };
  }
  componentDidMount() {
    const { tipoAsignacionId } = this.props.match.params;
    this.props.getTipoAsignacion(tipoAsignacionId);
  }
  componentWillReceiveProps(nextProps, nextState) {
    const { descripcion, estatus, nombre } = nextProps.tipoAsignacion;
    this.setState({ descripcion, estatus,nombre, isLoading: false });
  }
  nombreRef = React.createRef();
  descripcionRef = React.createRef();
  estatusRef = React.createRef();

  actualizarTipoAsignacion = e => {
    e.preventDefault();
    let editarTipoAsignacion= {
      descripcion: this.descripcionRef.current.value,
      nombre: this.nombreRef.current.value,
      estatus: this.estatusRef.current.value,
      tipoAsignacionId: this.props.tipoAsignacion.tipoAsignacionId
    };
    this.props
      .updateTipoAsignacion(editarTipoAsignacion)
      .then(resultado => {
        this.props.history.push("/admin/tiposAsignaciones/index");
      });
  };

  render() {
    // console.log(this.state)
    const { isLoading, descripcion, estatus, nombre } = this.state;
    return (
      <>
        {!this.props.isLoading ? (
          <div className="pb-6 pt-5 pt-md-7">
            <Container>
              <div className="container bg-white border">
                <form
                  className="p-3"
                  onSubmit={this.actualizarTipoAsignacion}
                >
                  <div className="form-group">
                    <label htmlFor="descripción">Tipo Asignación</label>
                    <input
                      type="text"
                      className="form-control text-dark"
                      id="descripción"
                      placeholder="Medio de Verificación"
                      ref={this.nombreRef}
                      defaultValue={nombre}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="descripción">Descripción</label>
                    <input
                      type="text"
                      className="form-control text-dark"
                      id="descripción"
                      placeholder="Medio de Verificación"
                      ref={this.descripcionRef}
                      defaultValue={descripcion}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="estatus">estatus</label>
                    <select
                      className="custom-select custom-select-sm text-dark"
                      id="estatus"
                      ref={this.estatusRef}
                    >
                      <option value={estatus} defaultValue={estatus}>
                        {estatus ? "Activo" : "Inactivo"}
                      </option>
                      {estatus ? (
                        <option value={false}>Inactivo</option>
                      ) : (
                        <option value={true}>Activo</option>
                      )}
                    </select>
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Editar
                  </button>
                </form>
              </div>
            </Container>
          </div>
        ) : (
          <Loading></Loading>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tipoAsignacion: state.tiposAsignaciones.tipoAsignacion,
    isLoading: state.tiposAsignaciones.isLoading,
    user: state.auth.signed
  };
};

const mapDispatchToProps = dispatch => ({
  updateTipoAsignacion: tipoAsignacion =>
    dispatch(updateTipoAsignacion(tipoAsignacion)),
  getTipoAsignacion: tipoAsignacionId =>
    dispatch(getTipoAsignacion(tipoAsignacionId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(EditarTipoAsignaciones, [
    "Analista Presupuesto",
    "Encargado Presupuesto",
    "Administrador general",
    "MAE"
  ])
);
