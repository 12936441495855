import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  Table,
  Container,
} from "reactstrap";
import { Authorization } from "../../../utils/Authorization";
import Loading from "../../utils/Loading";

import { getAreasOrganizacionales } from "../../../redux/actions/areas-actions";
import { getListaTipoCompras } from "../../../redux/actions/tipos-compras-actions";

import { getRequerimiento, createRequerimientoCompra, updateRequerimientoCompra, getRequerimientoCompraByRequerimiento } from "../../../redux/actions/requerimientos-actions";
import {  getListaComprasDocumentaciones } from "../../../redux/actions/compras-documentaciones-actions";

import { getFechasDocumentaciones, createFechasDocumentacionesCompras, updateFechasDocumentacionesCompras } from "../../../redux/actions/fechas-documentaciones-compra-actions.js";
class DepartamentoAdministrativoLanzamientoCompra extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [],
      isLoadingState:false,
    };
  }

  // Ref
  requerimientoCompraIdRef = React.createRef();
  requerimientoIdRef = React.createRef();
  noRequerimientoRef = React.createRef();
  fechaLanzamientoRef = React.createRef();
  numeroProcesoCompra = React.createRef();
  tipoProcesoCompraIdRef = React.createRef();
  fechaInicioRef = React.createRef();
  fechaFinRef = React.createRef();
  documentacionIdRef = React.createRef();

  componentDidMount() {
    this.cargarDatos();
  }

  cargarDatos = async () => {
    this.setState({isLoadingState:true})
    await this.props.getListaTipoCompras();    
    await this.props.getRequerimiento(this.props.match.params.solicitudCompraId);
    await this.props.getRequerimientoCompraByRequerimiento(this.props.match.params.solicitudCompraId);
    await this.props.getFechasDocumentaciones(this.props.match.params.solicitudCompraId);

    if(null !== this.props.requerimiento.tipoCompras) {
      await this.props.getListaComprasDocumentaciones(this.props.requerimiento.requerimiento.tipoCompras.tipoCompraId);
    } 

    this.setState({ isLoadingState: false });
    this.validarEditar();
  };

  validarGuardarLanzamientoCompras = () => {
    if (this.numeroProcesoCompra.current.value === "") {
      swal("Lanzamiento de compra","Debes de ingresar un el número para el proceso de compra","error");
      return false;
    }

    return true;
  };

  manejarLanzamientoCompras = async(esCreacion) => {
    if (this.validarGuardarLanzamientoCompras()) {
      this.setState({ isLoadingState: true });

      if(esCreacion) {
        let requerimientoCompra = {
          requerimiento_id: this.requerimientoIdRef.current ? this.requerimientoIdRef.current.value : "",
          tipo_compra_id: 2,
          fecha_inicio: this.fechaLanzamientoRef.current ? this.fechaLanzamientoRef.current.value : "",
          numero_proceso_compra: this.numeroProcesoCompra.current ? this.numeroProcesoCompra.current.value : ""
        }

        let fechaLanzamientoCompra = {
          documentacionFechas: this.state.formData,
          tipo_compra_id: this.tipoProcesoCompraIdRef.current ? this.tipoProcesoCompraIdRef.current.value : "",
          requerimiento_id: this.requerimientoIdRef.current ? this.requerimientoIdRef.current.value : "",
        }
  
        const { documentacionFechas, tipo_compra_id, requerimiento_id } = fechaLanzamientoCompra;
  
        for (const value of Object.values(documentacionFechas)) {
            const { documentacion_id, fecha_inicio, fecha_fin } = value;
  
            // utilizando un loop, insertar todos los datos a fecha documentacion tipo compra.
            const cuerpoFechasDocumentacion = {
                requerimiento_id,
                tipo_compra_id,
                documentacion_id,
                fecha_inicio,
                fecha_fin
            };
  
            try {
              await this.props.createFechasDocumentacionesCompras(cuerpoFechasDocumentacion);
            } catch (error) {
              swal("Error", "Ocurrió un error al lanzar el proceso de compra.", "error");
              return;
            }
        }
  
        // Guardar el requerimiento de compra
        try {
          await this.props.createRequerimientoCompra(requerimientoCompra);
          swal("Lanzamiento de compras", "Ha sido lanzado el proceso de compra correctamente.", "success");
        } catch (error) {
          swal("Error", "Ocurrió un error al lanzar el proceso de compra.", "error");
          return;
        }
  
      } else {
        let requerimientoCompra = {
          id: this.requerimientoCompraIdRef.current && this.requerimientoCompraIdRef.current.value,
          requerimiento_id: this.requerimientoIdRef.current && this.requerimientoIdRef.current.value,
          tipo_compra_id: this.tipoProcesoCompraIdRef.current && this.tipoProcesoCompraIdRef.current.value,
          fecha_inicio: this.fechaLanzamientoRef.current && this.fechaLanzamientoRef.current.value,
          numero_proceso_compra: this.numeroProcesoCompra.current &&   this.numeroProcesoCompra.current.value
        }
  
        let fechaLanzamientoCompra = {
          documentacionFechas: this.state.formData,
          tipo_compra_id: this.tipoProcesoCompraIdRef.current ? this.tipoProcesoCompraIdRef.current.value : "",
          requerimiento_id: this.requerimientoIdRef.current ? this.requerimientoIdRef.current.value : "",
        }
  
        const { documentacionFechas, tipo_compra_id, requerimiento_id } = fechaLanzamientoCompra;
  
        for (const value of Object.values(documentacionFechas)) {
            const { requerimiento_compra_id, documentacion_id, fecha_inicio, fecha_fin } = value;
  
            // utilizando un loop, insertar todos los datos a fecha documentacion tipo compra.
            const cuerpoFechasDocumentacion = {
                id : requerimiento_compra_id,
                requerimiento_id,
                tipo_compra_id,
                documentacion_id,
                fecha_inicio,
                fecha_fin
            };
  
            try {
              await this.props.updateFechasDocumentacionesCompras(cuerpoFechasDocumentacion);
            } catch (error) {
              swal("Error", "Ocurrió un error al lanzar el proceso de compra.", "error");
              return;
            }
        }
  
        // Actualizar el requerimiento de compra
        try {
          await this.props.updateRequerimientoCompra(requerimientoCompra);
          swal("Lanzamiento de compras", "Ha sido lanzado el proceso de compra correctamente.", "success");
        } catch (error) {
          swal("Error", "Ocurrió un error al lanzar el proceso de compra.", "error");
          return;
        }
      }

      setTimeout(() => {
        this.irAtras();
      }, 2000);
    }
  }  

  obtenerDocumentacionData = (event) => {
    event.preventDefault();

    const { id, name, value } = event.target;

    // Obtener el id de la documentacion
    const documentacionId = id.split('-')[1];

    const { formData } = this.state;
    const requerimientoCompraId = event.target.getAttribute('requerimientocompraid');

    // Actualizar los valores existentes del formulario
    const actualizadoFormData = {
      ...formData,
      [documentacionId]: {
        ...formData[documentacionId],
        [name]: value,
        requerimiento_compra_id: requerimientoCompraId,
        documentacion_id: documentacionId,
      }
    };

    this.setState({ formData: actualizadoFormData });

  }

  validarEditar = () => {
    let filter = ['Responsables de compras', 'Analista de Compras'];
    let resultado = this.props.user.roles.some(i => filter.includes(i));
  
    this.setState({ editarForm: !resultado });
  }

  irAtras = () => {
    this.props.history.goBack();
  }

  render() {
    const { requerimiento, comprasDocumentaciones, requerimientoCompra, fechasDocumentacionesCompras, tiposCompras } = this.props;
    const {  requerimientoId, numeroSolicitud, tipoCompras } = requerimiento.requerimiento;
    const { id, fechaInicio, numeroProcesoCompra } = requerimientoCompra;

    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              { this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (
                <div className="row">
                    <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-6 pb-4" >
                            <h3>Detalles</h3>
                            <hr style={{ marginTop: '0rem' }}/>
                            <input
                              type="hidden"
                              ref={this.requerimientoCompraIdRef}
                              defaultValue={id}
                            />
                            <input
                              type="hidden"
                              ref={this.requerimientoIdRef}
                              defaultValue={requerimientoId}
                            />
                            <label className="text-left" htmlFor="noRequerimiento">No. Requerimiento</label>
                            <input
                              type="text"
                              className="form-control text-dark"
                              id="noRequerimiento"
                              ref={this.noRequerimientoRef}
                              defaultValue={numeroSolicitud}
                              required
                              disabled={true}
                            />
                            <div className="form-group mt-4">
                                <label htmlFor="tipoProcesoCompra">Tipo proceso de compra</label>
                                <input 
                                id="tipoProcesoCompra"
                                  type="hidden"
                                  ref={this.tipoProcesoCompraIdRef}
                                  value={ tipoCompras !== null && tipoCompras !== undefined ? tipoCompras.tipoCompraId : ''}
                                />
                                <input 
                                  className="form-control"
                                  type="text"
                                  disabled
                                  value={ tipoCompras !== undefined && tipoCompras !== null && tipoCompras.nombre }
                                />
                            </div>

                            <div className="mt-4">
                              <label className="text-left " htmlFor="fechaLanzamiento">Fecha de Lanzamiento</label>
                              <input
                                  type="date"
                                  className="form-control text-dark"
                                  id="fechaLanzamiento"
                                  ref={this.fechaLanzamientoRef}
                                  required
                                  readOnly={!this.state.editarForm}
                                  defaultValue={(fechaInicio !== undefined) ? fechaInicio.split("T")[0] : new Date().toISOString().substr(0, 10)}
                              />
                            </div>

                            <div className="mt-4">
                              <label className="text-left" htmlFor="numeroProcesoCompra">Número de Proceso de Compra</label>
                              <input
                                  type="text"
                                  className="form-control text-dark"
                                  id="numeroProcesoCompra"
                                  ref={this.numeroProcesoCompra}
                                  required
                                  defaultValue={numeroProcesoCompra}
                                  readOnly={!this.state.editarForm}
                              />
                            </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-5">
                    <h1 className="text-left mb-3">Detalles de la Compra</h1>
                    <Table>
                        <thead>
                          <tr>
                            <th>Documentación</th>
                            <th>Fecha de inicio</th>
                            <th>Fecha Fin</th>
                          </tr>
                        </thead>
                        <tbody>
                          { null !== comprasDocumentaciones && comprasDocumentaciones.map((documentacion, index) => {
                            // Buscar los valores guardados en fechasDocumentacionesCompras
                            const fechaDocumento = fechasDocumentacionesCompras.find(item => item.documentacion.documentacionId === documentacion.documentaciones.documentacionId);
                            // Setear los valores encontrados
                            const id = fechaDocumento ? fechaDocumento.id : ''; 
                            const fechaInicioVal = fechaDocumento ? fechaDocumento.fechaInicio.substr(0, 10) : ''; 
                            const fechaFinVal = fechaDocumento ? fechaDocumento.fechaFin.substr(0, 10) : ''; 
                            
                            return (
                              <tr key={index}>
                               
                                <td style={{ display: "none" }}>
                                  <input 
                                    type="hidden" 
                                    value={documentacion.documentaciones.documentacionId} 
                                  />
                                </td>
                                <td >{documentacion.documentaciones.nombre}</td>
                                <td>
                                  <input
                                    type="date"
                                    className="form-control text-dark"
                                    id={`fecha_inicio-${documentacion.documentaciones.documentacionId}`}
                                    requerimientocompraid={id}
                                    ref={this.fechaInicioRef}
                                    required
                                    onChange={this.obtenerDocumentacionData }
                                    name="fecha_inicio"
                                    defaultValue={fechaInicioVal}
                                    readOnly={!this.state.editarForm}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    className="form-control text-dark"
                                    id={`fecha_fin-${documentacion.documentaciones.documentacionId}`}
                                    requerimientocompraid={id}
                                    ref={this.fechaFinRef}
                                    required
                                    onChange={this.obtenerDocumentacionData }
                                    name="fecha_fin"
                                    defaultValue={fechaFinVal}
                                    readOnly={!this.state.editarForm}
                                  />
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>

                      <div className="row pb-3 pt-5">
                        <div className="col-4 m-auto">
                          <button
                            onClick={() => this.irAtras()}
                            className="btn btn-danger"
                          >
                            CANCELAR
                          </button>
                          <button 
                            onClick={() => 
                                (requerimientoCompra && requerimientoCompra.requerimiento && requerimientoCompra.requerimiento.requerimientoId) == this.props.match.params.solicitudCompraId 
                                    ? this.manejarLanzamientoCompras(false) 
                                    : this.manejarLanzamientoCompras(true)} 
                            className="btn btn-primary"
                        >
                            { 
                                requerimientoCompra !== null && 
                                requerimientoCompra.requerimiento && 
                                requerimientoCompra.requerimiento.requerimientoId == this.props.match.params.solicitudCompraId 
                                    ? 'ACTUALIZAR' 
                                    : 'GUARDAR' 
                            }
                        </button>
                        </div>
                      </div>

                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
  tiposCompras: state.tiposCompras.tiposCompras,
  requerimiento: state.requerimientos.requerimiento,
  requerimientoCompra: state.requerimientos.requerimientoCompra,
  comprasDocumentaciones: state.comprasDocumentaciones.comprasDocumentaciones,
  fechasDocumentacionesCompras: state.fechasDocumentacionesCompras.fechasDocumentacionesCompras,
  isLoading: state.fechasDocumentacionesCompras.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
  getListaTipoCompras: () => dispatch(getListaTipoCompras()),
  getRequerimiento: (requerimientoId) => dispatch(getRequerimiento(requerimientoId)),
  getListaComprasDocumentaciones: (tipoCompraId) => dispatch(getListaComprasDocumentaciones(tipoCompraId)),
  createFechasDocumentacionesCompras: (data) => dispatch(createFechasDocumentacionesCompras(data)),
  updateFechasDocumentacionesCompras: (data) => dispatch(updateFechasDocumentacionesCompras(data)),
  createRequerimientoCompra: (requerimientoCompra) => dispatch(createRequerimientoCompra(requerimientoCompra)),
  getRequerimientoCompraByRequerimiento: (requerimientoId) => dispatch(getRequerimientoCompraByRequerimiento(requerimientoId)),
  getFechasDocumentaciones: (requerimientoId) => dispatch(getFechasDocumentaciones(requerimientoId)),
  updateRequerimientoCompra: (requerimientoCompraId) => dispatch(updateRequerimientoCompra(requerimientoCompraId))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(DepartamentoAdministrativoLanzamientoCompra, [
    "Administrador general",
    "Responsables de compras",
    "Analista Compras"
  ])
);
