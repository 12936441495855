import React, { Component } from "react";
import Select from "react-select";
import Async from "react-select/async";
import Loading from "./../utils/Loading";
import numbro from "numbro";
import { Multiselect } from "react-widgets";
import { meses } from "../../utils/mesesByTrimestre";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import swal from "sweetalert";

export const ModalDetalleArticulo = props => {

    let mesesCalendarSelectedRef = React.createRef();
    let mesesCalendar = [];

    const validarMesesLista = () => {
      let  mesesHabiles = [];
        mesesHabiles = [...mesesHabiles,...meses["t1"],...meses["t2"],...meses["t3"],...meses["t4"]]
        mesesCalendar= mesesHabiles
    }

      validarMesesLista();
    const toggle = () => props.ActivarModalDetalleArticulo();

    const handleCuenta = e => {
        e.preventDefault();
    };

    const handleInsumo = (newValue, e) => {
        // e.preventDefault();

        //   clearTimeout(timer);
        //   timer = setTimeout(() => {
        //     // const inputValue = newValue.replace(/\W/g, "");
        //     const inputValue = newValue;

        //     if (inputValue !== "") {
        //       props.consultarInsumosActividad(
        //         props.state.cuentaFiltro,
        //         inputValue,
        //         props.state.operacionFiltro
        //       );
        //     }
        //   }, 2000);

    };

    const converMesCompraArray = () => {
        mesesCalendarSelectedRef = [];
       if (props.presupuesto.mesCompra) {
         let array = props.presupuesto.mesCompra.split(',');
         // console.log(array);
         array.forEach(element => {
           mesesCalendarSelectedRef.push(parseInt(element));
           // console.log(parseInt(element))
         });
   
       }else{
         mesesCalendarSelectedRef =[];
       }
       // console.log("mesesCalendarSelectedRef", mesesCalendarSelectedRef)
     }
     converMesCompraArray();
    //   const calcularValorInsumo = () => {
    //     if (
    //       cantidadRef.current !== null &&
    //       frecuenciaRef.current !== null &&
    //       valorRef.current !== null &&
    //       cantidadRef.current.value !== "" &&
    //       frecuenciaRef.current.value !== "" &&
    //       valorRef.current !== ""
    //     ) {
    //       number =
    //         cantidadRef.current.value *
    //         frecuenciaRef.current.value *
    //         valorRef.current.value;
    //       totalRef.current.value = number;
    //     } else {
    //       if (totalRef.current != null) {
    //         totalRef.current.value = "";
    //       }
    //     }
    //   };

    //   const guardarPresupuesto = () => {
    //     // console.log("probadno el nuevio",mesesCalendarSelectedRef.toString());
    //     if (validarDatos()) {
    //       let presupuesto = {
    //         descripcion: insumoRef.current.value,
    //         unidadMedida: props.state.unidadMedidaInsumo,
    //         valor: valorRef.current.value,
    //         cuenta: noCuentaRef.current.value,
    //         cantidad: cantidadRef.current.value,
    //         frecuencia: frecuenciaRef.current.value,
    //         insumo: insumoRef.current.value,
    //         referenciaInsumo: referenciaInsumoRef.current.value,
    //         insumoId: insumoIdRef.current.value,
    //         subclase: subclaseRef.current.value,
    //         mesCompra: mesesCalendarSelectedRef.toString(),
    //         presupuestoId: 0
    //       };
    //       props.guardarInsumos(presupuesto);
    //     }
    //   };

    //   const validarDatos = () => {
    //     if (insumoRef.current === null || insumoRef.current.value === "") {
    //       swal("Insumos", "El insumo es un valor requerido", "error");

    //       return false;
    //     }

    //     if (cantidadRef.current === null || cantidadRef.current.value === "") {
    //       swal("Insumos", "La cantidad es un valor requerido", "error");

    //       return false;
    //     }

    //     if (frecuenciaRef.current === null || frecuenciaRef.current.value === "") {
    //       swal("Insumos", "La frecuencia es un valor requerido", "error");

    //       return false;
    //     }
    //     return true;
    //   };

    const { modalDetalleArticulo, presupuesto, precioCotizacion } = props;
   // console.log("presupuesto", presupuesto);
    return (
        <Modal isOpen={modalDetalleArticulo} toggle={toggle} size="lg" backdrop="static">
            <ModalHeader className="block-example border-bottom " toggle={toggle}>
                Detalle Insumo
            </ModalHeader>
            <ModalBody className="block-example border-bottom ">
                <form>
                    <div className="form-row mt-1">
                        <div className="col-12">
                            <label>Insumo</label>
                            <input
                                type="text"
                                className="form-control text-dark"
                                placeholder="Insumo"
                                readOnly
                                value={presupuesto.insumo}
                            />
                        </div>
                    </div>
                    <div className="form-row mt-1">
                        <div className="col-3">
                            <div className="form-group ">
                                <label htmlFor="cantidad">Cantidad</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control  text-dark"
                                        id="cantidad"
                                        placeholder="Cantidad"
                                        readOnly
                                        value={presupuesto.cantidad}
                                    />

                                    <div
                                        className="input-group-append"
                                        title="Unidad de Medida"
                                    >
                                        <span
                                            className="input-group-text text-dark  "
                                            style={{
                                                backgroundColor: "#e9ecef",
                                                borderLeft: "2px solid  #bebaba"
                                            }}
                                        >
                                            {presupuesto.unidadMedidaLabel}
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group ">
                                <label htmlFor="frecuencia">Frecuencia</label>
                                <input
                                    type="text"
                                    className="form-control  text-dark"
                                    id="frecuencia"
                                    placeholder="Frecuencia"
                                    readOnly
                                    value={presupuesto.frecuencia}
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group ">
                                <label htmlFor="frecuencia">Valor</label>
                                <input
                                    type="text"
                                    className="form-control  text-dark"
                                    id="valor"
                                    placeholder="Valor"
                                    readOnly
                                    value={'RD$ '+numbro(presupuesto.valor).format({thousandSeparated:true})}
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group ">
                                <label htmlFor="total">Total</label>{" "}
                                <input
                                    type="text"
                                    className="form-control  text-dark"
                                    id="total"
                                    placeholder="Total"
                                    readOnly
                                   value={'RD$ '+numbro(presupuesto.totalCosto).format({thousandSeparated:true})}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-row mt-1">
                        <div className="col-4">
                            <div className="form-group">

                                <label htmlFor="unidadResponsable">
                                    Mes Compra Insumo
                                </label>
                                {presupuesto.totalCosto ? (<Multiselect

                                    disabled={true}

                                    placeholder="--Seleccione el Mes--"
                                    data={mesesCalendar}
                                    valueField="id"
                                    id="mesInsumo"
                                    textField="value"
                                    defaultValue={mesesCalendarSelectedRef}
                                    onChange={(e) => {
                                        mesesCalendarSelectedRef = [];
                                        e.map((mes) =>
                                            mesesCalendarSelectedRef.push(
                                                mes.id
                                            )
                                        );
                                        // console.log(mesesCalendarSelectedRef);
                                    }}
                                // minLength={3}

                                />) : (<Multiselect
                                    readOnly
                                    disabled={true}

                                    placeholder="--Seleccione el Mes--"
                                    data={meses}
                                    defaultValue={mesesCalendarSelectedRef}
                                    valueField="id"
                                    id="mesInsumo"
                                    textField="value"

                                // minLength={3}

                                />)}

                                {/* <Multiselect
                  placeholder="--Seleccione el Mes--"
                  data={mesesCalendar}
                  valueField="id"
                  id="mesInsumo"
                  textField="value"
                  defaultValue={[]}
                  onChange={(e) => {
                    mesesCalendarSelectedRef = [];
                    e.map((mes) =>
                      mesesCalendarSelectedRef.push(
                        mes.id
                      )
                    );
                    // console.log(mesesCalendarSelectedRef);
                  }}
                // minLength={3}
                /> */}
                            </div>
                        </div>
                        <div className="col-5">
                            <div className="form-group ">
                                <label htmlFor="referenciaInsumo">Referencia Insumo</label>
                                <textarea rows={1} readOnly value={presupuesto.referenciaInsumo} className="form-control  text-dark" id="referenciaInsumo" placeholder="Referencia Insumo" ></textarea>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group ">
                                <label htmlFor="precioCotizacion">Precio Cotización</label>
                                <textarea rows={1} readOnly value={'RD$ '+numbro(precioCotizacion ? precioCotizacion : 0).format({thousandSeparated:true})} className="form-control  text-dark" id="precioCotizacion" placeholder="Precio cotizacion"/>
                            </div>
                        </div>
                    </div>
                    {/* <div className="form-row mt-1">
            <div className="col-12">
              <div className="form-group ">
                <label htmlFor="referenciaInsumo">Referencia Insumo</label>
                <textarea rows={1}  onBlur={e => {
                     if(e.target && e.target.value){
                       referenciaInsumoRef.current.value = e.target.value
                     }
                }} className="form-control  text-dark" id="referenciaInsumo" placeholder="Referencia Insumo"   ref={referenciaInsumoRef} ></textarea>
              </div>
            </div>
        
          </div> */}
                    <div className="form-row mt-1">
                        <div className="col-2">
                            <div className="form-group ">
                                <label htmlFor="noCuenta">Cuenta</label>
                                <input
                                    type="text"
                                    className="form-control  text-dark"
                                    id="noCuenta"
                                    placeholder="Cuenta"
                                    readOnly
                                    value={presupuesto.cuenta}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-group ">
                                <label htmlFor="subclase">Subclase</label>
                                <input
                                    type="text"
                                    className="form-control  text-dark"
                                    id="subclase"
                                    placeholder="Subclase"
                                    readOnly
                                    value={presupuesto.subclase}
                                />
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="form-group ">
                                <label htmlFor="unidadMedidaLabel">Unidad de Medida</label>
                                <input
                                    type="text"
                                    className="form-control  text-dark"
                                    id="unidadMedidaLabel"
                                    placeholder="Unidad de Medida"
                                    value={presupuesto.unidadMedidaLabel}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="col-4" hidden>
                            <div className="form-group ">
                                <label htmlFor="insumoId">InsumoId</label>
                                <input
                                    type="text"
                                    className="form-control  text-dark"
                                    id="insumoId"
                                    placeholder="insumoId"
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="form-row mt-1">
                        <div className="col-12">
                            <div className="form-group ">
                                <label htmlFor="noCuenta">Definición</label>
                               
                                <textarea rows={2} className="form-control  text-dark" id="definicion" placeholder="Definición" value={presupuesto.insumos.catalogoClasificador.definicion} readOnly></textarea>
                            </div>
                        </div>

                    </div> */}
                </form>
            </ModalBody>
            <ModalFooter className="block-example border-bottom ">
                <button
                    className="btn btn-danger"
                    onClick={e => {
                        e.preventDefault();
                        props.ActivarModalDetalleArticulo();
                    }}
                >
                    Cerrar
                </button>
            </ModalFooter>
        </Modal>
    );
};
