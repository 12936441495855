/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

import { signOutUser } from "./../../redux/actions/auth-actions";

class AdminNavbar extends React.Component {
  render() {
    if (!this.props.user) {
      this.props.history.push("/auth/login");
    }
    const { nombre } = this.props.user;
    //console.log("menu");
    //console.log(this.props);
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block"
              to={this.props.location.pathname}
            >
              {this.props.brandText}
            </Link>

            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      {
                        <img
                          alt="..."
                          src={
                            "https://lh5.googleusercontent.com/-b0-k99FZlyE/AAAAAAAAAAI/AAAAAAAAAAA/eu7opA4byxI/photo.jpg?sz=120"
                          }
                        />
                      }
                    </span>
                    */
                    <Media className="ml-2 d-none d-lg-block">
                      <span
                        style={{
                          color: "#32325d",
                          textTransform: "uppercase"
                        }}
                        className="mb-0 text-black text-sm font-weight-bold"
                      >
                        {nombre}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Cuenta</h6>
                  </DropdownItem><DropdownItem divider />
                  <DropdownItem
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      this.props.signOutUser();
                      this.props.history.push("/auth/login");
                    }}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}
const mapStateToProps = state => ({
  user: state.auth.signed
});

const mapDispatchToProps = dispatch => ({
  signOutUser: () => dispatch(signOutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
