import {
    LOADING_MONITOREO_MEDIOS_VERIFICACION,
    MONITOREO_MEDIOS_VERIFICACION_SUCCEEDED,
    MOSTRAR_MONITOREO_MEDIOS_VERIFICACION,
    MONITOREO_MEDIOS_VERIFICACION_FAILED
} from "./../types"

import initialState from "./../initial-state";

const {monitoreoMediosVerificacion} = initialState

const MonitoreMediosVerificacionReducer =(state = monitoreoMediosVerificacion, action)=>{

    switch(action.type){
        case LOADING_MONITOREO_MEDIOS_VERIFICACION:
            return{
                ...state,
                isLoading: !state.isLoading
            }
        case MOSTRAR_MONITOREO_MEDIOS_VERIFICACION:
          
            return{
                ...state,
                monitoreoMediosVerificacion: action.payload
            }
        case MONITOREO_MEDIOS_VERIFICACION_SUCCEEDED:
            return{
                ...state
            }
        case MONITOREO_MEDIOS_VERIFICACION_FAILED:
            return{
                ...state
            }
        default :
        return state;
    }
}

export default MonitoreMediosVerificacionReducer;
