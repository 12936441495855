import React, { Component } from "react";
import { connect } from "react-redux";
import { setPresupuestoAsignadoInstitucion, getListaPresupuestosAsignadosInstitucion } from "./../../../redux/actions/presupuesto-asignado-institucion-action";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";
import { DateTimePicker } from "react-widgets";
import "react-widgets/dist/css/react-widgets.css";
import { Authorization } from "./../../../utils/Authorization";
import Loading from "./../../utils/Loading";
import swal from "sweetalert";
import { TextInputMask,customMask,moneyMask } from 'react-masked-text'
import { dollarMoneyMask } from "../../../utils/moneyMask";

import {
    Container
} from "reactstrap";
import moment from "moment";

class CreatePresupuestoAsignadoInstitucion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            presupuestoAsignadoInstitucionState: {},
            ano: 0,
            fechaCierre: new Date(new Date().getFullYear() + 2, 0, 1),
            monto: 0,
            status: true,
            loading: false,
            loadingButton: false,
        };
    }
    montoRef = React.createRef();

    crearPresupuestoAsignadoInstitucion = async (e) => {
        e.preventDefault();
        let valor =0;
        if(this.montoRef.current !== null && this.montoRef.current !== undefined){
           valor= parseFloat(this.montoRef.current.value.replace("RD$: ","").replaceAll(',', ''))
        }

        let nuevoPresupuestoAsignadoInstitucion = {
            ano: Moment(this.state.ano).year(),
            fechaCierre: this.state.fechaCierre,
            monto:valor,                
            fecha: new Date()
        };
        
        // console.log(nuevoPresupuestoAsignadoInstitucion);
        this.setState({
            presupuestoAsignadoInstitucionState: nuevoPresupuestoAsignadoInstitucion,
            loadingButton: true,
            monto: nuevoPresupuestoAsignadoInstitucion.monto,
            ano: nuevoPresupuestoAsignadoInstitucion.ano
        });

        if (await this.validarPresupuestoAsignado()) {
            if (nuevoPresupuestoAsignadoInstitucion.monto > 0 && nuevoPresupuestoAsignadoInstitucion.ano) {
                this.props.setPresupuestoAsignadoInstitucion(nuevoPresupuestoAsignadoInstitucion);

                this.setState({ loading: true });
                //redirecciona a la raiz
                setTimeout(() => {
                    this.setState({
                        loading: false,
                        loadingButton: false,
                    });
                    this.props.history.push("/admin/presupuestosAsignadosInstitucion/index");
                }, 3000);
            } else {
                swal("Presupuesto Asignado", "Debe ingresar los valores a crear", "error");
            }
        }
    };

    validarPresupuestoAsignado = async () => {
        await this.props.getListaPresupuestosAsignadosInstitucion();

        let resultado = this.props.presupuestosAsignadosInstitucion.filter(
            (presupuestoAsignadoInstitucion) => presupuestoAsignadoInstitucion.ano === this.state.ano
        );
        if (Object.keys(resultado).length > 0) {
            this.setState({
                loadingButton: false,
            });

            swal("Presupuesto Asignado Institución", `El presupuesto del ${resultado[0].ano} existe, favor validar`, "error");
            return false;
        }
        let validateYear = moment(new Date()).year()
        if (this.state.ano - validateYear > 1) {
            this.setState({
                loadingButton: false,
            });

            swal("Presupuesto Asignado Institución", `El presupuesto solo puede ser creado para el año en curso o el siguiente`, "error");

            return false;
        }

        return true;
    };


    render() {
        
        const { loading } = this.state;
        const defaultMaskOptions = {
            prefix: 'RD$:',
            suffix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ',',
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: 2, // how many digits allowed after the decimal
            integerLimit: 12, // limit length of integer numbers
            allowNegative: false,
            allowLeadingZeroes: false,
        }
        Moment.locale("en");
        momentLocalizer();
        let anoRef = (e) => {
            this.setState({
                ano: e,
            });
        };
        let fechaCierreRef = (e) => this.setState({ fechaCierre: e });
        let datePickerAno = (
            <DateTimePicker
                footer={false}
                max={new Date(new Date().getFullYear() + 1, 0, 1)}
                time={false}
                defaultView="decade"
                onChange={anoRef}
                ref={this.anoRef}
            />
        );
        let datePickerFechaCierre = (
            <DateTimePicker
                footer={false}
                min={new Date(new Date().getFullYear() + 2, 0, 1)}
                defaultValue={new Date(new Date().getFullYear() + 2, 0, 1)}
                time={false}
                onChange={fechaCierreRef}
                ref={this.fechaCierreRef}
            />
        );



        return (
            <>
                {!loading ? (
                    <div className="pb-6 pt-5 pt-md-7">
                        <Container>
                            <div className="container bg-white border">
                                <form className="p-3" onSubmit={this.crearPresupuestoAsignadoInstitucion}>
                                    {/* <div className="form-group">
                    <label htmlFor="descripción">Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      id="descripción"
                      placeholder="Nombre de la meta del año"
                      ref={this.nombreRef}
                    />
                  </div> */}
                                    <div className="row">
                                        <div className="form-group col-md-6 ">
                                            <label htmlFor="ano">Año</label>
                                            <DateTimePicker
                                                footer={false}
                                                max={new Date(new Date().getFullYear() + 1, 0, 1)}
                                                min={new Date()}
                                                time={false}
                                                defaultView="decade"
                                                ref={this.anoRef}
                                                onChange={anoRef}
                                            />
                                        </div>
                                        <div className="form-group col-md-6 ">
                                            <label htmlFor="fechaCierre">Fecha Cierre</label>
                                            <DateTimePicker
                                                footer={false}
                                                defaultValue={
                                                    new Date(new Date().getFullYear() + 2, 0, 1)
                                                }
                                                time={false}
                                                onChange={fechaCierreRef}
                                                ref={this.fechaCierreRef}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6 ">
                                            <label htmlFor="monto">Monto</label>

                                            <TextInputMask
                                                ref={this.montoRef}
                                                mask={dollarMoneyMask()}
                                            />
                                            {/* <input
                        type="text"
                        className="form-control text-dark"
                        id="monto"
                        placeholder="Monto Presupuesto Asignado"
                        defaultValue={
                          Object.keys(this.state.monto) > 0
                            ? this.state.monto
                            : ""
                        }
                        ref={this.montoRef}
                      /> */}
                                        </div>
                                    </div>

                                    <button
                                        type="submit"
                                        disabled={this.state.loadingButton}
                                        className="btn btn-primary"
                                    >
                                        Guardar
                                    </button>
                                </form>
                            </div>
                        </Container>
                    </div>
                ) : (
                    <Loading></Loading>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        presupuestosAsignadosInstitucion: state.presupuestosAsignadosInstitucion.presupuestosAsignadosInstitucion,
        user: state.auth.signed,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPresupuestoAsignadoInstitucion: (presupuestoAsignadoInstitucion) => dispatch(setPresupuestoAsignadoInstitucion(presupuestoAsignadoInstitucion)),
    getListaPresupuestosAsignadosInstitucion: () => dispatch(getListaPresupuestosAsignadosInstitucion())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps //{ Login }
)(Authorization(CreatePresupuestoAsignadoInstitucion, ["Administrador general", "Encargado Presupuesto", "MAE"]));
