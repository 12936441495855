import {
  MOSTRAR_PROVEEDORES,
  MOSTRAR_PROVEEDOR,
  CREAR_PROVEEDOR,
  EDITAR_PROVEEDOR,
  ELIMINAR_PROVEEDOR,
  LOADING_PROVEEDOR,
  PROVEEDOR_SUCCEEDED,
  PROVEEDOR_FAILED,
  MOSTRAR_PROVEEDORES_INSUMOS_ADJUDICADOS,
  LOADING_PROVEEDOR_REQUERIMIENTO_COMPRA,
  CREAR_PROVEEDOR_REQUERIMIENTO_COMPRA,
  EDITAR_PROVEEDOR_REQUERIMIENTO_COMPRA,
  ELIMINAR_PROVEEDOR_REQUERIMIENTO_COMPRA,
  MOSTRAR_PROVEEDORES_REQUERIMIENTOS_COMPRAS,
  PROVEEDOR_REQUERIMIENTO_COMPRA_SUCCEEDED,
  PROVEEDOR_REQUERIMIENTO_COMPRA_FAILED,
} from "../types";
import { api_base } from "../../config";
import { getData } from "../../utils/api-client";

export const getProveedores = () => async (dispatch) => {
 let url = api_base + `api/v1/proveedores`;
 
  dispatch({ type: LOADING_PROVEEDOR });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_PROVEEDORES, payload: resultado.data });
      dispatch({ type:   PROVEEDOR_SUCCEEDED,});
    })
    .catch((error) => {
      if(error) {
        dispatch({ type: PROVEEDOR_FAILED });
      }
    });
};

export const getProveedor = (proveedorId) => async (dispatch) => {
  let url = api_base + `api/v1/proveedores/${proveedorId}`;
  dispatch({ type: LOADING_PROVEEDOR });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_PROVEEDOR, payload: resultado.data });
      dispatch({ type: PROVEEDOR_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: PROVEEDOR_FAILED });
      }
    });
};

export const createProveedor = (proveedor) => async (dispatch) => {
  let url = api_base + "api/v1/proveedores/crear";

  dispatch({ type: LOADING_PROVEEDOR });
  await getData
    .post(url, JSON.stringify(proveedor))
    .then((resultado) => {
      dispatch({
        type: CREAR_PROVEEDOR,
        payload: resultado.data,
      });
      dispatch({ type: PROVEEDOR_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: PROVEEDOR_FAILED });
      }
    });
};

export const createProveedorPorRequerimientoCompra = (data) => async (dispatch) => {
  let url = api_base + "api/v1/proveedorrequerimientocompra/crear";

  dispatch({ type: LOADING_PROVEEDOR });
  await getData
    .post(url, JSON.stringify(data))
    .then((resultado) => {
      dispatch({
        type: CREAR_PROVEEDOR_REQUERIMIENTO_COMPRA,
        payload: resultado.data,
      });
      dispatch({ type: PROVEEDOR_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: PROVEEDOR_FAILED });
      }
    });
};

export const getProveedoresInsumosAdjudicados = () => async (dispatch) => {
  let url = api_base + `api/v1/adjudicados`;
  
   dispatch({ type: LOADING_PROVEEDOR });
   await getData
     .get(url)
     .then((resultado) => {
       dispatch({ type: MOSTRAR_PROVEEDORES_INSUMOS_ADJUDICADOS, payload: resultado.data });
       dispatch({ type:   PROVEEDOR_SUCCEEDED,});
     })
     .catch((error) => {
       if(error) {
         dispatch({ type: PROVEEDOR_FAILED });
       }
     });
 };

 export const getProveedorPorRequerimientoCompra = () => async (dispatch) => {
  let url = api_base + `api/v1/proveedorrequerimientocompra`;
  
  dispatch({ type: LOADING_PROVEEDOR_REQUERIMIENTO_COMPRA });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_PROVEEDORES_REQUERIMIENTOS_COMPRAS, payload: resultado.data });
      dispatch({ type:   PROVEEDOR_REQUERIMIENTO_COMPRA_SUCCEEDED,});
    })
    .catch((error) => {
      if(error) {
        dispatch({ type: PROVEEDOR_REQUERIMIENTO_COMPRA_FAILED });
      }
    });
};

export const deleteProveedorRequerimientoCompra = (proveedorRequerimientoCompraId)=>async (dispatch)=>{
  let url = api_base + "api/v1/proveedorrequerimientocompra/eliminar";

  dispatch({ type: LOADING_PROVEEDOR_REQUERIMIENTO_COMPRA });
  await getData
    .post(url, {proveedorRequerimientoCompraId:proveedorRequerimientoCompraId})
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_PROVEEDOR_REQUERIMIENTO_COMPRA,
        payload: proveedorRequerimientoCompraId,
      });
      dispatch({ type: PROVEEDOR_REQUERIMIENTO_COMPRA_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: PROVEEDOR_REQUERIMIENTO_COMPRA_FAILED });
      }
    });
};
