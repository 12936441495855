import {
  MOSTRAR_NIVELES_APROBACIONES_USUARIOS,
  NIVELES_APROBACIONES_FAILED,
  NIVELES_APROBACIONES_SUCCEEDED,
  LOADING_NIVELES_APROBACIONES
} from "./../types";

import initialState from "./../initial-state";

const { nivelesAprobaciones } = initialState;

const NivelesAprobacionesReducer = (state = nivelesAprobaciones, action) => {
  switch (action.type) {
    case MOSTRAR_NIVELES_APROBACIONES_USUARIOS:
      return {
        ...state,
        nivelesAprobaciones: action.payload
      };
    case NIVELES_APROBACIONES_SUCCEEDED:
    case NIVELES_APROBACIONES_FAILED:
      return {
        ...state,
        isLoading: false
      };
    case LOADING_NIVELES_APROBACIONES:
      return {
        ...state,
        isLoading: true
      };
    default:
      return state;
  }
};

export default NivelesAprobacionesReducer;
