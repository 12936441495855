import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "./../utils/Loading.jsx";
import { Authorization } from "./../../utils/Authorization";
import { getAreasPoaMonitoreo } from "./../../redux/actions/area_poa_monitoreo_actions";
import {
  Container,
  UncontrolledCollapse,
} from "reactstrap";

import { getActividad } from "./../../redux/actions/actividades-actions";
import { getMonitoreosActividad } from "./../../redux/actions/monitoreo-actividad-actions";
import ListadoMonitoreosActividad from "./../../pages/monitoreo/ListadoMonitoreosActividad.jsx";
import ListaDetalleInsumos from "./ListaDetalleInsumos.jsx";

class DetalleActividad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      toggleMonitoreo: false,
      toggleInsumos: false,
      insumos: [],
      poaMonitoreo: [],
    };
  }
  componentDidMount() {
    const { actividadId } = this.props.match.params;
    this.cargarData(actividadId);
  }
  componentWillReceiveProps(nextProps, nextState) {}

  toggleMonitoreo = () =>
    this.setState({ toggleMonitoreo: !this.state.toggleMonitoreo });
  toggleInsumos = () =>
    this.setState({ toggleInsumos: !this.state.toggleInsumos });

  cargarData = async (actividadId) => {
    const { poaId } = this.props.poaActivo;
    await this.props.getAreasPoaMonitoreo(poaId);
    let areaPoaMonitoreo = await this.props.areaPoaMonitoreo.filter(
      (area) =>
        area.areaOrganizacional.areaOrganizacionalId ===
          this.props.user.areaOrganizacional.areaOrganizacionalId && area
    );
    await this.props.getActividad(actividadId);
    this.setState({
      poaMonitoreo:
        areaPoaMonitoreo.length > 0 ? areaPoaMonitoreo[0].poaMonitoreo : [],
      isLoading: false,
    });
  };

  render() {
    const { isLoading } = this.state;
    const {
      actividadId,
      descripcion,
      medioVerificacion,
      t1,
      t2,
      t3,
      t4,
      monitoreo,
      presupuesto,
      unidadMedida,
    } = this.props.actividad;
    return (
      <div className="pb-6 pt-5 pt-md-7">
        {!isLoading ? (
          <Container>
            <div className="container bg-white border">
              <form
                className="needs-validation p-3 "
                onSubmit={this.createMonitoreo}
                noValidate
              >
                <div className="form-group">
                  <label>Actividad</label>
                  <input
                    className="form-control text-dark form-control-sm"
                    type="text"
                    readOnly
                    defaultValue={descripcion}
                  ></input>
                </div>
                <div className="form-group">
                  <label>Medio Verificación</label>
                  <input
                    className="form-control text-dark form-control-sm"
                    type="text"
                    readOnly
                    defaultValue={medioVerificacion.descripcion}
                  ></input>
                </div>
                <div className="form-group">
                  <h3>Ejecución Programada</h3>
                  <div className="row">
                    <div className="col-md-3 text-center">
                      <label>E-M</label>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="text-dark">{unidadMedida}:</label>
                        </div>
                        <div className="col-md-8 input-group">
                          <input
                            className="form-control text-dark form-control-sm"
                            type="text"
                            readOnly
                            defaultValue={t1 !== null ? t1 : 0}
                          ></input>
                          {unidadMedida !== "Cantidad" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text text-dark form-control-sm "
                                style={{
                                  backgroundColor: "#e9ecef",
                                  borderLeft: "2px solid  #bebaba",
                                }}
                              >
                                %
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 text-center">
                      <label>A-J</label>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="text-dark">{unidadMedida}:</label>
                        </div>
                        <div className="col-md-8 input-group">
                          <input
                            className="form-control text-dark form-control-sm"
                            type="text"
                            readOnly
                            defaultValue={t2 !== null ? t2 : 0}
                          ></input>
                          {unidadMedida !== "Cantidad" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text text-dark form-control-sm "
                                style={{
                                  backgroundColor: "#e9ecef",
                                  borderLeft: "2px solid  #bebaba",
                                }}
                              >
                                %
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 text-center">
                      <label>J-S</label>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="text-dark">{unidadMedida}:</label>
                        </div>
                        <div className="col-md-8 input-group">
                          <input
                            className="form-control text-dark form-control-sm"
                            type="text"
                            readOnly
                            defaultValue={t3 !== null ? t3 : 0}
                          ></input>
                          {unidadMedida !== "Cantidad" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text text-dark form-control-sm "
                                style={{
                                  backgroundColor: "#e9ecef",
                                  borderLeft: "2px solid  #bebaba",
                                }}
                              >
                                %
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 text-center">
                      <label>O-D</label>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="text-dark">{unidadMedida}:</label>
                        </div>
                        <div className="col-md-8 input-group">
                          <input
                            className="form-control text-dark form-control-sm"
                            type="text"
                            readOnly
                            defaultValue={t4 !== null ? t4 : 0}
                          ></input>
                          {unidadMedida !== "Cantidad" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text text-dark form-control-sm "
                                style={{
                                  backgroundColor: "#e9ecef",
                                  borderLeft: "2px solid  #bebaba",
                                }}
                              >
                                %
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="form-inline">
                    <h3
                      className="mt-3"
                      id="monitoreo"
                      onClick={() => this.toggleMonitoreo()}
                    >
                      Monitoreo de Actividades
                    </h3>
                    {!this.state.toggleMonitoreo ? (
                      <i
                        onClick={() => this.toggleMonitoreo()}
                        style={{
                          fontSize: "20px",
                          marginLeft: "5px",
                          marginTop: "8px",
                        }}
                        className="ni ni-bold-up text-primary"
                        size={32}
                      ></i>
                    ) : (
                      <i
                        onClick={() => this.toggleMonitoreo()}
                        style={{
                          fontSize: "20px",
                          marginLeft: "5px",
                          marginTop: "8px",
                        }}
                        className="ni ni-bold-down text-primary"
                        size={32}
                      ></i>
                    )}
                  </div>
                  <hr style={{ marginTop: "1px" }}></hr>
                  <UncontrolledCollapse
                    isOpen={this.state.toggleMonitoreo}
                    toggler="#monitoreo"
                  >
                    <ListadoMonitoreosActividad
                      actividadId={actividadId}
                      monitoreo={monitoreo}
                      poaMonitoreo={this.state.poaMonitoreo}
                    ></ListadoMonitoreosActividad>
                  </UncontrolledCollapse>
                  <div>
                    <div className="form-inline">
                      <h3 className="mt-3" id="toggler">
                        Insumos de la Actividad
                      </h3>

                      {!this.state.toggleInsumos ? (
                        <i
                          onClick={() => this.toggleInsumos()}
                          style={{
                            fontSize: "20px",
                            marginLeft: "5px",
                            marginTop: "8px",
                          }}
                          className="ni ni-bold-up text-primary"
                          size={25}
                        ></i>
                      ) : (
                        <i
                          onClick={() => this.toggleInsumos()}
                          style={{
                            fontSize: "20px",
                            marginLeft: "5px",
                            marginTop: "8px",
                          }}
                          className="ni ni-bold-down text-primary"
                          size={25}
                        ></i>
                      )}
                    </div>
                    <hr style={{ marginTop: ".1px" }}></hr>

                    <UncontrolledCollapse
                      toggler="#toggler"
                      isOpen={this.state.toggleInsumos}
                    >
                      {presupuesto.length > 0 ? (
                        <ListaDetalleInsumos
                          insumos={presupuesto}
                        ></ListaDetalleInsumos>
                      ) : (
                        <h5 className="text-center">
                          No tienes insumos agregados
                        </h5>
                      )}
                    </UncontrolledCollapse>
                  </div>
                </div>
              </form>
            </div>
          </Container>
        ) : (
          <Loading></Loading>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  actividad: state.actividades.actividad,
  isLoading: state.actividades.isLoading,
  monitoreosActividad: state.monitoreosActividad.monitoreosActividad,
  user: state.auth.signed,
  presupuesto: state.actividades.presupuesto,
  areaPoaMonitoreo: state.areaPoaMonitoreo.areaPoaMonitoreo,
  poaActivo: state.poas.poaActivo,
});

const mapDispatchToProps = (dispatch) => ({
  getActividad: (actividadId) => dispatch(getActividad(actividadId)),
  getMonitoreosActividad: (actividadId) =>
    dispatch(getMonitoreosActividad(actividadId)),
  getAreasPoaMonitoreo: (poaId) => dispatch(getAreasPoaMonitoreo(poaId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(DetalleActividad, [
    "Visualizar Productos",
    "Visualizar Actividades",
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Analista del POA",
    "Gestor del POA",
    "MAE",
  ])
);
