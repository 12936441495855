import React from "react";
import {
  LOADING_INFORME_TRIMESTRAL,
  MOSTRAR_INFORMES_TRIMESTRALES,
  INFORME_TRIMESTRAL_SUCCEEDED,
  INFORME_TRIMESTRAL_FAILED,
  ELIMINAR_INFORME_TRIMESTRAL,
  CARGAR_INFORME_TRIMESTRAL,
  EDITAR_INFORME_TRIMESTRAL,
} from "./../types";
import { api_base } from "./../../config";
import { getData } from "./../../utils/api-client";

export const getInformesTrimestral = (poaId, areaOrganizacionalId) => async (
  dispatch
) => {
  let url =
    api_base +
    `api/v1/informesTrimestrales/poaId/${poaId}/area/${areaOrganizacionalId}`;
  dispatch({ type: LOADING_INFORME_TRIMESTRAL });

  await getData
    .get(url)
    .then((resultado) => {
      dispatch({
        type: MOSTRAR_INFORMES_TRIMESTRALES,
        payload: resultado.data,
      });
      dispatch({ type: INFORME_TRIMESTRAL_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: INFORME_TRIMESTRAL_FAILED });
      }
    });
};

export const createInformeTrimestral = (informeTrimestral) => async (
  dispatch
) => {
  let url = api_base + `api/v1/informeTrimestral/nuevo`;
  dispatch({ type: LOADING_INFORME_TRIMESTRAL });
  await getData
    .post(url, informeTrimestral, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resultado) => {
      dispatch({ type: CARGAR_INFORME_TRIMESTRAL, payload: resultado.data });
      dispatch({ type: INFORME_TRIMESTRAL_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: INFORME_TRIMESTRAL_FAILED });
      }
    });
};

export const updateInformeTrimestral = (informeTrimestral) => async (
  dispatch
) => {
  let url = api_base + `api/v1/informetrimestral/editar`;
  dispatch({ type: LOADING_INFORME_TRIMESTRAL });
  await getData
    .post(url, informeTrimestral, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resultado) => {
      dispatch({ type: EDITAR_INFORME_TRIMESTRAL, payload: resultado.data });
      dispatch({ type: INFORME_TRIMESTRAL_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: INFORME_TRIMESTRAL_FAILED });
      }
    });
};

export const deleteInformeTrimestral = (informeTrimestralId) => async (
  dispatch
) => {
  let url = api_base + `api/v1/informetrimestral/eliminar`;

  let informe = {
    informeTrimestralId,
  };

  dispatch({ type: LOADING_INFORME_TRIMESTRAL });
  await getData
    .post(url, JSON.stringify(informe))
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_INFORME_TRIMESTRAL,
        payload: informeTrimestralId,
      });
      dispatch({ type: INFORME_TRIMESTRAL_SUCCEEDED });
    })
    .catch((error) => {
      if (error.response) {
        dispatch({ type: INFORME_TRIMESTRAL_FAILED });
      }
    });
};
