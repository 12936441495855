import {
  MOSTRAR_FECHAS_DOCUMENTACIONES_COMPRA,
  MOSTRAR_FECHA_DOCUMENTACION_COMPRA,
  CREAR_FECHA_DOCUMENTACION_COMPRA,
  EDITAR_FECHA_DOCUMENTACION_COMPRA,
  ELIMINAR_FECHA_DOCUMENTACION_COMPRA,
  LOADING_FECHA_DOCUMENTACION_COMPRA,
  FECHA_DOCUMENTACION_COMPRA_SUCCEEDED,
  FECHA_DOCUMENTACION_COMPRA_FAILED,
} from "./../types";
import { api_base } from "../../config";
import { getData } from "./../../utils/api-client";

export const getFechasDocumentaciones = (requerimientoId) => async (dispatch) => {
  let url = api_base + `api/v1/fechas/requerimiento/${requerimientoId}`;
  dispatch({ type: LOADING_FECHA_DOCUMENTACION_COMPRA });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_FECHAS_DOCUMENTACIONES_COMPRA, payload: resultado.data });
      dispatch({ type: FECHA_DOCUMENTACION_COMPRA_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: FECHA_DOCUMENTACION_COMPRA_FAILED });
      }
    });
};

export const createFechasDocumentacionesCompras = (data) => async (dispatch) => {
  let url = api_base + "api/v1/fechas/crear";

  dispatch({ type: LOADING_FECHA_DOCUMENTACION_COMPRA });
  await getData
    .post(url, JSON.stringify(data))
    .then((resultado) => {
      dispatch({
        type: CREAR_FECHA_DOCUMENTACION_COMPRA,
        payload: resultado.data,
      });
      dispatch({ type: FECHA_DOCUMENTACION_COMPRA_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: FECHA_DOCUMENTACION_COMPRA_FAILED });
      }
    });
};

export const updateFechasDocumentacionesCompras = (data) => async (dispatch) => {
  let url = api_base + "api/v1/fechas/editar";

  dispatch({ type: LOADING_FECHA_DOCUMENTACION_COMPRA });
  await getData
    .post(url, JSON.stringify(data))
    .then((resultado) => {
      dispatch({
        type: EDITAR_FECHA_DOCUMENTACION_COMPRA,
        payload: resultado.data,
      });
      dispatch({ type: FECHA_DOCUMENTACION_COMPRA_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: FECHA_DOCUMENTACION_COMPRA_FAILED });
      }
    });
};