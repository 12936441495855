import Login from "./pages/Login.jsx";
import ListaPoa from "./pages/poa/index.jsx";
import CrearPoa from "./pages/poa/Nuevo.jsx";
import DetallePoa from "./pages/poa/Detalle.jsx";
import EditarPoa from "./pages/poa/Editar.jsx";
import Listaproductos from "./pages/productos/index.jsx";
import CrearProducto from "./pages/productos/Nuevo.jsx";
import EditarProducto from "./pages/productos/Editar.jsx";
import DetalleProducto from "./pages/productos/Detalle.jsx";
import DetalleProductoAdmin from "./pages/administracion/productos/DetalleAdmin.jsx";
import CrearActividad from "./pages/actividades/Nuevo.jsx";
import EditarActividad from "./pages/actividades/Editar.jsx";
import ListadoInvolucrado from "./pages/involucrados/index.jsx";
import CrearInvolucrado from "./pages/involucrados/Nuevo";
import EditarInvolucrado from "./pages/involucrados/Editar.jsx";
import ListadoMedioVerificacion from "./pages/medios-verificacion/index.jsx";
import crearMedioVerificacion from "./pages/medios-verificacion/Nuevo.jsx";
import EditarMedioVerificacion from "./pages/medios-verificacion/Editar.jsx";
import MonitoreoActividad from "./pages/monitoreo/Nuevo.jsx";
import ListaMonitoreoActividad from "./pages/monitoreo/index.jsx";
import EditarMonitoreoActividad from "./pages/monitoreo/Editar.jsx";
import DetalleActividad from "./pages/actividades/Detalle.jsx";
import ListaUsuarios from "./pages/usuarios/index.jsx";
import CrearUsuario from "./pages/usuarios/Nuevo.jsx";
import EditarUsuario from "./pages/usuarios/Editar.jsx";
import AdminProducto from "./pages/administracion/productos/index.jsx";
import ListaAdminProductos from "./pages/administracion/productos/ListaProductos.jsx";
import PonderacionAreas from "./pages/administracion/ponderacionAreas/index.jsx";
import ProgamacionMonitoreo from "./pages/administracion/monitoreo/index.jsx";
import AdministracionMonitoreo from "./pages/administracion/adminMonitoreo/adminMonitoreo.jsx";
import AreaMonitoreo from "./pages/administracion/areaMonitoreo/index.jsx";
import InformeTrimestral from "./pages/Informe-trimestral/index.jsx";
import AdminitracionInformesTrimestrales from "./pages/administracion/informes-trimestrales/index.jsx";
import ReporteMonitoreo from "./pages/reporte-monitoreo/index.jsx";
import SolicitudesDeCompras from "./pages/solicitudes-compras/index.jsx";
import EditarSolicitudCompra from "./pages/solicitudes-compras/Editar.jsx";
import SolicitudCompraDepartamentoPresupuesto from "./pages/solicitudes-compras/departamento-presupuesto/index.jsx";
import SolicitudCompraValidacionCompraPresupuesto from "./pages/solicitudes-compras/departamento-presupuesto/Validacion.jsx";
import SolicitudCompraAprobadasDepartamentoAdministrativo from "./pages/solicitudes-compras/departamento-administrativo/index.jsx";
import SolicitudCompraAprobadasDepartamentoAdminProcesoCompra from "./pages/solicitudes-compras/departamento-administrativo/DepartamentoAdministrativoProcesoCompra.jsx";
import SolicitudCompraAprobadasDepartamentoAdminLanzamientoCompra from "./pages/solicitudes-compras/proceso-compras/DepartamentoAdministrativoLanzamientoCompras.jsx";
import CrearProcesoCompras from "./pages/solicitudes-compras/proceso-compras/Crear.jsx";
import CrearRecepcionRemision from "./pages/solicitudes-compras/recepcion-remision/Crear.jsx";
import ValidacionCompraRecepcionRemision from "./pages/solicitudes-compras/recepcion-remision/Validacion.jsx";
import SolicitudRequerimiento from "./pages/requerimientos/index.jsx";
import ListadoRequerimientos from "./pages/requerimientos/Listado.jsx";
import CrearRequerimiento from "./pages/requerimientos/Crear.jsx";
import EditarRequerimiento from "./pages/requerimientos/Editar.jsx";
import MostrarRequerimiento from "./pages/requerimientos/Mostrar.jsx";
import ListaContratos from "./pages/contratos/index.jsx";
import ExpedientesFisicos from "./pages/expedientes-fisicos/index.jsx";
import GestionBienesServicios from "./pages/bienes-servicios/index.jsx";
import CrearBienesServicios from "./pages/bienes-servicios/Crear.jsx";
import EditarExpedientesFisicos from "./pages/expedientes-fisicos/Editar.jsx";
import ListaTiposAsignaciones from "./pages/presupuesto/tipoAsignaciones/index.jsx"
import CrearTipoAsignacion from "./pages/presupuesto/tipoAsignaciones/Nuevo.jsx"
import EditarTipoAsignaciones from "./pages/presupuesto/tipoAsignaciones/Editar.jsx"
import ListaPresupuestosAsignadosInstitucion from "./pages/presupuesto/PresupuestoInstitucion/index.jsx"
import CrearPresupuestoAsignadoInstitucion from "./pages/presupuesto/PresupuestoInstitucion/Nuevo.jsx"
import TiposCompras from "./pages/administracion/tipos-compras/index.jsx";
// NO POA
// PRODUCTOS
import AdminProductoNoPoa from "./pages/administracion/productosNoPoa/index.jsx";
import ListaAdminProductosNoPoa from "./pages/administracion/productosNoPoa/ListaProductos.jsx";
import DetalleProductoNoPoaAdmin from "./pages/administracion/productosNoPoa/DetalleAdmin.jsx";

import ListaproductosNoPoa from "./pages/productosNoPoa/index.jsx";
import CrearProductoNoPoa from "./pages/productosNoPoa/Nuevo.jsx";
import EditarProductoNoPoa from "./pages/productosNoPoa/Editar.jsx";
import DetalleProductoNoPoa from "./pages/productosNoPoa/Detalle.jsx";

// ACTIVIDADES
import CrearActividadNoPoa from "./pages/actividadesNoPoa/Nuevo.jsx";
import EditarActividadNoPoa from "./pages/actividadesNoPoa/Editar.jsx";
import DetalleActividadNoPoa from "./pages/actividadesNoPoa/Detalle.jsx";

// MONITOREOS
import MonitoreoActividadNoPoa from "./pages/monitoreoNoPoa/Nuevo.jsx";
import ListaMonitoreoActividadNoPoa from "./pages/monitoreoNoPoa/index.jsx";
import EditarMonitoreoActividadNoPoa from "./pages/monitoreoNoPoa/Editar.jsx";

var routes = [
  {
    path: "/index",
    name: "Listado Poas",
    icon: "ni ni-tv-2 text-primary",
    component: ListaPoa,
    layout: "/admin",
  },
  {
    path: "/poa/nuevo",
    name: "Crear POA",
    icon: "ni ni-planet text-blue",
    component: CrearPoa,
    layout: "/admin",
  },
  {
    path: "/poa/detalle/:ano/:poaId",
    name: "Detalle POA",
    icon: "ni ni-planet text-blue",
    component: DetallePoa,
    layout: "/admin",
  },
  {
    path: "/poa/editar/:poaId",
    state: "/poa/editar/:poaId",
    name: "Editar POA",
    icon: "ni ni-planet text-blue",
    component: EditarPoa,
    layout: "/admin",
  },
  {
    path: "/productos/index",
    name: "Listado de Productos",
    icon: "ni ni-list-67 text-primary",
    component: Listaproductos,
    layout: "/admin",
  },
  {
    path: "/producto/nuevo",
    name: "Crear productos",
    icon: "ni ni-planet text-blue",
    component: CrearProducto,
    layout: "/admin",
  },
  {
    path: "/producto/editar/:productoId/:codigoSnip?",
    name: "Editar Producto",
    icon: "ni ni-pin-3 text-orange",
    component: EditarProducto,
    layout: "/admin",
  },
  {
    path: "/producto/detalle/:productoId",
    name: "Detalle Producto",
    hash: "/producto/detalle/:productoId",
    icon: "ni ni-pin-3 text-orange",
    component: DetalleProducto,
    layout: "/admin",
  },
  {
    path: "/administracion/producto/detalle/:productoId",
    name: "Detalle Producto",
    hash: "/producto/detalle/:productoId",
    icon: "ni ni-pin-3 text-orange",
    component: DetalleProductoAdmin,
    layout: "/admin",
  },
  {
    path: "/actividad/nueva",
    name: "Crear Actividad",
    icon: "ni ni-pin-3 text-blue",
    component: CrearActividad,
    layout: "/admin",
  },
  {
    path: "/actividad/editar/:actividadId",
    name: "Editar Actividad",
    icon: "ni ni-pin-3 text-blue",
    component: EditarActividad,
    layout: "/admin",
  },
  {
    path: "/actividad/detalle/:actividadId",
    name: "Detalle Actividad",
    icon: "ni ni-pin-3 text-blue",
    component: DetalleActividad,
    layout: "/admin",
  },
  {
    path: "/involucrado/index",
    name: "Lista de Involucrados",
    icon: "ni ni-pin-3 text-blue",
    component: ListadoInvolucrado,
    layout: "/admin",
  },
  {
    path: "/involucrado/editar/:involucradoId",
    name: "Editar Involucrado",
    icon: "ni ni-pin-3 text-blue",
    component: EditarInvolucrado,
    layout: "/admin",
  },
  {
    path: "/involucrado/nuevo",
    name: "Crear Involucrado",
    icon: "ni ni-pin-3 text-blue",
    component: CrearInvolucrado,
    layout: "/admin",
  },
  {
    path: "/mediosverificacion/index",
    name: "Listado de Medios de Verificación",
    icon: "ni ni-pin-3 text-blue",
    component: ListadoMedioVerificacion,
    layout: "/admin",
  },
  {
    path: "/medioverificacion/nuevo",
    name: "Crear Medio de Verificación",
    icon: "ni ni-pin-3 text-blue",
    component: crearMedioVerificacion,
    layout: "/admin",
  },
  {
    path: "/medioverificacion/editar/:medioVerificacionId",
    name: "Editar Medio de Verificación",
    icon: "ni ni-pin-3 text-blue",
    component: EditarMedioVerificacion,
    layout: "/admin",
  },
  {
    path: "/monitoreo/actividad/:actividadId/nuevo",
    name: "Monitoreo Actividad",
    icon: "ni ni-pin-3 text-blue",
    component: MonitoreoActividad,
    layout: "/admin",
  },
  {
    path: "/monitoreo/actividad/:actividad/index",
    name: "Lista Monitoreo Actividades",
    icon: "ni ni-pin-3 text-blue",
    component: ListaMonitoreoActividad,
    layout: "/admin",
  },
  {
    path: "/monitoreo/actividad/:actividadId/editar/:monitoreoActividadId",
    name: "Editar Monitoreo Actividad",
    icon: "ni ni-pin-3 text-blue",
    component: EditarMonitoreoActividad,
    layout: "/admin",
  },
  {
    path: "/usuarios/index",
    name: "Lista de Usuarios",
    icon: "ni ni-pin-3 text-blue",
    component: ListaUsuarios,
    layout: "/admin",
  },
  {
    path: "/usuarios/nuevo",
    name: "Crear Usuario",
    icon: "ni ni-pin-3 text-blue",
    component: CrearUsuario,
    layout: "/admin",
  },
  {
    path: "/usuarios/usuario/editar/:usuarioId",
    name: "Editar Usuario",
    icon: "ni ni-pin-3 text-blue",
    component: EditarUsuario,
    layout: "/admin",
  },
  {
    path: "/producto/detalle/:productoId",
    hash: ":productoId",
    name: "Autorizacion Producto",
    icon: "ni ni-pin-3 text-orange",
    component: AdminProducto,
    layout: "/user",
  },
  {
    path: "/administracion/productosNopoa",
    name: "Administración de Producto No POA",
    icon: "ni ni-pin-3 text-orange",
    component: ListaAdminProductosNoPoa,
    layout: "/admin",
  },
  {
    path: "/administracion/ponderacionareas",
    name: "Administración de Ponderación por Área",
    icon: "ni ni-pin-3 text-orange",
    component: PonderacionAreas,
    layout: "/admin",
  },
  {
    path: "/administracion/monitoreo",
    name: "Programación del Monitoreo",
    icon: "ni ni-pin-3 text-orange",
    component: ProgamacionMonitoreo,
    layout: "/admin",
  },
  {
    path: "/administracion/adminmonitoreo",
    name: "Administración de los trimestres",
    icon: "ni ni-pin-3 text-orange",
    component: AdministracionMonitoreo,
    layout: "/admin",
  },
  {
    path: "/administracion/areamonitoreo",
    name: "Aprobación de Monitoreos por Área Organizacional",
    icon: "ni ni-pin-3 text-orange",
    component: AreaMonitoreo,
    layout: "/admin",
  },
  {
    path: "/informe-trimestral/index",
    name: "Informe Trimestral",
    icon: "ni ni-pin-3 text-orange",
    component: InformeTrimestral,
    layout: "/admin",
  },
  {
    path: "/adminitracion/informes-trimestrales/index",
    name: "Administración de los Informe Trimestral",
    icon: "ni ni-pin-3 text-orange",
    component: AdminitracionInformesTrimestrales,
    layout: "/admin",
  },
  {
    path: "/reporte-monitoreo/index",
    name: "Reporte Monitoreo",
    icon: "ni ni-pin-3 text-orange",
    component: ReporteMonitoreo,
    layout: "/admin",
  },
  {
    path: "/contratos/index",
    name: "Contratos",
    icon: "fas fa-file-alt text-orange",
    component: ListaContratos,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/solicitud-requerimiento/index",
    name: "Solicitud de Requerimiento",
    icon: "ni ni-pin-3 text-orange",
    component: SolicitudRequerimiento,
    layout: "/admin",
  },
  {
    path: "/solicitud-requerimiento/mostrar/:requerimientoId",
    name: "Mostrar  Requerimiento",
    icon: "ni ni-pin-3 text-orange",
    component: MostrarRequerimiento,
    layout: "/admin",
  },
  {
    path: "/solicitud-requerimiento/listado",
    name: "Listado de Requerimientos",
    icon: "ni ni-pin-3 text-orange",
    component: ListadoRequerimientos,
    layout: "/admin",
  },
  {
    path: "/solicitud-requerimiento/editar/:requerimientoId",
    name: "Editar Requerimiento",
    icon: "ni ni-pin-3 text-orange",
    component: EditarRequerimiento,
    layout: "/admin",
  },
  {
    path: "/solicitud-requerimiento/crear",
    name: "Crear Requerimiento",
    icon: "ni ni-pin-3 text-orange",
    component: CrearRequerimiento,
    layout: "/admin",
  },
  {
    path: "/solicitud-compra/index",
    name: "Solicitudes de Compras",
    icon: "ni ni-pin-3 text-orange",
    component: SolicitudesDeCompras,
    layout: "/admin",
  },
  {
    path: "/solicitud-compra/editar/:solicitudCompraId",
    name: "Modificación de Orden de Compras",
    icon: "ni ni-delivery-fast text-orange",
    component: EditarSolicitudCompra,
    layout: "/admin",
  },
  {
    path: "/solicitud-compra/crear-proceso-compra/:solicitudCompraId",
    name: "Proceso de compras",
    icon: "ni ni-delivery-fast text-orange",
    component: CrearProcesoCompras,
    layout: "/admin",
  },
  {
    path: "/gestion-bienes-servicios/index",
    name: "Gestión de Bienes y Servicios",
    icon: "ni ni-pin-3 text-orange",
    component:  GestionBienesServicios,
    layout: "/admin",
  },
  {
    path: "/solicitud-compra/gestion-bienes-servicios/crear-servicios/:solicitudCompraId",
    name: "Gestión de Bienes y Servicios",
    icon: "ni ni-pin-3 text-orange",
    component:  CrearBienesServicios,
    layout: "/admin",
  },
  {
    path: "/expedientes-fisicos/index",
    name: "Expedientes fisicos",
    icon: "ni ni-pin-3 text-orange",
    component:  ExpedientesFisicos,
    layout: "/admin",
  },
  {
    path: "/expedientes-fisicos/index",
    name: "Recepción o Remisión de Compras",
    icon: "ni ni-pin-3 text-orange",
    component:  CrearRecepcionRemision,
    layout: "/admin",
  },
  {
    path: "/solicitud-compra/recepcion-remision-compra/validacion/:solicitudCompraId",
    name: "Recepción o Remisión de Compras",
    icon: "ni ni-pin-3 text-orange",
    component:  ValidacionCompraRecepcionRemision,
    layout: "/admin",
  },
  {
    path: "/solicitud-compra/departamento-presupuesto/index",
    name: "Solicitud de Compras - Departamento Presupuesto",
    icon: "ni ni-pin-3 text-orange",
    component:  SolicitudCompraDepartamentoPresupuesto,
    layout: "/admin",
  },
  {
    path: "/solicitud-compra/departamento-presupuesto/validacion/:solicitudCompraId",
    name: "Validacion De Compras Según El Presupuesto",
    icon: "ni ni-pin-3 text-orange",
    component:  SolicitudCompraValidacionCompraPresupuesto,
    layout: "/admin",
  },
  {
    path: "/solicitud-compra/departamento-administrativo/index",
    name: "Aprobar Solicitud de Compras - Dirección Administrativa",
    icon: "ni ni-pin-3 text-orange",
    component:  SolicitudCompraAprobadasDepartamentoAdministrativo,
    layout: "/admin",
  },
  {
    path: "/solicitud-compra/departamento-administrativo/procesoCompra/:solicitudCompraId",
    name: "Proceso de Compras",
    icon: "ni ni-pin-3 text-orange",
    component:  SolicitudCompraAprobadasDepartamentoAdminProcesoCompra,
    layout: "/admin",
  },
  {
    path: "/solicitud-compra/departamento-administrativo/ProcesoCompralanzamientoCompra/:solicitudCompraId",
    name: "Lanzamiento de Compras",
    icon: "ni ni-pin-3 text-orange",
    component:  SolicitudCompraAprobadasDepartamentoAdminLanzamientoCompra,
    layout: "/admin",
  },
  {
    path: "/expedientes-fisicos/crear/:expedienteFisicoId",
    name: "Expedientes fisicos",
    icon: "ni ni-delivery-fast text-orange",
    component: EditarExpedientesFisicos,
    layout: "/admin",
  },
  {
    path: "/tiposAsignaciones/index",
    name: "Lista Tipo de Asignaciones",
    icon: "ni ni-delivery-fast text-orange",
    component: ListaTiposAsignaciones,
    layout: "/admin",
  },
  {
    path: "/tipoAsignacion/crear",
    name: "Crear Tipo de Asignaciones",
    icon: "ni ni-delivery-fast text-orange",
    component: CrearTipoAsignacion,
    layout: "/admin",
  },
  {
    path: "/tipoAsignacion/editar/:tipoAsignacionId",
    name: "Editar Tipo de Asignaciones",
    icon: "ni ni-delivery-fast text-orange",
    component: EditarTipoAsignaciones,
    layout: "/admin",
  },
  {
    path: "/presupuestosAsignadosInstitucion/index",
    name: "Presupuestos Asignados Institución",
    icon: "ni ni-delivery-fast text-orange",
    component: ListaPresupuestosAsignadosInstitucion,
    layout: "/admin",
  },
  {
    path: "/presupuestosAsignadosInstitucion/crear",
    name: "Crear Presupuesto Asignado Institución",
    icon: "ni ni-delivery-fast text-orange",
    component: CrearPresupuestoAsignadoInstitucion,
    layout: "/admin",
  },
  {
    path: "/administracion/tipos-compras/index",
    
    name: "Tipos de compras",
    icon: "ni ni-pin-3 text-orange",
    component: TiposCompras,
    layout: "/admin",
  },
  // No Poa - rutas
  // Productos
  {
    path: "/admin/productosNoPoa/index",
    name: "Productos No POA",
    icon: "ni ni-pin-3 text-orange",
    component: AdminProductoNoPoa,
    layout: "/admin",
  },
  {
    path: "/productosNoPoa/index",
    name: "Listado de Productos No Poa",
    icon: "ni ni-list-67 text-primary",
    component: ListaproductosNoPoa,
    layout: "/admin",
  },
  {
    path: "/productoNoPoa/nuevo",
    name: "Crear productos No Poa",
    icon: "ni ni-planet text-blue",
    component: CrearProductoNoPoa,
    layout: "/admin",
  },
  {
    path: "/productoNoPoa/editar/:productoNoPoaId/:codigoSnip?",
    name: "Editar Producto No Poa",
    icon: "ni ni-pin-3 text-orange",
    component: EditarProductoNoPoa,
    layout: "/admin",
  },
  {
    path: "/productoNoPoa/detalle/:productoNoPoaId",
    name: "Detalle Producto No Poa",
    hash: "/productoNoPoa/detalle/:productoNoPoaId",
    icon: "ni ni-pin-3 text-orange",
    component: DetalleProductoNoPoa,
    layout: "/admin",
  },
  {
    path: "/administracion/productoNoPoa/detalle/:productoNoPoaId",
    name: "Detalle Producto No Poa",
    hash: "/productoNoPoa/detalle/:productoNoPoaId",
    icon: "ni ni-pin-3 text-orange",
    component: DetalleProductoNoPoaAdmin,
    layout: "/admin",
  },
  {
    path: "/administracion/productos",
    name: "Administración de Producto",
    icon: "ni ni-pin-3 text-orange",
    component: ListaAdminProductos,
    layout: "/admin",
  },
  // Actividades
  {
    path: "/actividadNoPoa/nueva",
    name: "Crear Actividad No Poa",
    icon: "ni ni-pin-3 text-blue",
    component: CrearActividadNoPoa,
    layout: "/admin",
  },
  {
    path: "/actividadNoPoa/editar/:actividadNoPoaId",
    name: "Editar Actividad No Poa",
    icon: "ni ni-pin-3 text-blue",
    component: EditarActividadNoPoa,
    layout: "/admin",
  },
  {
    path: "/actividadNoPoa/detalle/:actividadNoPoaId",
    name: "Detalle Actividad No Poa",
    icon: "ni ni-pin-3 text-blue",
    component: DetalleActividadNoPoa,
    layout: "/admin",
  },
  // MONITOREOS
  {
    path: "/monitoreoNoPoa/actividad/:actividadNoPoaId/nuevo",
    name: "Monitoreo Actividad No Poa",
    icon: "ni ni-pin-3 text-blue",
    component: MonitoreoActividadNoPoa,
    layout: "/admin",
  },
  {
    path: "/monitoreoNoPoa/actividad/:actividad/index",
    name: "Lista Monitoreo Actividades No Poa",
    icon: "ni ni-pin-3 text-blue",
    component: ListaMonitoreoActividadNoPoa,
    layout: "/admin",
  },
  {
    path: "/monitoreoNoPoa/actividad/:actividadId/editar/:monitoreoActividadId",
    name: "Editar Monitoreo Actividad No Poa",
    icon: "ni ni-pin-3 text-blue",
    component: EditarMonitoreoActividadNoPoa,
    layout: "/admin",
  },
   {
    path: "/productoNoPoa/detalle/:productoNoPoaId",
    hash: ":productoNoPoaId",
    name: "Autorizacion Producto No Poa",
    icon: "ni ni-pin-3 text-orange",
    component: AdminProductoNoPoa,
    layout: "/user",
  },
];
export default routes;
