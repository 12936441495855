// @flow
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";
import reducers from "../reducers";
import requestMiddleware from "../reducers/requestMidleware";

const enhancer = compose(
  applyMiddleware(thunk, requestMiddleware)
);

export const store = createStore(reducers, enhancer);
export const persistor = persistStore(store);
