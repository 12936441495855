import {
    UNIDADES_MEDIDA_SUCCEEDED,
    LOADING_UNIDADES_MEDIDA,
    MOSTRAR_UNIDADES_MEDIDA
} from "./../types";

import { getData } from "./../../utils/api-client";
import { api_base } from "./../../config";

export const getUnidadesMedida = modulo => async dispatch => {
    const url = api_base + `api/v1/unidadesMedida/${modulo}`;

    dispatch({ type: LOADING_UNIDADES_MEDIDA });
    await getData
    .get(url)
    .then(resultado => {
        dispatch({ type: MOSTRAR_UNIDADES_MEDIDA, payload: resultado.data });
        dispatch({ type: UNIDADES_MEDIDA_SUCCEEDED });
    });
};