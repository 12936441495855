const initialState = {
  auth: {
    isLoading: false,
    isSignedIn: false,
    token: null,
    signed: {},
    attributes: {
      name: null,
      email: null,
    },
  },
  poas: {
    poas: [],
    isLoading: false,
    poa: {},
    poaActivo: {},
    poaElaboracion: {},
    detallePoa: [],
  },
  productos: {
    isLoading: false,
    productos: [],
    productosActividadInsumos: [],
    producto: { actividades: [] },
    productosAdministracion: [],
    totalPonderacionProductos: 0,
    totalPonderacionActividades: 0,
    totalPonderacionGeneralProductos: 0,
    totalProductosAdministracion: 0,
  },
  actividades: {
    isLoading: false,
    actividades: [],
    actividad: { presupuesto: {}, monitoreo: [] },
    totalPonderacionActividades: 0,
    actividadesArticuloSeguimientoPorProveedor: [],
    actividadesArticuloSeguimientoPorProveedorCantidad: {},
    actividadesArticuloSeguimiento: []
  },
  planesEstrategicos: {
    isLoading: false,
    ejesEstrategicos: [],
    objetivosEstrategicos: [],
    estrategias: [],
  },
  planesEstrategicosgeneral: {
    isLoading: false,
    ejesEstrategicos: [],
    objetivosEstrategicos: [],
    estrategias: [],
  },
  involucrados: { isLoading: false, involucrados: [], involucrado: {} },
  areasOrganizacionales: { isLoading: false, areasOrganizacionales: [] },
  mediosVerificacion: {
    isLoading: false,
    mediosVerificacion: [],
    medioVerificacion: {},
    operaciones: [],
  },
  unidadesMedida: { isLoading: false, unidadesMedida: [] },
  monitoreosActividad: {
    isLoading: false,
    monitoreosActividad: [],
    monitoreoActividad: {},
    adminMonitoreoActividad: [],
    reporteMonitoreoDetalle: {
      areaOrganizacional: "",
      productos: [],
      firmas: [],
      firmasAprobacionMonitoreo: [],
      firmasRevisionMonitoreo: [],
    },
  },
  usuarios: {
    isLoadingUsuario: false,
    isLoading: false,
    usuarios: [],
    usuario: {},
    usuariosAnalistasAsignados: []
  },
  notaProducto: {},
  notaActividad: {},
  roles: { isLoading: false, roles: [], rol: {} },
  nivelesAprobaciones: { isLoading: false, nivelesAprobaciones: [] },
  maestroInsumos: {
    isLoading: false,
    maestroInsumos: [],
    maestroInsumo: {},
    insumosTemporales:[],
    operaciones: [],
  },
  ponderacionAreas: { ponderacionAreas: [], ponderacionArea: {} },
  programacionMonitoreos: {
    programacionMonitoreos: [],
    programacionMonitoreo: {},
    isLoading: false,
  },
  trimestres: {
    trimestres: [],
    trimestre: {},
    isLoading: false,
  },
  areaPoaMonitoreo: {
    areaPoaMonitoreo: [],
    isLoading: false,
  },
  informesTrimestrales: {
    informesTrimestrales: [],
    informeTrimestral: {},
    isLoading: false,
  },
  areasInformesTrimestrales: {
    areasInformesTrimestrales: [],
    isLoading: false,
    areaInformeTrimestral: {},
  },

  monitoreoMediosVerificacion: {
    monitoreoMediosVerificacion: [],
    isLoading: false,
  },
  productoProgramaticas: {
    programaticas: [],
    programatica: {},
    isLoading: false,
  },
  tiposCompras: { isLoading: false, tiposCompras: [], tipoCompra: {} },
  tiposAsignaciones: {isLoading:false, tiposAsignaciones:[],tipoAsignacion:{},mensaje:"", success:'idle'},
  estados: { isLoading: false, estados: [] },
  requerimientos: { isLoading: false, requerimientos: [], requerimiento: {},requerimientosAdmin:[], requerimientosCompras: [], requerimientoCompra: {}, contratos: [], contrato: {}, contratoRequerimiento: [], bienesServicios: [], bienServicio: {}, success:'idle' },
  historicosrequerimientos: { isLoading: false, historicosrequerimientos: [], historicorequerimiento: {}, listaHistoricoRequerimientos:[] },
  comprasDocumentaciones: { isLoading: false, comprasDocumentaciones: [] },
  fechasDocumentacionesCompras: {isLoading: false,success:"idle", fechasDocumentacionCompra: {}, fechasDocumentacionesCompras: [] },
  proveedores: { isLoading: false, proveedor: {}, proveedores: [], proveedoresPorRequerimiento: {}, proveedoresInsumosAdjudicados: [], proveedorRequerimientoCompra: [] },
  presupuestosAsignadosInstitucion:{isLoading:false, presupuestosAsignadosInstitucion:[],presupuestoAsignadoInstitucion:{},success:'idle'},
  //No POA
  productosNoPoa: {
    isLoading: false,
    productosNoPoa: [],
    productosActividadInsumosNoPoa: [],
    productoNopoa: { actividades: [] },
    productosAdministracionNoPoa: [],
    totalPonderacionProductosNoPoa: 0,
    totalPonderacionActividadesNoPoa: 0,
    totalPonderacionGeneralProductosNoPoa: 0,
    totalProductosAdministracionNoPoa: 0,
  },
  actividadesNopoa: {
    isLoading: false,
    actividadesNopoa: [],
    actividadNopoa: { presupuestoNopoa: {} },
    totalPonderacionActividadesNopoa: 0,
    actividadesArticuloSeguimientoPorProveedorNopoa: [],
    actividadesArticuloSeguimientoPorProveedorCantidadNopoa: {},
    actividadesArticuloSeguimientoNopoa: []
  },
  maestroInsumosNopoa: {
    isLoading: false,
    maestroInsumosNopoa: [],
    maestroInsumoNopoa: {},
    insumosTemporalesNopoa:[],
    operacionesNopoa: [],
  },
};

export default initialState;
