import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../../utils/Loading.jsx";
import AdminListaActividadesNoPoa from "../actividadesNoPoa/listaActividades.jsx";
import {
  getProducto,
  calcularPonderacionArea,
  getProductoNopoa,
  updateProductoAprobarDevolverNopoa
} from "../../../redux/actions/productos-actions.js";
import { ModalProducto } from "../../utils/ModalProducto.jsx";
import { Authorization } from "../../../utils/Authorization.js";
import {ModalDevolucionProductoNoPoa} from  "./../../utils/ModalDevolucionProductoNoPoa.jsx"
import {
  Container,

} from "reactstrap";
import swal from "sweetalert";

class AdminProductoNoPoa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      producto: {},
      motivoDevolucion:"",
      productoNoPoaVisibilidad: false,
      productoNoPoaState:{},
      isLoading: true,
      file:[],
      documento:[],
    };
  }
  componentDidMount() {
    const { productoNoPoaId } = this.props.match.params;
    this.props.getProductoNopoa(productoNoPoaId);
  }

  activarModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  activarModalDevolucionProductoNoPoa = (productoNoPoaVisibilidad)=>{
    this.setState({productoNoPoaVisibilidad:!productoNoPoaVisibilidad})
  }

  cargarDocumento = (documento) => {
    let files = [];
    let documentos = [];
    if (documento.target.files.length > 0) {
      for (let i = 0; i < documento.target.files.length; i++) {
        files.push(documento.target.files[i]);
        documentos.push(documento.target.files[i].name);
      }
      this.setState({
        file: files,
        documento: documentos,
      });
    } else {
      this.setState({
        file: "",
        documento: "",
      });
    }
  };

  componentWillReceiveProps(nextProps, nextState) {
    const { productoNoPoa } = nextProps;
    this.setState({
      productoNoPoa: productoNoPoa,
      isLoading: false,
      modal: false,
      notaProducto: {},
      notaActividades: []
    });
  }

  dispatchProductos = productoNoPoaId => async dispatch => {
    dispatch(getProductoNopoa(productoNoPoaId));
  };

  aprobarProducto = productoNoPoa => {


    var documento = document.getElementById('inputDocumentoPDF');

    if(documento.files.length == 0) {
      swal({
        title: "Error en archivo",
        text:
          "¡Debe adjuntar un archivo, si desea aprobar el Producto No Poa!",
        icon: "error",
        button: "Aceptar",
      });
      return;
    }
  
    swal({
      title: "¿Está seguro de aprobar este producto no POA?",
      text: "Luego de aprobado este producto no podra ser modificado",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if (confirm) {

        let productoEditar = {
          productoNoPoaId: productoNoPoa.productoNoPoaId,
          devuelto: false,
          observacion: null,
          nivelAprobacionId:5,
          documento: this.state.file,
        };
        // crear un nuevo funcion para aprobar o devolver
        this.props.updateProductoAprobarDevolverNopoa(productoEditar);
        this.props.history.goBack();
      }
    });
  };

  devolverProducto = async productoNoPoa => {

    swal({
      title: "¿Está seguro de devolver el producto?",
      text: "Luego de devuelto este producto no podra ser modificado",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (confirm) => {
      if (confirm) {
        this.setState({productoNoPoaState:productoNoPoa,productoNoPoaVisibilidad:true})
      }
    });
  };

  devolverProductoAprobado = async motivo =>{
    if(motivo.length>8){
    let productoEditar = {
      productoNoPoaId: this.state.productoNoPoaState.productoNoPoaId,
      devuelto: true,
      observacion: motivo,
      modoEditar: true,
      nivelAprobacionId: 3
    };
    this.setState({productoNoPoaVisibilidad:false})
    await this.props.updateProductoAprobarDevolverNopoa(productoEditar);
    this.props.history.goBack();
  }
    else{
      swal("¡Motivo Devolución!","Debe especificar el motivo de la devolución", "error")
    }
  }

  render() {
    const { productoNoPoa, isLoading } = this.props;

    const {
      productoNoPoaId,
      descripcion,
      resultado,
      meta,
      indicador,
      ponderacionArea,
      devuelto,
      observacion
    } = productoNoPoa;

    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid >
        <div className="container bg-white border py-3">
          {isLoading ? (
            <Loading></Loading>
          ) : (
            <div className="container bg-white border">
              <form
                className="needs-validation p-3 "
                onSubmit={this.crearProducto}
                noValidate
              >
                <div className="form-group ">
                  <label htmlFor="descripción">Producto</label>
                  <input
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="descripción"
                    placeholder="Nombre del producto"
                    value={descripcion}
                    readOnly
                  />
                </div>
                <div className="form-group ">
                  <label htmlFor="descripción">Resultado</label>
                  <textarea
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="resultado"
                    placeholder="Resultado del producto"
                    value={resultado}
                    readOnly
                  />
                </div>

                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="meta">Meta</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="meta"
                      placeholder="Meta"
                      value={meta}
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="indicador">Indicador</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="indicador"
                      placeholder="Indicador del producto"
                      value={indicador}
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="observacion">Observacion por devolucion</label>
                  <textarea
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="observacion"
                    placeholder="Descripción de la devolución"
                    defaultValue={observacion}
                    readOnly
                  />
                </div>
                <div className="form-group ">
                  <label htmlFor="observacion">Documento (PDF)</label>
                  <input
                      id="inputDocumentoPDF"
                      className="form-control text-dark"
                      type="file" accept=".pdf"
                      name="file[]"
                      onChange = {
                        (e) => {
                          e.preventDefault();
                          ////////////////////////////////////////////////////////////
                          var fileInput = document.getElementById('inputDocumentoPDF');
                          var filePath = fileInput.value;
                          var allowedExtensions = /(\.pdf|\.PDF)$/i;
                          if (!allowedExtensions.exec(filePath)) {
                            swal("Error de tipo de archivo", "Debe cargar archivos en formato PDF", "error");
                            fileInput.value = '';
                            return false;
                          }
                          ///////////////////////////////////////////////
                            this.cargarDocumento(e);
                        }
                      }
                    />
                </div>
                <AdminListaActividadesNoPoa
                  productoNoPoaId={productoNoPoaId}
                ></AdminListaActividadesNoPoa>

                <ModalProducto
                  modal={this.state.modal}
                  activarModal={this.activarModal}
                ></ModalProducto>
                  <button
                    onClick={e => {
                      e.preventDefault();
                      this.aprobarProducto(productoNoPoa);
                    }}
                    className="btn btn-primary"
                  >
                    Aprobar
                  </button>
  
                  <button
                    onClick={e => {
                      e.preventDefault();
                      this.devolverProducto(productoNoPoa);
                    }}
                    className="btn btn-danger"
                  >
                    Devolver
                  </button>
            
              </form>
            </div>
          )}
          </div>
          <ModalDevolucionProductoNoPoa
          modalDevolucion ={this.state.productoNoPoaVisibilidad}
          activarModalDevolucionProductoNoPoa ={this.activarModalDevolucionProductoNoPoa}
          devolverProductoAprobado = {this.devolverProductoAprobado}/>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  productosNoPoa: state.productosNoPoa.productosNoPoa,
  productoNoPoa: state.productosNoPoa.productoNopoa,
  isLoading: state.productosNoPoa.isLoading,
  user: state.auth.signed,
  totalPonderacionProductosNopoa: state.productosNoPoa.totalPonderacionProductosNopoa
});

const mapDispatchToProps = dispatch => ({
  getProductoNopoa: productoId => dispatch(getProductoNopoa(productoId)),
  updateProductoAprobarDevolverNopoa: producto => dispatch(updateProductoAprobarDevolverNopoa(producto)),
  calcularPonderacionArea: (productos, user) => dispatch(calcularPonderacionArea(productos, user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(AdminProductoNoPoa, [
    "Administrador general",
    "Gestor del POA",
    "Responsables de areas",
    "MAE"
  ])
);
