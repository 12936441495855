import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  Table,
  Container,
} from "reactstrap";
import Pagination from "react-js-pagination";
import { Authorization } from "../../utils/Authorization";
import Loading from "../utils/Loading";

import { ModalCerficacionContrato } from "../utils/ModalCerficacionContrato";
import { getAreasOrganizacionales } from "../../redux/actions/areas-actions";
import { getRequerimientos, getRequerimiento, createRequerimientoContrato, getContratoRequerimiento, getContratos, getContratosDescargar } from "../../redux/actions/requerimientos-actions";

import Contratos from "./Contratos.jsx";

class ContratosDireccionAdministrativa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      areaid2:0,
      listaContratosState: [],
      listaContratosPageState: [],
      activePage: 1,
      totalPages: 0,
      itemPerPage: 10,
      modalCertificacionContratoStatus: false,
      requerimientoSeleccionado: '',
      contratoSeleccionado: '',
      file: "",
      documento: "",
    };
  }

  componentDidMount() {
    this.cargarSolicitudesContratos();
    this.props.getAreasOrganizacionales();
    this.setState({areaid2:this.props.user.areaOrganizacional.areaOrganizacionalId, isLoadingState: false});
  }

  cargarSolicitudesContratos = async () => {
    let areaConsulta = this.state.areaSelected ==0 ? this.props.user.areaOrganizacional.areaOrganizacionalId:this.state.areaSelected;
    await this.props.getRequerimientos(areaConsulta);
    this.props.getContratos();
    this.setState({ listaContratosState: this.props.requerimientos, isLoadingState: false }, () =>
      this.validarPaginacion()
    );
  }

  cargarCertificacionContrato = (contrato) => {
    let files = [];
    let documentos = [];
    if (contrato.target.files.length > 0) {
      for (let i = 0; i < contrato.target.files.length; i++) {
        files.push(contrato.target.files[i]);
        documentos.push(contrato.target.files[i].name);
      }
      this.setState({
        file: files,
        documento: documentos,
      });
    }
    else {
      this.setState({
        file: "",
        documento: "",
      });
    }
  };

  guardarCertificacionContrato = async (requerimientoId) => {
    this.setState({ isLoadingState: true });
    this.activarModalCertificacionContrato();

    let contrato = {
      requerimientoId : requerimientoId,
    }; 

    const fd = new FormData();
    if (this.state.file.length > 0) {
      for (let i = 0; i < this.state.file.length; i++) {
        fd.append(`documento[]`, this.state.file[i], this.state.documento[i]);
      }
    }

    Object.keys(contrato).forEach(function (item) {
      fd.append(item, contrato[item]);
    });

    await this.props.createRequerimientoContrato(fd);

    this.setState({ isLoadingState: false });

    setTimeout(() => {
      window.location.reload();
    }, "1000");
  }

  activarModalCertificacionContrato = (requerimientoId, numeroSolicitud) => {
    this.setState({
      modalCertificacionContratoStatus: !this.state.modalCertificacionContratoStatus,
      requerimientoSeleccionado: numeroSolicitud,
      requerimientoId: requerimientoId
    });
  };

  descargarContratos = async (requerimiento) => {
    // console.log(requerimiento);
    await this.props.getContratosDescargar(requerimiento);
  }

  validarPaginacion = (page = 1, elementos = 10) => {
    let index = elementos * page - elementos;
    let listaContratos = [];

    for (let i = 0; i < elementos; i++) {
      listaContratos.push(this.state.listaContratosState[index]);

      index++;
    }

    let totalPages = Math.ceil(
      this.state.listaContratosState.length / elementos
    );

    this.setState({
      listaContratosPageState: listaContratos,
      activePage: page,
      totalPages,
      itemPerPage: elementos,
    });
  };

  render() {

    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (
                <div className="row">

                  {/* //me */}
                  <div className="col-md-5 text-left" style={{display:"none"}}>
                  <label>Filtro Área</label>
                     <select
                      className="form-control"
                      defaultValue={this.state.areaid2}
                      
                      id="areaSelected"
                      onChange={(e) => {
                        e.preventDefault();
                   
                        this.setState({ isLoadingState: true });
                        this.setState({areaid2:document.getElementById("areaSelected").value});
                        this.cargarDatos(document.getElementById("areaSelected").value);
                      }}
                    >
                      {this.props.areaOrganizacionales.map((area) => (
                        <option key={area.areaOrganizacionalId} value={area.areaOrganizacionalId}>
                          {area.nombre}
                        </option>
                        
                      ))}
                    </select>

                    <label>Filtro Área</label>
                    <select
                        className="form-control"
                        defaultValue={this.state.areaid2}

                        id="areaSelected"
                        onChange={(e) => {
                          e.preventDefault();

                          this.setState({ isLoadingState: true });
                          this.setState({areaid2:document.getElementById("areaSelected").value});
                          this.cargarDatos(document.getElementById("areaSelected").value);
                        }}
                    >
                      {this.props.areaOrganizacionales.map((area) => (
                          <option key={area.areaOrganizacionalId} value={area.areaOrganizacionalId}>
                            {area.nombre}
                          </option>
                      ))}
                    </select>

                  </div>

                  <div className="col-md-12 mt-3 mb-4">
                    <h2>Contratos a evaluar por dirección administrativa</h2>
                    <Table>
                      <thead>
                        <tr>
                          <th>No. Producto</th>
                          <th>Analista Asignado</th>
                          <th>No. Requerimiento</th>
                          <th>Área</th>
                          <th>Fecha de Entrada</th>
                          <th>Contracto</th>
                          <th>Certificación</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listaContratosPageState.map(
                          (solicitud) =>
                            solicitud != null &&
                            solicitud != undefined && (
                              <Contratos
                                key={solicitud.requerimientoId}
                                requerimiento={solicitud}
                                activarModalCertificacionContrato={this.activarModalCertificacionContrato}
                                user={this.props.user}
                                descargarContratos = {this.descargarContratos}
                                contrato={this.props.contratos.find(item => item.requerimientoId == solicitud.requerimientoId)}
                                descargarContrato = {this.descargarContratos}
                              />
                            )
                        )}
                      </tbody>
                    </Table>
                    <div className="d-flex justify-content-center">
                      <Pagination
                        prevPageText="ant"
                        nextPageText="sig"
                        firstPageText="prime"
                        lastPageText="ultimo"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemPerPage}
                        totalItemsCount={this.state.listaContratosPageState.length}
                        onChange={this.validarPaginacion.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          <ModalCerficacionContrato
              cargarCertificacionContrato = {this.cargarCertificacionContrato}
              guardarCertificacionContrato={this.guardarCertificacionContrato}
              requerimiento={this.props.requerimiento}
              modalCertificacionContrato={this.modalCertificacionContrato}
              modalCertificacionContratoStatus={
                this.state.modalCertificacionContratoStatus
              }
              activarModalCertificacionContrato={this.activarModalCertificacionContrato}
              requerimientoSeleccionado = {this.state.requerimientoSeleccionado}
              contratoSeleccionado = {this.state.contratoSeleccionado}
              requerimientoId = {this.state.requerimientoId}
            ></ModalCerficacionContrato>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
  requerimientos: state.requerimientos.requerimientos,
  requerimiento: state.requerimientos.requerimiento,
  contratoRequerimiento: state.requerimientos.contratoRequerimiento,
  contratos : state.requerimientos.contratos
});

const mapDispatchToProps = (dispatch) => ({
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
  getRequerimientos: (areaId) => dispatch(getRequerimientos(areaId)),
  getRequerimiento: (requerimientoId) => dispatch(getRequerimiento(requerimientoId)),
  createRequerimientoContrato: (requerimiento) => dispatch(createRequerimientoContrato(requerimiento)),
  getContratoRequerimiento: (requerimientoId) => dispatch(getContratoRequerimiento(requerimientoId)),
  getContratos: () => dispatch(getContratos()),
  getContratosDescargar: (requerimientoId) => dispatch(getContratosDescargar(requerimientoId))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(ContratosDireccionAdministrativa, [
    "Administrador general",
    "Responsables de compras",
    "Analista Compras"
  ])
);
