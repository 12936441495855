import React, { Component } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getListaPresupuestosAsignadosInstitucion,deletePresupuestoAsignadoInstitucion } from "./../../../redux/actions/presupuesto-asignado-institucion-action.js";
import {
  Table,
  Container
} from "reactstrap";
import Pagination from "react-js-pagination";

import Loading from "./../../utils/Loading";

import { Authorization } from "./../../../utils/Authorization";

class ListadoPresupuestoAsignadoInstitucion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaPresupuestoAsignadoInstitucionState: [],
      listaPresupuestoAsignadoInstitucionPageState: [],
      activePage: 1,
      totalPages: 0,
      itemPerPage: 10,
    };
  }

  cargarPresupuestoAsignadoInstitucion =async ()=>{
   await this.props.getListaPresupuestosAsignadosInstitucion();
    this.setState(
      { listaPresupuestoAsignadoInstitucionState: this.props.presupuestosAsignadosInstitucion},
      () => this.validarPaginacion()
    );
  }

  componentDidMount() {
    this.cargarPresupuestoAsignadoInstitucion();
  }

//   filtroPresupuestoAsignadoInstitucion = (medioVerificacion) => {
//     let filtroMedioVerificacion = [];
//     if (medioVerificacion.length > 0) {
//       filtroMedioVerificacion = this.props.mediosVerificacion.filter((medio) =>
//         medio.descripcion
//           .toUpperCase()
//           .includes(medioVerificacion.toUpperCase())
//       );
//     } else {
//       filtroMedioVerificacion = this.props.mediosVerificacion;
//     }

//     this.setState(
//       { listaMediosVerificacionState: filtroMedioVerificacion },
//       () => {
//         this.validarPaginacion();
//       }
//     );
//   };

  validarPaginacion = (page = 1, elementos = 10) => {
    let index = elementos * page - elementos;
    let listaPresupuestoAsignadoInstitucion = [];

    for (let i = 0; i < elementos; i++) {
        listaPresupuestoAsignadoInstitucion.push(
        this.state.listaPresupuestoAsignadoInstitucionState[index]
      );

      index++;
    }

    let totalPages = Math.ceil(
      this.state.listaPresupuestoAsignadoInstitucionState.length / elementos
    );
   // console.log(totalPages);
    this.setState({
      //detallePoaState: listaProductos,
      listaPresupuestoAsignadoInstitucionPageState: listaPresupuestoAsignadoInstitucion,
      activePage: page,
      totalPages,
      itemPerPage: elementos,
    });
  };

  render() {
    
    const { isLoading, presupuestoAsignadoInstitucion } = this.props;
    // console.log(presupuestoAsignadoInstitucion);
    return (
      <div className="pb-6 pt-5 pt-md-7">
        {!isLoading ? (
          <Container fluid>
            <div className="container bg-white border py-3">
              <div className="header-body">
                <div className="row">
                  {/* <div className="form-group  col-md-6 ">
                    <label htmlFor="filtro">
                      Filtro Medios de Verificación
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="filtro"
                      placeholder="Medio de verificación"
                      onKeyUp={(e) => {
                        e.preventDefault();
                        this.filtroMedioVerificacion(e.currentTarget.value);
                      }}
                    />
                  </div> */}
                  <div className="col-md-12  ">
                    <Link
                      className="btn btn-lg btn-info float-right mb-3"
                      to="/admin/presupuestosAsignadosInstitucion/crear"
                    >
                      Agregar nuevo Presupuesto Institución
                    </Link>
                  </div>
                </div>

                <Table
                  style={{ backgroundColor: "#fff", color: "black" }}
                  responsive
                  className="table-striped"
                >
                  <thead>
                    <tr className="text-left">
                      <th>Año</th>
                      <th>Monto</th>
                      <th>Estatus</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.listaPresupuestoAsignadoInstitucionPageState.map(
                      (presupuestoAsignadoInstitucion) =>
                      presupuestoAsignadoInstitucion !== null &&
                      presupuestoAsignadoInstitucion !== undefined && (
                        <tr></tr>
                        //   <MedioVerificacion
                        //     key={medioVerificacion.medioVerificacionId}
                        //     medioVerificacion={medioVerificacion}
                        //     user={this.props.user}
                        //   />
                        )
                    )}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-center">
                  <Pagination
                    prevPageText="ant"
                    nextPageText="sig"
                    firstPageText="prime"
                    lastPageText="ultimo"
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemPerPage}
                    totalItemsCount={
                      this.state.listaPresupuestoAsignadoInstitucionState.length
                    }
                    //pageRangeDisplayed={5}
                    onChange={this.validarPaginacion.bind(this)}
                  />
                </div>
              </div>
            </div>
          </Container>
        ) : (
          <Loading></Loading>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    presupuestosAsignadosInstitucion:state.presupuestosAsignadosInstitucion.presupuestosAsignadosInstitucion,
    isLoading: state.presupuestosAsignadosInstitucion.isLoading,
    user: state.auth.signed,
  };
};

const mapDispatchToProps = (dispatch) => ({
    getListaPresupuestosAsignadosInstitucion: ()=>dispatch(getListaPresupuestosAsignadosInstitucion()),
    deletePresupuestoAsignadoInstitucion: (presupuestoAsignadoInstitucion) => dispatch(deletePresupuestoAsignadoInstitucion(presupuestoAsignadoInstitucion)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(ListadoPresupuestoAsignadoInstitucion, [
    "Encargado Presupuesto",
    "Administrador general",
    "MAE",
  ])
);
