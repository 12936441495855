import {
  CREAR_NOTAS_PRODUCTO,
  NOTAS_PRODUCTO_FAILED,
  NOTA_PRODUCTO_SUCCEEDED,
  LOADING_NOTA_PRODUCTO,
  AGREGAR_NOTA_PRODUCTO,
} from "../types";

import { api_base } from "../../config";
import { getData } from "../../utils/api-client";

export const createNotaProducto = (notaProducto) => async (dispatch) => {
  let url = api_base + `api/v1/notas/crear`;

  dispatch({ type: LOADING_NOTA_PRODUCTO });
  await getData
    .post(url, JSON.stringify(notaProducto))
    .then((resultado) => {
      dispatch({ type: CREAR_NOTAS_PRODUCTO, payload: resultado.data });
      dispatch({ type: NOTA_PRODUCTO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({type: NOTAS_PRODUCTO_FAILED});
      }
    });
};

export const agregarNotaProducto = (notaProducto) => async (dispatch) => {
  dispatch({ type: AGREGAR_NOTA_PRODUCTO, payload: notaProducto });
};
