import React, { Component } from "react";

import { connect } from "react-redux";
import {
  createProducto,
  calcularPonderacionArea,
} from "../../redux/actions/productos-actions";
import {
  getEjesEstrategicos,
  getEstrategias,
  getObjetivosEstrategicos,
} from "../../redux/actions/planesEstrategicos-actions";
import { getInvolucrados } from "../../redux/actions/involucrados-actions";
import {
  getAreasOrganizacionales,
  getAreasOrganizacionalesResponsables,
} from "../../redux/actions/areas-actions";
import { getPonderacionArea } from "../../redux/actions/ponderacion-areas-action";
import {
  EjeEstrategico,
  ObjetivoEstrategico,
  Estrategia,
} from "./PlanesEstrategicos";
import { Authorization } from "./../../utils/Authorization";

import { Multiselect } from "react-widgets";
import Loading from "./../utils/Loading.jsx";

import { Container } from "reactstrap";
import swal from "sweetalert";
class CrearProducto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      ejesEstrategicos: [],
      objetivosEstrategicos: [],
      estrategias: [],
      validarTotalPonderacion: false,
    };
  }

  productoRef = React.createRef();
  resultadoRef = React.createRef();
  indicadorRef = React.createRef();
  metaRef = React.createRef();
  ponderacionAreaRef = React.createRef();
  ejeEstrategicoRef = React.createRef();
  objetivoGeneralRef = React.createRef();
  estrategiaRef = React.createRef();
  unidadResponsableRef = [];
  involucradosRef = React.createRef();
  // cooperacionRef = React.createRef();
  ponderacionGeneralRef = React.createRef();
  // donanteRef = React.createRef();
  // accionMitigacionRef = React.createRef();
  // riesgoAsociadoRef = React.createRef();

  componentDidMount() {
    this.unidadResponsableRef.push(this.props.user.areaOrganizacional);
    this.cargarData();
    this.calcularPonderacionTotalProductos();
  }

  cargarData = async () => {
    await this.props.getAreasOrganizacionalesResponsables();
    await this.props.getPonderacionArea(
      this.props.poaActivo.poaId,
      this.props.user.areaOrganizacional.areaOrganizacionalId
    );
    await this.props.getEjesEstrategicos();
    await this.props.getEstrategias();
    await this.props.getObjetivosEstrategicos();
    await this.props.getInvolucrados();

    const {
      ejesEstrategicos,
      estrategias,
      objetivosEstrategicos,
    } = this.props.planesEstrategicos;

    if (
      objetivosEstrategicos.length > 0 &&
      this.state.objetivosEstrategicos.length === 0
    ) {
      this.setState(
        { ejesEstrategicos, objetivosEstrategicos, estrategias },
        () => {
          if (
            ejesEstrategicos.length === 1 &&
            this.state.ejesEstrategicos.length > 0
          ) {
            this.setState(
              {
                objetivosEstrategicos: this.state.ejesEstrategicos[0]
                  .objetivosEstrategicos,
              },
              () => {
                if (this.state.objetivosEstrategicos.length === 1) {
                  this.setState({
                    estrategias: this.state.objetivosEstrategicos[0]
                      .estrategias,
                  });
                }
              }
            );
          }
        }
      );
    }
    this.setState({ isLoadingState: false });
  };

  crearProducto = async (e) => {
    //console.log(this.unidadResponsableRef);
    this.setState({ isLoadingState: true });
    e.preventDefault();
    if (
      this.validarProducto(
        this.productoRef.current.value,
        this.resultadoRef.current.value,
        this.indicadorRef.current.value,
        this.metaRef.current.value,
        this.ponderacionAreaRef.current.value,
        this.ejeEstrategicoRef.current.value,
        this.objetivoGeneralRef.current.value,
        this.estrategiaRef.current.value,
        this.unidadResponsableRef,
        this.involucradosRef
        // this.cooperacionRef.current.value,
        // this.donanteRef.current.value
      )
    ) {
      let producto = {
        descripcion: this.productoRef.current.value,
        resultado: this.resultadoRef.current.value,
        indicador: this.indicadorRef.current.value,
        meta: this.metaRef.current.value,
        ponderacionArea: this.ponderacionAreaRef.current.value,
        ejeEstrategico:
          this.ejeEstrategicoRef.current.value !== "--Seleccione una opción--"
            ? JSON.parse(this.ejeEstrategicoRef.current.value).ejeEstrategicoId
            : null,
        objetivoEstrategico:
          this.objetivoGeneralRef.current.value !== "--Seleccione una opción--"
            ? JSON.parse(this.objetivoGeneralRef.current.value)
                .objetivoEstrategicoId
            : null,
        estrategia:
          this.estrategiaRef.current.value !== "--Seleccione una opción--"
            ? JSON.parse(this.estrategiaRef.current.value).estrategiaId
            : null,
        unidadResponsables: this.unidadResponsableRef,
        involucrados: this.involucradosRef,
        // recursosExterno: this.cooperacionRef.current.value,
        poaId: this.props.poaActivo.poaId,
        usuarioId: 2,
        ponderacionGeneral: this.ponderacionGeneralRef.current.value,
        nivelAprobacionId: this.props.user.nivelAprobacion.nivelAprobacionId,
        // donante:
        //   this.donanteRef.current !== null ? this.donanteRef.current.value : "",
        // accionMitigacion: this.accionMitigacionRef.current.value,
        // riesgoAsociado: this.riesgoAsociadoRef.current.value,
      };
      await this.props.createProducto(producto);

      this.props.history.push("/admin/productos/index");
    }
    this.setState({ isLoadingState: false });
  };

  validarProducto = (
    producto,
    resultado,
    indicador,
    meta,
    ponderacionArea,
    ejeEstrategico,
    objetivoGeneral,
    estrategia,
    unidadResponsable,
    involucrados,
    cooperacion,
    donante
  ) => {
    if (producto === "") {
      swal(
        "Crear Producto",
        "El campo producto es un valor requerido",
        "error"
      );

      return false;
    }
    if (resultado === "") {
      swal(
        "Crear Producto",
        "El campo resultado es un valor requerido",
        "error"
      );

      return false;
    }
    if (indicador === "") {
      swal(
        "Crear Producto",
        "El campo indicador es un valor requerido",
        "error"
      );

      return false;
    }
    if (meta === "") {
      swal("Crear Producto", "El campo meta es un valor requerido", "error");

      return false;
    }
    if (ponderacionArea === "") {
      swal(
        "Crear Producto",
        "El campo ponderación del producto en la unidad es un valor requerido",
        "error"
      );

      return false;
    }
    if (ejeEstrategico === "--Seleccione una opción--") {
      swal(
        "Crear Producto",
        "El campo eje estrategico es un valor requerido",
        "error"
      );

      return false;
    }
    if (objetivoGeneral === "--Seleccione una opción--") {
      swal(
        "Crear Producto",
        "El campo objetivo estratégico es un valor requerido",
        "error"
      );

      return false;
    }
    if (estrategia === "--Seleccione una opción--") {
      swal(
        "Crear Producto",
        "El campo estrategico es un valor requerido",
        "error"
      );

      return false;
    }
    if (unidadResponsable === "--Seleccione una opción--") {
      swal(
        "Crear Producto",
        "El campo unidad responsable es un valor requerido",
        "error"
      );

      return false;
    }
    if (unidadResponsable.length === 0) {
      swal(
        "Crear Producto",
        "El campo unidad responsable es un valor requerido",
        "error"
      );

      return false;
    }

    if (donante === "" && cooperacion !== "") {
      swal(
        "Crear Producto",
        "El campo donante es un valor requerido si el producto lleva cooperación",
        "error"
      );

      return false;
    }

    if (donante !== "" && cooperacion === "") {
      swal(
        "Crear Producto",
        "El campo cooperación es un valor requerido si el producto lleva donante",
        "error"
      );

      return false;
    }
    return true;
  };
  calcularPonderacionTotalProductos = () => {
    let ponderacionArea =
      this.ponderacionAreaRef.current !== null &&
      this.ponderacionAreaRef.current.value !== ""
        ? this.ponderacionAreaRef.current.value
        : 0;
    let total =
      this.props.totalPonderacionProductos + parseFloat(ponderacionArea);
    if (total <= 100) {
      this.setState({ validarTotalPonderacion: true });
    } else {
      this.setState({ validarTotalPonderacion: false });
      swal(
        "Crear Producto",
        "Excede el límite de ponderación permitido para los productos, actualmente tiene " +
          total +
          "%",
        "error"
      );
    }
  };

  calcularPonderacionArea = (e) => {
    e.preventDefault();

    if (Object.keys(this.props.ponderacionArea).length > 0) {
      this.ponderacionGeneralRef.current.value =
        this.ponderacionAreaRef.current.value *
        (this.props.ponderacionArea.ponderacion / 100);
    }
  };

  render() {
    const {
      ejesEstrategicos,
      estrategias,
      objetivosEstrategicos,
    } = this.props.planesEstrategicos;

    const { involucrados, areasOrganizacionales } = this.props;
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container>
          {this.state.isLoadingState ? (
            <Loading></Loading>
          ) : (
            <div className="container bg-white border">
              <form
                className="needs-validation p-3 "
                onSubmit={this.crearProducto}
                noValidate
              >
                    <div className="row">
                  <div className="form-group col-md-6 ">
                    <label htmlFor="ejeEstrategico">Eje Estratégico</label>
                    <select
                      className="custom-select custom-select-sm text-dark"
                      id="ejeEstrategico"
                      ref={this.ejeEstrategicoRef}
                      onChange={() => {
                        if (
                          this.ejeEstrategicoRef.current.value !==
                          "--Seleccione una opción--"
                        ) {
                          this.setState({
                            objetivosEstrategicos: JSON.parse(
                              this.ejeEstrategicoRef.current.value
                            ).objetivosEstrategicos,
                          });
                        } else {
                          this.setState({
                            objetivosEstrategicos: objetivosEstrategicos,
                            estrategias: estrategias,
                          });
                        }
                      }}
                    >
                      {ejesEstrategicos.length > 1 && (
                        <option defaultValue>--Seleccione una opción--</option>
                      )}

                      {ejesEstrategicos.map((ejeEstrategico) => (
                        <EjeEstrategico
                          key={ejeEstrategico.ejeEstrategicoId}
                          EjeEstrategico={ejeEstrategico}
                        ></EjeEstrategico>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-md-6 ">
                    <label htmlFor="objetivoGeneral">
                      Objetivo Estratégico
                    </label>
                    <select
                      className="custom-select custom-select-sm text-dark"
                      id="objetivoGeneral"
                      ref={this.objetivoGeneralRef}
                      onChange={() => {
                        if (
                          this.objetivoGeneralRef.current.value !==
                          "--Seleccione una opción--"
                        ) {
                          this.setState({
                            estrategias: JSON.parse(
                              this.objetivoGeneralRef.current.value
                            ).estrategias,
                          });
                        } else {
                        }
                      }}
                    >
                      {this.state.objetivosEstrategicos.length > 1 && (
                        <option defaultValue>--Seleccione una opción--</option>
                      )}
                      {this.state.objetivosEstrategicos.map(
                        (objetivoEstrategico) => (
                          <ObjetivoEstrategico
                            key={objetivoEstrategico.objetivoEstrategicoId}
                            objetivoEstrategico={objetivoEstrategico}
                          ></ObjetivoEstrategico>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6 ">
                    <label htmlFor="estrategia">Estrategia</label>
                    <select
                      className="custom-select custom-select-sm text-dark"
                      id="estrategia"
                      ref={this.estrategiaRef}
                    >
                      {this.state.estrategias.length > 1 && (
                        <option defaultValue>--Seleccione una opción--</option>
                      )}
                      {this.state.estrategias.map((estrategia) => (
                        <Estrategia
                          key={estrategia.estrategiaId}
                          estrategia={estrategia}
                        ></Estrategia>
                      ))}
                    </select>
                  </div>
                  <div className="form-group input-group-sm col-md-6 ">
                    <label htmlFor="unidadResponsable">
                      Unidad Responsable
                    </label>
                    <Multiselect
                      placeholder="--Seleccione las unidades responsables--"
                      data={areasOrganizacionales}
                      id="unidadResponsable"
                      textField="nombre"
                      defaultValue={areasOrganizacionales.filter(
                        (area) =>
                          area.areaOrganizacionalId ===
                          this.unidadResponsableRef[0].areaOrganizacionalId
                      )}
                      onChange={(e) => {
                        this.unidadResponsableRef = [];
                        e.map((area) =>
                          this.unidadResponsableRef.push(
                            area.areaOrganizacionalId
                          )
                        );
                      }}
                      minLength={3}
                    />
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="descripción">Producto</label>
                  <input
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="descripción"
                    placeholder="Nombre del producto"
                    ref={this.productoRef}
                  />
                  <div className="invalid-feedback">
                    Este es un campo obligatorio
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="resultado">Resultado (s)</label>
                  <textarea
                    className="form-control form-control-sm text-dark"
                    id="resultado"
                    placeholder="Resultado del producto"
                    ref={this.resultadoRef}
                  ></textarea>
                </div>
                {/* <div className="row">
                  <div className="form-group col-md-6 ">
                    <label htmlFor="riesgoAsociado">Riesgos Asociados</label>
                    <textarea
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="riesgoAsociado"
                      placeholder="Riesgo Asociado"
                      ref={this.riesgoAsociadoRef}
                    />
                  </div>
                  <div className="form-group col-md-6 ">
                    <label htmlFor="accionMitigacion">
                      Acción de Mitigación{" "}
                    </label>
                    <textarea
                      className="form-control form-control-sm  text-dark"
                      id="accionMitigacion"
                      placeholder="Accion de Mitigación"
                      ref={this.accionMitigacionRef}
                    />
                  </div>
                </div> */}
                <div className="row">
                  <div className="form-group col-md-6 ">
                    <label htmlFor="indicador">Indicador (es)</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="indicador"
                      placeholder="Indicador del producto"
                      ref={this.indicadorRef}
                    />
                  </div>
                  <div className="form-group col-md-6 ">
                    <label htmlFor="meta">Meta</label>
                    <input
                      type="text"
                      className="form-control form-control-sm  text-dark"
                      id="meta"
                      placeholder="Meta del producto"
                      ref={this.metaRef}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6  ">
                    <label htmlFor="ponderacionArea">
                      Ponderación del Producto en la Unidad
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control form-control-sm text-dark"
                        id="ponderacionArea"
                        placeholder="Ponderación del Producto en la Unidad"
                        onKeyUp={(e) => {
                          e.preventDefault();
                          this.calcularPonderacionArea(e);
                          this.calcularPonderacionTotalProductos();
                        }}
                        ref={this.ponderacionAreaRef}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text text-dark form-control-sm "
                          style={{ backgroundColor: "#e9ecef" }}
                        >
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6 ">
                    <label htmlFor="ponderacionGeneral">
                      Ponderación General
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control form-control-sm text-dark"
                        id="ponderacionGeneral"
                        placeholder="Ponderacion General del Producto"
                        ref={this.ponderacionGeneralRef}
                        disabled
                        title="Este campo es llenado por el departamento de PPyD"
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text text-dark form-control-sm "
                          style={{
                            backgroundColor: "#e9ecef",
                            borderLeft: "2px solid  #bebaba",
                          }}
                        >
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group input-group-sm col-md-6">
                    <label htmlFor="involucrados">Involucrados</label>

                    <Multiselect
                      placeholder="--Seleccione los involucrados--"
                      data={involucrados}
                      textField="descripcion"
                      id="involucrados"
                      onChange={(e) => {
                        this.involucradosRef = [];
                        e.map((involucrado) =>
                          this.involucradosRef.push(involucrado.involucradoId)
                        );
                      }}
                      minLength={3}
                      required
                    />
                  </div>
                </div>
                {this.state.validarTotalPonderacion ? (
                  <button type="submit" className="btn btn-primary">
                    Guardar
                  </button>
                ) : (
                  <button
                    onClick={(e) => e.preventDefault()}
                    className="btn btn-primary opacity"
                    title="Excede el límite de ponderación permitido para los productos"
                  >
                    Guardar
                  </button>
                )}
              </form>
            </div>
          )}
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    poaActivo: state.poas.poaActivo,
    planesEstrategicos: state.planesEstrategicos,
    involucrados: state.involucrados.involucrados,
    areasOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
    user: state.auth.signed,
    totalPonderacionProductos: state.productos.totalPonderacionProductos,
    ponderacionArea: state.ponderacionAreas.ponderacionArea,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getEjesEstrategicos: () => dispatch(getEjesEstrategicos()),
  getEstrategias: () => dispatch(getEstrategias()),
  getObjetivosEstrategicos: () => dispatch(getObjetivosEstrategicos()),
  getInvolucrados: () => dispatch(getInvolucrados()),
  getAreasOrganizacionalesResponsables: () =>
    dispatch(getAreasOrganizacionalesResponsables()),
  createProducto: (producto) => dispatch(createProducto(producto)),
  getPonderacionArea: (poaId, areaOrganizacionalId) =>
    dispatch(getPonderacionArea(poaId, areaOrganizacionalId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(CrearProducto, [
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Gestor del POA",
    "MAE",
  ])
);
