import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { deleteTipoAsignacion } from "../../../redux/actions/tipos_asignaciones_action";
import { Button } from "reactstrap";

class TipoAsignacion extends Component {
  eliminarTipoAsignacion = async () => {
    let confirmed = window.confirm(
      "Está seguro de eliminar el tipo de asignación"
    );
    if (confirmed) {
     await this.props.deleteTipoAsignacion(this.props.tipoAsignacion);
     this.props.cargarTiposAsignaciones()
    }
  };

  render() {
    const {
      descripcion,
      nombre,
      estatus,
      tipoAsignacionId
    } = this.props.tipoAsignacion;

    return (
      <tr>
        <td> {nombre}</td>
        <td> {descripcion}</td>
        <td>{estatus ? "Activo" : "Inactivo"}</td>
        <td>
            <Link
              // to={`/admin/medioverificacion/editar/${medioVerificacionId}`}
              to={{
                pathname: `/admin/tipoAsignacion/editar/${tipoAsignacionId}`,
                state: "/admin/tipoAsignacion/editar/:tipoAsignacionId",
              }}
              className="btn btn-success btn-sm"
            >
              Editar
            </Link>
          <button
            onClick={() => this.eliminarTipoAsignacion()}
            className="btn btn-danger btn-sm"
          >
            Eliminar
          </button>
        </td>
      </tr>
    );
  }
}

export default connect(null, { deleteTipoAsignacion })(TipoAsignacion);
