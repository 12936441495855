import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../../utils/Loading.jsx";
import { Link } from "react-router-dom";
import { Authorization } from "../../../utils/Authorization.js";
import { Table, Container } from "reactstrap";
import numbro from "numbro";
import { UncontrolledTooltip } from "reactstrap";

import {
  getPoaElaboracion,
  mostrarPoas,
  mostrarPoa,
} from "../../../redux/actions/poa-actions.js";
import {
  getProductosAdministracionNopoa,
  calcularPonderacionGeneral,
  getTotalProductosAdministracionNopoa,
  cleanProductosAdministracionNopoa,
  getDocumentoDescargar
} from "../../../redux/actions/productos-actions.js";
import Pagination from "react-js-pagination";
import { getAreasOrganizacionales } from "../../../redux/actions/areas-actions.js";

class ListaAdminProductosNoPoa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: false,
      activePage: 1,
      totalPages: 0,
      itemPerPage: 10,
      listaProductosState: [],
      listaProductosPageState: [],
      areaidSeleccionada: 0,
    };
  }

  cargarData = async () => {
    this.setState({ isLoadingState: true });
    let start = 0;
    await this.props.cleanProductosAdministracionNopoa();
    await this.props.getPoaElaboracion();
    const { poaId } = this.props.poaElaboracion;
    const area = this.state.areaidSeleccionada;
    await this.props.getTotalProductosAdministracionNopoa(poaId, area);

    let paginas =
      this.props.totalProductosAdministracionNoPoa !== 0
        ? Math.round(this.props.totalProductosAdministracionNoPoa / 10)
        : 1;

    for (let index = 0; index < paginas; index++) {
      start = index * 10 + 1;
      await this.props.getProductosAdministracionNopoa(
        start,
        this.props.user.areaOrganizacional.areaOrganizacionalId
      );
    }

    await this.props.calcularPonderacionGeneral(poaId);
    await this.props.mostrarPoa(poaId);
    this.setState(
      {
        isLoadingState: false,
        listaProductosState: this.props.productosAdministracionNoPoa,
      },
      () => {
        this.validarPaginacion();
      }
    );
  };

  cargarDatos = async (poaId, area = 0) => {
    if (area > 0) {
      this.setState({ areaidSeleccionada: area });
    }
    this.setState({ isLoadingState: true });
    let start = 0;
    await this.props.cleanProductosAdministracionNopoa();
    await this.props.getTotalProductosAdministracionNopoa(poaId, area);
    let paginas =
      this.props.totalProductosAdministracionNoPoa !== 0
        ? Math.round(this.props.totalProductosAdministracionNoPoa / 10)
        : 1;
    for (let index = 0; index < paginas; index++) {
      start = index * 10 + 1;
    }
    await this.props.getProductosAdministracionNopoa(start, area);
    await this.props.calcularPonderacionGeneral(poaId);
    await this.props.mostrarPoa(poaId);

    this.setState(
      {
        isLoadingState: false,
        listaProductosState: this.props.productosAdministracionNoPoa,
      },
      () => {
        this.validarPaginacion();
      }
    );
  };

  validarPaginacion = async (page = 1, elementos = 10) => {
    const { poaId } = this.props.poaElaboracion;
    let index = elementos * page - elementos;
    await this.props.getTotalProductosAdministracionNopoa(
      poaId,
      this.state.areaidSeleccionada
    );
    let paginas =
      this.props.totalProductosAdministracionNoPoa !== 0
        ? Math.round(this.props.totalProductosAdministracionNoPoa / 10)
        : 1;
    await this.props.getProductosAdministracionNopoa(
      index,
      this.state.areaidSeleccionada
    );
    let listaProductos = this.props.productosAdministracionNoPoa;
    let totalPages = paginas;
    this.setState({
      listaProductosPageState: listaProductos,
      activePage: page,
      totalPages,
      itemPerPage: elementos,
    });
  };

  componentDidMount() {
    this.cargarData();
    this.setState({
      areaidSeleccionada: this.props.user.areaOrganizacional.areaOrganizacionalId,
    });
  }

  validarOpcionEditar = (productoNopoa) => {
    let resultado = true;
    if (this.props.productosAdministracionNoPoa.length > 0 && productoNopoa != null) {
      resultado =
        this.props.user.nivelAprobacion.nivelAprobacionId ===
        productoNopoa.nivelAprobacion.nivelAprobacionId;
    }
    // console.log(resultado, "resultado aprobacion")
    return resultado;
  };

  // eliminarProducto = (producto) => {
  //   const { poaId } = producto.poa;
  //   this.props.deleteProducto(producto, poaId);
  // };

  truncateString = (str) => {
    let num = 62;

    return str && str.length > num ? str.slice(0, num) + "..." : str;
  };

  descargarDocumentoNoPoa = async (productoNoPoaId) => {
    await this.props.getDocumentoDescargar(productoNoPoaId);
  }

  render() {
    const {
      productosAdministracionNoPoa,
      isLoading,
      totalProductosAdministracionNoPoa,
    } = this.props;
    const {
      listaProductosPageState,
      activePage,
      itemPerPage,
    } = this.state;
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <div className="container bg-white border py-3">
            {isLoading || this.state.isLoadingState ? (
              <Loading></Loading>
            ) : (
              <div className="header-body">
                <div className="row ">
                  <div className="col-md-4">
                    <label>Año</label>
                    <select
                      className="form-control"
                      defaultValue={this.props.poaElaboracion.poaId}
                      id="anoSelected"
                      ref={this.anoSelectedRef}
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({ isLoadingState: true });
                        this.cargarDatos(
                          document.getElementById("anoSelected").value,
                          document.getElementById("areaSelected").value
                        );
                      }}
                    >
                      {this.props.poas.map((poa) => (
                        <option key={poa.poaId} value={poa.poaId}>
                          {poa.ano}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label>Filtro Área</label>
                    <select
                      className="form-control"
                      defaultValue={this.state.areaidSeleccionada}
                      id="areaSelected"
                      onChange={(e) => {
                        e.preventDefault();

                        this.setState({ isLoadingState: true });
                        this.setState({
                          areaidSeleccionada: document.getElementById(
                            "areaSelected"
                          ).value,
                        });
                        this.cargarDatos(
                          document.getElementById("anoSelected").value,
                          document.getElementById("areaSelected").value
                        );
                      }}
                    >
                      {this.props.areaOrganizacionales.map((area) => (
                        <option
                          key={area.areaOrganizacionalId}
                          value={area.areaOrganizacionalId}
                        >
                          {area.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="mt-2">
                  {productosAdministracionNoPoa.length > 0 ? (
                    <div>
                      <Table className="table-flush table-striped" responsive>
                        <thead>
                          <tr>
                            <th>Área Organizacional</th>
                            <th>Producto</th>
                            <th>Presupuesto</th>
                            <th>Documento</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listaProductosPageState.map(
                            (producto) =>
                              producto !== null &&
                              producto !== undefined && (
                                <tr key={producto.id}>
                                  <td title={producto.areaOrganizacional.nombre}>
                                    {this.truncateString(
                                      producto.areaOrganizacional.nombre
                                    )}
                                  </td>
                                  <td title={producto.descripcion}>
                                    {this.truncateString(producto.descripcion)}
                                  </td>
                                  <td title={''}>
                                  {'RD$ '+  numbro( producto.actividadesNopoa.reduce((accumulator, obj)=>{
                                                  return accumulator + (obj.presupuestoNopoa.reduce((value, objPresupuesto)=>{ return value +(objPresupuesto.valor*objPresupuesto.cantidad*objPresupuesto.frecuencia)},0))
                                                   },0)).format({thousandSeparated:true})
                                  }
                                  </td>
                                  <td>
                                  { producto.documento ?
                                    <a 
                                    className="p-2" 
                                    title={ producto.documento } 
                                    onClick={async () => {
                                      this.descargarDocumentoNoPoa(producto.productoNoPoaId)
                                    }}
                                    >
                                    <div>
                                      <i 
                                      className="fas fa-download text-primary"
                                      disabled={true}
                                      id="TooltipDescargarDocumentoNoPoa"
                                      >
                                      </i>
                                      <UncontrolledTooltip
                                        placement="down"
                                        target="TooltipDescargarDocumentoNoPoa"
                                      >
                                        {producto.documento}
                                      </UncontrolledTooltip>
                                    </div>
                                    </a>
                                    : <a className="p-2">
                                    <i className="fas fa-download text-muted"></i>
                                    </a>
                                    }
                                  </td>
                                  <td className="d-flex flex-row">
                                    <Link
                                      to={{
                                        pathname: `/admin/administracion/productoNoPoa/detalle/${producto.productoNoPoaId}`,
                                        state:
                                          "/admin/administracion/producto/productoNoPoa/:productoId",
                                      }}
                                      className="btn btn-primary btn-sm"
                                    >
                                      Detalle
                                    </Link>

                                    {this.validarOpcionEditar(producto) ? (
                                      <>
                                      <Link
                                        to={{
                                          pathname: `/user/productoNoPoa/detalle/${producto.productoNoPoaId}`,
                                          state:
                                            "/user/productoNoPoa/detalle/:productoId",
                                        }}
                                        className="btn btn-info btn-sm"
                                      >
                                        Aprobar
                                      </Link>
                                      <Link
                                      to={{
                                        pathname: `/user/productoNoPoa/detalle/${producto.productoNoPoaId}`,
                                        state:
                                          "/user/productoNoPoa/detalle/:productoId",
                                      }}
                                      className="btn btn-danger btn-sm"
                                    >
                                      Devolver
                                    </Link>
                                    </>
                                    ) : (
                                      <>
                                        <button className="btn btn-sm btn-success" disabled>
                                          Aprobado
                                        </button>
                                        
                                      </>
                                    )}
                                  </td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </Table>
                      <div className="d-flex justify-content-center">
                        <Pagination
                          prevPageText="ant"
                          nextPageText="sig"
                          firstPageText="prime"
                          lastPageText="ultimo"
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={activePage}
                          itemsCountPerPage={itemPerPage}
                          totalItemsCount={totalProductosAdministracionNoPoa}
                          onChange={this.validarPaginacion.bind(this)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="row d-flex justify-content-center mb-3">
                      <h3>No existen productos agregadas</h3>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.productosNoPoa.isLoading,
  productosAdministracionNoPoa: state.productosNoPoa.productosAdministracionNoPoa,
  poaElaboracion: state.poas.poaElaboracion,
  totalProductosAdministracionNoPoa: state.productosNoPoa.totalProductosAdministracionNoPoa,
  poas: state.poas.poas,
  user: state.auth.signed,
  ponderacionGeneral: state.productosNoPoa.totalPonderacionActividadesNoPoa,
  productosNoPoa: state.productosNoPoa.productosNoPoa,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
});
const mapDispatchToProps = (dispatch) => ({
  getProductosAdministracionNopoa: (start, area) => dispatch(getProductosAdministracionNopoa(start, area)),
  calcularPonderacionGeneral: (poaId) => dispatch(calcularPonderacionGeneral(poaId)),
  getPoaElaboracion: () => dispatch(getPoaElaboracion()),
  mostrarPoas: () => dispatch(mostrarPoas()),
  mostrarPoa: (poaId) => dispatch(mostrarPoa(poaId)),
  getTotalProductosAdministracionNopoa: (poaId, areaid) => dispatch(getTotalProductosAdministracionNopoa(poaId, areaid)),
  cleanProductosAdministracionNopoa: () => dispatch(cleanProductosAdministracionNopoa()),
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
  getDocumentoDescargar: (productoNoPoaId) => dispatch(getDocumentoDescargar(productoNoPoaId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(ListaAdminProductosNoPoa, [
    "Administrador general",
    "Gestor del POA",
    "MAE",
  ])
);
