import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  mostrarPoas,
  mostrarDetallePoa,
  getReportePoa,
} from "./../../redux/actions/poa-actions";
import Loading from "./../utils/Loading.jsx";
import { Authorization } from "./../../utils/Authorization";
import Pagination from "react-js-pagination";
import { ModalLoading } from "./../utils/ModalLoading";
import {
  getEjesEstrategicosGeneral,
  getObjetivosEstrategicosGeneral,
  getEstrategiasGeneral,
} from "./../../redux/actions/planesEstrategicos-actions";

import { getAreasOrganizacionales } from "./../../redux/actions/areas-actions";

import {
  Container
} from "reactstrap";

class DetallePoa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ano: 0,
      poaId: 0,
      detallePoaState: [],
      detallePoaPage: [],
      activePage: 1,
      totalPages: 0,
      itemPerPage: 3,
      isloadingState: true,
      modal: false,
      mensaje: "",
      ejestrategicoState: "",
      objetivoState: "",
      estrategiaState: "",
      areaOrganizacionalState: "",
    };
  }
  componentDidMount() {
    const { ano, poaId } = this.props.match.params;
    this.cargarData(poaId, ano);
    // this.props.mostrarPoas();
  }

  filtroAreaOrganizacional = () => {
    let FiltroDetalle = [];
    const {
      ejestrategicoState,
      objetivoState,
      areaOrganizacionalState,
      estrategiaState,
    } = this.state;

    if (areaOrganizacionalState.length > 0) {
      FiltroDetalle = this.props.detallePoa.filter(
        (area) => area.unidadResponsable === areaOrganizacionalState
      );
    } else {
      FiltroDetalle = this.props.detallePoa;
    }

    if (ejestrategicoState.length > 0) {
      FiltroDetalle = FiltroDetalle.filter(
        (eje) => eje.ejeEstrategico === ejestrategicoState
      );
    }

    if (objetivoState.length > 0) {
      FiltroDetalle = FiltroDetalle.filter(
        (objetivo) => objetivo.objetivoEstrategico === objetivoState
      );
    }

    if (estrategiaState.length > 0) {
      FiltroDetalle = FiltroDetalle.filter(
        (estrategia) => estrategia.estrategia === estrategiaState
      );
    }

    this.setState({ detallePoaState: FiltroDetalle }, () => {
      this.validarPaginacion();
    });
  };

  cargarData = async (poaId, ano) => {
    // console.log(this.state.isloadingState);
    await this.props.mostrarDetallePoa(poaId);
    await this.props.getEjesEstrategicosGeneral();
    await this.props.getObjetivosEstrategicosGeneral();
    await this.props.getEstrategiasGeneral();
    await this.props.getAreasOrganizacionales();
    this.setState(
      {
        ano,
        poaId,
        isloadingState: false,
        detallePoaState: this.props.detallePoa,
      },
      () => {
        this.validarPaginacion();
      }
    );
  };

  truncateString = (str) => {
    let num = 60;

    return str && str.length > num ? str.slice(0, 57) + "..." : str;
  };

  reportePoa = async () => {
    const { ano, poaId } = this.props.poaActivo;

    this.setState({
      modal: true,
      mensaje: `Elaborando Reporte del POA ${ano}`,
    });
    await this.props.getReportePoa(poaId);
    this.setState({
      mensaje: `Reporte del POA ${ano} descargado`,
    });

    setTimeout(() => {
      this.setState({ modal: false });
    }, 1500);
  };

  validarPaginacion = (page = 1, elementos = 3) => {
    let index = elementos * page - elementos;
    let listaProductos = [];

    for (let i = 0; i < elementos; i++) {
      listaProductos.push(this.state.detallePoaState[index]);

      index++;
    }

    let totalPages = Math.ceil(this.state.detallePoaState.length / elementos);
    this.setState({
      detallePoaPage: listaProductos,
      activePage: page,
      totalPages,
      itemPerPage: elementos,
    });
  };

  render() {
    const { detallePoa } = this.props;

    return (
      <>
        {!this.state.isloadingState ? (
          <div className="pb-6 pt-5 pt-md-7">
            <Container fluid>
              <div className="pr-2 pl-2 bg-white border py-3">
                <div className="header-body">
                  <h3>PLAN OPERATIVO ANUAL {this.state.ano}</h3>
                  <div className="row">
                    <div className="col-md-12 ">
                      <h4 className="mt-3">Filtros POA</h4>
                    </div>
                    <div className="col-md-3">
                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState(
                            { areaOrganizacionalState: e.currentTarget.value },
                            () => this.filtroAreaOrganizacional()
                          );
                        }}
                      >
                        <option key="0" value={""}>
                          --Todas las Áreas
                        </option>
                        {this.props.areasOrganizacionales.map((area) => (
                          <option key={area.nombre} value={area.nombre}>
                            {area.nombre}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState(
                            { ejestrategicoState: e.currentTarget.value },
                            () => this.filtroAreaOrganizacional()
                          );
                        }}
                      >
                        <option key="0" value={""}>
                          --Todos los Ejes Estrategicos
                        </option>
                        {this.props.planesEstrategicos.ejesEstrategicos.map(
                          (eje) => (
                            <option
                              key={eje.descripcion}
                              value={eje.descripcion}
                            >
                              {eje.descripcion}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <select
                        className="form-control"
                        onChange={(e) =>
                          this.setState(
                            { objetivoState: e.currentTarget.value },
                            () => this.filtroAreaOrganizacional()
                          )
                        }
                      >
                        <option key="0" value={""}>
                          --Todos los Objetivos Estrategicos
                        </option>
                        {this.props.planesEstrategicos.objetivosEstrategicos.map(
                          (objetivo) => (
                            <option
                              key={objetivo.descripcion}
                              value={objetivo.descripcion}
                            >
                              {objetivo.descripcion}
                            </option>
                          )
                        )}
                      </select>
                    </div>{" "}
                    <div className="col-md-3">
                      <select
                        className="form-control"
                        onChange={(e) =>
                          this.setState(
                            { estrategiaState: e.currentTarget.value },
                            () => this.filtroAreaOrganizacional()
                          )
                        }
                      >
                        <option key="0" value={""}>
                          --Todas las Estrategías
                        </option>
                        {this.props.planesEstrategicos.estrategias.map(
                          (estrategia) => (
                            <option
                              key={estrategia.descripcion}
                              value={estrategia.descripcion}
                            >
                              {estrategia.descripcion}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>

                  <button
                    className="btn btn-info float-right mt-3"
                    onClick={(e) => {
                      e.preventDefault();
                      this.reportePoa();
                    }}
                  >
                    Exportar Poa
                  </button>

                  {detallePoa.length > 0 ? (
                    <React.Fragment>
                      <table
                        className="table table-bordered table-sm table-responsive "
                        style={{ lineHeight: 1 }}
                      >
                        <thead>
                          <tr
                            className="text-center"
                            style={{
                              backgroundColor: "#ee2a24",
                              color: "white",
                            }}
                          >
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                            <th>5</th>
                            <th>6</th>
                            <th>7</th>
                            <th>8</th>
                            <th>9</th>
                          </tr>
                          <tr
                            className="text-center"
                            style={{
                              backgroundColor: "#003876",
                              color: "white",
                            }}
                          >
                            <th style={{ whiteSpace: "normal" }} rowSpan={2}>
                              Producto (s)
                            </th>
                            <th style={{ whiteSpace: "normal" }} rowSpan={2}>
                              Resultado (s)
                            </th>
                            <th style={{ whiteSpace: "normal" }} rowSpan={2}>
                              Indicador (es)
                            </th>
                            <th style={{ whiteSpace: "normal" }} rowSpan={2}>
                              Meta
                            </th>
                            <th style={{ whiteSpace: "normal" }} rowSpan={2}>
                              Unidad Responsables
                            </th>
                            <th style={{ whiteSpace: "normal" }} rowSpan={2}>
                              involucrado (s)
                            </th>
                            <th style={{ whiteSpace: "normal" }} rowSpan={2}>
                              Actividad (es)
                            </th>
                            <th style={{ whiteSpace: "normal" }} rowSpan={2}>
                              Medio Verificación
                            </th>

                            <th
                              rowSpan={1}
                              colSpan="4"
                              style={{
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                              }}
                            >
                              Cronograma
                              <table>
                                <thead>
                                  <tr>
                                    <th>E-M</th>
                                    <th>A-J</th>
                                    <th>J-S</th>
                                    <th>O-D</th>
                                  </tr>
                                </thead>
                              </table>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.detallePoaPage.map(
                            (producto) =>
                              producto !== null &&
                              producto !== undefined && (
                                <tr key={producto.producto}>
                                  <td
                                    rowSpan={producto.colspan}
                                    style={{
                                      whiteSpace: "normal",
                                      verticalAlign: "inherit",
                                    }}
                                    title={producto.producto.trim()}
                                  >
                                    {producto.producto.trim()}
                                  </td>
                                  <td
                                    rowSpan={producto.colspan}
                                    style={{
                                      whiteSpace: "normal",
                                      verticalAlign: "inherit",
                                    }}
                                  >
                                    {producto.resultado.trim()}
                                  </td>
                                  <td
                                    rowSpan={producto.colspan}
                                    style={{
                                      whiteSpace: "normal",
                                      verticalAlign: "inherit",
                                    }}
                                  >
                                    {producto.indicador.trim()}
                                  </td>
                                  <td
                                    rowSpan={producto.colspan}
                                    style={{
                                      whiteSpace: "normal",
                                      verticalAlign: "inherit",
                                    }}
                                  >
                                    {producto.meta}
                                  </td>
                                  <td
                                    rowSpan={producto.colspan}
                                    style={{
                                      whiteSpace: "normal",
                                      verticalAlign: "inherit",
                                    }}
                                  >
                                    {producto.unidadResponsable.trim()}
                                  </td>

                                  <td
                                    rowSpan={producto.colspan}
                                    style={{
                                      whiteSpace: "normal",
                                      verticalAlign: "inherit",
                                    }}
                                  >
                                    {producto.involucrados}
                                  </td>
                                  <td
                                    rowSpan={producto.colspan}
                                    style={{ padding: 0 }}
                                  >
                                    <table>
                                      <tbody>
                                        {producto.actividades.map(
                                          (actividad) => (
                                            <tr key={actividad.actividadId}>
                                              <td
                                                title={actividad.actividad}
                                                style={{
                                                  whiteSpace: "normal",
                                                  height: 100,
                                                }}
                                              >
                                                {this.truncateString(
                                                  actividad.actividad
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                  <td
                                    rowSpan={producto.colspan}
                                    style={{ padding: 0 }}
                                  >
                                    <table style={{ tableLayout: "fixed" }}>
                                      <tbody>
                                        {producto.actividades.map(
                                          (actividad) => (
                                            <tr key={actividad.actividadId}>
                                              <td
                                                style={{
                                                  whiteSpace: "normal",
                                                  height: 100,
                                                  width: 150,
                                                  verticalAlign: "inherit",
                                                }}
                                              >
                                                {this.truncateString(
                                                  actividad.medioVerificacion
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </td>

                                  <td
                                    rowSpan={producto.colspan}
                                    style={{ padding: 0 }}
                                  >
                                    <table>
                                      <tbody>
                                        {producto.actividades.map(
                                          (actividad) => (
                                            <tr key={actividad.actividadId}>
                                              <td
                                                style={{
                                                  whiteSpace: "normal",
                                                  height: 100,
                                                  width: 45,
                                                }}
                                              >
                                                {actividad.t1}
                                              </td>
                                              <td
                                                style={{
                                                  whiteSpace: "normal",
                                                  height: 100,
                                                  width: 45,
                                                }}
                                              >
                                                {actividad.t2}
                                              </td>
                                              <td
                                                style={{
                                                  whiteSpace: "normal",
                                                  height: 100,
                                                  width: 45,
                                                }}
                                              >
                                                {actividad.t3}
                                              </td>
                                              <td
                                                style={{
                                                  whiteSpace: "normal",
                                                  height: 100,
                                                  width: 45,
                                                }}
                                              >
                                                {actividad.t4}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                      <div className="d-flex justify-content-center">
                        <Pagination
                          prevPageText="ant"
                          nextPageText="sig"
                          firstPageText="prime"
                          lastPageText="ultimo"
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemPerPage}
                          totalItemsCount={this.state.detallePoaState.length}
                          onChange={this.validarPaginacion.bind(this)}
                        />
                      </div>
                    </React.Fragment>
                  ) : (
                    <h3 className="text-center">
                      No tiene productos agregados en el POA {this.state.ano}{" "}
                    </h3>
                  )}
                </div>
              </div>
            </Container>
            <ModalLoading
              modal={this.state.modal}
              mensaje={this.state.mensaje}
            ></ModalLoading>
          </div>
        ) : (
          <Loading></Loading>
        )}
      </>
    );
  }
}
// state
const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  detallePoa: state.poas.detallePoa,
  user: state.auth.signed,
  areasOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
  planesEstrategicos: state.planesEstrategicosGeneral,
});

const mapDispatchToProps = (dispatch) => ({
  //mostrarPoas: () => dispatch(mostrarPoas())
  mostrarDetallePoa: (poaId) => dispatch(mostrarDetallePoa(poaId)),
  getReportePoa: (poaId) => dispatch(getReportePoa(poaId)),
  getEjesEstrategicosGeneral: () => dispatch(getEjesEstrategicosGeneral()),
  getObjetivosEstrategicosGeneral: () =>
    dispatch(getObjetivosEstrategicosGeneral()),
  getEstrategiasGeneral: () => dispatch(getEstrategiasGeneral()),
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps //{ Login }
)(
  Authorization(DetallePoa, [
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Analista del POA",
    "MAE",
  ])
);
