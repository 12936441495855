import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getInvolucrados } from "./../../redux/actions/involucrados-actions";
import Loading from "./../utils/Loading.jsx";
import Pagination from "react-js-pagination";
import { Authorization } from "./../../utils/Authorization";
import {
  Table,
  Container
} from "reactstrap";

import Involucrado from "./Involucrado.jsx";

class ListaInvolucrados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaInvolucradosState: [],
      listaInvolucradosPageState: [],
      activePage: 1,
      totalPages: 0,
      itemPerPage: 10,
    };
  }

  cargarInvolucrados =async ()=> {
    await this.props.getInvolucrados();
    this.setState({ listaInvolucradosState: this.props.involucrados }, () =>
    this.validarPaginacion()
  );
  }

  componentDidMount() {
   this.cargarInvolucrados();
  }

  filtroInvolucrado = (involucradoName) => {
    let filtroInvolucrado = [];
    if (involucradoName.length > 0) {
      filtroInvolucrado = this.props.involucrados.filter((involucrado) =>
        involucrado.descripcion
          .toUpperCase()
          .includes(involucradoName.toUpperCase())
      );
    } else {
      filtroInvolucrado = this.props.involucrados;
    }

    this.setState({ listaInvolucradosState: filtroInvolucrado }, () => {
      this.validarPaginacion();
    });
  };

  validarPaginacion = (page = 1, elementos = 10) => {
    let index = elementos * page - elementos;
    let listaInvolucrados = [];

    for (let i = 0; i < elementos; i++) {
      listaInvolucrados.push(this.state.listaInvolucradosState[index]);

      index++;
    }

    let totalPages = Math.ceil(
      this.state.listaInvolucradosState.length / elementos
    );

    this.setState({
      listaInvolucradosPageState: listaInvolucrados,
      activePage: page,
      totalPages,
      itemPerPage: elementos,
    });
  };

  render() {
    const { involucrados,isLoading } = this.props;

    return (
      <>
        {!isLoading ? (
          <div className="pb-6 pt-5 pt-md-7">
            <Container fluid>
              <div className="container bg-white border py-3">
                <div className="header-body">
                  <div className="row">
                    <div className="form-group  col-md-6 ">
                      <label htmlFor="filtro">Filtro Involucrados</label>
                      <input
                        type="text"
                        className="form-control form-control-sm text-dark"
                        id="filtro"
                        placeholder="Involucrado"
                        onKeyUp={(e) => {
                          e.preventDefault();
                          this.filtroInvolucrado(e.currentTarget.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <Link
                        className="btn btn-lg btn-info float-right mb-3"
                        to="/admin/involucrado/nuevo"
                      >
                        Agregar nuevo involucrado
                      </Link>
                    </div>
                  </div>

                  <Table
                    style={{ backgroundColor: "#fff", color: "black" }}
                    responsive
                    className="table-striped"
                  >
                    <thead>
                      <tr className="">
                        <th>Descripción</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.listaInvolucradosPageState.map(
                        (involucrado) =>
                          involucrado != null &&
                          involucrado != undefined && (
                            <Involucrado
                              key={involucrado.involucradoId}
                              involucrado={involucrado}
                              user={this.props.user}
                            />
                          )
                      )}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-center">
                    <Pagination
                      prevPageText="ant"
                      nextPageText="sig"
                      firstPageText="prime"
                      lastPageText="ultimo"
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.itemPerPage}
                      totalItemsCount={this.state.listaInvolucradosState.length}
                      onChange={this.validarPaginacion.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </Container>
          </div>
        ) : (
          <Loading></Loading>
        )}
      </>
    );
  }
}
// state
const mapStateToProps = (state) => ({
  involucrados: state.involucrados.involucrados,
  isLoading : state.involucrados.isLoading,
  user: state.auth.signed,
});

const mapDispatchToProps = (dispatch) => ({
  getInvolucrados: () => dispatch(getInvolucrados()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps //{ Login }
)(
  Authorization(ListaInvolucrados, [
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Analista del POA",
    "MAE",
  ])
);
