import React, { Component } from "react";
import {
  Table
} from "reactstrap";
import Moment from "react-moment";
import { api_download_informes } from "./../../../config";

const AreaOrganziacional = (props) => {
  const {
    areaOrganizacional,
    informesTrimestrales,
  } = props.areaInformesTrimestrales;
  return (
    <React.Fragment>
      <div className="row">
        <h3>{areaOrganizacional.nombre}</h3>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Table className="align-items-center table-flush" responsive>
            <thead>
              <tr>
                <th>Trimestre</th>
                <th>Documento</th>
                <th>Descargar</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {informesTrimestrales.map((informe) => (
                <tr key={informe.informeTrimestralId}>
                  <td>{informe.trimestreId}</td>
                  <td>{informe.nombreImagen}</td>
                  <td>
                    <a
                      style={{ color: "#525f7f" }}
                      target="_blank"
                      rel="nooponer noreferrer"
                      href={`${api_download_informes}${informe.documento.substring(
                        0,
                        4
                      )}/${informe.documento.substring(4, 8)}${
                        informe.documento
                      }`}
                      download={informe.nombreImagen}
                    >
                      <i className=" btn fas fa-cloud-download-alt"></i>
                    </a>
                  </td>
                  <td>
                    {informe.nivelAprobacion.nivelAprobacionId ===
                      props.nivelAprobacionId &&
                    informe.nivelAprobacion.nivelAprobacionId < 5 ? (
                      <React.Fragment>
                        <button
                          className="btn btn-sm btn-success"
                          onClick={(e) => {
                            e.preventDefault();
                            props.aprobarInformeTrimestral(
                              informe,
                              areaOrganizacional
                            );
                          }}
                        >
                          Aprobar
                        </button>

                        <button
                          className="btn btn-sm btn-danger"
                          onClick={(e) => {
                            e.preventDefault();
                            props.activarModalDevolucionInformeTrimestral(
                              informe,
                              areaOrganizacional
                            );
                          }}
                        >
                          Rechazar
                        </button>
                      </React.Fragment>
                    ) : informe.nivelAprobacion.nivelAprobacionId <
                        props.nivelAprobacionId && informe.noHistorial >= 3 ? (
                      <button className="btn btn-danger btn-sm" disabled={true}>
                        Rechazado
                      </button>
                    ) : informe.noHistorial < 3 &&
                      informe.nivelAprobacion.nivelAprobacionId <
                        props.nivelAprobacionId ? (
                      <button className="btn btn-info btn-sm" disabled={true}>
                        En Proceso
                      </button>
                    ) : (
                      <button
                        className="btn btn-success btn-sm"
                        disabled={true}
                      >
                        Aprobado
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AreaOrganziacional;
