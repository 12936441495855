import { api_base } from "./../../config";
import { getData } from "./../../utils/api-client";
import {
  MOSTRAR_PROGRAMACION_MONITOREO,
  PROGRAMACION_MONITOREO_SUCCESS,
  PROGRAMACION_MONITOREO_FAILED,
  LOADING_PROGRAMACION_MONITOREO,
  CREAR_PROGRAMACION_MONITOREO,
  EDITAR_PROGRAMACION_MONITOREO,
} from "./../types";

export const getProgramacionMonitoreos = (poaId) => async (dispatch) => {
  dispatch({ type: LOADING_PROGRAMACION_MONITOREO });
  let url = `${api_base}api/v1/poamonitoreo/${poaId}`;

  await getData
    .get(url)
    .then((reesultado) => {
      dispatch({ type: PROGRAMACION_MONITOREO_SUCCESS });
      dispatch({
        type: MOSTRAR_PROGRAMACION_MONITOREO,
        payload: reesultado.data,
      });
    })
    .catch((error) => {
      if(error){
        dispatch({ type: PROGRAMACION_MONITOREO_FAILED });
      }
    });
};

export const createProgramacionMonitoreos = (
  poaId,
  programacionMonitoreo
) => async (dispatch) => {
  dispatch({ type: LOADING_PROGRAMACION_MONITOREO });
  let obj = {
    poaId: poaId,
    poaMonitoreo: programacionMonitoreo,
  };
  let url = `${api_base}api/v1/poamonitoreo/crear`;
  // console.log(obj)
  await getData
    .post(url, JSON.stringify(obj))
    .then((resultado) => {
      dispatch({ type: CREAR_PROGRAMACION_MONITOREO, payload: resultado.data });
      dispatch({ type: PROGRAMACION_MONITOREO_SUCCESS });
    })
    .catch((error) => {
      if(error){
        dispatch({ type: PROGRAMACION_MONITOREO_FAILED });
      }
    });
};

export const updateProgramacionMonitoreos = (
  poaId,
  programacionMonitoreo
) => async (dispatch) => {
  dispatch({ type: LOADING_PROGRAMACION_MONITOREO });

  let url = `${api_base}api/v1/poamonitoreo/actualizar`;
  let obj = {
    poaId: poaId,
    poaMonitoreo: programacionMonitoreo,
  };
  // console.log(obj);
  await getData
    .post(url, JSON.stringify(obj))
    .then((resultado) => {
      dispatch({
        type: EDITAR_PROGRAMACION_MONITOREO,
        payload: resultado.data,
      });
      dispatch({ type: PROGRAMACION_MONITOREO_SUCCESS });
    })
    .catch((error) => {
      if(error){
        dispatch({ type: PROGRAMACION_MONITOREO_FAILED });
      }
    });
};
