import {
  MOSTRAR_REQUERIMIENTOS,
  MOSTRAR_REQUERIMIENTO,
  CREAR_REQUERIMIENTO,
  EDITAR_REQUERIMIENTO,
  ELIMINAR_REQUERIMIENTO,
  LOADING_REQUERIMIENTO,
  REQUERIMIENTO_SUCCEEDED,
  REQUERIMIENTO_FAILED,
  PRESUPUESTO_FAILED,
  PRESUPUESTO_SUCCEEDED,
  EDITAR_PRESUPUESTO,
  MOSTRAR_PRODUCTOS_REQUERIMIENTO_COMPRA_ADMIN,
  REQUERIMIENTO_DOWNLOAD_SUCCEEDED,
  REQUERIMIENTO_DOWNLOAD_FAILED,

  MOSTRAR_REQUERIMIENTOS_COMPRAS,
  MOSTRAR_REQUERIMIENTOS_CONTRATOS,
  MOSTRAR_REQUERIMIENTO_COMPRA,
  CREAR_REQUERIMIENTO_COMPRA,
  EDITAR_REQUERIMIENTO_COMPRA,
  ELIMINAR_REQUERIMIENTO_COMPRA,
  LOADING_REQUERIMIENTO_COMPRA,
  REQUERIMIENTO_COMPRA_SUCCEEDED,
  REQUERIMIENTO_COMPRA_FAILED,
  
  MOSTRAR_REQUERIMIENTO_CONTRATO,
  LOADING_CONTRATOS,
  MOSTRAR_CONTRATOS,
  CONTRATOS_SUCCEEDED,
  CONTRATOS_FAILED,

  LOADING_REQUERIMIENTO_BIEN_SERVICIO,
  CREAR_REQUERIMIENTO_BIEN_SERVICIO,
  REQUERIMIENTO_BIEN_SERVICIO_SUCCEEDED,
  REQUERIMIENTO_BIEN_SERVICIO_FAILED
} from "./../types";

import initialState from "./../initial-state";

const { requerimientos } = initialState;

const RequerimientosReducer = (state = requerimientos, action) => {
  switch (action.type) {
    case MOSTRAR_REQUERIMIENTOS:
      return {
        ...state,
        requerimientos: action.payload
      };
    case MOSTRAR_PRODUCTOS_REQUERIMIENTO_COMPRA_ADMIN:
      return {
        ...state,
        requerimientosAdmin: action.payload
      };
    case CREAR_REQUERIMIENTO:
      return {
        ...state,
        requerimientos: [...state.requerimientos, action.payload]
      };
    case MOSTRAR_REQUERIMIENTO:
      return {
        ...state,
        requerimiento: action.payload
      };
    case EDITAR_REQUERIMIENTO:
      return {
        ...state,
        requerimientos: state.requerimientos.map(requerimiento =>
          requerimiento.requerimientoId === action.payload.requerimientoId
            ? (requerimiento = action.payload)
            : requerimiento
        )
      };
    case ELIMINAR_REQUERIMIENTO:
      return {
        ...state,
        requerimientos: state.requerimientos.filter(
          requerimiento => requerimiento.requerimientoId !== action.payload
        )
      };
    case LOADING_REQUERIMIENTO:
      return {
        ...state,
        isLoading: true,
        success:'idle'
      };
    case REQUERIMIENTO_SUCCEEDED:
    case REQUERIMIENTO_FAILED:
      return {
        ...state,
        isLoading: false
      };
    case REQUERIMIENTO_DOWNLOAD_FAILED:
      return {
        ...state,
        isLoading: false,
        success:"false"
      };
    case REQUERIMIENTO_DOWNLOAD_SUCCEEDED:
        return {
        ...state,
        isLoading: false,
        success:"true"
      };
    case MOSTRAR_REQUERIMIENTOS_COMPRAS:
      return {
        ...state,
        requerimientosCompras: action.payload
      };
    case MOSTRAR_REQUERIMIENTO_COMPRA:
        return {
        ...state,
        requerimientoCompra: action.payload
    };
    case CREAR_REQUERIMIENTO_COMPRA:
      return {
        ...state,
        requerimientosCompras: [...state.requerimientosCompras, action.payload]
    };
    case MOSTRAR_REQUERIMIENTO_CONTRATO:
      return {
        ...state,
        contratoRequerimiento: action.payload
      };
    case MOSTRAR_REQUERIMIENTOS_CONTRATOS:
      return {
        ...state,
        contratoRequerimiento: action.payload
      };
    case MOSTRAR_CONTRATOS:
    return {
      ...state,
      contratos: action.payload
    };
    default:
      return state;
  }
  
};
export default RequerimientosReducer;
