import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setTipoAsignacion
} from "./../../../redux/actions/tipos_asignaciones_action";
import { Authorization } from "./../../../utils/Authorization";
import "react-widgets/dist/css/react-widgets.css";
import {
  Container
} from "reactstrap";
import swal from "sweetalert";
import Loading from "./../../utils/Loading";

class CrearTipoAsignacion extends Component {
  descripcionRef = React.createRef();
  nombreRef = React.createRef();
  estadoRef = React.createRef();

  crearTipoAsignacion = e => {
    e.preventDefault();
    if (
      this.nombreRef.current !== null &&
      this.nombreRef.current.value !== ""
    ) {
      let nuevoTipoAsignacion = {
        descripcion: this.descripcionRef.current.value,
        estatus: this.estadoRef.current.value,
        nombre: this.nombreRef.current.value
      };

      if (nuevoTipoAsignacion) {
        this.props
          .setTipoAsignacion(nuevoTipoAsignacion)
          .then(resultado => this.props.history.goBack());
      } else {
        swal("Tipo Asignación","Debe ingresar los valores a crear","error");
        
      }
    } else {
      swal("Tipo Asignación","El tipo de asignación es un valor requerido","error");
    }
  };
  render() {
    const { isLoading } = this.props;

    return (
      <>
        {!isLoading ? (
          <div className="pb-6 pt-5 pt-md-7">
            <Container>
              <div className="container bg-white border">
                <form className="p-3" onSubmit={this.crearTipoAsignacion}>
                  <div className="form-group">
                    <label htmlFor="descripción">Tipo de Asignación</label>
                    <input
                      type="text"
                      className="form-control text-dark"
                      id="nombre"
                      placeholder="Tipo Asignacion"
                      ref={this.nombreRef}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="descripción">Descripción</label>
                    <textarea
                      type="text"
                      rows={2}
                      className="form-control text-dark"
                      id="descripción"
                      placeholder="Descripción"
                      ref={this.descripcionRef}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="estado">Estado</label>
                    <select
                      className="form-control text-dark"
                      id="estado"
                      ref={this.estadoRef}
                    >
                      <option defaultValue={true} value={true}>
                        Activo
                      </option>
                      <option value={false}>Inactivo</option>
                    </select>
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Guardar
                  </button>
                </form>
              </div>
            </Container>
          </div>
        ) : (
          <div className="pb-6 pt-5 pt-md-7">
            <Loading></Loading>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tiposAsignaciones: state.tiposAsignaciones.tiposAsignaciones,
    isLoading: state.tiposAsignaciones.isLoading,
    user: state.auth.signed
  };
};

const mapDispatchToProps = dispatch => ({
  setTipoAsignacion: tipoAsignacion =>
    dispatch(setTipoAsignacion(tipoAsignacion)),
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps //{ Login }
)(
  Authorization(CrearTipoAsignacion, [
    "Administrador general",
    "Analista Presupuesto",
    "Encargado Presupuesto",
    "MAE"
  ])
);
