import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  deleteActividadesNopoa,
  getActividadNopoa
} from "../../redux/actions/actividades-actions";
import ReactTooltip from 'react-tooltip';
import numbro from "numbro";

const ActividadNoPoa = (props) => {
  const {
    actividadNoPoaId,
    descripcion,
    presupuestoNopoa,
    medioVerificacion,
    ponderacionActividad,
    notasActividad
  } = props.actividadNopoa;

  const cargarActividad = () => async (dispatch) => {
    props.getActividadNopoa(props.actividadNopoa.actividadNoPoaId);
  };

  const truncateString = (str) => {
    let num = 50;

    return str && str.length > num ? str.slice(0, num) + "..." : str;
  };
  const eliminarActividad = () => {
    let confirmed = window.confirm("¿Está seguro de eliminar la actividad No Poa?");
    if (confirmed) {
      let actividad = {
        actividadNoPOAId: props.actividadNopoa.actividadNoPoaId,
        productoNoPoaId: props.productoNoPoaId,
      };
      // console.log(actividad)
      props.deleteActividadesNopoa(actividad).then(() => window.location.reload());
    }
  };

  return (
    <tr>
      <td title={descripcion}>{truncateString(descripcion)}</td>
      <td>{props.actividadNopoa.presupuestoNopoa.length}</td>
      <td>{'RD$ '+numbro(props.actividadNopoa.presupuestoNopoa.reduce((accumulator,obj)=>{
              return accumulator + (obj.valor * obj.frecuencia * obj.cantidad);
            },0)).format({thousandSeparated:true})}</td>
      
      <td>
        <ReactTooltip globalEventOff='click' />
        <ReactTooltip/>
        <Link
          to={{
            pathname: `/admin/actividadNoPoa/detalle/${actividadNoPoaId}`,
            state: `/admin/actividadNoPoa/detalle/:actividadNoPoaId`,
          }}
          className="btn btn-sm btn-warning"
        >
          Detalle
        </Link>

        {props.validarOpcionEditar() ? (
          <Link
            to={{
              pathname: `/admin/actividadNoPoa/editar/${actividadNoPoaId}`,
              state: "/admin/actividadNoPoa/editar/:actividadNoPoaId",
            }}
            className="btn btn-sm btn-primary"
          >
            Editar
          </Link>
        ) : (
          <button
            title="Producto Enviado"
            className="btn btn-primary btn-sm opacity"
            onClick={(e) => e.preventDefault()}
          >
            Editar
          </button>
        )}

        {props.validarOpcionEditar() ? (
          <button
            onClick={(e) => {
              e.preventDefault();
              eliminarActividad();
            }}
            className="btn btn-sm btn-danger"
          >
            Eliminar
          </button>
        ) : (
          <button
            onClick={(e) => e.preventDefault()}
            className="btn btn-danger btn-sm opacity"
          >
            Eliminar
          </button>
        )}
      </td>
    </tr>
  );
};

export default connect(null, { deleteActividadesNopoa, getActividadNopoa })(ActividadNoPoa);
