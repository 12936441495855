import {
  MOSTRAR_INVOLUCRADOS,
  MOSTRAR_INVOLUCRADO,
  CREAR_INVOLUCRADO,
  EDITAR_INVOLUCRADO,
  ELIMINAR_INVOLUCRADO,
  LOADING_INVOLUCRADO,
  INVOLUCRADO_SUCCEEDED,
  INVOLUCRADO_FAILED
} from "./../types";

import initialState from "./../initial-state";

const { involucrados } = initialState;

const InvolucradosReducer = (state = involucrados, action) => {
  switch (action.type) {
    case MOSTRAR_INVOLUCRADOS:
      return {
        ...state,
        involucrados: action.payload
      };
    case CREAR_INVOLUCRADO:
      return {
        ...state,
        involucrados: [...state.involucrados, action.payload]
      };
    case MOSTRAR_INVOLUCRADO:
      return {
        ...state,
        involucrado: action.payload
      };
    case EDITAR_INVOLUCRADO:
      return {
        ...state,
        involucrados: state.involucrados.map(involucrado =>
          involucrado.involucradoId === action.payload.involucradoId
            ? (involucrado = action.payload)
            : involucrado
        )
      };
    case ELIMINAR_INVOLUCRADO:
      return {
        ...state,
        involucrados: state.involucrados.filter(
          involucrado => involucrado.involucradoId !== action.payload
        )
      };
    case LOADING_INVOLUCRADO:
      return {
        ...state,
        isLoading: true
      };
    case INVOLUCRADO_SUCCEEDED:
    case INVOLUCRADO_FAILED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};
export default InvolucradosReducer;
