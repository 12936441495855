import React, { Component } from "react";
import { Multiselect, DropdownList } from "react-widgets";
import { connect } from "react-redux";
import { Authorization } from "./../../utils/Authorization";
import { getNivelesAprobaciones } from "../../redux/actions/niveles-aprobaciones-actions";
import {
  Container,
  CustomInput,
} from "reactstrap";
import swal from "sweetalert"
import {
  getUsuarioEntity,
  updateUsuario,
} from "./../../redux/actions/usuarios-actions";
import { getAreasOrganizacionales } from "./../../redux/actions/areas-actions";
import { getRoles } from "./../../redux/actions/roles-action";
import Loading from "../utils/Loading";

class EditarUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }

  emailRef = React.createRef();
  passwordRef = React.createRef();
  localRef = React.createRef();
  rolesRef = [];
  nombreRef = React.createRef();
  nivelAprobacionRef = React.createRef();
  emailSeach = "";
  areaOrganizacionalRef = {};
  organizacionalRef = React.createRef();
  rolesRef = React.createRef();
  firmaRef = false;
  firmaAprobacionPoaRef = false;
  firmaRevisionRef = false;
  firmaAprobacionMonitoreoRef = false;

  componentDidMount() {
    this.buscarUsuario().then(() => {
      this.rolesRef = [];
     if(Array.isArray(this.props.usuario.user.roles)){
      this.props.usuario.user.roles.map(
        (rol) => rol !== "ROLE_USER" && this.rolesRef.push(rol)
      );
    }
      this.props.getNivelesAprobaciones();
      this.props.getRoles();
      this.props.getAreasOrganizacionales();
      this.setState({ isLoading: false });
      this.firmaRef = this.props.usuario.firma;
      this.firmaAprobacionPoaRef = this.props.usuario.firmaAprobacionPoa;
      this.firmaRevisionRef = this.props.usuario.firmaRevision;
      this.firmaAprobacionMonitoreoRef = this.props.usuario.firmaAprobacionMonitoreo;
    });
  }

  buscarUsuario = async () => {
    const { usuarioId } = this.props.match.params;
    await this.props.getUsuarioEntity(usuarioId);
  };

  validarActualizarUsuario = () => {
    if (this.nombreRef.current.value === "") {
      swal("Editar usuario","Buscar el usuario es necesario antes de poder crearlo","error");
      return false;
    }
    if (Object.keys(this.areaOrganizacionalRef).length === 0) {
      swal("Editar usuario","El area organizacional es requerido","error");
      return false;
    }
    if (this.nivelAprobacionRef.current.value === "0") {
      swal("Editar usuario","Debe seleccionar el nivel de aprobación del usuario","error");
      return false;
    }
   
    if (this.rolesRef.length === 0) {
      swal("Editar usuario","Debe especificar los roles del usuario","error");
      return false;
    }
    
    return true;
  };

  editarUsuario = async (e) => {
    e.preventDefault();
    this.setState({isLoading:true})
    let usuario = {
      email: this.props.usuario.user.username,
      password: this.passwordRef.current
        ? this.passwordRef.current.value
        : null,
      local: this.localRef.current ? this.localRef.current.value : false,
      nombre: this.props.usuario.name,
      roles: this.rolesRef,
      areaOrganizacionalId: this.areaOrganizacionalRef.areaOrganizacionalId,
      nivelAprobacionId: this.nivelAprobacionRef.current
        ? this.nivelAprobacionRef.current.value
        : null,
      estatus: 1,
      firma: this.firmaRef,
      firmaAprobacionPoa: this.firmaAprobacionPoaRef,
      firmaRevision: this.firmaRevisionRef,
      firmaAprobacionMonitoreo: this.firmaAprobacionMonitoreoRef,
    };
    // console.log(usuario);
    if(this.validarActualizarUsuario()){
     
      await this.props.updateUsuario(usuario);
      this.setState({isLoading:false})
      this.props.history.push("/admin/usuarios/index");
  }else{
    this.setState({isLoading:false})
  }
   
  };
  render() {
    const {
      roles,
      usuario,
      isLoadingUsuario,
      areaOrganizacionales,
      nivelesAprobaciones,
    } = this.props;
    const { email, name, areaOrganizacional } = usuario;
    this.areaOrganizacionalRef = areaOrganizacional;

    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container>
          <div className="container bg-white border">
            {this.state.isLoading || isLoadingUsuario ? (
              <Loading></Loading>
            ) : (
              <form
                className="needs-validation p-3 "
                onSubmit={this.editarUsuario}
                noValidate
              >
                <div className="row">
                  <div className="form-group col-md-6 ">
                    <label htmlFor="email">Email</label>
                    <div className="input-group ">
                      <input
                        type="email"
                        className="form-control  text-dark"
                        id="email"
                        placeholder="Correo Electronico"
                        ref={this.emailRef}
                        defaultValue={email}
                        readOnly
                        required
                      />
                    </div>
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>

                  <div className="form-group col-md-6 ">
                    <label htmlFor="nombre">Nombre</label>

                    <input
                      type="text"
                      className="form-control  text-dark"
                      id="nombre"
                      placeholder="Nombre Empleado"
                      ref={this.nombreRef}
                      defaultValue={name}
                      readOnly
                      required
                    />
                  </div>
                </div>
                {this.props.user.roles.includes("Administrador general")&& (this.props.user.email==="miguel.eve@mem.gob.do"|| this.props.user.email==="rrodriguez@mem.gob.do") ? (
                  <React.Fragment>
                    <div className="row">
                      <div className="form-group col-md-6 ">
                        <label htmlFor="email">Contraseña</label>

                        <input
                          type="password"
                          className="form-control  text-dark"
                          id="contrasena"
                          placeholder="Contraseña"
                          ref={this.passwordRef}
                          autoComplete="off"
                        />
                      </div>

                      <div className="form-group col-md-6 ">
                        <label htmlFor="constrasenaConfig">
                          Confirmar Contraseña
                        </label>

                        <input
                          type="password"
                          className="form-control  text-dark"
                          id="constrasenaConfig"
                          placeholder="Contraseña"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="form-group ">
                      <label htmlFor="local">Local</label>
                      <select
                        id="local"
                        className="form-control"
                        ref={this.localRef}
                      >
                        <option value={false}>NO</option>
                        <option value={true}>SI</option>
                      </select>
                    </div>
                  </React.Fragment>
                ) : (
                  <div></div>
                )}
                <div className="row">
                  <div className="form-group col-md-6 ">
                    <label htmlFor="Usuario">Usuario</label>

                    <input
                      type="text"
                      className="form-control  text-dark"
                      id="usuario"
                      placeholder="Usuario"
                      ref={this.usuarioRef}
                      defaultValue={usuario.user.username}
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="roles">Roles</label>
                    <Multiselect
                      id="roles"
                      placeholder="--Seleccione los roles del usuario--"
                      data={roles}
                      valueField="rol"
                      defaultValue={Array.isArray(usuario.user.roles)? usuario.user.roles.filter(
                        (rol) => rol !== "ROLE_USER"
                      ):[]}
                      textField="rol"
                      onChange={(e) => {
                        this.rolesRef = [];
                        e.map((rol) => this.rolesRef.push(rol.rol));
                      }}
                      minLength={3}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="areaOrganizacional">
                      Area Organizacional
                    </label>
                    <DropdownList
                      filter
                      id="areaOrganizacional"
                      placeholder="--Seleccione el Area Organizacional--"
                      data={areaOrganizacionales}
                      defaultValue={areaOrganizacional}
                      allowCreate="onFilter"
                      onCreate={(nombre) => this.handleCreate(nombre)}
                      onChange={(value) => (this.areaOrganizacionalRef = value)}
                      textField="nombre"
                      minLength={3}
                    />
                  </div>
                  <div className="from-group col-md-6">
                    <label htmlFor="areaOrganizacional">
                      Nivel de Aprobación
                    </label>
                    <select
                      className=" form-control  text-dark "
                      id="nivelAprobacion"
                      ref={this.nivelAprobacionRef}
                      defaultValue={usuario.nivelAprobaciones.nivelAprobacionId}
                    >
                      {nivelesAprobaciones.map((niveles) => (
                        <option
                          key={niveles.nivelAprobacionId}
                          value={niveles.nivelAprobacionId}
                        >
                          {niveles.descripcion}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="from-group col-md-3">
                    <label htmlFor="firma">Firma Reportes</label>
                    <CustomInput
                      type="checkbox"
                      id="firma"
                      label="SI"
                      defaultChecked={usuario.firma}
                      onClick={(e) => {
                        this.firmaRef = e.currentTarget.checked;
                      }}
                    />
                  </div>
                  <div className="from-group col-md-3">
                    <label htmlFor="firmaAprobacionPoa">Aprobación POA</label>
                    <CustomInput
                      type="checkbox"
                      id="firmaAprobacionPoa"
                      label="SI"
                      defaultChecked={usuario.firmaAprobacionPoa}
                      onClick={(e) => {
                        this.firmaAprobacionPoaRef = e.currentTarget.checked;
                      }}
                    />
                  </div>
                  <div className="from-group col-md-3">
                    <CustomInput
                      type="checkbox"
                      id="firmaRevision"
                      label="SI"
                      defaultChecked={usuario.firmaRevision}
                      onClick={(e) => {
                        this.firmaRevisionRef = e.currentTarget.checked;
                      }}
                    />
                  </div>
                  <div className="from-group col-md-3">
                    <label htmlFor="firmaAprobacionMonitoreo">
                      Aprobación Monitoreo
                    </label>
                    <CustomInput
                      type="checkbox"
                      id="firmaAprobacionMonitoreo"
                      label="SI"
                      defaultChecked={usuario.firmaAprobacionMonitoreo}
                      onClick={(e) => {
                        this.firmaAprobacionMonitoreoRef =
                          e.currentTarget.checked;
                      }}
                    />
                  </div>
                </div>

                <br />
                <button className="btn btn-info" type="submit">
                  Guardar
                </button>
              </form>
            )}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.usuarios.usuario,
  isLoading: state.usuarios.isLoading,
  roles: state.roles.roles,
  usuarios: state.usuarios.usuarios,
  isLoadingUsuario: state.usuarios.isLoadingUsuario,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
  user: state.auth.signed,
  nivelesAprobaciones: state.nivelesAprobaciones.nivelesAprobaciones,
});

const mapDispatchToProps = (dispatch) => ({
  getUsuarioEntity: (usuarioId) => dispatch(getUsuarioEntity(usuarioId)),
  getRoles: () => dispatch(getRoles()),
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
  updateUsuario: (usuario) => dispatch(updateUsuario(usuario)),
  getNivelesAprobaciones: () => dispatch(getNivelesAprobaciones()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(EditarUsuario, [
    "Administrador general",
    "Analista del POA",
    "Gestor del POA",
    "MAE",
  ])
);
