import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import numbro from "numbro";
import { UncontrolledTooltip } from "reactstrap";

class InsumosNoPoa extends Component {

  render() {
    const { articuloId, actividadId, nombreArticulo, estatus, analista, tipoCompras, sigef, precioCotizacion,precioAdjudicacion,precioReferencia } = this.props.ActividadArticuloSeguimiento;
    const { subclase, cuenta, totalCosto, } = this.props.presupuesto;
    //  console.log("this.props.ActividadArticuloSeguimiento",this.props.ActividadArticuloSeguimiento);
    //  console.log("this.props.presupuesto",this.props.presupuesto);
    return (
      estatus && (<tr >
        <td className="text-left">{nombreArticulo}</td>
        <td>{subclase}</td>
        <td>
          {cuenta}
        </td>
        <td>{'RD$ '+numbro(precioReferencia).format({thousandSeparated:true})}</td>
        <td>{precioCotizacion?'RD$ '+numbro(precioCotizacion).format({thousandSeparated:true}):'RD$ 0.00'}</td>
        <td>{precioAdjudicacion?'RD$ '+numbro(precioAdjudicacion).format({thousandSeparated:true}):'RD$ 0.00'}</td>
        <td >
          <i className="fas fa-eye text-primary" style={{ cursor: "pointer" }} onClick={() => this.props.ActivarModalDetalleArticulo(this.props.presupuesto, precioCotizacion, actividadId)}></i>

          {this.props.editar && (
          !this.props.user.roles.some(x=> x=="Administrador general" || x=="Analista Compras" || x=="Responsables de Compras" || x=="Encargado Administrativo") || !this.props.editar ? (
            <button
              className="btn ml-2 btn-sm"
              disabled={true}
              title="No tiene permisos para editar los Insumos"
            >
              <i className="fas  fa-pen text-muted" disabled={true} id="TooltipEditarRequerimiento"/>
              <UncontrolledTooltip
                placement="down"
                target="TooltipEditarRequerimiento"
              >
                No tiene permisos para editar los Insumos
              </UncontrolledTooltip>
            </button>
          ) : (
            <i className="p-2 fas fa-pen text-primary" onClick={() => this.props.ActivarModalAsignarCotizacionArticulo(this.props.presupuesto, precioCotizacion, articuloId, actividadId)}></i>
          )
          )}
          {this.props.editar ? (
            
              <button
                className="btn btn-sm"
                disabled={true}
                title="No tiene permisos para eliminar el requerimiento"
              >
                <i className="fas fa-times text-gray"></i>
              </button>
            ) : (

              <i className="fas fa-times text-primary " style={{ cursor: "pointer", marginLeft: 5 }} onClick={() => {
                this.props.eliminarPresupuesto(articuloId);
              }}></i>

            )
          }
        </td>
      </tr>

      ));

  }

}

export default connect(null, {})(InsumosNoPoa);
