import { LOADING_MONITOREO_MEDIOS_VERIFICACION,
    MOSTRAR_MONITOREO_MEDIOS_VERIFICACION,
         MONITOREO_MEDIOS_VERIFICACION_FAILED,
         MONITOREO_MEDIOS_VERIFICACION_SUCCEEDED    
    } from "./../types";
    import { api_base } from "./../../config";
    import { getData } from "./../../utils/api-client";

    export const getListMonitoreoMediosVerificacion  = (monitoreoId) => async dispatch=> {

        let url  = `${api_base}api/v1/monitoreoMediosVerificacion/monitoreo/${monitoreoId}`;
        dispatch({type:LOADING_MONITOREO_MEDIOS_VERIFICACION});

        await getData.get(url).then(data=> {
            dispatch({type:MOSTRAR_MONITOREO_MEDIOS_VERIFICACION , payload:data.data});
            dispatch({type:MONITOREO_MEDIOS_VERIFICACION_SUCCEEDED})
            dispatch({type:LOADING_MONITOREO_MEDIOS_VERIFICACION});
        }).catch(error => {
             dispatch({type:LOADING_MONITOREO_MEDIOS_VERIFICACION});
             dispatch({type:MONITOREO_MEDIOS_VERIFICACION_FAILED})
        });
    }