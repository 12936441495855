import React from "react";
import Select from "react-select";
import Async from "react-select/async";
import Loading from "./../utils/Loading";
import Pagination from "react-js-pagination";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import moment from "moment";

export const ModalHistoricoRequerimiento = props => {

    const toggle = () => props.ActivarModalHistoricoRequerimiento();
    const { modalHistoricoRequerimiento, requerimiento ,historicoSelected, changeHistoricoSelected,activePage,itemPerPage,listaHistoricoRequerimientos} = props;

    // console.log("props",props);
    // this.props.historicoSelected
   //  console.log(requerimiento);
    // let activePage= 1;
    //  let totalPages= 0;
    //  let itemPerPage= 4;
    //  let listaHistoricoRequerimientos=[];
// const validarPaginacion = (e)=>{
//     console.log(e);
// }

    // props.validarPaginacionHistoricoRequerimiento(activePage,itemPerPage,requerimiento)
    // let historicoSelected = requerimiento.requerimiento[requerimiento.requerimiento.length - 1];
   //  console.log(listaHistoricoRequerimientos);
    // const historicoRequerimientos = requerimiento.requerimiento.reverse();
    return (
        <Modal isOpen={modalHistoricoRequerimiento} toggle={toggle} size="lg" backdrop="static">
            <ModalHeader className="block-example border-bottom " toggle={toggle}>
                Historico Requerimientos
            </ModalHeader>
            <ModalBody className="block-example border-bottom ">
                <form>
                    <div className="form-row mt-1">
                        <div className="col-6">
                            <label>Número Solicitud</label>
                            <input
                                type="text"
                                className="form-control text-dark"
                                placeholder="Número Solicitud"
                                readOnly
                                value={requerimiento.numeroSolicitud}
                            />
                        </div>
                        <div className="col-6">
                            <div className="form-group ">
                                <label htmlFor="estado">Estado</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control  text-dark"
                                        id="Estado"
                                        placeholder="Estado"
                                        readOnly
                                        value={historicoSelected.estado.nombre}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row mt-1">
                        <div className="col-6">
                            <div className="form-group ">
                                <label htmlFor="fecha">Fecha Movimiento</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control  text-dark"
                                        id="fechaMovimiento"
                                        placeholder="Fecha Movimiento"
                                        readOnly
                                        value={moment(historicoSelected.fechaMovimiento).format('DD/MM/YYYY')}
                                    />
                                </div>
                            </div>
                        </div>

                        {historicoSelected.devolucion ? (<>
                            <div className="col-6">
                                <div className="form-group ">
                                    <label htmlFor="fechaDevolucion">Fecha Devolución</label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control  text-dark"
                                            id="fechaDevolucion"
                                            placeholder="Fecha Devolución"
                                            readOnly
                                            value={moment(historicoSelected.fechaMovimiento).format('DD/MM/YYYY')}
                                        />
                                    </div>
                                </div>
                            </div>

                        </>) : (
                            <>
                                <div className="col-3">
                                    <div className="form-group ">
                                        <label htmlFor="fecha">Fecha Recepción</label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control  text-dark"
                                                id="fechaRecepcion"
                                                placeholder="Fecha Recepción"
                                                readOnly
                                                value={moment(historicoSelected.fechaMovimiento).format('DD/MM/YYYY')}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="form-group ">
                                        <label htmlFor="fecha">Fecha Emisión</label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control  text-dark"
                                                id="Estado"
                                                placeholder="Fecha Emisión"
                                                readOnly
                                                value={moment(historicoSelected.fechaMovimiento).format('DD/MM/YYYY')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="form-row mt-1">
                        <div className="col-6">
                            <div className="form-group ">
                                <label htmlFor="frecuencia">Área Organizacional</label>
                                <textarea
                                    type="text"
                                    className="form-control  text-dark"
                                    id="frecuencia"
                                    placeholder="Frecuencia"
                                    readOnly
                                    value={historicoSelected.area.nombre}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group ">
                                <label htmlFor="referenciaInsumo">Comentario</label>
                                <textarea rows={2} readOnly value={historicoSelected.comentario} className="form-control  text-dark" id="referenciaInsumo" placeholder="Referencia Insumo" ></textarea>
                            </div>
                        </div>
                    </div>
                    {historicoSelected.devolucion ? (
                        <div className="form-row mt-1">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="razonDevolucion">Razón Devolución</label>

                                    <textarea rows={2} className="form-control  text-dark" id="razonDevolucion" placeholder="Razón Devolución" readOnly></textarea>
                                </div>
                            </div>
                        </div>
                    ):(<></>)}

                        <table className="table table-striped responsive">
                            <thead>
                                <tr>
                                    <th>Estado</th>
                                    <th>Comentario</th>
                                    <th>Devuelto</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listaHistoricoRequerimientos.map(registro=>registro&&(
                                    <tr key={registro.historialRequerimientoId}>
                                      <td>{registro.estado.nombre}</td>
                                      <td>{registro.comentario}</td>
                                      <td>{registro.devolucion?"Devuelto":"-"}</td>
                                      <td><i className="fas fa-eye text-primary" style={{ cursor: "pointer" }} onClick={(e)=>{ changeHistoricoSelected(registro)}}></i></td>
                                   </tr>
                                ))}
                            </tbody>

                        </table>
                        <div className="d-flex justify-content-center">
                      <Pagination
                        prevPageText="ant"
                        nextPageText="sig"
                        firstPageText="prime"
                        lastPageText="ultimo"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={activePage}
                        itemsCountPerPage={itemPerPage}
                        totalItemsCount={requerimiento['requerimiento'].requerimiento.length}
                        onChange={e=>props.validarPaginacionHistoricoRequerimiento(e)}
                      />
                    </div>
                    
                </form>
            </ModalBody>
            <ModalFooter className="block-example border-bottom ">
                <button
                    className="btn btn-danger"
                    onClick={e => {
                        e.preventDefault();
                        props.ActivarModalHistoricoRequerimiento();
                    }}
                >
                    Cerrar
                </button>
            </ModalFooter>
        </Modal>
    )
}