import {
  MOSTRAR_HISTORICO_REQUERIMIENTOS,
  CREAR_HISTORICO_REQUERIMIENTO,
  LISTA_HISTORICO_PAGINACION,
  MOSTRAR_HISTORICO_REQUERIMIENTO,
  EDITAR_HISTORICO_REQUERIMIENTO,
  ELIMINAR_HISTORICO_REQUERIMIENTO,
  LOADING_HISTORICO_REQUERIMIENTO,
  HISTORICO_REQUERIMIENTO_SUCCEEDED,
  HISTORICO_REQUERIMIENTO_FAILED,
} from "../types";

import initialState from "../initial-state";

const { historicosrequerimientos } = initialState;

const HistoricoRequerimientosReducer = (state = historicosrequerimientos, action) => {
  switch (action.type) {
    case MOSTRAR_HISTORICO_REQUERIMIENTOS:
      return {
        ...state,
        historicoRequerimientos: action.payload
      };
    case CREAR_HISTORICO_REQUERIMIENTO:
      return {
        ...state,
        historicoRequerimientos: [...state.historicoRequerimientos, action.payload]
      };
    case MOSTRAR_HISTORICO_REQUERIMIENTO:
      return {
        ...state,
        historicoRequerimiento: action.payload
      };
    case EDITAR_HISTORICO_REQUERIMIENTO:
      return {
        ...state,
        historicoRequerimientos: state.historicoRequerimientos.map(requerimiento =>
          requerimiento.requerimientoId === action.payload.requerimientoId
            ? (requerimiento = action.payload)
            : requerimiento
        )
      };
    case ELIMINAR_HISTORICO_REQUERIMIENTO:
      return {
        ...state,
        historicoRequerimientos: state.historicoRequerimientos.filter(
          requerimiento => requerimiento.requerimientoId !== action.payload
        )
      };
    case LOADING_HISTORICO_REQUERIMIENTO:
      return {
        ...state,
        isLoading: true
      };
    case LISTA_HISTORICO_PAGINACION:
      return{
        ...state,
        isLoading: false,
        listaHistoricoRequerimientos:action.payload
      }
    case HISTORICO_REQUERIMIENTO_SUCCEEDED:
    case HISTORICO_REQUERIMIENTO_FAILED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default HistoricoRequerimientosReducer;
