import {
  MOSTRAR_PRODUCTO_PROGRAMATICA_SUCCESS,
  MOSTRAR_PRODUCTO_PROGRAMATICA_FAILED,
  MOSTRAR_PRODUCTO_PROGRAMATICA,
  MOSTRAR_PRODUCTO_PROGRAMATICAS,
  EDITAR_MOSTRAR_PRODUCTO_PROGRAMATICA,
  LOADING_MOSTRAR_PRODUCTO_PROGRAMATICA,
} from "./../types";

import initialState from "./../initial-state";

const { productoProgramaticas } = initialState;

const ProductoProgramatica = (state = productoProgramaticas, action) => {
  switch (action.type) {
    case LOADING_MOSTRAR_PRODUCTO_PROGRAMATICA:
      return {
        ...state,
        isLoading: true,
      };
    case MOSTRAR_PRODUCTO_PROGRAMATICAS:
      return {
        ...state,
        programaticas: action.payload,
      };
    case MOSTRAR_PRODUCTO_PROGRAMATICA_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case MOSTRAR_PRODUCTO_PROGRAMATICA:
      return {
        ...state,
        payload: action.payload,
      };
    case MOSTRAR_PRODUCTO_PROGRAMATICA:
      return {
        ...state,
        payload: action.payload,
      };
    default:
      return state;
  }
};

export default ProductoProgramatica;
