import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  Table,
  Container,
} from "reactstrap";

import { Authorization } from "./../../utils/Authorization";
import Loading from "./../utils/Loading";

import { ModalInformeTrimestral } from "./../utils/ModalInformeTrimestral";
import { getTrimestres } from "./../../redux/actions/trimestres-actions";
import { api_download_informes } from "./../../config";
import {
  createInformeTrimestral,
  updateInformeTrimestral,
  getInformesTrimestral,
  deleteInformeTrimestral,
} from "./../../redux/actions/informe_trimestral_actions";

import { getAreasOrganizacionales } from "./../../redux/actions/areas-actions"; //me

class InformeTrimestral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      trimestres: [],
      trimestresEntity: [],
      modalInformeTrimestralStatus: false,
      selectedFile: null,
      file: [],
      fecha: "",
      documento: [],
      trimestreId: "",
      estatusEnviar: false,
      areaid2:0
    };
  }

  componentDidMount() {
    this.cargarDatos();
    this.props.getAreasOrganizacionales();//me
    this.setState({areaid2:this.props.user.areaOrganizacional.areaOrganizacionalId})
  }

  cargarDatos = async (areafiltro) => {
    await this.props.getTrimestres();

    if(areafiltro > 0){
    await this.props.getInformesTrimestral(
      this.props.poaActivo.poaId,
      areafiltro
    );
    this.setState({areaid2:areafiltro});
  } else {
    await this.props.getInformesTrimestral(
      this.props.poaActivo.poaId,
      this.props.user.areaOrganizacional.areaOrganizacionalId
    );
  }

    this.setState({ isLoadingState: false, trimestres: this.props.trimestres });
    await this.CargarTrimestre();
  };

  cargarInformeTrimestral = (informe) => {
    let files = [];
    let documentos = [];
    if (informe.target.files.length > 0) {
      for (let i = 0; i < informe.target.files.length; i++) {
        files.push(informe.target.files[i]);
        documentos.push(informe.target.files[i].name);
      }
      this.setState({
        file: files,
        documento: documentos,
      });
    }
    else {
      this.setState({
        file: "",
        documento: "",
      });
    }
  };

  activarModalInformeTrimestral = () => {
    this.CargarTrimestre();
    this.setState({
      modalInformeTrimestralStatus: !this.state.modalInformeTrimestralStatus,
    });
  };

  CargarTrimestre = () => {
    let informesTrimestral = this.props.informesTrimestrales;
    let trimestreState = [...this.state.trimestres];
    for (let i = 0; i < trimestreState.length; i++) {
      for (let m = 0; m < informesTrimestral.length; m++) {
        if (
          informesTrimestral[m].trimestre.trimestreId ===
          trimestreState[i].trimestreId
        ) {
          trimestreState.splice(i, 1);
          this.setState({ trimestresEntity: trimestreState });
          i = -1;
          break;
        }
      }
    }
    if (
      this.state.trimestresEntity.length === 0 &&
      informesTrimestral.length === 0
    ) {
      this.setState({ trimestresEntity: this.state.trimestres });
    }
  };

  seleccionarTrimestre = (trimestreId) => {
    this.setState({ trimestreId: trimestreId });
  };

  enviarInforme = async (informe) => {
    this.setState({ isLoadingState: true });

    swal({
      title: "Está seguro de enviar a revisión el informe del trimestre?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (confirm) => {
      if (confirm) {
        let informeTrimestral = {
          informeTrimestralId: informe.informeTrimestralId,
          nivelAprobacionId: this.props.user.nivelAprobacion.padre,
          envio: true,
          comentario: "Enviado para aprobación",
        };
        await this.props.updateInformeTrimestral(informeTrimestral);
        this.setState({ isLoadingState: false });
      } else {
        this.setState({ isLoadingState: false });
      }
    });
  };

  eliminarInformeTrimestral = async (informeTrimestralId) => {

    swal({
      title: "Está seguro de querer eliminar este informe del trimestre?",
      text: "No podrá ser recuperado luego de ser eliminado",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (confirm) => {
      if (confirm) {
         this.setState({ isLoadingState: true });
         await this.props.deleteInformeTrimestral(informeTrimestralId);
         this.setState({ isLoadingState: false });
      }
    });
  };

  devolverInforme = async (informeTrimestralId, comentario) => {
    let informeTrimestral = {
      informeTrimestralId: informeTrimestralId,
      nivelAprobacionId: this.props.user.nivelAprobacion.hijo,
      envio: true,
      comentario,
    };

    await this.props.updateInformeTrimestral(informeTrimestral);
  };

  validarEstatusEnviado = (informeTrimestral) => {
    let resultado =
      informeTrimestral.nivelAprobacion.nivelAprobacionId !==
      this.props.user.nivelAprobacion.nivelAprobacionId
        ? true
        : false;
    return resultado;
  };
  validarGuardarInforme = () => {
    if (this.state.trimestreId.length === 0) {
      swal("Informe Trimestral", "El campo trimestre es requerido para el informe del trimestre","error");

      return false;
    }

    if (this.state.file.length === 0) {
      swal("Informe Trimestral", "El campo de archivo es un valor requerido para el informe del trimestre","error");

      return false;
    }
    return true;
  };

  guardarInformeTrimestral = async () => {
    if (this.validarGuardarInforme()) {
      this.setState({ isLoadingState: true });
      this.activarModalInformeTrimestral();

      let informeTrimestral = {
        trimestreId: this.state.trimestreId,
        nivelAprobacionId: this.props.user.nivelAprobacion.nivelAprobacionId,
        nombreImagen: this.state.documento[0],
        areaOrganizacionalId: this.props.user.areaOrganizacional
          .areaOrganizacionalId,
        poaId: this.props.poaActivo.poaId,
      };

      const fd = new FormData();
      if (this.state.file.length > 0) {
        for (let i = 0; i < this.state.file.length; i++) {
          fd.append(`documento[]`, this.state.file[i], this.state.documento[i]);
        }
      }

      Object.keys(informeTrimestral).forEach(function (item) {
        fd.append(item, informeTrimestral[item]);
      });

      await this.props.createInformeTrimestral(fd);

      this.setState({ isLoadingState: false });
    }
  };

  validar;

  render() {
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (
                <div className="row">

                  {/* //me */}
                  <div className="col-md-5 text-left" style={{display:"none"}}>
                  <label>Filtro Área</label>
                     <select
                      className="form-control"
                      defaultValue={this.state.areaid2}
                      
                      id="areaSelected"
                      onChange={(e) => {
                        e.preventDefault();
                   
                        this.setState({ isLoadingState: true });
                        this.setState({areaid2:document.getElementById("areaSelected").value});
                        this.cargarDatos(document.getElementById("areaSelected").value);
                      }}
                    >
                      {this.props.areaOrganizacionales.map((area) => (
                        <option key={area.areaOrganizacionalId} value={area.areaOrganizacionalId}>
                          {area.nombre}
                        </option>
                        
                      ))}
                    </select>

                    <label>Filtro Área</label>
                    <select
                        className="form-control"
                        defaultValue={this.state.areaid2}

                        id="areaSelected"
                        onChange={(e) => {
                          e.preventDefault();

                          this.setState({ isLoadingState: true });
                          this.setState({areaid2:document.getElementById("areaSelected").value});
                          this.cargarDatos(document.getElementById("areaSelected").value);
                        }}
                    >
                      {this.props.areaOrganizacionales.map((area) => (
                          <option key={area.areaOrganizacionalId} value={area.areaOrganizacionalId}>
                            {area.nombre}
                          </option>

                      ))}
                    </select>

                  </div>

                  <div className="col-md-12 text-right">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        this.activarModalInformeTrimestral();
                      }}
                    >
                      Agregar Informe
                    </button>
                  </div>
                  <div className="col-md-12 mt-3">
                    <Table>
                      <thead>
                        <tr>
                          <th>Trimestre</th>
                          <th>Documento</th>
                          <th>Archivo</th>
                          <th>Acción</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.informesTrimestrales.map((informe) => (
                          <tr key={informe.informeTrimestralId}>
                            <td>{informe.trimestre.trimestreId}</td>
                            <td>{informe.nombreImagen}</td>
                            <td>
                              <a
                                style={{ color: "#525f7f" }}
                                target="_blank"
                                rel="nooponer noreferrer"
                                href={`${api_download_informes}${informe.documento.substring(
                                  0,
                                  4
                                )}/${informe.documento.substring(4, 8)}/${
                                  informe.documento
                                }`}
                                download={informe.nombreImagen}
                              >
                                <i className=" btn fas fa-cloud-download-alt"></i>
                              </a>
                            </td>
                            <td>
                              <button
                                className="btn btn-success btn-sm"
                                disabled={
                                  informe.nivelAprobacion.nivelAprobacionId !==
                                  this.props.user.nivelAprobacion
                                    .nivelAprobacionId
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.enviarInforme(informe);
                                }}
                              >
                                Enviar
                              </button>
                              <button
                                className="btn btn-danger btn-sm"
                                disabled={
                                  informe.nivelAprobacion.nivelAprobacionId !==
                                  this.props.user.nivelAprobacion
                                    .nivelAprobacionId
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.eliminarInformeTrimestral(
                                    informe.informeTrimestralId
                                  );
                                }}
                              >
                                Eliminar
                              </button>
                            </td>
                          </tr>
                        ))}
                        <tr></tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
            </div>
            <ModalInformeTrimestral
              guardarInformeTrimestral={this.guardarInformeTrimestral}
              trimestres={this.state.trimestresEntity}
              seleccionarTrimestre={this.seleccionarTrimestre}
              modalInformeTrimestralStatus={
                this.state.modalInformeTrimestralStatus
              }
              cargarInformeTrimestral={this.cargarInformeTrimestral}
              activarModalInformeTrimestral={this.activarModalInformeTrimestral}
            ></ModalInformeTrimestral>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  trimestres: state.trimestres.trimestres,
  informesTrimestrales: state.informesTrimestrales.informesTrimestrales,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,//me
});

const mapDispatchToProps = (dispatch) => ({
  getTrimestres: () => dispatch(getTrimestres()),
  createInformeTrimestral: (informeTrimestral) =>
    dispatch(createInformeTrimestral(informeTrimestral)),
  getInformesTrimestral: (poaId, areaOrganizacionalId) =>
    dispatch(getInformesTrimestral(poaId, areaOrganizacionalId)),
  deleteInformeTrimestral: (informeTrimestralId) =>
    dispatch(deleteInformeTrimestral(informeTrimestralId)),
  updateInformeTrimestral: (informeTrimestral) =>
    dispatch(updateInformeTrimestral(informeTrimestral)),
    getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),//me
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(InformeTrimestral, [
    "Responsables de areas",
    "Administrador general",
    "MAE",
  ])
);
