import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../utils/Loading.jsx";
import ListadoActividades from "../actividadesNoPoa/index.jsx";
import { getProductoNopoa, getDocumentoDescargar } from "../../redux/actions/productos-actions.js";
import { calcularPonderacionAreaNopoa } from "../../redux/actions/actividades-actions.js";

import { Authorization } from "../../utils/Authorization.js";
import {
  Container,
  UncontrolledTooltip
} from "reactstrap";

import {} from "../../redux/types.js";

class DetalleProductoNoPoa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productoNopoa: {},
      isLoadingState: true
    };
  }
  componentDidMount() {
    const { productoNoPoaId } = this.props.match.params;
    this.consultarProducto(productoNoPoaId);
  }

  validarOpcionEditar = () => {
    let resultado =
      this.props.user.nivelAprobacion.nivelAprobacionId ===
        this.props.productoNopoa.nivelAprobacion.nivelAprobacionId
    return resultado;

    return true;
  };

  consultarProducto = async productoNoPoaId => {
    await this.props.getProductoNopoa(productoNoPoaId);
    // if(undefined !== this.props.productoNopoa.actividades) {
    //   await this.props.calcularPonderacionAreaNopoa();
    // }
    this.setState({ isLoadingState: false });
  };

  componentWillReceiveProps(nextProps, nextState) {
    const { productoNopoa } = nextProps;
    this.setState({
      productoNopoa: productoNopoa,
      isLoading: false
    });
  }

  dispatchProductos = productoNoPoaId => async dispatch => {
    dispatch(getProductoNopoa(productoNoPoaId));
  };

  descargarDocumentoNoPoa = async (productoNoPoaId) => {
    await this.props.getDocumentoDescargar(productoNoPoaId);
  }

  render() {
    const { productoNopoa } = this.props;

    const {
      id,
      productoNoPoaId,
      descripcion,
      codigoSnip,
      resultado,
      meta,
      indicador,
      ponderacionArea,
      actividadesNopoa,
      observacion,
      documento
    } = productoNopoa;

    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          {this.state.isLoadingState? (
            <Loading></Loading>
          ) : (
            <div className="container bg-white border">
              <form
                className="needs-validation p-3 "
                onSubmit={this.crearProducto}
                noValidate
              >

            <div className="form-group">
                  <label htmlFor="descripción">Producto</label>
                  <input
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="descripción"
                    placeholder="Nombre del producto"
                    value={descripcion}
                    readOnly
                  />
            </div>

                <div className="form-group ">
                  <label htmlFor="descripción">Resultado</label>
                  <textarea
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="resultado"
                    placeholder="Resultado del producto"
                    defaultValue={resultado}
                    readOnly
                  />
                </div>

                <div className="row">
                  <div className="form-group col-md-4">
                    <label htmlFor="indicador">Indicador</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="indicador"
                      placeholder="Indicador del producto"
                      value={indicador}
                      readOnly
                    />
                  </div>

                  <div className="form-group col-md-4">
                    <label htmlFor="meta">Meta</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="meta"
                      placeholder="Meta"
                      value={meta}
                      readOnly
                    />
                  </div>

                  <div className="form-group col-md-4">
                    <label htmlFor="meta">Documento</label>
                    { documento ?
                    <a 
                    className="p-2" 
                    onClick={async () => {
                      this.descargarDocumentoNoPoa(productoNoPoaId)
                    }}
                    >
                    <i className="fas fa-download text-primary" id="TooltipDescargarDocumentoNoPoa">{ ' ' + documento.substring(0, 25) + ' ...' }</i>
                    <UncontrolledTooltip
                      placement="down"
                      target="TooltipDescargarDocumentoNoPoa"
                    >
                      {documento}
                    </UncontrolledTooltip>
                  </a>
                    : <a className="p-2">
                    <i className="fas fa-download text-muted"></i>
                    </a>
                    }
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="observacion">Observacion por devolucion</label>
                  <textarea
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="observacion"
                    placeholder="Descripción de la devolución"
                    defaultValue={observacion}
                    readOnly
                  />
                </div>
                {actividadesNopoa && (
                  <ListadoActividades
                    validarOpcionEditar={this.validarOpcionEditar}
                    productoNoPoaId={productoNoPoaId}
                    actividadesprueba={actividadesNopoa}
                    getDocumentoDescargar={getDocumentoDescargar}
                  ></ListadoActividades>
                )} 
              </form>
            </div>
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  productoNopoa: state.productosNoPoa.productoNopoa,
  productosNoPoa: state.productosNoPoa.productosNoPoa,
  actividadesNopoa: state.actividadesNopoa.actividadesNopoa,
  isLoading: state.productos.isLoading,
  user: state.auth.signed,
});

const mapDispatchToProps = dispatch => ({
  getProductoNopoa: (productoNoPoaId) => dispatch(getProductoNopoa(productoNoPoaId)),
  calcularPonderacionAreaNopoa: actividades => dispatch(calcularPonderacionAreaNopoa(actividades)),
  getDocumentoDescargar: (productoNoPoaId) => dispatch(getDocumentoDescargar(productoNoPoaId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(DetalleProductoNoPoa, [
    "Administrador general",
    "Producto Fuera del POA",
    "Gestor del POA",
    "MAE",
  ])
);
