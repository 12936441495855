import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { deleteRequerimiento } from "../../redux/actions/requerimientos-actions.js";
import Moment from "react-moment";
import {
  UncontrolledTooltip
} from "reactstrap";

class Requerimiento extends Component {
  state = {};
  eliminarRequerimiento = () => {
    let confirmed = window.confirm("Está seguro de eliminar el requerimiento");
    if (confirmed) {
      this.props.deleteRequerimiento(this.props.requerimiento);
    }
  };
  
  render() {
    const { requerimientoId, numeroSolicitud, fechaCreacion, creadoPor, estado, analista, tipoCompras, sigef, requerimiento, requerimientoProcesoPlurianual} = this.props.requerimiento;

    // let ultimaActualizacionHistorico = requerimiento[requerimiento.length - 1];
    let areaCreadora = requerimiento[0].area.areaOrganizacionalId;


    return (
      <tr className="text-center">
        <td>{numeroSolicitud}</td>
        <td>{estado.nombre}</td>
        <td>{requerimiento[requerimiento.length-1].area.nombre}</td>
        <td>
            <Moment
              format="DD/MM/YYYY"
              date={fechaCreacion}
            >
            </Moment>
        </td>
        <td>{(tipoCompras && tipoCompras.nombre !== null) ? tipoCompras.nombre : '-'}</td>
        <td>{(analista && analista.name !== null) ? analista.name : '-' }</td>
        <td>{requerimientoProcesoPlurianual !== null ? 'Si' : 'No'}</td>
        {/* <td>{sigef}</td> */}
      
        <td>
          <div className="d-flex flex-row mb-3">
            <div class="p-2">
              <Link
                to={{
                  pathname: `/admin/solicitud-requerimiento/mostrar/${requerimientoId}`,
                  state: "/admin/solicitud-requerimiento/mostrar/:requerimientoId",
                }}
                className="p-2"
              >
                <i className="fas fa-eye text-primary"></i>
              </Link>
            </div>
            <div class="p-2">
              {this.props.user.areaOrganizacional.areaOrganizacionalId== requerimiento[requerimiento.length-1].area.areaOrganizacionalId?(
              <Link
                to={{
                  pathname: `/admin/solicitud-compra/recepcion-remision-compra/validacion/${requerimientoId}`,
                  state: `/admin/solicitud-compra/recepcion-remision-compra/validacion/:solicitudCompraId`,
                }}
                className="p-2"
              >
                <i className="fas fas fa-angle-double-right text-primary"></i>
              </Link>
              ):(
                <i className="fas fas fa-angle-double-right " style={{color:"gray"}}></i>
              )}
            </div>
            <div class="p-2">
              {this.props.user.nivelAprobacion.nivelAprobacionId < 1 ? (
                <button
                  className="btn btn-success btn-sm"
                  disabled={true}
                  title="No tiene permisos para editar los requerimientos"
                >
                  Editar
                </button>
              ) : (
                <Link
                to={{
                  pathname: `/admin/solicitud-requerimiento/editar/${requerimientoId}`,
                  state: "/admin/solicitud-requerimiento/editar/:requerimientoId",
                }}
                className="p-2"
              >
                <i className="fas fa-pen text-primary"></i>
              </Link>
              )}
            </div>
            <div class="p-2">
              { (
                // (!estado.estadoId == 8 || !estado.estadoId ==2) && creadoPor.id !== this.props.obtenerIdUsuario(creadoPor.id) && ultimaActualizacionHistorico.fechaEmision === null ? (
                (!(estado.estadoId == 8) || !(estado.estadoId ==2)) && !(this.props.user.areaOrganizacionalId==areaCreadora) ? (
                  <div>
                      <i 
                      className="fas fa-times text-muted"
                      disabled={true}
                      title="El requerimiento debe haber sido devuelto o aún no haber sido enviado para eliminar el requerimiento"
                      id="TooltipEliminarRequerimiento"
                      >
                      </i>
                    <UncontrolledTooltip
                      placement="down"
                      target="TooltipEliminarRequerimiento"
                    >
                      El requerimiento debe haber sido devuelto o aún no haber sido enviado para eliminar el requerimiento
                    </UncontrolledTooltip>
                  </div>
                ) : (
                  <i
                    className="fas fa-times text-primary"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      this.props.eliminarRequerimiento(requerimientoId);
                    }}
                  ></i>
                )
              )}
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default connect(null, { deleteRequerimiento })(Requerimiento);
