import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getInvolucrado,
  updateInvolucrados
} from "./../../redux/actions/involucrados-actions";
import Loading from "./../utils/Loading.jsx";

import { Authorization } from "./../../utils/Authorization";
import {
  Container,
} from "reactstrap";

class EditarInvolucrados extends Component {
  componentDidMount() {
    const { involucradoId } = this.props.match.params;
    this.props.getInvolucrado(involucradoId);
  }
  componentWillReceiveProps(nextProps, nextState) {
    const { descripcion } = nextProps.involucrado;
  }
  descripcionRef = React.createRef();
  estadoRef = React.createRef();

  actualizarInvolucrados = e => {
    e.preventDefault();
    let editarInvolucrados = {
      descripcion: this.descripcionRef.current.value,
      estado: this.estadoRef.current.value,
      involucradoId: this.props.involucrado.involucradoId
    };
    this.props.updateInvolucrados(editarInvolucrados).then(resultado => {
      this.props.history.push("/admin/involucrado/index");
    });
  };

  render() {
    const { isLoading, involucrado } = this.props;
    const { descripcion, estado } = involucrado;

    return (
      <>
        {!isLoading ? (
          <div className="pb-6 pt-5 pt-md-7">
            <Container>
              <div className="container bg-white border">
                <form className="p-3" onSubmit={this.actualizarInvolucrados}>
                  <div className="form-group">
                    <label htmlFor="descripción">Nombre</label>
                    <input
                      type="text"
                      className="form-control text-dark"
                      id="descripción"
                      placeholder="Involucrado"
                      ref={this.descripcionRef}
                      defaultValue={descripcion}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="estado">Estado</label>
                    <select
                      className="custom-select custom-select-sm text-dark"
                      id="estado"
                      ref={this.estadoRef}
                    >
                      <option value={estado} defaultValue={estado}>
                        {estado ? "Activo" : "Inactivo"}
                      </option>
                      {estado ? (
                        <option value={false}>Inactivo</option>
                      ) : (
                        <option value={true}>Activo</option>
                      )}
                    </select>
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Editar
                  </button>
                </form>
              </div>
            </Container>
          </div>
        ) : (
          <Loading></Loading>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    involucrados: state.involucrados.involucrados,
    involucrado: state.involucrados.involucrado,
    isLoading: state.involucrados.isLoading,
    user: state.auth.signed
  };
};

const mapDispatchToProps = dispatch => ({
  updateInvolucrados: involucrado => dispatch(updateInvolucrados(involucrado)),
  getInvolucrado: involucradoId => dispatch(getInvolucrado(involucradoId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(EditarInvolucrados, [
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Analista del POA",
    "MAE"
  ])
);
