import React, { Component } from "react";

import { connect } from "react-redux";
import {
  createProductoNopoa,
  calcularPonderacionAreaNopoa,
} from "../../redux/actions/productos-actions.js";
import {
  getEjesEstrategicos,
  getEstrategias,
  getObjetivosEstrategicos,
} from "../../redux/actions/planesEstrategicos-actions.js";
import { getInvolucrados } from "../../redux/actions/involucrados-actions.js";
import {
  getAreasOrganizacionalesResponsables,
} from "../../redux/actions/areas-actions.js";
import { getPonderacionArea } from "../../redux/actions/ponderacion-areas-action.jsx";
import { Authorization } from "../../utils/Authorization.js";

import { Multiselect } from "react-widgets";
import Loading from "../utils/Loading.jsx";

import { Container } from "reactstrap";
import swal from "sweetalert";

class CrearProductoNoPoa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      ejesEstrategicos: [],
      objetivosEstrategicos: [],
      estrategias: [],
      validarTotalPonderacion: false,
      file:[],
      documento:[],
    };
  }

  productoRef = React.createRef();
  resultadoRef = React.createRef();
  indicadorRef = React.createRef();
  metaRef = React.createRef();
  ponderacionAreaRef = React.createRef();
  ejeEstrategicoRef = React.createRef();
  objetivoGeneralRef = React.createRef();
  estrategiaRef = React.createRef();
  unidadResponsableRef = [];
  involucradosRef = React.createRef();
  ponderacionGeneralRef = React.createRef();

  componentDidMount() {
    // console.log("this.props.user.areaOrganizacional",this.props.user.areaOrganizacional)
    this.unidadResponsableRef.push(this.props.user.areaOrganizacional);
    this.cargarData();
  }

  cargarData = async () => {
    await this.props.getAreasOrganizacionalesResponsables();
    await this.props.getPonderacionArea(
      this.props.poaActivo.poaId,
      this.props.user.areaOrganizacional.areaOrganizacionalId
    );
    // await this.props.getEjesEstrategicos();
    // await this.props.getEstrategias();
    // await this.props.getObjetivosEstrategicos();
    // await this.props.getInvolucrados();

    const {
      ejesEstrategicos,
      estrategias,
      objetivosEstrategicos,
    } = this.props.planesEstrategicos;

    if (
      objetivosEstrategicos.length > 0 &&
      this.state.objetivosEstrategicos.length === 0
    ) {
      this.setState(
        { ejesEstrategicos, objetivosEstrategicos, estrategias },
        () => {
          if (
            ejesEstrategicos.length === 1 &&
            this.state.ejesEstrategicos.length > 0
          ) {
            this.setState(
              {
                objetivosEstrategicos: this.state.ejesEstrategicos[0]
                  .objetivosEstrategicos,
              },
              () => {
                if (this.state.objetivosEstrategicos.length === 1) {
                  this.setState({
                    estrategias: this.state.objetivosEstrategicos[0]
                      .estrategias,
                  });
                }
              }
            );
          }
        }
      );
    }
    this.setState({ isLoadingState: false });
  };

  crearProducto = async (e) => {
    this.setState({ isLoadingState: true });
    e.preventDefault();
    if (
      this.validarProducto(
        this.productoRef.current.value,
        this.resultadoRef.current.value,
        this.indicadorRef.current.value,
        this.metaRef.current.value,
        this.unidadResponsableRef,
        this.state.file
      )
    ) {
      let producto = {
        descripcion: this.productoRef.current.value,
        resultado: this.resultadoRef.current.value,
        indicador: this.indicadorRef.current.value,
        meta: this.metaRef.current.value,
        areaOrganizacionalId: this.unidadResponsableRef[0].areaOrganizacionalId,
        usuarioId: 2,
        nivelAprobacionId: this.props.user.nivelAprobacion.nivelAprobacionId,
        documento: this.state.file,
      };

      // Cargar documento
      const fd = new FormData();
      if (this.state.file.length > 0) {
        for (let i = 0; i < this.state.file.length; i++) {
          fd.append(`documento[]`, this.state.file[i], this.state.documento[i]);
        }
      }
      Object.keys(producto).forEach(function (item) {
        fd.append(item, producto[item]);
      });

      await this.props.createProductoNopoa(producto);
      this.props.history.push("/admin/productosNoPoa/index");
    }
    this.setState({ isLoadingState: false });
  };

  validarProducto = (
    producto,
    resultado,
    indicador,
    meta,
    unidadResponsable,
    documento
  ) => {
    if (producto === "") {
      swal(
        "Crear Producto",
        "El campo producto es un valor requerido",
        "error"
      );

      return false;
    }
    if (resultado === "") {
      swal(
        "Crear Producto",
        "El campo resultado es un valor requerido",
        "error"
      );

      return false;
    }
    if (indicador === "") {
      swal(
        "Crear Producto",
        "El campo indicador es un valor requerido",
        "error"
      );

      return false;
    }
    if (meta === "") {
      swal("Crear Producto", "El campo meta es un valor requerido", "error");

      return false;
    }
    if (documento < 1) {
      swal("Crear Producto", "El campo documento es un valor requerido", "error");

      return false;
    }
    // if (ponderacionArea === "") {
    //   swal(
    //     "Crear Producto",
    //     "El campo ponderación del producto en la unidad es un valor requerido",
    //     "error"
    //   );

    //   return false;
    // }
    // if (ejeEstrategico === "--Seleccione una opción--") {
    //   swal(
    //     "Crear Producto",
    //     "El campo eje estrategico es un valor requerido",
    //     "error"
    //   );

    //   return false;
    // }
    // if (objetivoGeneral === "--Seleccione una opción--") {
    //   swal(
    //     "Crear Producto",
    //     "El campo objetivo estratégico es un valor requerido",
    //     "error"
    //   );

    //   return false;
    // }
    // if (estrategia === "--Seleccione una opción--") {
    //   swal(
    //     "Crear Producto",
    //     "El campo estrategico es un valor requerido",
    //     "error"
    //   );

    //   return false;
    // }
    if (unidadResponsable === "--Seleccione una opción--") {
      swal(
        "Crear Producto",
        "El campo unidad responsable es un valor requerido",
        "error"
      );

      return false;
    }
    if (unidadResponsable.length === 0) {
      swal(
        "Crear Producto",
        "El campo unidad responsable es un valor requerido",
        "error"
      );

      return false;
    }

    // if (donante === "" && cooperacion !== "") {
    //   swal(
    //     "Crear Producto",
    //     "El campo donante es un valor requerido si el producto lleva cooperación",
    //     "error"
    //   );

    //   return false;
    // }

    // if (donante !== "" && cooperacion === "") {
    //   swal(
    //     "Crear Producto",
    //     "El campo cooperación es un valor requerido si el producto lleva donante",
    //     "error"
    //   );

    //   return false;
    // }
    return true;
  };

  calcularPonderacionTotalProductos = () => {
    let ponderacionArea =
      this.ponderacionAreaRef.current !== null &&
      this.ponderacionAreaRef.current.value !== ""
        ? this.ponderacionAreaRef.current.value
        : 0;
    let total =
      this.props.totalPonderacionProductosNopoa + parseFloat(ponderacionArea);
    if (total <= 100) {
      this.setState({ validarTotalPonderacion: true });
    } else {
      this.setState({ validarTotalPonderacion: false });
      swal(
        "Crear Producto",
        "Excede el límite de ponderación permitido para los productos, actualmente tiene " +
          total +
          "%",
        "error"
      );
    }
  };

  calcularPonderacionArea = (e) => {
    e.preventDefault();

    if (Object.keys(this.props.ponderacionArea).length > 0) {
      this.ponderacionGeneralRef.current.value =
        this.ponderacionAreaRef.current.value *
        (this.props.ponderacionArea.ponderacion / 100);
    }
  };

  cargarDocumento = (documento) => {
    let files = [];
    let documentos = [];
    if (documento.target.files.length > 0) {
      for (let i = 0; i < documento.target.files.length; i++) {
        files.push(documento.target.files[i]);
        documentos.push(documento.target.files[i].name);
      }
      this.setState({
        file: files,
        documento: documentos,
      });
    } else {
      this.setState({
        file: "",
        documento: "",
      });
    }
  };

  render() {
    const { involucrados, areasOrganizacionales } = this.props;
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container>
          {this.state.isLoadingState ? (
            <Loading></Loading>
          ) : (
            <div className="container bg-white border">
              <form
                className="needs-validation p-3 "
                onSubmit={this.crearProducto}
                noValidate
              >
                <div className="form-group ">
                  <label htmlFor="descripción">Producto</label>
                  <input
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="descripción"
                    placeholder="Nombre del producto"
                    ref={this.productoRef}
                  />
                  <div className="invalid-feedback">
                    Este es un campo obligatorio
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="resultado">Resultado (s)</label>
                  <textarea
                    className="form-control form-control-sm text-dark"
                    id="resultado"
                    placeholder="Resultado del producto"
                    ref={this.resultadoRef}
                  ></textarea>
                </div>
                <div className="row">
                  <div className="form-group col-md-6 ">
                    <label htmlFor="indicador">Indicador (es)</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="indicador"
                      placeholder="Indicador del producto"
                      ref={this.indicadorRef}
                    />
                  </div>
                  <div className="form-group col-md-6 ">
                    <label htmlFor="meta">Meta</label>
                    <input
                      type="text"
                      className="form-control form-control-sm  text-dark"
                      id="meta"
                      placeholder="Meta del producto"
                      ref={this.metaRef}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group input-group-sm col-md-6 ">
                    <label htmlFor="unidadResponsable">
                      Unidad Responsable
                    </label>
                    <Multiselect
                      placeholder="--Seleccione las unidades responsables--"
                      data={areasOrganizacionales}
                      id="unidadResponsable"
                      textField="nombre"
                      defaultValue={areasOrganizacionales.filter(
                        (area) =>
                          area.areaOrganizacionalId ===
                          this.unidadResponsableRef[0].areaOrganizacionalId
                      )}
                      onChange={(e) => {
                        this.unidadResponsableRef = [];
                        e.map((area) =>
                          this.unidadResponsableRef.push(
                            area.areaOrganizacionalId
                          )
                        );
                      }}
                      minLength={3}
                    />
                  </div>
                  <div className="form-group input-group-sm col-md-6 ">
                    <label htmlFor="inputDocumentoPDF">Documento (PDF)</label>
                    <input
                      id="inputDocumentoPDF"
                      className="form-control text-dark"
                      type="file" accept=".pdf"
                      name="file[]"
                      onChange = {
                        (e) => {
                          e.preventDefault();
                          ////////////////////////////////////////////////////////////
                          var fileInput = document.getElementById('inputDocumentoPDF');
                          var filePath = fileInput.value;
                          var allowedExtensions = /(\.pdf|\.PDF)$/i;
                          if (!allowedExtensions.exec(filePath)) {
                            swal("Error de tipo de archivo", "Debe cargar archivos en formato PDF", "error");
                            fileInput.value = '';
                            return false;
                          }
                          ///////////////////////////////////////////////
                            this.cargarDocumento(e);
                        }
                      }
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  Guardar
                </button>
              </form>
            </div>
          )}
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    poaActivo: state.poas.poaActivo,
    planesEstrategicos: state.planesEstrategicos,
    involucrados: state.involucrados.involucrados,
    areasOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
    user: state.auth.signed,
    totalPonderacionProductosNopoa: state.productosNoPoa.totalPonderacionProductosNoPoa,
    ponderacionArea: state.ponderacionAreas.ponderacionArea,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getEjesEstrategicos: () => dispatch(getEjesEstrategicos()),
  getEstrategias: () => dispatch(getEstrategias()),
  getObjetivosEstrategicos: () => dispatch(getObjetivosEstrategicos()),
  getInvolucrados: () => dispatch(getInvolucrados()),
  getAreasOrganizacionalesResponsables: () => dispatch(getAreasOrganizacionalesResponsables()),
  createProductoNopoa: (producto) => dispatch(createProductoNopoa(producto)),
  getPonderacionArea: (poaId, areaOrganizacionalId) => dispatch(getPonderacionArea(poaId, areaOrganizacionalId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(CrearProductoNoPoa, [
    "Administrador general",
    "Producto Fuera del POA",
    "Gestor del POA",
    "MAE",
  ])
);
