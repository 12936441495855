import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import Select from 'react-select'
import {
  Table,
  Container,
} from "reactstrap";
import { Authorization } from "../../../utils/Authorization";
import Loading from "../../utils/Loading";

import { ModalInformeTrimestral } from "../../utils/ModalInformeTrimestral";
import { getTrimestres } from "../../../redux/actions/trimestres-actions";
import { api_download_informes } from "../../../config";
import {
  createInformeTrimestral,
  updateInformeTrimestral,
  getInformesTrimestral,
  deleteInformeTrimestral,
} from "../../../redux/actions/informe_trimestral_actions";

import { getListaTipoCompras } from "../../../redux/actions/tipos-compras-actions";
import { getListaEstados } from "../../../redux/actions/estados-actions";
import { getListaUsuarios, getListaAnalistasAsignados } from "../../../redux/actions/usuarios-actions";

class DepartamentoAdministrativoProcesoCompra extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      trimestres: [],
      trimestresEntity: [],
      modalInformeTrimestralStatus: false,
      selectedFile: null,
      file: [],
      fecha: "",
      documento: [],
      trimestreId: "",
      estatusEnviar: false,
      areaid2:0,
      listaUsuariosState: [],

      editarArticulo: {}
    };
  }

  componentWillReceiveProps(next_props, nextState) {
    this.setState({ listaUsuariosState: this.props.usuarios });
  }
  
  componentDidMount() {
    this.cargarDatos();
   
    // console.log(this.props)
    this.setState({areaid2:this.props.user.areaOrganizacional.areaOrganizacionalId})
  }

  cargarDatos = async () => {
    await this.props.getListaUsuarios();
    this.setState({ listaUsuariosState: this.props.usuarios }, () =>
      this.validarPaginacion()
    );

    this.setState({ isLoading: false });
  };

  cargarDatos = async (areafiltro) => {
    await this.props.getTrimestres();
    await this.props.getListaTipoCompras();
    await this.props.getListaEstados();
    await this.props.getListaUsuarios();
    await this.props.getListaAnalistasAsignados();

    // console.log(this.props)

    await this.props.getInformesTrimestral(
      this.props.poaActivo.poaId,
    );

    this.setState({ listaUsuariosState: this.props.usuarios });

    this.setState({ isLoadingState: false, trimestres: this.props.trimestres });
    await this.CargarTrimestre();
  };

  cargarInformeTrimestral = (informe) => {
    let files = [];
    let documentos = [];
    if (informe.target.files.length > 0) {
      for (let i = 0; i < informe.target.files.length; i++) {
        files.push(informe.target.files[i]);
        documentos.push(informe.target.files[i].name);
      }
      this.setState({
        file: files,
        documento: documentos,
      });
    }
    else {
      this.setState({
        file: "",
        documento: "",
      });
    }
  };

  editarArticulo = (IndiceFila) => {
    this.setState((prevState) => {
      const editadoEditarArticulo = { ...prevState.editarArticulo };
      editadoEditarArticulo[IndiceFila] = !editadoEditarArticulo[IndiceFila];
      return { editarArticulo: editadoEditarArticulo };
    });
  };

  eliminarArticulo = (IndiceFila) => {
    alert('Proceso para eliminar')
  };

  activarModalInformeTrimestral = () => {
    this.CargarTrimestre();
    this.setState({
      modalInformeTrimestralStatus: !this.state.modalInformeTrimestralStatus,
    });
  };

  CargarTrimestre = () => {
    let informesTrimestral = this.props.informesTrimestrales;
    let trimestreState = [...this.state.trimestres];
    for (let i = 0; i < trimestreState.length; i++) {
      for (let m = 0; m < informesTrimestral.length; m++) {
        if (
          informesTrimestral[m].trimestre.trimestreId ===
          trimestreState[i].trimestreId
        ) {
          trimestreState.splice(i, 1);
          this.setState({ trimestresEntity: trimestreState });
          i = -1;
          break;
        }
      }
    }
    if (
      this.state.trimestresEntity.length === 0 &&
      informesTrimestral.length === 0
    ) {
      this.setState({ trimestresEntity: this.state.trimestres });
    }
  };

  seleccionarTrimestre = (trimestreId) => {
    this.setState({ trimestreId: trimestreId });
  };

  enviarInforme = async (informe) => {
    this.setState({ isLoadingState: true });

    swal({
      title: "Está seguro de enviar a revisión el informe del trimestre?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (confirm) => {
      if (confirm) {
        let informeTrimestral = {
          informeTrimestralId: informe.informeTrimestralId,
          nivelAprobacionId: this.props.user.nivelAprobacion.padre,
          envio: true,
          comentario: "Enviado para aprobación",
        };
        await this.props.updateInformeTrimestral(informeTrimestral);
        this.setState({ isLoadingState: false });
      } else {
        this.setState({ isLoadingState: false });
      }
    });
  };

  eliminarInformeTrimestral = async (informeTrimestralId) => {

    swal({
      title: "Está seguro de querer eliminar este informe del trimestre?",
      text: "No podrá ser recuperado luego de ser eliminado",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (confirm) => {
      if (confirm) {
         this.setState({ isLoadingState: true });
         await this.props.deleteInformeTrimestral(informeTrimestralId);
         this.setState({ isLoadingState: false });
      }
    });
  };

  devolverInforme = async (informeTrimestralId, comentario) => {
    let informeTrimestral = {
      informeTrimestralId: informeTrimestralId,
      nivelAprobacionId: this.props.user.nivelAprobacion.hijo,
      envio: true,
      comentario,
    };

    await this.props.updateInformeTrimestral(informeTrimestral);
  };

  validarEstatusEnviado = (informeTrimestral) => {
    let resultado =
      informeTrimestral.nivelAprobacion.nivelAprobacionId !==
      this.props.user.nivelAprobacion.nivelAprobacionId
        ? true
        : false;
    return resultado;
  };

  validarGuardarInforme = () => {
    if (this.state.trimestreId.length === 0) {
      swal("Informe Trimestral", "El campo trimestre es requerido para el informe del trimestre","error");

      return false;
    }

    if (this.state.file.length === 0) {
      swal("Informe Trimestral", "El campo de archivo es un valor requerido para el informe del trimestre","error");

      return false;
    }
    return true;
  };

  guardarInformeTrimestral = async () => {
    if (this.validarGuardarInforme()) {
      this.setState({ isLoadingState: true });
      this.activarModalInformeTrimestral();

      let informeTrimestral = {
        trimestreId: this.state.trimestreId,
        nivelAprobacionId: this.props.user.nivelAprobacion.nivelAprobacionId,
        nombreImagen: this.state.documento[0],
        areaOrganizacionalId: this.props.user.areaOrganizacional
          .areaOrganizacionalId,
        poaId: this.props.poaActivo.poaId,
      };

      const fd = new FormData();
      if (this.state.file.length > 0) {
        for (let i = 0; i < this.state.file.length; i++) {
          fd.append(`documento[]`, this.state.file[i], this.state.documento[i]);
        }
      }

      Object.keys(informeTrimestral).forEach(function (item) {
        fd.append(item, informeTrimestral[item]);
      });

      await this.props.createInformeTrimestral(fd);

      this.setState({ isLoadingState: false });
    }
  };

  irAtras = () => {
    this.props.history.goBack();
  }

  validar;

  render() {
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (
               
                <div className="row">
                    <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-6 pb-4 border-right">
                            <h3>Detalles</h3>
                            <hr style={{ marginTop: '0rem' }}/>
                            <label className="text-left" htmlFor="">No. Requerimiento</label>
                            <input
                            type="text"
                            className="form-control text-dark"
                            id="requerimiento"
                            // ref={this.actividadRef}
                            // defaultValue={descripcion}
                            // readOnly={this.state.actividadEdit}
                            required
                            disabled={true}
                            value="INT-123"
                            />

                            <div className="form-group mt-4">
                                <label htmlFor="analistaAsignado">Analista asignado</label>
                                {/* <select
                                    id="analistaAsignado"
                                    className="form-control text-capitalize"
                                    ref={this.localRef}
                                >
                                  {this.props.usuarios.map((usuario) => (
                                    <option
                                      key={usuario.id}
                                      value={usuario.id}
                                    >
                                      {usuario.name}
                                    </option>
                                  ))}
                                </select> */}
                                <Select options={ this.props.usuariosAnalistasAsignados.map(usuario => ({
                                  value: usuario.id,
                                  label: usuario.name
                                })) }/>
                            </div>

                            <div className="form-group mt-4">
                                <label htmlFor="tipoProcesoCompra">Tipo proceso de compra</label>
                                <select
                                    id="tipoProcesoCompra"
                                    className="form-control"
                                    ref={this.localRef}
                                >
                                    {this.props.tiposCompras.map((tipoCompra) => (
                                  <option
                                    key={tipoCompra.tipoCompraId}
                                    value={tipoCompra.tipoCompraId}
                                  >
                                    {tipoCompra.nombre}
                                  </option>
                                ))}
                                </select>
                            </div>

                            <div className="form-group mt-4">
                                <label htmlFor="estatusSecundario">Estatus secundario</label>
                                <select
                                    id="estado"
                                    className="form-control"
                                    ref={this.localRef}
                                >
                                    {this.props.estados.map((estado) => (
                                  <option
                                    key={estado.estadoId}
                                    value={estado.estadoId}
                                  >
                                    {estado.nombre}
                                  </option>
                                ))}
                                </select>
                            </div>

                        </div>
                        <div className="col-6 mb-4">
                            <h3>Recepción</h3>
                            <div className="mt-2">
                                <hr style={{ marginTop: '0rem' }}/>
                                <label className="text-left" htmlFor="fechaRecepcion">Fecha</label>
                                <input
                                    type="date"
                                    className="form-control text-dark"
                                    id="fechaRecepcion"
                                    // ref={this.actividadRef}
                                    // defaultValue={descripcion}
                                    // readOnly={this.state.actividadEdit}
                                    required
                                    value=""
                                />
                            </div>

                            <div className="mt-4">
                                <label className="text-left" htmlFor="horaRecepcion">Hora</label>
                                <input
                                    type="time"
                                    className="form-control text-dark"
                                    id="horaRecepcion"
                                    // ref={this.actividadRef}
                                    // defaultValue={descripcion}
                                    // readOnly={this.state.actividadEdit}
                                    required
                                    value=""
                                />
                            </div>

                            <h3 className="mt-5">Remisión</h3>
                            <hr style={{ marginTop: '0rem' }}/>
                            <label className="text-left" htmlFor="fechaRemision">Fecha</label>
                            <input
                                type="date"
                                className="form-control text-dark"
                                id="fechaRemision"
                                // ref={this.actividadRef}
                                // defaultValue={descripcion}
                                // readOnly={this.state.actividadEdit}
                                required
                                value=""
                            />

                            <div className="mt-4">
                                <label className="text-left" htmlFor="">Hora</label>
                                <input
                                    type="time"
                                    className="form-control text-dark"
                                    id="horaRemision"
                                    // ref={this.actividadRef}
                                    // defaultValue={descripcion}
                                    // readOnly={this.state.actividadEdit}
                                    required
                                    value=""
                                />
                            </div>
                        </div>
                    </div>
                  </div>
                 
                  <div className="col-md-12 mt-5">
                    <h3 className="text-center">Insumos</h3>
                    <Table>
                        <thead>
                          <tr>
                            <th>Sub-clase</th>
                            <th>Cod. Gasto</th>
                            <th>Nombre</th>
                            <th>Precio</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                  type="text"
                                  className="form-control text-dark"
                                  id="subclase"
                                  // ref={this.actividadRef}
                                  // defaultValue={descripcion}
                                  // readOnly={this.state.actividadEdit}
                                  required
                                  value="D-24510"
                                  disabled={ !this.state.editarArticulo[1] }
                              />
                            </td>
                            <td>
                              <input
                                  type="text"
                                  className="form-control text-dark"
                                  id="subclase"
                                  // ref={this.actividadRef}
                                  // defaultValue={descripcion}
                                  // readOnly={this.state.actividadEdit}
                                  required
                                  value="884841215120"
                                  disabled={ !this.state.editarArticulo[1] }
                              />
                            </td>
                            <td>
                              <input
                                  type="text"
                                  className="form-control text-dark"
                                  id="subclase"
                                  // ref={this.actividadRef}
                                  // defaultValue={descripcion}
                                  // readOnly={this.state.actividadEdit}
                                  required
                                  value="Disco duro SSD"
                                  disabled={ !this.state.editarArticulo[1] }
                              />
                            </td>
                            <td>
                              <input
                                  type="text"
                                  className="form-control text-dark"
                                  id="subclase"
                                  // ref={this.actividadRef}
                                  // defaultValue={descripcion}
                                  // readOnly={this.state.actividadEdit}
                                  required
                                  value=" 5000 $RD"
                                  disabled={ !this.state.editarArticulo[1] }
                              />
                             
                             </td>
                            <td>
                           
                            <a
                                // to={{
                                //   pathname: `procesoCompra/${1}`,
                                //   state: "/admin/solicitud-compra/departamento-administrativo/procesoCompra/:solicitudCompraId",
                                // }}
                                className="p-2"
                                onClick={() => this.eliminarArticulo(1)}
                              >
                                <i className="fas fa-times text-danger"></i>
                              </a>
                              <a
                                // to={{
                                //   pathname: `procesoCompra/${1}`,
                                //   state: "/admin/solicitud-compra/departamento-administrativo/procesoCompra/:solicitudCompraId",
                                // }}
                                className="p-2"
                                onClick={() => this.editarArticulo(1)}
                              >
                                <i className="fas fa-pen text-primary"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>D-2451d0</td>
                            <td>884841215120</td>
                            <td>Disco duro SSD</td>
                            <td>5000 $RD</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </Table>

                      <div className="row pb-3 pt-5">
                        <div className="col-4 m-auto">
                          <button
                            onClick={() => this.irAtras()}
                            className="btn btn-danger"
                          >
                            CANCELAR
                          </button>
                          <button
                            // onClick={() => this.eliminarPoa(poaId)}
                            className="btn btn-primary"
                          >
                            GUARDAR
                          </button>
                        </div>
                      </div>

                  </div>
                </div>
              )}
            </div>
            <ModalInformeTrimestral
              guardarInformeTrimestral={this.guardarInformeTrimestral}
              trimestres={this.state.trimestresEntity}
              seleccionarTrimestre={this.seleccionarTrimestre}
              modalInformeTrimestralStatus={
                this.state.modalInformeTrimestralStatus
              }
              cargarInformeTrimestral={this.cargarInformeTrimestral}
              activarModalInformeTrimestral={this.activarModalInformeTrimestral}
            ></ModalInformeTrimestral>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  trimestres: state.trimestres.trimestres,
  informesTrimestrales: state.informesTrimestrales.informesTrimestrales,
  tiposCompras: state.tiposCompras.tiposCompras,
  estados: state.estados.estados,
  usuarios: state.usuarios.usuarios,
  usuariosAnalistasAsignados: state.usuarios.usuariosAnalistasAsignados,
});

const mapDispatchToProps = (dispatch) => ({
  getTrimestres: () => dispatch(getTrimestres()),
  createInformeTrimestral: (informeTrimestral) =>
    dispatch(createInformeTrimestral(informeTrimestral)),
  getInformesTrimestral: (poaId, areaOrganizacionalId) =>
    dispatch(getInformesTrimestral(poaId, areaOrganizacionalId)),
  deleteInformeTrimestral: (informeTrimestralId) =>
    dispatch(deleteInformeTrimestral(informeTrimestralId)),
  updateInformeTrimestral: (informeTrimestral) =>
    dispatch(updateInformeTrimestral(informeTrimestral)),
    getListaTipoCompras: () => dispatch(getListaTipoCompras()),
    getListaEstados: () => dispatch(getListaEstados()),
    getListaUsuarios: () => {
      dispatch(getListaUsuarios());
    },
    getListaAnalistasAsignados:  () => dispatch(getListaAnalistasAsignados())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(DepartamentoAdministrativoProcesoCompra, [
    "Administrador general",
    "Responsables de compras",
    "Encargado Administrativo",
    "Analista Compras"
  ])
);
