import {
  MOSTRAR_ESTADOS,
  ESTADOS_FAILED,
  LOADING_ESTADOS,
  ESTADOS_SUCCESS,
} from "../types";
import initialState from "../initial-state.js";
const { estados } = initialState;

const estadosReducer = (state = estados, action) => {
  switch (action.type) {
    case MOSTRAR_ESTADOS:
      return {
        ...state,
        estados: action.payload
      };
    case LOADING_ESTADOS:
      return {
        ...state,
        isLoading: true
      };
    case ESTADOS_SUCCESS:
    case ESTADOS_FAILED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default estadosReducer;
