import React from "react";
import { Link } from "react-router-dom";
import { deleteProducto } from "../../redux/actions/productos-actions";

import { connect } from "react-redux";

const ProductoAdministracion = (props) => {
  const eliminarProducto = () => {
    const { poaId } = props.producto.poa;
    const { producto } = props;

    props.deleteProducto(producto, poaId);
  };

  const {
    productoId,
    descripcion,
    meta,
    indicador,
    ponderacionArea,
    areaOrganizacional,
  } = props.producto;
  return (
    <tr>
      <td>{areaOrganizacional.nombre}</td>
      <td>{descripcion}</td>
      <td>
        <Link
          to={{
            pathname: `/admin/producto/detalle/${productoId}`,
            state: "/admin/producto/detalle/:productoId",
          }}
          className="btn btn-primary btn-sm"
        >
          Detalle
        </Link>
        {props.validarOpcionEditar(props.producto) ? (
          <Link
            to={{
              pathname: `/user/producto/detalle/${productoId}`,
              state: "/user/producto/detalle/:productoId",
            }}
            className="btn btn-info btn-sm"
          >
            Aprobar
          </Link>
        ) : (
          <button className="btn btn-sm btn-info opacity">Aprobar</button>
        )}
        {props.validarOpcionEditar(props.producto) ? (
          <Link
            to={{
              pathname: `/admin/producto/editar/${productoId}`,
              state: "/admin/producto/editar/:productoId",
            }}
            className="btn btn-success btn-sm"
          >
            Editar
          </Link>
        ) : (
          <button className="btn btn-sm btn-success opacity">Editar</button>
        )}
        {props.validarOpcionEditar(props.producto) ? (
          <button
            onClick={() => {
              eliminarProducto();
            }}
            className="btn btn-danger btn-sm"
          >
            Eliminar
            <span className="fa fa-remove"></span>
          </button>
        ) : (
          <button className="btn btn-sm btn-danger opacity">Eliminar</button>
        )}
      </td>
    </tr>
  );
};

export default connect(null, { deleteProducto })(ProductoAdministracion);
