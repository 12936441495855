import {
    LOADING_ACTIVIDAD_NOPOA,
    MOSTRAR_ACTIVIDADES_NOPOA,
    MOSTRAR_ACTIVIDAD_NOPOA,
    ACTIVIDAD_SUCCEEDED_NOPOA,
    CREAR_ACTIVIDADES_NOPOA,
    EDITAR_ACTIVIDADES_NOPOA,
    ELIMINAR_ACTIVIDADES_NOPOA,
    MOSTRAR_ACTIVIDAD_LOCAL_NOPOA,
    ELIMINAR_PRESUPUESTO_NOPOA,
    ADD_PRESUPUESTO_NOPOA,
} from "../types";
import initialState from "../initial-state";

const { actividadesNopoa } = initialState;

const actividadesNopoaReducer = (state = actividadesNopoa, action) => {
    switch (action.type) {
        case LOADING_ACTIVIDAD_NOPOA:
            return {
                ...state,
                isLoading: true
            };
        case ACTIVIDAD_SUCCEEDED_NOPOA:
            return {
                ...state,
                isLoading: false
            };
        case MOSTRAR_ACTIVIDAD_NOPOA:
            return {...state, actividadNopoa: action.payload };
        case MOSTRAR_ACTIVIDADES_NOPOA:
            return {
                ...state,
                actividadesNopoa: action.payload
            };
        case CREAR_ACTIVIDADES_NOPOA:
            return {
                ...state,
                actividadesNopoa: [...state.actividadesNopoa, action.payload]
            };
            
        case EDITAR_ACTIVIDADES_NOPOA:
            return {
                ...state,
                actividadesNopoa: state.actividadesNopoa.map(actividadNopoa =>
                    actividadNopoa.actividadNoPoaId === action.payload.actividadNoPoaId ?
                    (actividadNopoa = action.payload) : actividadNopoa
                )
            };
        case ELIMINAR_ACTIVIDADES_NOPOA:
            return {
                ...state,
                actividadesNopoa: [
                    ...state.actividadesNopoa.filter(
                        actividadNopoa => actividadNopoa.actividadId !== action.payload
                    )
                ]
            };
        case MOSTRAR_ACTIVIDAD_LOCAL_NOPOA:
            return {
                ...state,
                actividadNopoa: state.actividadesNopoa.find(
                    actividadNopoa => actividadNopoa.actividadId === action.payload
                )
            };
        case ELIMINAR_PRESUPUESTO_NOPOA:
            return {
                ...state,
                presupuestoNopoa: [
                    ...state.actividadesNopoa.actividadNopoa.presupuestoNopoa.filter(
                        insumo => insumo.presupuestoId !== action.payload
                    )
                ]
            };
        case ADD_PRESUPUESTO_NOPOA:
            return {
                ...state,
                presupuestoNopoa: [
                    ...state.actividadesNopoa.actividadNopoa.presupuestoNopoa,
                    action.payload
                ]
            };
        default:
            return state;
    }
};

export default actividadesNopoaReducer;