import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import swal from "sweetalert";
import {
  deletMonitoreoActividad,
  getMonitoreoActividad,
  getMonitoreosActividad,
} from "./../../redux/actions/monitoreo-actividad-actions";

const Monitoreo = (props) => {
  const {
    monitoreoId,
    trimestre,
    nivelEjecucion,
    fecha,
    fechaCreacion,
    monitoreoMediosVerificacion,
    justificacionDeNoCumplimiento,
    creadoPor,
    areaOrganizacional,
  } = props.monitoreoActividad;

  const { actividadId, statusEditar, poaMonitoreo } = props;

  const eliminarMonitoreoActividad = () => {
    swal({
      title:`Está seguro de querer eliminar el monitoreo del trimestre ${props.monitoreoActividad.trimestre}?`,
      text: "Una vez eliminado no podrá recuperarse!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if (confirm) {
        let obj = {
          monitoreoActividadId: props.monitoreoActividad.monitoreoId,
          actividadId: props.actividadId,
          monitoreoMediosVerificacion:
            props.monitoreoActividad.monitoreoMediosVerificacion,
        };
  
        props.deletMonitoreoActividad(obj);
      }
    });
  };

  const validarStatus = () => {
    let value = poaMonitoreo.find(
      (statusMonitoreo) => statusMonitoreo.trimestreId === trimestre
    );
    return value === undefined ? false : !value.status;
  };

  const validarNivelAprobacion = () => {
    let resultado =
      props.monitoreoActividad.nivelAprobacion 
        ? props.monitoreoActividad.nivelAprobacion.nivelAprobacionId !==
          props.user.nivelAprobacion.nivelAprobacionId
        : false;
    return resultado;
  };
// console.log(props.monitoreoActividad);
  return (
    <tr>
      <td>{trimestre}</td>
      <td>{nivelEjecucion}</td>
      <td>
        {
          <Moment format="DD/MM/YYYY">
            {fecha !== null ? fecha : fechaCreacion}
          </Moment>
        }
      </td>
      <td>
        <i
          className=" btn fas fa-cloud-download-alt"
          onClick={(e) => {
            e.preventDefault();
            props.activarModalArchivos(
              monitoreoMediosVerificacion,
              validarStatus() || validarNivelAprobacion(),
              justificacionDeNoCumplimiento
            );
          }}
        />
      </td>

      <td>
        <button
          onClick={(e) => {
            e.preventDefault();
            props.enviarMonitoreo(props.monitoreoActividad, props.actividadId);
          }}
          disabled={validarStatus() || validarNivelAprobacion()}
          className="btn btn-success btn-sm"
          title={
            validarStatus()
              ? "El trimestre se ecuentra cerrado"
              : (
                  props.monitoreoActividad.nivelAprobacion 
                    ? props.monitoreoActividad.nivelAprobacion
                        .nivelAprobacionId !==
                      props.user.nivelAprobacion.nivelAprobacionId
                    : false
                )
              ? "El trimestre fue enviado"
              : ""
          }
        >
          {props.monitoreoActividad.nivelAprobacion == null
            ? "Enviar"
            : props.monitoreoActividad.nivelAprobacion.nivelAprobacionId > 4
            ? "Aprobado"
            : props.monitoreoActividad.nivelAprobacion.nivelAprobacionId ===
              props.user.nivelAprobacion.nivelAprobacionId
            ? "Enviar"
            : "Enviado"}
        </button>
        {props.user.nivelAprobacion.nivelAprobacionId === 2 &&
          creadoPor.name !== props.user.nombre && (
            <button
              className="btn btn-sm btn-primary"
              onClick={(e) => {
                e.preventDefault();
                props.activarModalDevolucionMonitoreo(props.monitoreoActividad);
              }}
              disabled={validarStatus()}
              title={validarStatus() ? "El trimestre se ecuentra cerrado" : ""}
            >
              Devolver
            </button>
          )}

        {validarStatus() ? (
          <button
            onClick={(e) => e.preventDefault()}
            className="btn btn-info btn-sm opacity"
            title="El trimestre se ecuentra cerrado"
          >
            Editar
          </button>
        ) : validarNivelAprobacion() ? (
          <button
            onClick={(e) => e.preventDefault()}
            className="btn btn-info btn-sm opacity"
            title="El trimestre se encuentra enviado"
          >
            Editar
          </button>
        ) : (
          <Link
            to={{
              pathname: `/admin/monitoreo/actividad/${actividadId}/editar/${monitoreoId}`,
              state:
                "/admin/monitoreo/actividad/:actividadId/editar/:monitoreoActividadId",
            }}
            className="btn btn-sm btn-info"
          >
            Editar
          </Link>
        )}

        <button
          onClick={(e) => {
            e.preventDefault();
            eliminarMonitoreoActividad();
          }}
          className="btn btn-sm btn-danger"
          disabled={validarStatus() || validarNivelAprobacion()}
          title={
            validarStatus()
              ? "El trimestre se ecuentra cerrado"
              : validarNivelAprobacion()
              ? "El trimestre fue enviado"
              : ""
          }
        >
          Eliminar
        </button>
      </td>
    </tr>
  );
};

export default connect(null, {
  deletMonitoreoActividad,
  getMonitoreoActividad,
  getMonitoreosActividad,
})(Monitoreo);
