import React, { Component } from "react";
import {
  Table,
  Container
} from "reactstrap";
import swal from "sweetalert";
import { connect } from "react-redux";
import { Authorization } from "./../../../utils/Authorization";
import Loading from "./../../utils/Loading";
import { ModalArchivos } from "./../../utils/ModalArchivos";
import { ModalDevolucionMonitoreo } from "./../../utils/ModalDevolucionMonitoreo";
import { getAdminMonitoreoActividad } from "./../../../redux/actions/monitoreo-actividad-actions";
import { updateMonitoreoActividad } from "./../../../redux/actions/monitoreo-actividad-actions";
import { getListMonitoreoMediosVerificacion } from "./../../../redux/actions/monitoreo_medios_verificacion_actions";
import {mostrarPoa,mostrarPoas,getPoaActivo} from "./../../../redux/actions/poa-actions";
import { getAreasOrganizacionales } from "./../../../redux/actions/areas-actions"; //me
import {
  ModalDevolucionInformesTrimestralesTodasAreas,
  ModalDevolucionInformeTrimestral,
} from "../../utils/ModalDevolucionInformesTrimestralesTodasAreas";

import {
  getAreasInformesTrimestrales,
  updateAreaInformeTrimestral,
  updateAllInformesTrimestrales,
} from "./../../../redux/actions/areas_informes_Trimestrales_actions";

import AreaOrganizacional from "../informes-trimestrales/areaOrganizaional";

class AreaMonitoreo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      isLoadingModalArchivo:true,
      modalArchivos: false,
      monitoreoMediosVerificacion: [],
      modalDevolucion: false,
      monitoreo: {},
      comentarioHistorial: "",
      areaidSeleccionada:0,
      adminMonitoreoActividadState:[]
    };
  }
  filtroAreaRef = new React.createRef();
  componentDidMount() {
    this.cargarDatos();

    this.props.getAreasOrganizacionales();
    this.setState({areaidSeleccionada:this.props.user.areaOrganizacional.areaOrganizacionalId})

  }

  filtroProducto = (productosName) => {
    let filtroProducto = [];
   
      filtroProducto = this.props.adminMonitoreoActividad.filter((productos) =>
        productos.producto
          .toUpperCase()
          .includes(productosName.toUpperCase())
          
      );
    
    this.setState({adminMonitoreoActividadState: filtroProducto});
  };

  cargarDatos = async (area=0) => {
    // console.log(this.props.poaActivo.poaId);
    await this.props.getAdminMonitoreoActividad(this.props.poaActivo.poaId,this.props.user.areaOrganizacional.areaOrganizacionalId);
    this.setState({ isLoadingState: false,
      areaInformesTrimestrales: this.props.areasInformesTrimestrales,adminMonitoreoActividadState: this.props.adminMonitoreoActividad });
  };

  cargarData = async (poaId,area=0) => {
    // await this.props.mostrarPoas();
    this.setState({isLoadingState:true});
    await this.props.getAdminMonitoreoActividad(poaId,area);

    await this.props.mostrarPoa(poaId,area);
    this.setState({isLoadingState:false});
    this.setState({adminMonitoreoActividadState: this.props.adminMonitoreoActividad });
  };

  filtroAreaOrganizacional = () => {
    if (
      this.filtroAreaRef.current === null ||
      this.filtroAreaRef.current.value === null ||
      this.filtroAreaRef.current.value === ""
    ) {
      this.setState({
        areaInformesTrimestrales: this.props.areasInformesTrimestrales,
      });
    } else {
      let filtroAreaInformeTrimestral = this.props.areasInformesTrimestrales.filter(
        (area) =>
          area.areaOrganizacional.nombre
            .toUpperCase()
            .includes(this.filtroAreaRef.current.value.toUpperCase())
      );
      this.setState({ areaInformesTrimestrales: filtroAreaInformeTrimestral });
    }
  };

  activarModalArchivos =  (monitoreoMediosVerificacion) => {
    this.setState({
      modalArchivos: !this.state.modalArchivos,
      isLoadingModalArchivo:true,
      monitoreoMediosVerificacion,
    });
  };
  activarModalDevolucionMonitoreo = (monitoreo) => {
    this.setState({
      modalDevolucion: !this.state.modalDevolucion,
      monitoreo,
    });
  };

  aprobarMonitoreo = async (monitoreo) => {
    this.setState({isLoadingState:true})
    swal({
      title: "Está seguro de aprobar este monitoreo?",
      text: "Una vez aprobado no podrá ser editado nuevamente!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (willDelete) => {
      if (willDelete) {
        const monitoreoActividadentity = {
          monitoreoActividadId: monitoreo.monitoreoId,
          productoId: monitoreo.productoId,
          actividadId: monitoreo.actividadId,
          nivelAprobacionId: this.props.user.nivelAprobacion.padre,
          envio: true,
          trimestre: monitoreo.trimestre,
          comentarioHistorial:
            "El monitoreo de la actividad " +
            monitoreo.actividad +
            " fue aprobado",
        };
        // console.log(monitoreoActividadentity);
  
        const fd = new FormData();
  
        Object.keys(monitoreoActividadentity).forEach(function (item) {
          fd.append(item, monitoreoActividadentity[item]);
        });
        await this.props.updateMonitoreoActividad(fd);
        this.setState({isLoadingState:false})
      } else {
        this.setState({isLoadingState:false})
      }
    });
  };
  devolverMonitoreo = async (monitoreo, comentarioHistorial) => {
    this.setState({
      isLoadingState: true,
      modalDevolucion: !this.state.modalDevolucion,
    });

    const monitoreoActividadentity = {
      monitoreoActividadId: monitoreo.monitoreoId,
      productoId: monitoreo.productoId,
      actividadId: monitoreo.actividadId,
      nivelAprobacionId: this.props.user.nivelAprobacion.hijo,
      envio: true,
      trimestre: monitoreo.trimestre,
      comentarioHistorial:
        "El monitoreo de la actividad " +
        monitoreo.actividad +
        " fue devuelto debido a que " +
        comentarioHistorial,
    };

    await this.props.updateMonitoreoActividad(monitoreoActividadentity);
    this.setState({ isLoadingState: false });
  };

   truncateString = (str) => {
    let num = 41;

    return str && str.length > num ? str.slice(0, num) + "..." : str;
  };
  cargarMonitoreoMediosVerificacion = async(monitoreo)=> {
    let   monitoreoMediosVerificacion = [];
    this.activarModalArchivos([]);
    await this.props.getListMonitoreoMediosVerificacion(monitoreo.monitoreoId)
    monitoreoMediosVerificacion = this.props.monitoreoMediosVerificacion

    this.setState({monitoreoMediosVerificacion,isLoadingModalArchivo:false});
  }
  render() {
    return (
      <React.Fragment>
        <Container >
          <div className="pb-6 pt-5 pt-md-7">
            <div className=" bg-white border">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (
                <React.Fragment>
                  <h3 className="pt-3 pl-3">Listado de Monitoreos</h3>
                  <div className="row" style={{margin:"2%"}}>
                  
                  <div className="col-md-4">
                    <label>Año del Poa</label>
                    <select
                      className="form-control"
                      defaultValue={this.props.poaActivo.poaId}
                      id="anoSelected"
                      ref={this.anoSelectedRef}
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({ isLoadingState: true });
                        this.cargarData(
                          document.getElementById("anoSelected").value,document.getElementById("areaSelected").value
                        );
                      }}
                    >
                      {this.props.poas.map((poa) => (
                        <option key={poa.poaId} value={poa.poaId}>
                          {poa.ano}
                        </option>
                      ))}
                    </select>
                  </div>
                <div className="col-md-4">
                  <label>Filtro Área</label>
                     <select
                      className="form-control"
                      defaultValue={this.state.areaidSeleccionada}
                      
                      id="areaSelected"
                      onChange={(e) => {
                        e.preventDefault();
                   
                        this.setState({ isLoadingState: true });
                        this.setState({areaidSeleccionada:document.getElementById("areaSelected").value});
                        this.cargarData(
                          document.getElementById("anoSelected").value,document.getElementById("areaSelected").value);
                          this.setState({adminMonitoreoActividadState: this.props.adminMonitoreoActividad});
                      }}
                    >
                      {/* <option vlaue="" >Filtrar Área</option> */}
                      {this.props.areaOrganizacionales.map((area) => (
                        <option key={area.areaOrganizacionalId} value={area.areaOrganizacionalId}>
                          {area.nombre}
                        </option>
                      ))}
                    </select> 
                  
                 </div>
                 <div className="col-md-4">
                  <label htmlFor="filtro">Filtro Productos </label>
                      <input style={{height:"60%"}}
                        type="text"
                        className="form-control form-control-sm text-dark"
                        id="filtro"
                        placeholder="Filtrar por Producto"
                        onKeyUp={(e) => {
                          e.preventDefault();
                          this.filtroProducto(e.currentTarget.value);
                        }}
                      />
                  </div>
                </div>
                  <div>
                    {this.state.adminMonitoreoActividadState.length > 0 ? (
                      <Table className="table-flush table-striped" responsive>
                        <thead>
                          <tr>
                            <th>Producto</th>
                            <th>Actividad</th>
                            <th>Trim.</th>
                            <th>Ejec.</th>
                            <th>M. Verificación</th>
                            <th>Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.adminMonitoreoActividadState.map(
                            (monitoreo) => (
                              
                              <tr key={monitoreo.monitoreoId}>
                                <td title={monitoreo.producto}>{this.truncateString(monitoreo.producto)}</td>
                                <td title={monitoreo.actividad}>{this.truncateString(monitoreo.actividad)}</td>
                                <td>{monitoreo.trimestre}</td>
                                <td>{monitoreo.nivelEjecucion}</td>
                                <td>
                                  <i
                                    className="btn fas fa-cloud-download-alt"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.cargarMonitoreoMediosVerificacion( monitoreo);
                                    }}
                                  />
                                </td>
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.aprobarMonitoreo(monitoreo);
                                    }}
                                  >
                                    Aprobar
                                  </button>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.activarModalDevolucionMonitoreo(
                                        monitoreo
                                      );
                                    }}
                                  >
                                    Devolver
                                  </button>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    ) : (
                      <div className="text-center my-5">
                        <h3>No existen monitoreos para aprobación</h3>
                      </div>
                    )}
                  </div>

                  <ModalArchivos
                    activarModalArchivos={this.activarModalArchivos}
                    state={this.state}
                    isLoadingModalArchivo={this.state.isLoadingModalArchivo}
                    modalArchivos={this.state.modalArchivos}
                    monitoreoMediosVerificacion={
                      this.state.monitoreoMediosVerificacion
                    }
                  ></ModalArchivos>

                  <ModalDevolucionMonitoreo
                    activarModalDevolucionMonitoreo={
                      this.activarModalDevolucionMonitoreo
                    }
                    modalDevolucion={this.state.modalDevolucion}
                    monitoreo={this.state.monitoreo}
                    devolverMonitoreo={this.devolverMonitoreo}
                  ></ModalDevolucionMonitoreo>
                </React.Fragment>
              )}
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  poas: state.poas.poas,
  adminMonitoreoActividad: state.monitoreosActividad.adminMonitoreoActividad,
  monitoreoMediosVerificacion:state.monitoreoMediosVerificacion.monitoreoMediosVerificacion,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,//me
});

const mapDispatchToProps = (dispatch) => ({
  getAdminMonitoreoActividad: (poaId,area) => dispatch(getAdminMonitoreoActividad(poaId,area)),
  updateMonitoreoActividad: (monitoreoActividad) =>  dispatch(updateMonitoreoActividad(monitoreoActividad)),
    getPoaActivo: () => dispatch(getPoaActivo()),
    mostrarPoas: () => dispatch(mostrarPoas()),
    mostrarPoa: (poaId) => dispatch(mostrarPoa(poaId)),
    getListMonitoreoMediosVerificacion:(monitoreoId)=>dispatch(getListMonitoreoMediosVerificacion(monitoreoId)),
    getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),//me
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(AreaMonitoreo, [
    "Administrador general",
    "Analista del POA",
    "MAE",
  ])
);
