import React, { Component } from "react";
import Loading from "./../utils/Loading";

import {
  Modal,
  ModalHeader
} from "reactstrap";
import NotaActividad from "./../administracion/notaActividad/crearNotaActividad.jsx";

export const ModalActividad = props => {
  const toggle = () => props.activarModalActividad();
  const { modalActividad } = props;
  return (
    <Modal isOpen={modalActividad} toggle={toggle} size="lg" backdrop="static">
      <ModalHeader className="block-example border-bottom " toggle={toggle}>
        Crear Nota de la actividad
      </ModalHeader>

      <NotaActividad
        agregarNotaActividad={props.agregarNotaActividad}
        activarModalActividad={props.activarModalActividad}
        actividad={props.actividad}
        notaActividad={props.notaActividad}
      ></NotaActividad>
    </Modal>
  );
};
