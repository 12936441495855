import {
  MOSTRAR_MEDIOS_VERIFICACION,
  LOADING_MEDIOS_VERIFICACION,
  MEDIOS_VERIFICACION_SUCCEEDED,
  MEDIOS_VERIFICACION_FAILED,
  MOSTRAR_MEDIO_VERIFICACION,
  EDITAR_MEDIOS_VERIFICACION,
  CREAR_MEDIOS_VERIFICACION,
  ELIMINAR_MEDIOS_VERIFICACION,
} from "./../types";

import initialState from "./../initial-state";
import Editar from "../../pages/medios-verificacion/Editar";

const { mediosVerificacion } = initialState;

const MediosVerificacionReducer = (state = mediosVerificacion, action) => {
  switch (action.type) {
    case LOADING_MEDIOS_VERIFICACION:
      return {
        ...state,
        isLoading: true,
      };
    case MEDIOS_VERIFICACION_FAILED:
    case MEDIOS_VERIFICACION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
      };
    case MOSTRAR_MEDIOS_VERIFICACION:
      return {
        ...state,
        mediosVerificacion: action.payload,
      };
    case MOSTRAR_MEDIO_VERIFICACION:
      return {
        ...state,
        medioVerificacion: action.payload,
      };
    case CREAR_MEDIOS_VERIFICACION:
      return {
        ...state,
        mediosVerificacion: [...state, action.payload],
      };
    case EDITAR_MEDIOS_VERIFICACION:
      return {
        ...state,
        mediosVerificacion: state.mediosVerificacion.map((medioVerificacion) =>
          medioVerificacion.medioVerificacionId ===
          action.payload.medioVerificacionId
            ? (medioVerificacion = action.payload)
            : medioVerificacion
        ),
      };
    case ELIMINAR_MEDIOS_VERIFICACION:
      return {
        ...state,
        mediosVerificacion: [
          ...state.mediosVerificacion.filter(
            (medioVerificacion) =>
              medioVerificacion.medioVerificacionId !== action.payload
          ),
        ],
      };
    default:
      return state;
  }
};

export default MediosVerificacionReducer;
