import {
  MOSTRAR_NIVELES_APROBACIONES_USUARIOS,
  NIVELES_APROBACIONES_FAILED,
  NIVELES_APROBACIONES_SUCCEEDED,
  LOADING_NIVELES_APROBACIONES
} from "./../types";

import { getData } from "./../../utils/api-client";
import { api_base } from "./../../config";
export const getNivelesAprobaciones = () => async dispatch => {
  dispatch({ type: LOADING_NIVELES_APROBACIONES });
  let url = `${api_base}api/v1/nivelesaprobaciones`;
  await getData
    .get(url)
    .then(resultado => {
      dispatch({
        type: MOSTRAR_NIVELES_APROBACIONES_USUARIOS,
        payload: resultado.data
      });
      dispatch({ type: NIVELES_APROBACIONES_SUCCEEDED });
    })
    .catch(error => {
        if(error) {
            dispatch({ type: NIVELES_APROBACIONES_FAILED });
        }
    });
};
