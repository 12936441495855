import { persistCombineReducers } from "redux-persist";
import authReducer from "./auth-reducer";
import storage from "redux-persist/lib/storage";
import poaReducer from "./poa-reducer";
import productosReducer from "./productos-reducer";
import planesEstrategicosReducer from "./planes-estrategicos-reducer";
import involucradosReducer from "./involucrados-reducer";
import areasReducer from "./areas-reducer";
import actividadesReducer from "./actividades-reducer";
import mediosVerificacionReducer from "./medios-verificacion-reducer";
import unidadesMedidaReducer from "./unidades-medida-reducer";
import MonitoreoActividadReducer from "./monitoreo-actividad-reducer";
import UsuariosReducer from "./usuarios-reducer";
import RolesReducer from "./roles-reducer";
import notaProductoReducer from "./notas-productos-reducer";
import nivelesAprobaciones from "./niveles-aprobacion-reducer";
import MaestroInsumos from "./maestro-insumos-reducer";
import PonderacionAreasReducer from "./ponderacion-areas-reducer";
import TrimestresReducer from "./trimestres-reducer";
import ProgramacionMonitoreoReducer from "./programacion-monitoreo-reducer";
import AreaPoaMonitoreoReducer from "./area_poa_monitoreo_reducer";
import InformesTrimestralReducer from "./informe_trimestral_reducer";
import AreasInformesTrimestralesReducer from "./areas_informes_trimestrales_reducer";
import PlanesEstrategicosGeneralReducer from "./planes-estrategicos-general-reducer";
import MonitoreoMediosVerificacion from "./monitoreo-medios-verificacion-reducer";
import ProductoProgramaticaReducer from "./producto_programatica_reducer";
import TiposComprasReducer from "./tipos-compras-reducer";
import EstadosReducer from "./estados-reducer";
import RequerimientosReducer from "./requerimientos-reducer";
import comprasDocumentacionesReducer from "./compras-documentaciones-reducer";
import FechasDocumentacionesComprasReducer from "./fechas-documentaciones-compras-reducer";
import ProveedoresReducer from "./proveedores-reducer";
import HistoricoRequerimientosReducer from "./historicos-requerimientos-reducer";
import TiposAsignacionesReducer from "./tipos-asignaciones-reducer";
import PresupuestoAsignadoInstitucionReducer from "./presupuesto-asignado-institucion-reducer";

// NO POA
import productosNoPoaReducer from "./productos-nopoa-reducer";
import actividadesNopoaReducer from "./actividades-nopoa-reducer";

export default persistCombineReducers(
  {
    key: "primary",
    storage,
  },
  {
    auth: authReducer,
    poas: poaReducer,
    productos: productosReducer,
    planesEstrategicos: planesEstrategicosReducer,
    involucrados: involucradosReducer,
    areasOrganizacionales: areasReducer,
    actividades: actividadesReducer,
    mediosVerificacion: mediosVerificacionReducer,
    unidadesMedida: unidadesMedidaReducer,
    monitoreosActividad: MonitoreoActividadReducer,
    usuarios: UsuariosReducer,
    roles: RolesReducer,
    notaProducto: notaProductoReducer,
    nivelesAprobaciones: nivelesAprobaciones,
    maestroInsumos: MaestroInsumos,
    ponderacionAreas: PonderacionAreasReducer,
    trimestres: TrimestresReducer,
    programacionMonitoreos: ProgramacionMonitoreoReducer,
    areaPoaMonitoreo: AreaPoaMonitoreoReducer,
    informesTrimestrales: InformesTrimestralReducer,
    areasInformesTrimestrales: AreasInformesTrimestralesReducer,
    planesEstrategicosGeneral: PlanesEstrategicosGeneralReducer,
    monitoreoMediosVerificacion: MonitoreoMediosVerificacion,
    productoProgramaticas: ProductoProgramaticaReducer,
    tiposCompras: TiposComprasReducer,
    estados: EstadosReducer,
    requerimientos: RequerimientosReducer,
    comprasDocumentaciones: comprasDocumentacionesReducer,
    fechasDocumentacionesCompras: FechasDocumentacionesComprasReducer,
    proveedores: ProveedoresReducer,
    historicoRequerimientos:HistoricoRequerimientosReducer,
    tiposAsignaciones: TiposAsignacionesReducer,
    presupuestosAsignadosInstitucion: PresupuestoAsignadoInstitucionReducer,

    // NO POA
    productosNoPoa: productosNoPoaReducer,
    actividadesNopoa: actividadesNopoaReducer
  }
);
