export const REGISTRATION_REQUEST_SENT = "REGISTRATION_REQUEST_SENT";
export const REGISTRATION_REQUEST_SUCCEEDED = "REGISTRATION_REQUEST_SUCCEEDED";
export const REGISTRATION_REQUEST_FAILED = "REGISTRATION_REQUEST_FAILED";
export const VERIFY_TOKEN_REQUEST_SENT = "VERIFY_TOKEN_REQUEST_SENT";
export const VERIFY_TOKEN_REQUEST_SUCCEEDED = "VERIFY_TOKEN_REQUEST_SUCCEEDED";
export const VERIFY_TOKEN_REQUEST_FAILED = "VERIFY_TOKEN_REQUEST_FAILED";
export const SIGNIN_REQUEST_SENT = "SIGNIN_REQUEST_SENT";
export const SIGNIN_REQUEST_SUCCEEDED = "SIGNIN_REQUEST_SUCCEEDED";
export const SIGNIN_REQUEST_FAILED = "SIGNIN_REQUEST_FAILED";
export const SIGNOUT_REQUEST_SENT = "SIGNOUT_REQUEST_SENT";
export const SIGNOUT_REQUEST_SUCCEEDED = "SIGNOUT_REQUEST_SUCCEEDED";
export const SIGNOUT_REQUEST_FAILED = "SIGNOUT_REQUEST_FAILED";
export const VERIFY_USER_SIGNED = "VERIFY_USER_SIGNED";
export const USER_SIGNED_SUCCESS = "USER_SIGNED_SUCCESS";
export const USER_SIGNED_FAILED = "USER_SIGNED_FAILED";
export const SHOW_TOKEN = "SHOW_TOKEN";

//POAS TYPES
export const MOSTRAR_POAS = "MOSTRAR_POAS";
export const MOSTRAR_POA = "MOSTRAR_POA";
export const MOSTRAR_POA_ACTIVO = "MOSTRAR_POA_ACTIVO";
export const MOSTRAR_POA_ELABORACION = "MOSTRAR_POA_ELABORACION";
export const CREAR_POA = "CREAR_PRODUCTO";
export const EDITAR_POA = "EDITAR_POA";
export const ELIMINAR_POA = "ELIMINAR_POA";
export const LOADING_POA = "LOADING_POA";
export const MOSTRAR_TOTAL_PRODUCTOS_ADMINISTRACION =
  "MOSTRAR_TOTAL_PRODUCTOS_ADMINISTRACION";
export const POA_SUCCEEDED = "POA_SUCCEEDED";
export const POA_FAILED = "POA_FAILED";
export const MOSTRAR_DETALLE_POA = "MOSTRAR_DETALLE_POA";

//PRODUCTOS TYPES
export const CREAR_PRODUCTOS = "CREAR_PRODUCTOS";
export const MOSTRAR_PRODUCTOS = "MOSTRAR_PRODUCTOS";
export const MOSTRAR_PRODUCTOS_INSUMOS = "MOSTRAR_PRODUCTOS_INSUMOS";
export const MOSTRAR_PRODUCTO = "MOSTRAR_PRODUCTO ";
export const EDITAR_PRODUCTO = "EDITAR_PRODUCTO  ";
export const ELIMINAR_PRODUCTO = "ELIMINAR_PRODUCTO";
export const LOADING_PRODUCTO = "LOADING_PRODUCTO";
export const PRODUCTO_SUCCEEDED = "PRODUCTO_SUCCEEDED";
export const PRODUCTO_FAILED = "PRODUCTO_FAILED";
export const CLEAN_PRODUCTOS_ADMINISTRACION = "CLEAN_PRODUCTOS_ADMINISTRACION";
export const CREAR_ACTIVIDADES_PRODUCTOS = "CREAR_ACTIVIDADES_PRODUCTOS";
export const TOTAL_PONDERACION_PRODUCTO_ACTIVIDADES =
  "TOTAL_PONDERACION_PRODUCTO_ACTIVIDADES";
export const MOSTRAR_PRODUCTOS_ADMINISTRACION =
  "MOSTRAR_PRODUCTOS_ADMINISTRACION";

//ACTIVIDADES TYPES
export const LOADING_ACTIVIDAD = "LOADING_ACTIVIDAD";
export const ACTIVIDAD_SUCCEEDED = "ACTIVIDAD_SUCCEEDED";
export const MOSTRAR_ACTIVIDADES = "MOSTRAR_ACTIVIDADES";
export const MOSTRAR_ACTIVIDAD = "MOSTRAR_ACTIVIDAD";
export const CREAR_ACTIVIDADES = "CREAR_ACTIVIDADES";
export const EDITAR_ACTIVIDADES = "EDITAR_ACTIVIDADES";
export const ELIMINAR_ACTIVIDADES = "ELIMINAR_ACTIVIDADES";
export const ACTIVIDAD_FAILED = "ACTIVIDAD_FAILED";
export const MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDOR = "MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDOR";
export const MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDORES = "MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDORES";
export const CREAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO = "CREAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO";
export const EDITAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO = "EDITAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO";
export const ELIMINAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO = "ELIMINAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO";

export const MOSTRAR_EJES_ESTRATEGICOS = "MOSTRAR_EJES_ESTRATEGICOS";
export const MOSTRAR_ESTRATEGIAS = "MOSTRAR_ESTRATEGIAS";
export const MOSTRAR_OBJETIVOS_ESTRATEGICOS = "MOSTRAR_OBJETIVOS_ESTRATEGICOS";

export const MOSTRAR_EJES_ESTRATEGICOS_GENERAL =
  "MOSTRAR_EJES_ESTRATEGICOS_GENERAL";
export const MOSTRAR_ESTRATEGIAS_GENERAL = "MOSTRAR_ESTRATEGIAS_GENERAL";
export const MOSTRAR_OBJETIVOS_ESTRATEGICOS_GENERAL =
  "MOSTRAR_OBJETIVOS_ESTRATEGICOS_GENERAL";
export const MONITOREO_UPDATE_APROBACION_ACTIVIDADES =
  "MONITOREO_UPDATE_APROBACION_ACTIVIDADES";

// INVOLUCRADOS
export const MOSTRAR_INVOLUCRADOS = "MOSTRAR_INVOLUCRADOS";
export const MOSTRAR_INVOLUCRADO = "MOSTRAR_INVOLUCRADO";
export const CREAR_INVOLUCRADO = "CREAR_INVOLUCRADO";
export const EDITAR_INVOLUCRADO = "EDITAR_INVOLUCRADO";
export const ELIMINAR_INVOLUCRADO = "ELIMINAR_INVOLUCRADO";
export const LOADING_INVOLUCRADO = "LOADING_INVOLUCRADO";
export const INVOLUCRADO_SUCCEEDED = "INVOLUCRADO_SUCCEEDED";
export const INVOLUCRADO_FAILED = "INVOLUCRADO_FAILED";

export const MOSTRAR_AREAS = "MOSTRAR_AREAS";
export const AREAS_FAILED = "AREAS_FAILED";
export const AREAS_SUCCESS = "AREAS_SUCCESS";
export const LOADING_AREAS = "LOADING_AREAS";

//MEDIOS VERIFICACION
export const MOSTRAR_MEDIOS_VERIFICACION = "MOSTRAR_MEDIOS_VERIFICACION";
export const MOSTRAR_MEDIO_VERIFICACION = "MOSTRAR_MEDIO_VERIFICACION";
export const LOADING_MEDIOS_VERIFICACION = "LOADING_MEDIOS_VERIFICACION";
export const MEDIOS_VERIFICACION_SUCCEEDED = "MEDIOS_VERIFICACION_SUCCEEDED";
export const MEDIOS_VERIFICACION_FAILED = "MEDIOS_VERIFICACION_FAILED";
export const CREAR_MEDIOS_VERIFICACION = "CREAR_MEDIOS_VERIFICACION";
export const EDITAR_MEDIOS_VERIFICACION = "EDITAR_MEDIOS_VERIFICACION";
export const ELIMINAR_MEDIOS_VERIFICACION = "ELIMINAR_MEDIOS_VERIFICACION";

// EVIDENCIAS DE JUSTIFICACION DE NO CUMPLIMIENTO
export const EVIDENCIA_NO_CUMPLIMIENTO_SUCCEEDED = "EVIDENCIA_NO_CUMPLIMIENTO_SUCCEEDED";
export const EVIDENCIA_NO_CUMPLIMIENTO_FAILED = "EVIDENCIA_NO_CUMPLIMIENTO_FAILED";
export const ELIMINAR_EVIDENCIA_NO_CUMPLIMIENTO = "ELIMINAR_EVIDENCIA_NO_CUMPLIMIENTO";

//MONITOREO MEDIOS VERIFICACION

export const MOSTRAR_MONITOREO_MEDIOS_VERIFICACION =
  "MOSTRAR_MONITOREO_MEDIOS_VERIFICACION";
export const LOADING_MONITOREO_MEDIOS_VERIFICACION =
  "LOADING_MONITOREO_MEDIOS_VERIFICACION";
export const MONITOREO_MEDIOS_VERIFICACION_SUCCEEDED =
  "MONITOREO_MEDIOS_VERIFICACION_SUCCEEDED";
export const MONITOREO_MEDIOS_VERIFICACION_FAILED =
  "MONITOREO_MEDIOS_VERIFICACION_FAILED";

//UNidades de medida
export const MOSTRAR_UNIDADES_MEDIDA = "MOSTRAR_UNIDADES_MEDIDA";
export const LOADING_UNIDADES_MEDIDA = "LOADING_UNIDADES_MEDIDA";
export const UNIDADES_MEDIDA_SUCCEEDED = "UNIDADES_MEDIDA_SUCCEEDED";

//MONITOREO ACTIVIDAD
export const MOSTRAR_MONITOREOS_ACTIVIDAD = "MOSTRAR_MONITOREOS_ACTIVIDAD";
export const MOSTRAR_MONITOREO_ACTIVIDAD = "MOSTRAR_MONITOREO_ACTIVIDAD";
export const MOSTRAR_ADMIN_MONITOREO_ACTIVIDAD =
  "MOSTRAR_ADMIN_MONITOREO_ACTIVIDAD";
export const MOSTRAR_REPORTE_MONITOREO_DETALLE =
  "MOSTRAR_REPORTE_MONITOREO_DETALLE";
export const LOADING_MONITOREO_ACTIVIDAD = "LOADING_MONITOREO_ACTIVIDAD";
export const MONITOREO_ACTIVIDAD_SUCCEEDED = "MONITOREO_ACTIVIDAD_SUCCEEDED";
export const MONITOREO_ACTIVIDAD_FAILED = "MONITOREO_ACTIVIDAD_FAILED";
export const CREAR_MONITOREO_ACTIVIDAD = "CREAR_MONITOREO_ACTIVIDAD";
export const EDITAR_MONITOREO_ACTIVIDAD = "EDITAR_MONITOREO_ACTIVIDAD";
export const ELIMINAR_MONITOREO_ACTIVIDAD = "ELIMINAR_MONITOREO_ACTIVIDAD";
export const MOSTRAR_ACTIVIDAD_LOCAL = "MOSTRAR_ACTIVIDAD_LOCAL";
export const MONITOREO_MEDIOS_VERIFICACION = "MONITOREO_MEDIOS_VERIFICACION";

// USUARIOS
export const MOSTRAR_USUARIOS = "MOSTRAR_USUARIOS";
export const MOSTRAR_USUARIO = "MOSTRAR_USUARIO";
export const LOADING_USUARIOS = "LOADING_USUARIOS";
export const LOADING_USUARIO = "LOADING_USUARIO";
export const USUARIOS_SUCCEEDED = "USUARIOS_SUCCEEDED";
export const USUARIOS_FAILED = "USUARIOS_FAILED";
export const CREAR_USUARIO = "CREAR_USUARIO";
export const EDITAR_USUARIO = "EDITAR_USUARIO";
export const ELIMINAR_USUARIO = "ELIMINAR_USUARIO";
export const MOSTRAR_USUARIOS_ANALISTAS_ASIGNADOS = "MOSTRAR_USUARIOS_ANALISTAS_ASIGNADOS"

export const MOSTRAR_ROLES = "MOSTRAR_ROLES";
export const LOADING_ROLES = "LOADING_ROLES";
export const ROLES_SUCCEEDED = "ROLES_SUCCEEDED";
export const ROLES_FAILD = "ROLES_FAILD";

export const MOSTRAR_NOTAS_PRODUCTO = "MOSTRAR_NOTAS_PRODUCTO";
export const CREAR_NOTAS_PRODUCTO = "CREAR_NOTAS_PRODUCTO";
export const NOTA_PRODUCTO_SUCCEEDED = "NOTA_PRODUCTO_SUCCEEDED";
export const NOTAS_PRODUCTO_FAILED = "NOTAS_PRODUCTO_FAILED";
export const LOADING_NOTA_PRODUCTO = "LOADING_NOTA_PRODUCTO";
export const AGREGAR_NOTA_PRODUCTO = "AGREGAR_NOTA_PRODUCTO";
export const TOTAL_PONDERACION_GENERAL_PRODUCTOS =
  "TOTAL_PONDERACION_GENERAL_PRODUCTOS";
export const TOTAL_PONDERACION_AREA_PRODUCTOS =
  "TOTAL_PONDERACION_AREA_PRODUCTOS";

export const AGREGAR_NOTA_ACTIVIDADES = "AGREGAR_NOTA_ACTIVIDADES";
export const CREAR_NOTAS_ACTIVIDAD = "CREAR_NOTAS_PRODUCTO";
export const NOTA_ACTIVIDAD_SUCCEEDED = "NOTA_PRODUCTO_SUCCEEDED";
export const NOTAS_ACTIVIDAD_FAILED = "NOTAS_PRODUCTO_FAILED";
export const LOADING_NOTA_ACTIVIDAD = "LOADING_NOTA_ACTIVIDAD";
export const AGREGAR_NOTA_ACTIVIDAD = "AGREGAR_NOTA_ACTIVIDAD";
export const TOTAL_PONDERACION_AREA_ACTIVIDADES =
  "TOTAL_PONDERACION_AREA_ACTIVIDADES";

export const MOSTRAR_NIVELES_APROBACIONES_USUARIOS =
  "MOSTRAR_NIVELES_APROBACIONES_USUARIOS";
export const LOADING_NIVELES_APROBACIONES = "LOADING_NIVELES_APROBACIONES";
export const NIVELES_APROBACIONES_SUCCEEDED = "NIVELES_APROBACIONES_SUCCEEDED";
export const NIVELES_APROBACIONES_FAILED = "NIVELES_APROBACIONES_FAILED";

export const MOSTRAR_INSUMOS = "MOSTRAR_INSUMOS";
export const LOADING_INSUMOS = "LOADING_INSUMOS";
export const AGREGAR_INSUMOS_TEMPORALES = "AGREGAR_INSUMOS_TEMPORALES";
export const LIMPIAR_INSUMOS_TEMPORALES = "LIMPIAR_INSUMOS_TEMPORALES";

export const INSUMOS_SUCCEED = "INSUMOS_SUCCEED";
export const INSUMOS_FAILED = "INSUMOS_FAILED";

export const MOSTRAR_OPERACIONES = "MOSTRAR_OPERACIONES";
export const LOADING_OPERACIONES = "LOADING_OPERACIONES";
export const OPERACIONES_SUCCEED = "OPERACIONES_SUCCEED";
export const OPERACIONES_FAILED = "OPERACIONES_FAILED";

export const ELIMINAR_PRESUPUESTO = "ELIMINAR_PRESUPUESTO";
export const EDITAR_PRESUPUESTO = "EDITAR_PRESUPUESTO";
export const LOADING_PRESUPUESTO = "LOADING_PRESUPUESTO";
export const PRESUPUESTO_SUCCEEDED = "PRESUPUESTO_SUCCEEDED";
export const PRESUPUESTO_FAILED = "PRESUPUESTO_FAILED";
export const ADD_PRESUPUESTO = "ADD_PRESUPUESTO";

export const MOSTRAR_PONDERACION_AREAS = "MOSTRAR_PONDERACION_AREAS";
export const MOSTRAR_PONDERACION_AREA = "MOSTRAR_PONDERACION_AREA";
export const CREAR_PONDERACION_AREA = "Crear_PONDERACION_AREA";
export const EDITAR_PONDERACION_AREA = "EDITAR_PONDERACION_AREA";
export const LOADING_PONDERACION_AREA = "LOADING_PONDERACION_AREA";
export const PONDERACION_AREA_SUCCEEDED = "PONDERACION_AREA_SUCCEEDED";
export const PONDERACION_AREA_FAILED = "PONDERACION_AREA_FAILED";

export const CREAR_PROGRAMACION_MONITOREO = "CREAR_PROGRAMACION_MONITOREO";
export const MOSTRAR_PROGRAMACION_MONITOREO = "MOSTRAR_PROGRAMACION_MONITOREO";
export const EDITAR_PROGRAMACION_MONITOREO = "EDITAR_PROGRAMACION_MONITOREO";
export const LOADING_PROGRAMACION_MONITOREO = "LOADING_PROGRAMACION_MONITOREO";
export const PROGRAMACION_MONITOREO_SUCCESS = "PROGRAMACION_MONITOREO_SUCCESS";
export const PROGRAMACION_MONITOREO_FAILED = "PROGRAMACION_MONITOREO_FAILED";

export const MOSTRAR_TRIMESTRES = "MOSTRAR_TRIMESTRES";
export const LOADING_TRIMESTRES = "LOADING_TRIMESTRES";
export const TRIMESTRES_SUCCESS = "TRIMESTRES_SUCCESS";
export const TRIMESTRES_FAILED = "TRIMESTRES_FAILED";

export const MOSTRAR_AREA_POA_MONITOREO = "MOSTRAR_AREA_POA_MONITOREO";
export const EDITAR_AREA_POA_MONITOREO = "EDITAR_AREA_POA_MONITOREO";
export const LOADING_AREA_POA_MONITOREO = "LOADING_AREA_POA_MONITOREO";
export const AREA_POA_MONITOREO_FAILED = "AREA_POA_MONITOREO_FAILED";
export const AREA_POA_MONITOREO_SUCCESS = "AREA_POA_MONITOREO_SUCCESS";

export const LOADING_INFORME_TRIMESTRAL = "LOADING_INFORME_TRIMESTRAL";
export const INFORME_TRIMESTRAL_SUCCEEDED = "INFORME_TRIMESTRAL_SUCCEEDED";
export const MOSTRAR_INFORMES_TRIMESTRALES = "MOSTRAR_INFORMES_TRIMESTRALES";
export const MOSTRAR_INFORME_TRIMESTRAL = "MOSTRAR_INFORME_TRIMESTRAL";
export const CARGAR_INFORME_TRIMESTRAL = "CARGAR_INFORME_TRIMESTRAL";
export const EDITAR_INFORME_TRIMESTRAL = "EDITAR_INFORME_TRIMESTRAL";
export const ELIMINAR_INFORME_TRIMESTRAL = "ELIMINAR_INFORME_TRIMESTRAL";
export const INFORME_TRIMESTRAL_FAILED = "INFORME_TRIMESTRAL_FAILED";

export const MOSTRAR_AREA_INFORMES_TRIMESTRALES =
  "MOSTRAR_AREA_INFORMES_TRIMESTRALES";
export const EDITAR_AREA_INFORMES_TRIMESTRALES =
  "EDITAR_AREA_INFORMES_TRIMESTRALES";
export const LOADING_AREA_INFORMES_TRIMESTRALES =
  "LOADING_AREA_INFORMES_TRIMESTRALES";
export const AREA_INFORMES_TRIMESTRALES_FAILED =
  "AREA_INFORMES_TRIMESTRALES_FAILED";
export const AREA_INFORMES_TRIMESTRALES_SUCCESS =
  "AREA_INFORMES_TRIMESTRALES_SUCCESS";

export const REPORTE_POA_LOADING = "REPORTE_POA_LOADING";
export const REPORTE_POA_SUCCESS = " REPORTE_POA_SUCCESS";
export const REPORTE_POA_FAILE = "REPORTE_POA_FAILE";
export const REPORTE_MONITOREO_LOADING = "REPORTE_MONITOREO_LOADING";
export const REPORTE_MONITOREO_SUCCESS = "REPORTE_MONITOREO_SUCCESS";
export const REPORTE_MONITOREO_FAILE = "REPORTE_MONITOREO_FAILE";

// PRODUCTO PROGRAMATICA TYPES
export const MOSTRAR_PRODUCTO_PROGRAMATICA = "MOSTRAR_PRODUCTO_PROGRAMATICA";
export const MOSTRAR_PRODUCTO_PROGRAMATICAS = "MOSTRAR_PRODUCTO_PROGRAMATICAS";
export const EDITAR_MOSTRAR_PRODUCTO_PROGRAMATICA =
  "EDITAR_MOSTRAR_PRODUCTO_PROGRAMATICA";
export const EDITAR_MOSTRAR_PRODUCTO_PROGRAMATICAS =
  "EDITAR_MOSTRAR_PRODUCTO_PROGRAMATICAS";
export const LOADING_MOSTRAR_PRODUCTO_PROGRAMATICA =
  "LOADING_MOSTRAR_PRODUCTO_PROGRAMATICA";
export const MOSTRAR_PRODUCTO_PROGRAMATICA_FAILED =
  "MOSTRAR_PRODUCTO_PROGRAMATICA_FAILED";
export const MOSTRAR_PRODUCTO_PROGRAMATICA_SUCCESS =
  "MOSTRAR_PRODUCTO_PROGRAMATICA_SUCCESS";


  // PRODUCTO REQUERIMIENTOS COMPRAS TYPES
export const MOSTRAR_PRODUCTOS_REQUERIMIENTO_COMPRA = "MOSTRAR_PRODUCTO_REQUERIMIENTO_COMPRA";
export const MOSTRAR_PRODUCTOS_REQUERIMIENTO_COMPRA_ADMIN = "MOSTRAR_PRODUCTOS_REQUERIMIENTO_COMPRA_ADMIN";
export const MOSTRAR_PRODUCTO_REQUERIMIENTO_COMPRA =  "MOSTRAR_PRODUCTO_REQUERIMIENTO_COMPRA";
export const EDITAR_PRODUCTO_REQUERIMIENTO_COMPRA =   "EDITAR_PRODUCTO_REQUERIMIENTO_COMPRA";
export const LOADING_PRODUCTO_REQUERIMIENTO_COMPRA =  "LOADING_PRODUCTO_REQUERIMIENTO_COMPRA";
export const PRODUCTOS_REQUERIMIENTO_COMPRA_FAILED =  "PRODUCTOS_REQUERIMIENTO_COMPRA_FAILED";
export const PRODUCTOS_REQUERIMIENTO_COMPRA_SUCCESS = "PRODUCTOS_REQUERIMIENTO_COMPRA_SUCCESS";

// TIPOS_COMPRAS TYPES
export const MOSTRAR_TIPOS_COMPRAS = "MOSTRAR_TIPOS_COMPRAS";
export const CREAR_TIPO_COMPRA = "CREAR_TIPO_COMPRA";
export const EDITAR_TIPO_COMPRA = "EDITAR_TIPO_COMPRA";
export const ELIMINAR_TIPO_COMPRA = "ELIMINAR_TIPO_COMPRA";
export const TIPOS_COMPRAS_FAILED = "TIPOS_COMPRAS_FAILED";
export const TIPOS_COMPRAS_SUCCESS = "TIPOS_COMPRAS_SUCCESS";
export const LOADING_TIPOS_COMPRAS = "LOADING_TIPOS_COMPRAS";

// TIPOS_ASIGNACIONES TYPES
export const MOSTRAR_TIPOS_ASIGNACIONES = "MOSTRAR_TIPOS_ASIGNACIONES";
export const MOSTRAR_TIPO_ASIGNACION = "MOSTRAR_TIPO_ASIGNACION";
export const TIPOS_ASIGNACIONES_FAILED = "TIPOS_ASIGNACIONES_FAILED";
export const TIPOS_ASIGNACIONES_SUCCESS = "TIPOS_ASIGNACIONES_SUCCESS";
export const LOADING_TIPOS_ASIGNACIONES = "LOADING_TIPOS_ASIGNACIONES";
export const CREATE_TIPOS_ASIGNACIONES = "CREATE_TIPOS_ASIGNACIONES";
export const UPDATE_TIPOS_ASIGNACIONES = "UPDATE_TIPOS_ASIGNACIONES";
export const DELETE_TIPOS_ASIGNACIONES = "DELETE_TIPOS_ASIGNACIONES";

// PRESUPUESTOS_ASIGNADOS_INSTITUCION TYPES
export const MOSTRAR_PRESUPUESTOS_ASIGNADOS_INSTITUCION = "MOSTRAR_PRESUPUESTOS_ASIGNADOS_INSTITUCION";
export const MOSTRAR_PRESUPUESTO_ASIGNADO_INSTITUCION = "MOSTRAR_PRESUPUESTO_ASIGNADO_INSTITUCION";
export const PRESUPUESTO_ASIGNADO_INSTITUCION_FAILED = "PRESUPUESTO_ASIGNADO_INSTITUCION_FAILED";
export const PRESUPUESTO_ASIGNADO_INSTITUCION_SUCCESS = "PRESUPUESTO_ASIGNADO_INSTITUCION_SUCCESS";
export const LOADING_PRESUPUESTO_ASIGNADO_INSTITUCION = "LOADING_PRESUPUESTO_ASIGNADO_INSTITUCION";
export const CREATE_PRESUPUESTO_ASIGNADO_INSTITUCION = "CREATE_PRESUPUESTO_ASIGNADO_INSTITUCION";
export const UPDATE_PRESUPUESTO_ASIGNADO_INSTITUCION = "UPDATE_PRESUPUESTO_ASIGNADO_INSTITUCION";
export const DELETE_PRESUPUESTO_ASIGNADO_INSTITUCION = "DELETE_PRESUPUESTO_ASIGNADO_INSTITUCION";



// ESTADOS TYPES
export const MOSTRAR_ESTADOS = "MOSTRAR_ESTADOS";
export const ESTADOS_FAILED = "ESTADOS_FAILED";
export const ESTADOS_SUCCESS = "ESTADOS_SUCCESS";
export const LOADING_ESTADOS = "LOADING_ESTADOS";

// REQUERIMIENTOS
export const MOSTRAR_REQUERIMIENTOS = "MOSTRAR_REQUERIMIENTOS";
export const MOSTRAR_REQUERIMIENTO = "MOSTRAR_REQUERIMIENTO";
export const CREAR_REQUERIMIENTO = "CREAR_REQUERIMIENTO";
export const EDITAR_REQUERIMIENTO = "EDITAR_REQUERIMIENTO";
export const ELIMINAR_REQUERIMIENTO = "ELIMINAR_REQUERIMIENTO";
export const LOADING_REQUERIMIENTO = "LOADING_REQUERIMIENTO";
export const REQUERIMIENTO_DOWNLOAD_SUCCEEDED = "REQUERIMIENTO_DOWNLOAD_SUCCEEDED";
export const REQUERIMIENTO_SUCCEEDED = "REQUERIMIENTO_SUCCEEDED";
export const REQUERIMIENTO_FAILED = "REQUERIMIENTO_FAILED";
export const REQUERIMIENTO_DOWNLOAD_FAILED = "REQUERIMIENTO_DOWNLOAD_FAILED";
export const MOSTRAR_REQUERIMIENTOS_COMPRAS = "MOSTRAR_REQUERIMIENTOS_COMPRAS";
export const MOSTRAR_REQUERIMIENTO_COMPRA = "MOSTRAR_REQUERIMIENTO_COMPRA";
export const CREAR_REQUERIMIENTO_COMPRA = "CREAR_REQUERIMIENTO_COMPRA";
export const EDITAR_REQUERIMIENTO_COMPRA = "EDITAR_REQUERIMIENTO_COMPRA";
export const ELIMINAR_REQUERIMIENTO_COMPRA = "ELIMINAR_REQUERIMIENTO_COMPRA";
export const LOADING_REQUERIMIENTO_COMPRA = "LOADING_REQUERIMIENTO_COMPRA";
export const REQUERIMIENTO_COMPRA_SUCCEEDED = "REQUERIMIENTO_COMPRA_SUCCEEDED";
export const REQUERIMIENTO_COMPRA_FAILED = "REQUERIMIENTO_COMPRA_FAILED";

// HISTORICAL REQUERIMIENTOS
export const MOSTRAR_HISTORICO_REQUERIMIENTOS = "MOSTRAR_HISTORICO_REQUERIMIENTOS";
export const CREAR_HISTORICO_REQUERIMIENTO = "CREAR_HISTORICO_REQUERIMIENTO";
export const MOSTRAR_HISTORICO_REQUERIMIENTO = "MOSTRAR_HISTORICO_REQUERIMIENTO";
export const EDITAR_HISTORICO_REQUERIMIENTO = "EDITAR_HISTORICO_REQUERIMIENTO";
export const ELIMINAR_HISTORICO_REQUERIMIENTO = "ELIMINAR_HISTORICO_REQUERIMIENTO";
export const LOADING_HISTORICO_REQUERIMIENTO = "LOADING_HISTORICO_REQUERIMIENTO";
export const HISTORICO_REQUERIMIENTO_SUCCEEDED = "HISTORICO_REQUERIMIENTO_SUCCEEDED";
export const HISTORICO_REQUERIMIENTO_FAILED = "HISTORICO_REQUERIMIENTO_FAILED";
export const LISTA_HISTORICO_PAGINACION = "LISTA_HISTORICO_PAGINACION";
export const LOADING_GUARDAR_HISTORICO_REQUERIMIENTO = "LOADING_GUARDAR_HISTORICO_REQUERIMIENTO";
export const GUARDAR_HISTORICO_REQUERIMIENTO = "GUARDAR_HISTORICO_REQUERIMIENTO";
export const GUARDAR_HISTORICO_REQUERIMIENTO_SUCCEEDED = "GUARDAR_HISTORICO_REQUERIMIENTO_SUCCEEDED";
export const GUARDAR_HISTORICO_REQUERIMIENTO_FAILED = "GUARDAR_HISTORICO_REQUERIMIENTO_FAILED";

// COMPRAS DOCUMENTACIONES TYPES
export const MOSTRAR_COMPRAS_DOCUMENTACIONES = "MOSTRAR_COMPRAS_DOCUMENTACIONES";
export const COMPRAS_DOCUMENTACIONES_FAILED = "COMPRAS_DOCUMENTACIONES_FAILED";
export const COMPRAS_DOCUMENTACIONES_SUCCESS = "COMPRAS_DOCUMENTACIONES_SUCCESS";
export const LOADING_COMPRAS_DOCUMENTACIONES = "LOADING_COMPRAS_DOCUMENTACIONES";

// FECHAS DOCUMENTACIONES COMPRAS TYPES
export const MOSTRAR_FECHAS_DOCUMENTACIONES_COMPRA = 'MOSTRAR_FECHAS_DOCUMENTACIONES_COMPRA';
export const MOSTRAR_FECHA_DOCUMENTACION_COMPRA = 'MOSTRAR_FECHA_DOCUMENTACION_COMPRA';
export const CREAR_FECHA_DOCUMENTACION_COMPRA = 'CREAR_FECHA_DOCUMENTACION_COMPRA';
export const EDITAR_FECHA_DOCUMENTACION_COMPRA = 'EDITAR_FECHA_DOCUMENTACION_COMPRA';
export const ELIMINAR_FECHA_DOCUMENTACION_COMPRA = 'ELIMINAR_FECHA_DOCUMENTACION_COMPRA';
export const LOADING_FECHA_DOCUMENTACION_COMPRA = 'LOADING_FECHA_DOCUMENTACION_COMPRA';
export const FECHA_DOCUMENTACION_COMPRA_SUCCEEDED = 'FECHA_DOCUMENTACION_COMPRA_SUCCEEDED';
export const FECHA_DOCUMENTACION_COMPRA_FAILED = 'FECHA_DOCUMENTACION_COMPRA_FAILED';

// PROVEEDORES TYPES
export const MOSTRAR_PROVEEDORES = "MOSTRAR_PROVEEDORES";
export const MOSTRAR_PROVEEDOR = "MOSTRAR_PROVEEDOR";
export const CREAR_PROVEEDOR = "CREAR_PROVEEDOR";
export const CREAR_PROVEEDOR_POR_REQUERIMIENTO = "CREAR_PROVEEDOR_POR_REQUERIMIENTO";
export const LOADING_PROVEEDOR = "LOADING_PROVEEDOR";
export const PROVEEDOR_SUCCEEDED = "PROVEEDOR_SUCCEEDED";
export const PROVEEDOR_FAILED = "PROVEEDOR_FAILED";
export const MOSTRAR_PROVEEDORES_INSUMOS_ADJUDICADOS = "MOSTRAR_PROVEEDORES_INSUMOS_ADJUDICADOS";
export const LOADING_PROVEEDOR_REQUERIMIENTO_COMPRA = "  LOADING_PROVEEDOR_REQUERIMIENTO_COMPRA,";
export const CREAR_PROVEEDOR_REQUERIMIENTO_COMPRA = "CREAR_PROVEEDOR_REQUERIMIENTO_COMPRA";
export const EDITAR_PROVEEDOR_REQUERIMIENTO_COMPRA = "  EDITAR_PROVEEDOR_REQUERIMIENTO_COMPRA,";
export const ELIMINAR_PROVEEDOR_REQUERIMIENTO_COMPRA = "ELIMINAR_PROVEEDOR_REQUERIMIENTO_COMPRA";
export const MOSTRAR_PROVEEDORES_REQUERIMIENTOS_COMPRAS = "MOSTRAR_PROVEEDORES_REQUERIMIENTOS_COMPRAS";
export const PROVEEDOR_REQUERIMIENTO_COMPRA_SUCCEEDED = "PROVEEDOR_REQUERIMIENTO_COMPRA_SUCCEEDED";
export const PROVEEDOR_REQUERIMIENTO_COMPRA_FAILED = "PROVEEDOR_REQUERIMIENTO_COMPRA_FAILED";
 
// CONTRATOS
export const CREAR_REQUERIMIENTO_CONTRATO = "CREAR_REQUERIMIENTO_CONTRATO";
export const LOADING_REQUERIMIENTO_CONTRATO = "LOADING_REQUERIMIENTO_CONTRATO";
export const REQUERIMIENTO_CONTRATO_SUCCEEDED = "REQUERIMIENTO_CONTRATO_SUCCEEDED";
export const REQUERIMIENTO_CONTRATO_FAILED = "REQUERIMIENTO_CONTRATO_FAILED";
export const MOSTRAR_REQUERIMIENTO_CONTRATO = "MOSTRAR_REQUERIMIENTO_CONTRATO";
export const MOSTRAR_REQUERIMIENTOS_CONTRATOS = "MOSTRAR_REQUERIMIENTOS_CONTRATOS";

export const LOADING_CONTRATOS = "LOADING_CONTRATOS";
export const CONTRATOS_SUCCEEDED = "CONTRATOS_SUCCEEDED";
export const CONTRATOS_FAILED = "CONTRATOS_FAILED";
export const MOSTRAR_CONTRATOS = "MOSTRAR_CONTRATOS";
 
// BIENES Y SERVICIOS
export const LOADING_REQUERIMIENTO_BIEN_SERVICIO = "LOADING_REQUERIMIENTO_BIEN_SERVICIO";
export const CREAR_REQUERIMIENTO_BIEN_SERVICIO = "CREAR_REQUERIMIENTO_BIEN_SERVICIO";
export const REQUERIMIENTO_BIEN_SERVICIO_SUCCEEDED = "REQUERIMIENTO_BIEN_SERVICIO_SUCCEEDED";
export const REQUERIMIENTO_BIEN_SERVICIO_FAILED = "REQUERIMIENTO_BIEN_SERVICIO_FAILED";

// NO POA

// PRODUCTO NO POA
export const CREAR_PRODUCTOS_NO_POA = "CREAR_PRODUCTOS_NO_POA";
export const MOSTRAR_PRODUCTOS_NO_POA = "MOSTRAR_PRODUCTOS_NO_POA";
export const MOSTRAR_PRODUCTO_NO_POA = "MOSTRAR_PRODUCTO_NO_POA";
export const EDITAR_PRODUCTO_NO_POA = "EDITAR_PRODUCTO_NO_POA";
export const ELIMINAR_PRODUCTO_NO_POA = "ELIMINAR_PRODUCTO_NO_POA";
export const LOADING_PRODUCTO_NO_POA = "LOADING_PRODUCTO_NO_POA";
export const PRODUCTO_NO_POA_SUCCEEDED = "PRODUCTO_NO_POA_SUCCEEDED";
export const PRODUCTO_NO_POA_FAILED = "PRODUCTO_NO_POA_FAILED";
export const MOSTRAR_PRODUCTOS_NO_POA_ADMINISTRACION = "MOSTRAR_PRODUCTOS_NO_POA_ADMINISTRACION";
export const TOTAL_PONDERACION_AREA_PRODUCTOS_NO_POA = "TOTAL_PONDERACION_AREA_PRODUCTOS_NO_POA";
export const TOTAL_PONDERACION_GENERAL_PRODUCTOS_NO_POA = "TOTAL_PONDERACION_GENERAL_PRODUCTOS_NO_POA";
export const TOTAL_PONDERACION_PRODUCTO_NO_POA_ACTIVIDADES = "TOTAL_PONDERACION_PRODUCTO_NO_POA_ACTIVIDADES";
export const MOSTRAR_TOTAL_PRODUCTOS_NO_POA_ADMINISTRACION = "MOSTRAR_TOTAL_PRODUCTOS_NO_POA_ADMINISTRACION";
export const CLEAN_PRODUCTOS_NO_POA_ADMINISTRACION = "CLEAN_PRODUCTOS_NO_POA_ADMINISTRACION";
export const MOSTRAR_PRODUCTOS_NO_POA_INSUMOS = "MOSTRAR_PRODUCTOS_NO_POA_INSUMOS";

// ACTIVIDADES NO POA
export const MOSTRAR_ACTIVIDADES_NOPOA = "MOSTRAR_ACTIVIDADES_NOPOA";
export const MOSTRAR_ACTIVIDAD_NOPOA = "MOSTRAR_ACTIVIDAD_NOPOA";
export const LOADING_ACTIVIDAD_NOPOA = "LOADING_ACTIVIDAD_NOPOA";
export const ACTIVIDAD_SUCCEEDED_NOPOA = "ACTIVIDAD_SUCCEEDED_NOPOA";
export const CREAR_ACTIVIDADES_NOPOA = "CREAR_ACTIVIDADES_NOPOA";
export const EDITAR_ACTIVIDADES_NOPOA = "EDITAR_ACTIVIDADES_NOPOA";
export const ELIMINAR_ACTIVIDADES_NOPOA = "ELIMINAR_ACTIVIDADES_NOPOA";
export const ACTIVIDAD_FAILED_NOPOA = "ACTIVIDAD_FAILED_NOPOA";
export const MOSTRAR_ACTIVIDAD_LOCAL_NOPOA = "MOSTRAR_ACTIVIDAD_LOCAL_NOPOA";
export const ELIMINAR_PRESUPUESTO_NOPOA = "ELIMINAR_PRESUPUESTO_NOPOA";
export const PRESUPUESTO_SUCCEEDED_NOPOA = "PRESUPUESTO_SUCCEEDED_NOPOA";
export const PRESUPUESTO_FAILED_NOPOA = "PRESUPUESTO_FAILED_NOPOA";
export const ADD_PRESUPUESTO_NOPOA = "ADD_PRESUPUESTO_NOPOA";
export const TOTAL_PONDERACION_AREA_ACTIVIDADES_NOPOA = "TOTAL_PONDERACION_AREA_ACTIVIDADES_NOPOA";
export const CREAR_ACTIVIDADES_PRODUCTOS_NOPOA = "CREAR_ACTIVIDADES_PRODUCTOS_NOPOA";
export const EDITAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO_NOPOA = "EDITAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO_NOPOA";
export const MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDOR_NOPOA = "MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDOR_NOPOA";
export const MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDORES_NOPOA = "MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDORES_NOPOA";
export const CREAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO_NOPOA = "CREAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO_NOPOA";
export const ELIMINAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO_NOPOA = "ELIMINAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO_NOPOA";

// INSUMOS NO POA
export const MOSTRAR_INSUMOS_NOPOA = "MOSTRAR_INSUMOS_NOPOA";
export const LOADING_INSUMOS_NOPOA = "LOADING_INSUMOS_NOPOA";
export const AGREGAR_INSUMOS_TEMPORALES_NOPOA = "AGREGAR_INSUMOS_TEMPORALES_NOPOA";
export const LIMPIAR_INSUMOS_TEMPORALES_NOPOA = "LIMPIAR_INSUMOS_TEMPORALES_NOPOA";

export const INSUMOS_SUCCEED_NOPOA = "INSUMOS_SUCCEED_NOPOA";
export const INSUMOS_FAILED_NOPOA = "INSUMOS_FAILED_NOPOA";

export const MOSTRAR_PONDERACION_AREAS_NOPOA = "MOSTRAR_PONDERACION_AREAS_NOPOA";
export const MOSTRAR_PONDERACION_AREA_NOPOA = "MOSTRAR_PONDERACION_AREA_NOPOA";
export const CREAR_PONDERACION_AREA_NOPOA= "Crear_PONDERACION_AREA_NOPOA";
export const EDITAR_PONDERACION_AREA_NOPOA = "EDITAR_PONDERACION_AREA_NOPOA";
export const LOADING_PONDERACION_AREA_NOPOA = "LOADING_PONDERACION_AREA_NOPOA";
export const PONDERACION_AREA_SUCCEEDED_NOPOA = "PONDERACION_AREA_SUCCEEDED_NOPOA";
export const PONDERACION_AREA_FAILED_NOPOA = "PONDERACION_AREA_FAILED_NOPOA";