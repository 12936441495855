import {
  MOSTRAR_NOTAS_PRODUCTO,
  CREAR_NOTAS_PRODUCTO,
  NOTAS_PRODUCTO_FAILED,
  NOTA_PRODUCTO_SUCCEEDED,
  AGREGAR_NOTA_PRODUCTO
} from "./../types";

import inicialState from "./../initial-state";

const { notaProducto } = inicialState;

const notaProductoReducer = (state = notaProducto, action) => {
  switch (action.type) {
    case MOSTRAR_NOTAS_PRODUCTO: {
      return {
        ...state,
        notaProducto: action.payload
      };
    }
    case AGREGAR_NOTA_PRODUCTO:
    case CREAR_NOTAS_PRODUCTO: {
      return {
        ...state,
        notaProducto: action.payload
      };
    }
    case NOTAS_PRODUCTO_FAILED:
    case NOTA_PRODUCTO_SUCCEEDED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default notaProductoReducer;
