import React from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateStatusUsuario } from "./../../redux/actions/usuarios-actions";

const Usuario = (props) => {
  const eliminarProducto = async() => {
   await props.updateStatusUsuario(props.usuario);
  };
  const truncateString = (str) => {
    let num = 25;

    return str && str.length > num ? str.slice(0, num) + "..." : str;
  };

  const { id, email, name, areaOrganizacional, user } = props.usuario;

  return (
    <tr>
      <td>{email}</td>
      <td>{name}</td>
      <td title={areaOrganizacional.nombre}>
        {truncateString(areaOrganizacional.nombre)}
      </td>
      <td>{user.active ? "Activo" : "Inactivo"}</td>
      <td>
        <Link
          to={{
            pathname: `/admin/usuarios/usuario/editar/${id}`,
            state: "/admin/usuarios/usuario/editar/:usuarioId",
          }}
          className="btn btn-success btn-sm"
        >
          Editar
        </Link>
        {user.active ? (
          <button
            onClick={() => {
              eliminarProducto();
            }}
            className="btn btn-danger btn-sm"
          >
            Inactivar
            <span className="fa fa-remove"></span>
          </button>
        ) : (
          <button
            onClick={() => {
              eliminarProducto();
            }}
            className="btn btn-success btn-sm"
          >
            Activar
            <span className="fa fa-remove"></span>
          </button>
        )}
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateStatusUsuario: (usuario) => dispatch(updateStatusUsuario(usuario)),
});

export default connect(null, mapDispatchToProps)(Usuario);
