import React, { Component } from "react";
import Loading from "./../utils/Loading";

import {
  Modal,
  ModalHeader
} from "reactstrap";
import NotaProducto from "./../administracion/notaProducto/crearNota.jsx";

export const ModalProducto = props => {
  const toggle = () => props.activarModal();
  const { modal } = props;
  return (
    <Modal isOpen={modal} toggle={toggle} size="lg" backdrop="static">
      <ModalHeader className="block-example border-bottom " toggle={toggle}>
        Crear Nota del Producto
      </ModalHeader>
      <NotaProducto
        toggle={toggle}
        producto={props.producto}
        notaProducto={props.notaProducto}
        agregarNota={props.agregarNota}
      ></NotaProducto>
    </Modal>
  );
};
