import {
  MOSTRAR_REQUERIMIENTOS,
  MOSTRAR_REQUERIMIENTO,
  CREAR_REQUERIMIENTO,
  EDITAR_REQUERIMIENTO,
  ELIMINAR_REQUERIMIENTO,
  LOADING_REQUERIMIENTO,
  REQUERIMIENTO_SUCCEEDED,
  REQUERIMIENTO_FAILED,
  PRESUPUESTO_SUCCEEDED,
  PRESUPUESTO_FAILED,
  EDITAR_PRESUPUESTO,
  MOSTRAR_PRODUCTOS_REQUERIMIENTO_COMPRA_ADMIN,
  REQUERIMIENTO_DOWNLOAD_SUCCEEDED,
  REQUERIMIENTO_DOWNLOAD_FAILED,
  
  MOSTRAR_REQUERIMIENTOS_COMPRAS,
  MOSTRAR_REQUERIMIENTO_COMPRA,
  CREAR_REQUERIMIENTO_COMPRA,
  EDITAR_REQUERIMIENTO_COMPRA,
  ELIMINAR_REQUERIMIENTO_COMPRA,
  LOADING_REQUERIMIENTO_COMPRA,
  REQUERIMIENTO_COMPRA_SUCCEEDED,
  REQUERIMIENTO_COMPRA_FAILED,

  CREAR_REQUERIMIENTO_CONTRATO,
  MOSTRAR_REQUERIMIENTO_CONTRATO,
  MOSTRAR_REQUERIMIENTOS_CONTRATOS,
  LOADING_REQUERIMIENTO_CONTRATO,
  REQUERIMIENTO_CONTRATO_SUCCEEDED,
  REQUERIMIENTO_CONTRATO_FAILED,

  LOADING_CONTRATOS,
  MOSTRAR_CONTRATOS,
  CONTRATOS_SUCCEEDED,
  CONTRATOS_FAILED,

  LOADING_REQUERIMIENTO_BIEN_SERVICIO,
  CREAR_REQUERIMIENTO_BIEN_SERVICIO,
  REQUERIMIENTO_BIEN_SERVICIO_SUCCEEDED,
  REQUERIMIENTO_BIEN_SERVICIO_FAILED,
  ELIMINAR_PRESUPUESTO

} from "../types";
import { api_base } from "../../config";
import { getData,getInformes, getDocument } from "../../utils/api-client";

export const getRequerimientos = (areaId) => async (dispatch) => {
  
 let url = api_base + `api/v1/requerimientos/2024/${areaId}`;
 
  dispatch({ type: LOADING_REQUERIMIENTO });

  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_REQUERIMIENTOS, payload: resultado.data });
      dispatch({ type:   REQUERIMIENTO_SUCCEEDED,});
    })
    .catch((error) => {
      if(error) {
        dispatch({ type: REQUERIMIENTO_FAILED });
      }
    });
};
export const getRequerimientosAdmin = (estadoId) => async (dispatch) => {
  
 let url = api_base + `api/v1/requerimientos/administrativo/2024/${estadoId}`;
 
  dispatch({ type: LOADING_REQUERIMIENTO });

  await getData
    .get(url)
    .then((resultado) => {
      // console.log("Resultados",resultado)
      dispatch({ type: MOSTRAR_PRODUCTOS_REQUERIMIENTO_COMPRA_ADMIN, payload: resultado.data });
      dispatch({ type:   REQUERIMIENTO_SUCCEEDED,});
    })
    .catch((error) => {
      if(error) {
        dispatch({ type: REQUERIMIENTO_FAILED });
      }
    });
};

export const getRequerimiento = (requerimientoId) => async (dispatch) => {
  let url = api_base + `api/v1/requerimiento/${requerimientoId}`;
  dispatch({ type: LOADING_REQUERIMIENTO });
  await getData
    .get(url)
    .then((resultado) => {
      // console.log("this.props.requerimiento resultado",resultado);
      dispatch({ type: MOSTRAR_REQUERIMIENTO, payload: resultado.data });
      dispatch({ type: REQUERIMIENTO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: REQUERIMIENTO_FAILED });
      }
    });
};

export const createRequerimientos = (requerimiento) => async (dispatch) => {
  let url = api_base + "api/v1/requerimientos/crear";

  dispatch({ type: LOADING_REQUERIMIENTO });
  await getData
    .post(url, requerimiento, {
      headers: {
        "Content-Type": "multipart/form-data"
      }})
    .then((resultado) => {
      dispatch({
        type: CREAR_REQUERIMIENTO,
        payload: resultado.data,
      });
      dispatch({ type: REQUERIMIENTO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: REQUERIMIENTO_FAILED });
      }
    });
};

export const updateRequerimientos = (requerimiento) => async (dispatch) => {
  let url = api_base + "api/v1/requerimientos/editar";

  dispatch({ type: LOADING_REQUERIMIENTO });
  await getData
  .post(url, requerimiento, {
    headers: {
      "Content-Type": "multipart/form-data"
    }})
    .then((resultado) => {
      dispatch({
        type: EDITAR_REQUERIMIENTO,
        payload: resultado.data,
      });
      dispatch({ type: REQUERIMIENTO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: REQUERIMIENTO_FAILED });
      }
    });
};

export const deleteRequerimiento = (historialRequerimiento) => async (dispatch) => {
  // let url = api_base + "api/v1/requerimientos/eliminar";
  let url = api_base + "api/v1/historialrequerimientos/crear";

  dispatch({ type: LOADING_REQUERIMIENTO });
  await getData
    .post(url, historialRequerimiento)
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_REQUERIMIENTO,
        payload: historialRequerimiento.requerimientoId,
      });
      dispatch({ type: REQUERIMIENTO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: REQUERIMIENTO_FAILED });
      }
    });
};

export const deleteRequerimientoBase = (requerimientoId) => async (dispatch) => {
  let url = api_base + "api/v1/requerimientos/eliminar";
  dispatch({ type: LOADING_REQUERIMIENTO });
  await getData
    .post(url, { requerimientoId: requerimientoId} )
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_REQUERIMIENTO,
        payload: requerimientoId,
      });
      dispatch({ type: REQUERIMIENTO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: REQUERIMIENTO_FAILED });
      }
    });
};

export const deletePresupuesto = (presupuestoId)=>async (dispatch)=>{
  let url = api_base + "api/v1/presupuesto/eliminar";

  dispatch({ type: LOADING_REQUERIMIENTO });
  await getData
    .post(url, {presupuestoId:presupuestoId})
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_PRESUPUESTO,
        payload: presupuestoId,
      });
      dispatch({ type: PRESUPUESTO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: PRESUPUESTO_FAILED });
      }
    });
}

export const getEspecificacionesTecnicasMonitoreo = (requerimiento) => async (dispatch) => {
  let url =
    api_base +
    `api/v1/requerimiento/especificacionesTecnicas/${requerimiento.requerimientoId}`;
    dispatch({type:LOADING_REQUERIMIENTO});
  await getInformes
    .get(url, {
      headers: {
        "Content-Type": "application/octet-stream",
      }, responseType: 'blob'
    },)
    .then((resultado) => {
     
      var a = document.createElement("a");
      // console.log("success", resultado.data)
      let blob = new Blob([resultado.data], { type: "octet/stream" }),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Especificaciones_${requerimiento.numeroSolicitud}_${requerimiento.nombreDocumento.replace(/\s/g, '_')}`;
      a.click();
      window.URL.revokeObjectURL(url);
      dispatch({ type: REQUERIMIENTO_DOWNLOAD_SUCCEEDED });
    })
    .catch((error) => {
      // console.log("Error", error)
      if (error) {
        if (error) {
          dispatch({ type: REQUERIMIENTO_DOWNLOAD_FAILED });
        }
      }
    });
};

// Requerimientos Compras
export const getRequerimientosCompras = () => async (dispatch) => {
   let url = api_base + `api/v1/requerimientoCompra`;
  
   dispatch({ type: LOADING_REQUERIMIENTO_COMPRA });
 
   await getData
     .get(url)
     .then((resultado) => {
       dispatch({ type: MOSTRAR_REQUERIMIENTOS_COMPRAS, payload: resultado.data });
       dispatch({ type: REQUERIMIENTO_COMPRA_SUCCEEDED,});
     })
     .catch((error) => {
       if(error) {
         dispatch({ type: REQUERIMIENTO_COMPRA_FAILED });
       }
     });
 };

 export const getRequerimientoCompraByRequerimiento = (requerimientoId) => async (dispatch) => {
  let url = api_base + `api/v1/requerimientoCompra/requerimiento/${requerimientoId}`;
  dispatch({ type: LOADING_REQUERIMIENTO_COMPRA });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_REQUERIMIENTO_COMPRA, payload: resultado.data });
      dispatch({ type: REQUERIMIENTO_COMPRA_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: REQUERIMIENTO_COMPRA_FAILED });
      }
    });
};

export const getRequerimientoCompra = (requerimientoCompraId) => async (dispatch) => {
  let url = api_base + `api/v1/requerimientoCompra/${requerimientoCompraId}`;
  dispatch({ type: LOADING_REQUERIMIENTO_COMPRA });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_REQUERIMIENTO_COMPRA, payload: resultado.data });
      dispatch({ type: REQUERIMIENTO_COMPRA_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: REQUERIMIENTO_COMPRA_FAILED });
      }
    });
};

export const createRequerimientoCompra = (requerimientoCompra) => async (dispatch) => {
  let url = api_base + "api/v1/requerimientoCompra/crear";

  dispatch({ type: LOADING_REQUERIMIENTO_COMPRA });
  await getData
    .post(url, requerimientoCompra, {
      headers: {
        "Content-Type": "multipart/form-data"
      }})
    .then((resultado) => {
      dispatch({
        type: CREAR_REQUERIMIENTO_COMPRA,
        payload: resultado.data,
      });
      dispatch({ type: REQUERIMIENTO_DOWNLOAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: REQUERIMIENTO_COMPRA_FAILED });
      }
    });
};

export const updateRequerimientoCompra = (requerimientoCompra) => async (dispatch) => {
  let url = api_base + "api/v1/requerimientoCompra/actualizar";

  dispatch({ type: LOADING_REQUERIMIENTO_COMPRA });
  await getData
    .post(url, JSON.stringify(requerimientoCompra))
    .then((resultado) => {
      dispatch({
        type: EDITAR_REQUERIMIENTO_COMPRA,
        payload: resultado.data,
      });
      dispatch({ type: REQUERIMIENTO_COMPRA_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: REQUERIMIENTO_COMPRA_FAILED });
      }
    });
};

export const deleteRequerimientoCompra = (requerimientoCompra) => async (dispatch) => {
  let url = api_base + "api/v1/requerimientoCompra/eliminar";

  dispatch({ type: LOADING_REQUERIMIENTO_COMPRA });
  await getData
    .post(url, requerimientoCompra)
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_REQUERIMIENTO_COMPRA,
        payload: requerimientoCompra.requerimientoCompraId,
      });
      dispatch({ type: REQUERIMIENTO_COMPRA_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: REQUERIMIENTO_COMPRA_FAILED });
      }
    });
};

export const createRequerimientoContrato = (requerimiento) => async (dispatch) => {
  let url = api_base + "api/v1/contrato/crear";

  dispatch({ type: LOADING_REQUERIMIENTO_CONTRATO });
  await getData
    .post(url, requerimiento, {
      headers: {
        "Content-Type": "multipart/form-data"
      }})
    .then((resultado) => {
      dispatch({
        type: CREAR_REQUERIMIENTO_CONTRATO,
        payload: resultado.data,
      });
      dispatch({ type: REQUERIMIENTO_CONTRATO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: REQUERIMIENTO_CONTRATO_FAILED });
      }
    });
};

export const getContratoRequerimiento = (requerimientoId) => async (dispatch) => {
  let url = api_base + `api/v1/contrato/requerimiento/${requerimientoId}`;
  dispatch({ type: LOADING_REQUERIMIENTO_CONTRATO });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_REQUERIMIENTO_CONTRATO, payload: resultado.data });
      dispatch({ type: REQUERIMIENTO_CONTRATO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: REQUERIMIENTO_CONTRATO_FAILED });
      }
    });
};

export const getContratos = () => async (dispatch) => {
  let url = api_base + "api/v1/contratos";
  dispatch({ type: LOADING_CONTRATOS });

  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_CONTRATOS, payload: resultado.data });
      dispatch({ type: CONTRATOS_SUCCEEDED });
    })
    .catch((error) => {
        // console.log('error', error)
        dispatch({ type: CONTRATOS_FAILED });
    });
};

export const getContratosDescargar = (requerimiento) => async (dispatch) => {
  let url =
    api_base +
    `api/v1/contrato/requerimiento/descargar/${requerimiento.requerimientoId}`;
    dispatch({type:LOADING_REQUERIMIENTO});
  await getDocument
    .get(url, {
      headers: {
        "Content-Type": "application/octet-stream",
      }, responseType: 'blob'
    },)
    .then((resultado) => {
      var a = document.createElement("a");
      let blob = new Blob([resultado.data], { type: "octet/stream" }),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `contrato-${requerimiento.numeroSolicitud}-descargar.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      dispatch({ type: REQUERIMIENTO_DOWNLOAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: REQUERIMIENTO_DOWNLOAD_FAILED });
      }
    });
};

export const asignarBienServicioRequerimiento = (requerimiento) => async (dispatch) => {
  let url = api_base + "api/v1/bienes_servicios/crear";
  dispatch({ type: LOADING_REQUERIMIENTO_BIEN_SERVICIO });
  // console.log(requerimiento)
  await getData
    .post(url, requerimiento, {
      headers: {
        "Content-Type": "multipart/form-data"
      }})
    .then((resultado) => {
      dispatch({
        type: CREAR_REQUERIMIENTO_BIEN_SERVICIO,
        payload: resultado.data,
      });
      dispatch({ type: REQUERIMIENTO_BIEN_SERVICIO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: REQUERIMIENTO_BIEN_SERVICIO_FAILED });
      }
    });
};
