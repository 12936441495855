import {
  MOSTRAR_FECHAS_DOCUMENTACIONES_COMPRA,
  LOADING_FECHA_DOCUMENTACION_COMPRA,
  CREAR_FECHA_DOCUMENTACION_COMPRA,
  EDITAR_FECHA_DOCUMENTACION_COMPRA,
  ELIMINAR_FECHA_DOCUMENTACION_COMPRA,
  FECHA_DOCUMENTACION_COMPRA_SUCCEEDED,
  FECHA_DOCUMENTACION_COMPRA_FAILED,
} from "../types";

import initialState from "../initial-state";

const { fechasDocumentacionesCompras } = initialState;

const FechasDocumentacionesComprasReducer = (state = fechasDocumentacionesCompras, action) => {
  switch (action.type) {
  case MOSTRAR_FECHAS_DOCUMENTACIONES_COMPRA:
    return {
      ...state,
      fechasDocumentacionesCompras: action.payload,
      isLoading:false
    };
    case CREAR_FECHA_DOCUMENTACION_COMPRA:
      return {
        ...state,
        fechasDocumentacionesCompras: [...state.fechasDocumentacionesCompras, action.payload]
      };
      case LOADING_FECHA_DOCUMENTACION_COMPRA:
        return {
          ...state,
          isLoading: true,
          success:'idle'
        };
    default:
      return state;
  }
};
export default FechasDocumentacionesComprasReducer;
