import React, { Component } from "react";

import {
  Container
} from "reactstrap";
import swal from "sweetalert";
import { connect } from "react-redux";
import { Authorization } from "./../../../utils/Authorization";
import {
  getAreasPoaMonitoreo,
  cerrarTodasAreasPoaMonitoreo,
  habilitarTodasAreasPoaMonitoreo,
  updateAreasPoaMonitoreo,
} from "./../../../redux/actions/area_poa_monitoreo_actions";

import {
  ModalDevolucionInformesTrimestralesTodasAreas,
  ModalDevolucionInformeTrimestral,
} from "../../utils/ModalDevolucionInformesTrimestralesTodasAreas";

import {
  getAreasInformesTrimestrales,
  updateAreaInformeTrimestral,
  updateAllInformesTrimestrales,
} from "./../../../redux/actions/areas_informes_Trimestrales_actions";

import AreaOrganizacional from "./areaOrganizaional";
import Loading from "./../../utils/Loading";

class AdminitracionInformesTrimestrales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaInformesTrimestrales: [],
      isloadingState: true,
      modalInformeTrimestralTodasAreas: false,
      modalInformeTrimestral: false,
      informeTrimestralSeleccionado: {},
    };
  }
  filtroAreaRef = new React.createRef();
  trimestreRef = new React.createRef();
  componentDidMount() {
    this.cargarDatos();
  }
  cargarDatos = async () => {
    const { poaId } = this.props.poaActivo;
    await this.props.getAreasInformesTrimestrales(poaId);
    this.setState({
      areaInformesTrimestrales: this.props.areasInformesTrimestrales,
      isloadingState: false,
    });
  };
  filtroAreaOrganizacional = () => {
    if (
      this.filtroAreaRef.current === null ||
      this.filtroAreaRef.current.value === null ||
      this.filtroAreaRef.current.value === ""
    ) {
      this.setState({
        areaInformesTrimestrales: this.props.areasInformesTrimestrales,
      });
    } else {
      let filtroAreaInformeTrimestral = this.props.areasInformesTrimestrales.filter(
        (area) =>
          area.areaOrganizacional.nombre
            .toUpperCase()
            .includes(this.filtroAreaRef.current.value.toUpperCase())
      );
      this.setState({ areaInformesTrimestrales: filtroAreaInformeTrimestral });
    }
  };

  activarModalDevolucionInformeTrimestralTodasAreas = () => {
    if (
      this.trimestreRef.current !== null &&
      this.trimestreRef.current.value !== "--Elegir Trimestre--"
    ) {
      swal({
        title: `Esta seguro de querer rechazar los informes del trimestre ${this.trimestreRef.current.value}?`,
        text: "Seran rechazados los informes para todas las áreas organizacionales",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          this.setState({
            modalInformeTrimestralTodasAreas: !this.state
              .modalInformeTrimestralTodasAreas,
          });
        }
      });
    } else {
      swal("Informes Trimestrales", "Debe elegir el trimestre que desea rechazar","error");
    }
  };

  activarModalDevolucionInformeTrimestral = (informe, area) => {
    swal({
      title: `Esta seguro de querer rechazar el informe del trimestre ${informe.trimestreId}?`,
      text: `Este proceso rechazara el informe del trimestre para el área ${informe.trimestreId}! `,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if (confirm) {
        this.setState({
          modalInformeTrimestral: !this.state.modalInformeTrimestral,
          informeTrimestralSeleccionado: informe,
        });
      }
    });
  };
  aprobarInformesTodasAreas = async () => {
    if (
      this.trimestreRef.current !== null &&
      this.trimestreRef.current.value !== "--Elegir Trimestre--"
    ) {
      swal({
        title: `Esta seguro de querer aprobar el informe del trimestre ${this.trimestreRef.current.value}?`,
        text: "Seran aprobados los informes para todas las áreas organizacionales",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then(async (confirm) => {
        if (confirm) {
             this.setState({ isloadingState: true });
           let areaInformeTrimestral = {
             trimestreId: this.trimestreRef.current.value,
             poaId: this.props.poaActivo.poaId,
             comentario: `Fueron Aprobados los informes del trimestre ${this.trimestreRef.current.value} para todas las areas`,
             nivelAprobacionId: this.props.user.nivelAprobacion.padre,
           };
           await this.props.updateAllInformesTrimestrales(areaInformeTrimestral);
           this.setState({
             isloadingState: false,
             areaInformesTrimestrales: this.props.areasInformesTrimestrales,
           });
        }
      });
    } else {
      swal("Informes Trimestrales", "Debe elegir el trimestre que desea habilitar","error");
    }
  };
  rechazarInformesTrimestralesTodasAreas = async (comentario) => {
    this.setState({ isloadingState: true });
    let areaInformeTrimestral = {
      trimestreId: this.trimestreRef.current.value,
      poaId: this.props.poaActivo.poaId,
      comentario: comentario,
      nivelAprobacionId: this.props.user.nivelAprobacion.hijo,
    };
    await this.props.updateAllInformesTrimestrales(areaInformeTrimestral);
    this.setState({
      isloadingState: false,
      areaInformesTrimestrales: this.props.areasInformesTrimestrales,
      modalInformeTrimestralTodasAreas: !this.state
        .modalInformeTrimestralTodasAreas,
    });
  };

  aprobarInformeTrimestral = async (informe, areaOrganizacional) => {
    swal({
      title:  `Esta seguro de querer aprobar el informe ${informe.nombreImagen}?`,
      text: `Este proceso aprobara el informe del trimestre para el área ${informe.trimestreId}! `,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (confirm) => {
      if (confirm) {
        let obj = {
          informeTrimestralId: informe.informeTrimestralId,
          comentario: "Fue aprobado el Informe del Trimestre",
          nivelAprobacionId: this.props.user.nivelAprobacion.padre,
          poaId: this.props.poaActivo.poaId,
        };
        this.setState({ isloadingState: true });
        await this.props.updateAreaInformeTrimestral(obj);
        this.setState({
          isloadingState: false,
          areaInformesTrimestrales: this.props.areasInformesTrimestrales,
        });
      }
    });
  };
  rechazarInformeTrimestral = async (informe, comentario) => {
    let obj = {
      informeTrimestralId: informe.informeTrimestralId,
      comentario: comentario,
      nivelAprobacionId: this.props.user.nivelAprobacion.hijo,
      poaId: this.props.poaActivo.poaId,
    };
    this.setState({ isloadingState: true });
    await this.props.updateAreaInformeTrimestral(obj);
    this.setState({
      isloadingState: false,
      areaInformesTrimestrales: this.props.areasInformesTrimestrales,
      modalInformeTrimestral: false,
      informeTrimestralSeleccionado: {},
    });
  };
  render() {
    return (
      <React.Fragment>
        <Container>
          <div className="pb-6 pt-5 pt-md-7">
            <div className="container bg-white border">
              {this.state.isloadingState ? (
                <Loading></Loading>
              ) : (
                <React.Fragment>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mt-3 ">
                        <label htmlFor="filtro">
                          Filtro Área Organizacional
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm text-dark"
                          id="filtro"
                          placeholder="Área organizacional"
                          onKeyUp={(e) => {
                            e.preventDefault();
                            this.filtroAreaOrganizacional();
                          }}
                          ref={this.filtroAreaRef}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mt-3">
                        <label>Elegir Trimestre</label>
                        <select
                          ref={this.trimestreRef}
                          className=" form-control form-control-sm"
                        >
                          <option>--Elegir Trimestre--</option>
                          {this.props.trimestres.map((trimestre) => (
                            <option
                              key={trimestre.alias}
                              value={trimestre.alias}
                            >
                              {trimestre.alias}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className=" from-group mt-3">
                        <label>Acción...</label>
                        <div>
                          <button
                            className="btn btn-sm btn-success"
                            disabled={
                              this.state.areaInformesTrimestrales.length < 1
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              this.aprobarInformesTodasAreas();
                            }}
                          >
                            Aprobar todos
                          </button>
                          <button
                            className="btn btn-sm btn-danger"
                            disabled={
                              this.state.areaInformesTrimestrales.length < 1
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              this.activarModalDevolucionInformeTrimestralTodasAreas();
                            }}
                          >
                            Rechazar todos
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    {this.state.areaInformesTrimestrales.length > 0 ? (
                      this.state.areaInformesTrimestrales.map(
                        (area) =>
                          area.informesTrimestrales.length > 0 && (
                            <AreaOrganizacional
                              key={area.areaOrganizacional.areaOrganizacionalId}
                              areaInformesTrimestrales={area}
                              aprobarInformeTrimestral={
                                this.aprobarInformeTrimestral
                              }
                              nivelAprobacionId={
                                this.props.user.nivelAprobacion
                                  .nivelAprobacionId
                              }
                              rechazarInformeTrimestral={
                                this.rechazarInformeTrimestral
                              }
                              activarModalDevolucionInformeTrimestral={
                                this.activarModalDevolucionInformeTrimestral
                              }
                            ></AreaOrganizacional>
                          )
                      )
                    ) : (
                      <h2 className="text-center">
                        No existen informes para revisión
                      </h2>
                    )}
                  </div>

                  <ModalDevolucionInformesTrimestralesTodasAreas
                    activarModalDevolucionInformeTrimestralTodasAreas={
                      this.activarModalDevolucionInformeTrimestralTodasAreas
                    }
                    modalInformeTrimestralTodasAreas={
                      this.state.modalInformeTrimestralTodasAreas
                    }
                    rechazarInformesTrimestralesTodasAreas={
                      this.rechazarInformesTrimestralesTodasAreas
                    }
                  ></ModalDevolucionInformesTrimestralesTodasAreas>
                  <ModalDevolucionInformeTrimestral
                    activarModalDevolucionInformeTrimestral={
                      this.activarModalDevolucionInformeTrimestral
                    }
                    modalInformeTrimestral={this.state.modalInformeTrimestral}
                    rechazarInformeTrimestral={this.rechazarInformeTrimestral}
                    informeTrimestral={this.state.informeTrimestralSeleccionado}
                  ></ModalDevolucionInformeTrimestral>
                </React.Fragment>
              )}
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  poas: state.poas.poas,
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  trimestres: state.trimestres.trimestres,
  programacionMonitoreos: state.programacionMonitoreos.programacionMonitoreos,
  areaPoaMonitoreo: state.areaPoaMonitoreo.areaPoaMonitoreo,
  areasInformesTrimestrales:
    state.areasInformesTrimestrales.areasInformesTrimestrales,
});

const mapDispatchToProps = (dispatch) => ({
  getAreasInformesTrimestrales: (poaId) =>
    dispatch(getAreasInformesTrimestrales(poaId)),
  updateAreaInformeTrimestral: (areaInformeTrimestral) =>
    dispatch(updateAreaInformeTrimestral(areaInformeTrimestral)),
  updateAllInformesTrimestrales: (areaInformeTrimestral) =>
    dispatch(updateAllInformesTrimestrales(areaInformeTrimestral)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(AdminitracionInformesTrimestrales, [
    "Administrador general",
    "Analista del POA",
    "Gestor del POA",
    "MAE",
  ])
);
