import React from "react";
import { connect } from "react-redux";

const TipoCompra = (props) => {
  const { tipoCompraId, nombre } = props.compra;
  const { eliminarTipoCompra, editarTipoCompra, activarModalEditarTipoCompra, tipoCompras } = props;

  return (
    <tr>
      <td>{tipoCompraId}</td>
      <td>{nombre}</td>
      <td>
        <a
          className="btn btn-success text-white btn-sm"
          onClick={(e) => {
            e.preventDefault();
            activarModalEditarTipoCompra(props.compra);
          }} >
          Editar
        </a>
          <button
            onClick={() => {
              eliminarTipoCompra(tipoCompraId);
            }}
            className="btn btn-danger btn-sm"
          >
            Eliminar
            <span className="fa fa-remove"></span>
          </button>
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch) => ({
 
});

export default connect(null, mapDispatchToProps)(TipoCompra);
