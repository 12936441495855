import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createInvolucrados,
  getInvolucrados
} from "./../../redux/actions/involucrados-actions";

import "react-widgets/dist/css/react-widgets.css";

import { Authorization } from "./../../utils/Authorization";
import {
  Container
} from "reactstrap";
import swal from "sweetalert";

import Loading from "../utils/Loading";

class CrearInvolucrado extends Component {
  descripcionRef = React.createRef();
  estadoRef = React.createRef();

  crearInvolucrado = e => {
    e.preventDefault();
    if (
      this.descripcionRef.current !== null &&
      this.descripcionRef.current.value !== ""
    ) {
      let nuevoIvolucrado = {
        descripcion: this.descripcionRef.current.value,
        estado: this.estadoRef.current.value
      };

      if (nuevoIvolucrado) {
        this.props
          .createInvolucrados(nuevoIvolucrado)
          .then(resultado =>
            this.props.history.push("/admin/involucrado/index")
          );
      } else {
        swal("Involucrados","Debe ingresar los valores a crear","error");
      }
    } else {
      swal("Involucrados","El campo involucrado es un valor requerido","error");
    }
  };
  render() {
    const { isLoading } = this.props;

    return (
      <>
        {!isLoading ? (
          <div className="pb-6 pt-5 pt-md-7">
            <Container>
              <div className="container bg-white border">
                <form className="p-3" onSubmit={this.crearInvolucrado}>
                  <div className="form-group">
                    <label htmlFor="descripción">Involucrado</label>
                    <input
                      type="text"
                      className="form-control text-dark"
                      id="descripción"
                      placeholder="Involucrado"
                      ref={this.descripcionRef}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="estado">Estado</label>
                    <select
                      className="form-control text-dark"
                      id="estado"
                      ref={this.estadoRef}
                    >
                      <option defaultValue={true} value={true}>
                        Activo
                      </option>
                      <option value={false}>Inactivo</option>
                    </select>
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Guardar
                  </button>
                </form>
              </div>
            </Container>
          </div>
        ) : (
          <div className="pb-6 pt-5 pt-md-7">
            <Loading></Loading>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    involucrados: state.involucrados.involucrados,
    isLoading: state.involucrados.isLoading,
    user: state.auth.signed
  };
};

const mapDispatchToProps = dispatch => ({
  createInvolucrados: involucrado => dispatch(createInvolucrados(involucrado)),
  getInvolucrados: () => dispatch(getInvolucrados())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps //{ Login }
)(
  Authorization(CrearInvolucrado, ["Administrador general", "Analista del POA", "Gestor del POA",
  "MAE"])
);
