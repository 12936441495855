import initialState from "../initial-state";
import {
  MOSTRAR_AREA_INFORMES_TRIMESTRALES,
  EDITAR_AREA_INFORMES_TRIMESTRALES,
  LOADING_AREA_INFORMES_TRIMESTRALES,
  AREA_INFORMES_TRIMESTRALES_FAILED,
  AREA_INFORMES_TRIMESTRALES_SUCCESS,
} from "../types";

const { areasInformesTrimestrales } = initialState;

const AreasInformesTrimestralesReducer = (
  state = areasInformesTrimestrales,
  action
) => {
  switch (action.type) {
    case LOADING_AREA_INFORMES_TRIMESTRALES:
      return {
        ...state,
        isLoading: true,
      };
    case MOSTRAR_AREA_INFORMES_TRIMESTRALES:
      return {
        ...state,
        areasInformesTrimestrales: action.payload,
        isLoading: false,
      };
    case AREA_INFORMES_TRIMESTRALES_FAILED:
    case AREA_INFORMES_TRIMESTRALES_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case EDITAR_AREA_INFORMES_TRIMESTRALES:
      return {
        ...state,
        areasInformesTrimestrales: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default AreasInformesTrimestralesReducer;
