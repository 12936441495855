import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  Table,
  Container,
} from "reactstrap";

import { Authorization } from "../../../utils/Authorization";
import Loading from "../../utils/Loading";
import { Link } from "react-router-dom";

import { ModalRecepcionBienesServicios } from "../../utils/ModalRecepcionBienesServicios";
import Requerimiento from "../../requerimientos/Requerimiento";

import { api_download_informes } from "../../../config";
import Pagination from "react-js-pagination";

import { getAreasOrganizacionales } from "../../../redux/actions/areas-actions";
import { getRequerimientosAdmin } from "./../../../redux/actions/requerimientos-actions";

import {
  getUsuarioEntity,
} from "./../../../redux/actions/usuarios-actions";

import { deleteRequerimientoBase } from "./../../../redux/actions/requerimientos-actions";

class ListaDepartamentoAdministrativo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,

      file: [],
      fecha: "",
      documento: [],
      trimestreId: "",
      estatusEnviar: false,
      areaid2: 0,
      activePage: 1,
      totalPages: 0,
      itemPerPage: 10,
      listaRequerimientosPageState: [],
      filtroHabilitado: false
    };
  }

  componentDidMount() {
    this.cargarDatos();
    this.setState({ areaid2: this.props.user.areaOrganizacional.areaOrganizacionalId });
  }

  filtroRequerimiento = (requerimientoValor) => {
    let filtroRequerimiento = [];
    if (requerimientoValor.length > 0) {
      filtroRequerimiento = this.props.requerimientosAdmin.filter((requerimiento) =>
        requerimiento.numeroSolicitud.toString().includes(requerimientoValor)
      );
    } else {
      filtroRequerimiento = this.props.requerimientosAdmin;
    }

    this.setState({ listaRequerimientosState: filtroRequerimiento, filtroHabilitado: true }, () => {
      this.validarPaginacion();
    });
  };

  validarPaginacion = (page = 1, elementos = 10) => {
    let index = elementos * page - elementos;
    let listaRequerimientos = [];

    for (let i = 0; i < elementos; i++) {
      listaRequerimientos.push(this.state.listaRequerimientosState[index]);

      index++;
    }

    let totalPages = Math.ceil(
      this.state.listaRequerimientosState.length / elementos
    );

    this.setState({
      listaRequerimientosPageState: listaRequerimientos,
      activePage: page,
      totalPages,
      itemPerPage: elementos,
    });
  };

  obtenerIdUsuario = (usuarioId) => {
    this.props.getUsuarioEntity(usuarioId);
    return this.props.usuarioId ?? 0;
  }

  cargarDatos = async () => {
    await this.props.getAreasOrganizacionales();
    this.setState({ areaid2: this.props.user.areaOrganizacional.areaOrganizacionalId })
    await this.props.getRequerimientosAdmin(3);
    this.setState({ listaRequerimientosState: this.props.requerimientosAdmin, isLoadingState: false })
    await this.props.getUsuarioEntity(2);
    this.validarPaginacion();
  }

  eliminarRequerimiento = (requerimientoId) => {
    swal({
      title: "Eliminar Requerimiento?",
      text: "¡Esta seguro de eliminar el requerimiento, esta acción sera definitiva!",
      icon: "warning",
      buttons: [
        'Cancelar',
        'Aceptar'
      ],
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm) {
        this.props.deleteRequerimientoBase(requerimientoId);
      }
    })
  }

  render() {
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="form-group  col-md-6 ">
                          <label htmlFor="filtro">Filtro Requerimientos</label>
                          <input
                            type="text"
                            className="form-control form-control-sm text-dark"
                            id="filtro"
                            placeholder="Requerimientos"
                            onKeyUp={(e) => {
                              this.filtroRequerimiento(e.currentTarget.value);
                            }}
                          />
                        </div>
                    </div>
  
                    {this.state.listaRequerimientosState && this.state.listaRequerimientosState.length > 0 ? (
                      <Table responsive className="table-striped">
                        <thead>
                          <tr>
                            <th># Solicitud</th>
                            <th>Estado</th>
                            <th>Área Actual</th>
                            <th>Fecha de Entrada</th>
                            <th>Tipo de Compra</th>
                            <th>Analista</th>
                            <th>Proceso Plurianual</th>
                            {/* <th>SIGEF</th> */}
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.listaRequerimientosPageState.map(
                            (requerimiento) =>
                              requerimiento != null &&
                              requerimiento != undefined && (
                                <Requerimiento
                                  key={requerimiento.requerimientoId}
                                  requerimiento={requerimiento}
                                  user={this.props.user}
                                  eliminarRequerimiento={this.eliminarRequerimiento}
                                  obtenerIdUsuario={this.obtenerIdUsuario}
                                />
                              )
                          )}
                        </tbody>
                        <div className="d-flex justify-content-center">
                          <Pagination
                            prevPageText="ant"
                            nextPageText="sig"
                            firstPageText="prime"
                            lastPageText="ultimo"
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemPerPage}
                            totalItemsCount={this.state.listaRequerimientosState.length}
                            onChange={this.validarPaginacion.bind(this)}
                          />
                        </div>
                      </Table>
                    ) :!this.state.filtroHabilitado && (
                      <h3>No tiene solicitudes de compras pendientes por aprobación administrativa</h3>
                    )}
                  </div>
                </div>
              )}
            </div>
            <ModalRecepcionBienesServicios
              guardarInformeTrimestral={this.guardarInformeTrimestral}
              trimestres={this.state.trimestresEntity}
              seleccionarTrimestre={this.seleccionarTrimestre}
              modalInformeTrimestralStatus={this.state.modalInformeTrimestralStatus}
              cargarInformeTrimestral={this.cargarInformeTrimestral}
              activarModalInformeTrimestral={this.activarModalInformeTrimestral}
            ></ModalRecepcionBienesServicios>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  requerimientosAdmin: state.requerimientos.requerimientosAdmin,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
  usuarioId: state.usuarios.usuario.id,
});

const mapDispatchToProps = (dispatch) => ({
  getRequerimientosAdmin: (estadoId) => dispatch(getRequerimientosAdmin(estadoId)),
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
  getUsuarioEntity: (id) => dispatch(getUsuarioEntity(id)),
  deleteRequerimientoBase: (requerimientoId) => dispatch(deleteRequerimientoBase(requerimientoId))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(ListaDepartamentoAdministrativo, [
    "Administrador general",
    "Responsables de compras",
    "Encargado Presupuesto",
    "Analista Presupuesto"
  ])
);
