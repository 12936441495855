import {
  UNIDADES_MEDIDA_SUCCEEDED,
  LOADING_UNIDADES_MEDIDA,
  MOSTRAR_UNIDADES_MEDIDA
} from "./../types";

import initialState from "./../initial-state";

const { unidadesMedida } = initialState;

const UnidadesMedidaReducer = (state = unidadesMedida, action) => {
  switch (action.type) {
    case LOADING_UNIDADES_MEDIDA:
      return {
        ...state,
        isLoading: true
      };
    case UNIDADES_MEDIDA_SUCCEEDED:
      return {
        ...state,
        isLoading: false
      };
    case MOSTRAR_UNIDADES_MEDIDA:
      return {
        ...state,
        unidadesMedida: action.payload
      };
    default:
      return state;
  }
};

export default UnidadesMedidaReducer;
