import React from "react";
import { Link } from "react-router-dom";
import { deleteProductoNopoa } from "../../redux/actions/productos-actions";
import { MOSTRAR_PRODUCTO_NO_POA } from "../../redux/types";
import swal from "sweetalert";
import { connect } from "react-redux";
import numbro from "numbro";
import { UncontrolledTooltip } from "reactstrap";

let activarNota= false;
let notas = "";
const ProductoNoPoa = (props) => {
  const eliminarProductoNopoa = (e) => {
    e.preventDefault();
    const { productoNoPoa } = props;
    let confirmed = window.confirm("¿Está seguro de eliminar el producto?");
    if (confirmed) {
      props.deleteProductoNopoa(productoNoPoa);
      window.location.reload();
    }
  };

  const truncateString = (str) => {
    let num = 30;

    return str && str.length > num ? str.slice(0, num) + "..." : str;
  };

  const {
    productoNoPoaId,
    descripcion,
    meta,
    indicador,
    ponderacionArea,
    actividadesNopoa,
    devuelto,
    observacion,
    documento
  } = props.productoNoPoa;

  return (
    <tr>
      <td title={descripcion}>{truncateString(descripcion)}</td>
      <td>{meta}</td>
      <td title={indicador}>{truncateString(indicador)}</td>
      <td>{'RD$ '+numbro( actividadesNopoa.reduce((accumulator, obj)=>{
       return accumulator + (obj.presupuestoNopoa.reduce((value, objPresupuesto)=>{ return value +(objPresupuesto.valor*objPresupuesto.cantidad*objPresupuesto.frecuencia)},0))
      },0)).format({thousandSeparated:true}) }</td>
      {/* <td>{actividades.length}</td> */}
      <td>{ devuelto == 1 ? truncateString(observacion) : 'No' }</td>
      <td>
        { documento ?
        <a 
        className="p-2" 
        onClick={async () => {
          props.descargarDocumentoNoPoa(productoNoPoaId)
        }}
        >
        <div>
          <i 
          className="fas fa-download text-primary"
          disabled={true}
          id="TooltipDescargarDocumentoNoPoa"
          >
          </i>
          <UncontrolledTooltip
            placement="down"
            target="TooltipDescargarDocumentoNoPoa"
          >
            {documento}
          </UncontrolledTooltip>
                  </div>
        </a>
         : <a className="p-2">
         <i className="fas fa-download text-muted"></i>
         </a>
        }
      </td>
      <td className="d-flex flex-row">
     {activarNota && (
          <button data-tip={notas} data-event='click' className="btn btn-sm btn-danger">
          <i className="ni ni-chat-round"></i>
          </button>
      )}
        <Link
          to={{
            pathname: `/admin/productoNoPoa/detalle/${productoNoPoaId}`,
            state: `/admin/productoNoPoa/detalle/:productoNoPoaId`,
          }}
          className="btn btn-primary btn-sm"
        >
          Detalle
        </Link>
        {
          (
            props.productoNoPoa.nivelAprobacion &&  props.productoNoPoa.nivelAprobacion.nivelAprobacionId === props.user.nivelAprobacion.nivelAprobacionId
          ) ? (
            <>
                        <button
                     className="btn btn-info btn-sm "
                     onClick={(e) => {
                       e.preventDefault();
                         props.aprobarProducto(props.productoNoPoa);
                     }}
                   >
                     Enviar
                   </button>
                   
                   
                   <Link
                   to={{
                     pathname: `/admin/productoNoPoa/editar/${productoNoPoaId}`,
                     state: "/admin/productoNoPoa/editar/:productoId",
                   }}
                   className="btn btn-success btn-sm"
                 >
                   Editar
                 </Link>
                 <button
                   onClick={(e) => {
                     eliminarProductoNopoa(e);
                   }}
                   className="btn btn-danger btn-sm"
                 >
                   Eliminar
                   <span className="fa fa-remove"></span>
                 </button>
            </>
          ) : (
            <>
            <button
              onClick={(e) =>{ e.preventDefault()
                swal("Productos","El producto fue enviado para aprobación","error");
              } 
              
              }
              className="btn btn-info btn-sm opacity"
              title="El producto fue enviado para aprobación"
            >
              Enviar
            </button>
                          <button
                          onClick={(e) =>{ e.preventDefault()
                            swal("Productos","El producto fue enviado para aprobación","error");
                          } 
                          
                          }
                          className="btn btn-success btn-sm opacity"
                          title="El producto fue enviado para aprobación"
                        >
                          Editar
                        </button>
                         <button
                              onClick={(e) =>{ e.preventDefault()
                                swal("Productos","El producto fue enviado para aprobación","error");
                              } 
                              
                              }
                              className="btn btn-danger btn-sm opacity"
                              title="El producto fue enviado para aprobación"
                         >
                           Eliminar
                           <span className="fa fa-remove"></span>
                         </button>
                         </>
          )}
      </td>
    </tr>
  );
};

export default connect(null, { deleteProductoNopoa })(ProductoNoPoa);
