import React, { Component } from "react";
import { connect } from "react-redux";
import { getActividades } from "./../../../redux/actions/actividades-actions";
import AdminActividad from "./../actividades/actividad.jsx";
import { Authorization } from "./../../../utils/Authorization";
import { ModalActividad } from "./../../utils/ModalActividad.jsx";
import {
  Table
} from "reactstrap";
import Loading from "./../../utils/Loading";

class AdminListaActividades extends Component {
  constructor(props) {
    super(props);
    this.state = { modalActividad: false, actividad: {}, indexNota: "" };
  }
  componentDidMount() {
    ////console.log(this.props.pr);
    this.props.getActividades(this.props.productoId);
  }

  activarModalActividad = (actividad, indexNota) => {
    this.setState(
      {
        modalActividad: !this.state.modalActividad,
        actividad,
        indexNota
      }
      // () => //console.log(this.state.indexNota)
    );
  };

  render() {
    const { isLoading, actividades } = this.props;
    return (
      <React.Fragment>
        <h3 className="mt-3">Listado de Actividades</h3>
        {isLoading && actividades ? (
          <Loading></Loading>
        ) : !isLoading && actividades.length > 0 ? (
          <Table
            style={{ backgroundColor: "#fff", color: "black" }}
            responsive
            className="table-striped"
          >
            <thead>
              <tr className="text-left">
                <th>Actividad</th>
                <th>ponderación</th>
                <th>Medio Verificación</th>
                <th>t1</th>
                <th>t2</th>
                <th>t3</th>
                <th>t4</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody className="text-left">
              {actividades.map(actividad => (
                <AdminActividad
                  key={actividad.actividadId}
                  actividad={actividad}
                  activarModalActividad={this.activarModalActividad}
                  notaActividades={this.props.notaActividades}
                  EliminarNotaActividad={this.props.EliminarNotaActividad}
                ></AdminActividad>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="row d-flex justify-content-center mb-3">
            <h5>No tiene actividades agregadas</h5>
          </div>
        )}
        <ModalActividad
          modalActividad={this.state.modalActividad}
          activarModalActividad={this.activarModalActividad}
          actividad={this.state.actividad}
          agregarNotaActividad={this.props.agregarNotaActividad}
          notaActividad={this.props.notaActividades[this.state.indexNota]}
        ></ModalActividad>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  actividades: state.actividades.actividades,
  isLoading: state.actividades.isLoading,
  user: state.auth.signed
});

const mapDispatchToProps = dispatch => ({
  getActividades: productoId => dispatch(getActividades(productoId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(AdminListaActividades, [
    "Administrador general",
    "Analista del POA",
    "Gestor del POA",
    "Responsables de areas",
    "MAE"
  ])
);
