import React, { Component } from "react";
import { connect } from "react-redux";
import { getActividadesNopoa } from "./../../../redux/actions/actividades-actions";
import AdminActividadNoPoa from "./../actividadesNoPoa/actividad.jsx";
import { Authorization } from "./../../../utils/Authorization";
import { ModalActividad } from "./../../utils/ModalActividad.jsx";
import {
  Table
} from "reactstrap";
import Loading from "./../../utils/Loading";

class AdminListaActividadesNoPoa extends Component {
  constructor(props) {
    super(props);
    this.state = { modalActividad: false, actividad: {}, indexNota: "" };
  }
  componentDidMount() {
    this.props.getActividadesNopoa(this.props.productoNoPoaId);
  }

  activarModalActividad = (actividad, indexNota) => {
    this.setState(
      {
        modalActividad: !this.state.modalActividad,
        actividad,
        indexNota
      }
    );
  };

  render() {
    const { isLoading, actividadesNopoa } = this.props;
    return (
      <React.Fragment>
        <h3 className="mt-3">Listado de Actividades No POA</h3>
        {isLoading && actividadesNopoa ? (
          <Loading></Loading>
        ) : !isLoading && actividadesNopoa.length > 0 ? (
          <Table
            style={{ backgroundColor: "#fff", color: "black" }}
            responsive
            className="table-striped"
          >
            <thead>
              <tr className="text-left">
                <th>Actividad</th>
                <th>Presupuesto</th>
                <th>t1</th>
                <th>t2</th>
                <th>t3</th>
                <th>t4</th>
              </tr>
            </thead>
            <tbody className="text-left">
              {actividadesNopoa.map(actividad => (
                <AdminActividadNoPoa
                  key={actividad.actividadNoPoaId}
                  actividad={actividad}
                  activarModalActividad={this.activarModalActividad}
                ></AdminActividadNoPoa>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="row d-flex justify-content-center mb-3">
            <h5>No tiene actividades agregadas</h5>
          </div>
        )}
        <ModalActividad
          modalActividad={this.state.modalActividad}
          activarModalActividad={this.activarModalActividad}
          actividad={this.state.actividad}
        ></ModalActividad>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  actividadesNopoa: state.actividadesNopoa.actividadesNopoa,
  isLoading: state.actividadesNopoa.isLoading,
  user: state.auth.signed
});

const mapDispatchToProps = dispatch => ({
  getActividadesNopoa: productoId => dispatch(getActividadesNopoa(productoId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(AdminListaActividadesNoPoa, [
    "Administrador general",
    "Gestor del POA",
    "Responsables de areas",
    "MAE"
  ])
);
