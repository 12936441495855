import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import swal from "sweetalert";

export const ModalCerficacionContrato = (props) => {

    const toggle = () =>
      props.activarModalCertificacionContrato(props.modalCertificacionContrato);
  
    const { requerimientoId, modalCertificacionContratoStatus } = props;
    return (
      <Modal isOpen={modalCertificacionContratoStatus} toggle={toggle} size="lg" backdrop="static">
        <ModalHeader className="block-example border-bottom " toggle={toggle}>
          ANEXAR CERTIFICACIÓN DE CONTRATO DEL SISTEMA TREE
        </ModalHeader>
  
        <ModalBody>
            <div className="row">
              <div className="form-group col-md-8 m-auto">
                <label>No. Requerimiento</label>
                <input 
                    id="requerimiento"
                    className="form-control" 
                    value={props.requerimientoSeleccionado}
                    disabled
                  />
              </div>
              <div className="form-group col-md-8 m-auto pt-4">
                    <label>Documento (PDF)</label>
                    <input
                      id="inputContratopdf"
                      className="form-control text-dark"
                      type="file" accept=".pdf"
                      name="file[]"
                      required
                      // value={props.contratoSeleccionado}
                      onChange= {
                        (e) => {
                            e.preventDefault();
                            ////////////////////////////////////////////////////////////
                            var fileInput = document.getElementById('inputContratopdf');
                            var filePath = fileInput.value;
                            var allowedExtensions = /(\.pdf|\.PDF)$/i;
                            if (!allowedExtensions.exec(filePath)) {
                              swal("Error de tipo de archivo","Debe cargar archivos en formato PDF","error");
                                fileInput.value = '';
                                return false;
                            } 
                            ///////////////////////////////////////////////
                            props.cargarCertificacionContrato(e);
                        }
                      }
                    />
              </div>
           </div>
         </ModalBody>
        <ModalFooter>
          <div className="row">
            <div>
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  props.activarModalCertificacionContrato(props.modalCertificacionContrato);
                }}
              >
              Cancelar
              </button>
            </div>
            <div className="ml-3">
              <button
                className="btn btn-primary"
                onClick={(e)=>{
                    e.preventDefault();
                    props.guardarCertificacionContrato(requerimientoId);
                }}
              >
              Guardar
            </button>
            </div>
          </div>
          
        </ModalFooter>
      </Modal>
    );
};