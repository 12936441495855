import { BaseMask } from 'react-masked-text';

// interface DollarMoneyMaskSettings {
//   precision: number;
//   separator: string;
//   delimiter: string;
//   unit: string;
//   suffixUnit: string;
//   zeroCents: boolean;
// }

const DOLLAR_MONEY_MASK_SETTINGS= {
  precision: 2,
  separator: '.',
  delimiter: ',',
  unit: 'RD$: ',
  suffixUnit: '',
  zeroCents: false,
};

class DollarMoneyMask extends BaseMask {
  static getType() {
    return 'dollar-money';
  }

  getValue(value, settings, oldValue) {
    const mergedSettings = super.mergeSettings(DOLLAR_MONEY_MASK_SETTINGS, settings);

    if (mergedSettings.suffixUnit && oldValue && value) {
      if (value.length === oldValue.length - 1) {
        const cleared = this.removeNotNumbers(value);
        value = cleared.substring(0, cleared.length - 1);
      }
    }

    const masked = this.getVMasker().toMoney(value, mergedSettings);
    return masked;
  }

  getRawValue(maskedValue, settings) {
    const mergedSettings = super.mergeSettings(DOLLAR_MONEY_MASK_SETTINGS, settings);
    let normalized = super.removeNotNumbers(maskedValue);

    const dotPosition = normalized.length - mergedSettings.precision;
    normalized = this._insert(normalized, dotPosition, '.');

    return Number(normalized);
  }

  validate() {
    return true;
  }

  _insert(text, index, stringToInsert) {
    if (index > 0) {
      return text.substring(0, index) + stringToInsert + text.substring(index, text.length);
    } else {
      return stringToInsert + text;
    }
  }
}

export const dollarMoneyMask = () => new DollarMoneyMask();