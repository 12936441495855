import {
  MOSTRAR_USUARIOS,
  CREAR_USUARIO,
  USUARIOS_FAILED,
  USUARIOS_SUCCEEDED,
  EDITAR_USUARIO,
  MOSTRAR_USUARIO,
  LOADING_USUARIOS,
  LOADING_USUARIO,
  MOSTRAR_USUARIOS_ANALISTAS_ASIGNADOS
} from "./../types";

import initialState from "./../initial-state";

const { usuarios } = initialState;
const UsuariosReducer = (state = usuarios, action) => {
  switch (action.type) {
    case MOSTRAR_USUARIO:
      return {
        ...state,
        usuario: action.payload
      };
    case MOSTRAR_USUARIOS:
      return {
        ...state,
        usuarios: action.payload
      };
    case CREAR_USUARIO:
      return {
        ...state,
        usuarios: [...state, action.payload]
      };
    case EDITAR_USUARIO:
      return {
        ...state,
        usuarios: state.usuarios.map(usuario =>
          usuario.email === action.payload.email
            ? (usuario = action.payload)
            : usuario
        )
      };
    case LOADING_USUARIOS:
      return {
        ...state,
        isLoading: true
      };
    case LOADING_USUARIO:
      return {
        ...state,
        isLoadingUsuario: true
      };
    case USUARIOS_SUCCEEDED:
    case USUARIOS_FAILED:
      return {
        ...state,
        isLoading: false,
        isLoadingUsuario: false
      };
    case MOSTRAR_USUARIOS_ANALISTAS_ASIGNADOS:
      return {
        ...state,
        usuariosAnalistasAsignados: action.payload
      };
    default:
      return state;
  }
};

export default UsuariosReducer;
