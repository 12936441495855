import React from "react";
import { Table } from "reactstrap";
import numbro from "numbro";

const ListaInsumos = props => {
  // console.log("props.insumos",props.insumos)
  return (
    <React.Fragment>
      <Table
        style={{ backgroundColor: "#fff", color: "black" }}
        responsive
        className="table-striped"
      >
        <thead>
          <tr>
            <th>Insumo</th>
            <th>Subclase</th>
            <th>Cuenta</th>
            <th>Valor</th>
            <th>Accciones</th>
          </tr>
        </thead>
        <tbody>
          {props.insumos !== null &&
            props.insumos.length > 0 &&
            props.insumos.map((insumo, index) => (
              <React.Fragment key={index}>
                <tr key={index} className="text-dark">
                  <td>{insumo.insumo}</td>
                  <td>{insumo.subclase}</td>
                  <td>{insumo.cuenta}</td>
                  <td>{'RD$ '+numbro(insumo.valor * insumo.frecuencia * insumo.cantidad).format({thousandSeparated: true})}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-warning"
                      onClick={e => {
                        e.preventDefault();
                        props.insumoEditar(insumo);
                      }}
                    >
                      Editar
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={e => props.eliminarPresupuesto(e, insumo)}
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default ListaInsumos;
