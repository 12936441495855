import {
    MOSTRAR_TIPO_ASIGNACION,
    MOSTRAR_TIPOS_ASIGNACIONES,
    TIPOS_ASIGNACIONES_FAILED,
    LOADING_TIPOS_ASIGNACIONES,
    TIPOS_ASIGNACIONES_SUCCESS,
    CREATE_TIPOS_ASIGNACIONES,
    DELETE_TIPOS_ASIGNACIONES,
    UPDATE_TIPOS_ASIGNACIONES
  } from "../types";
  import initialState from "../initial-state.js";
  const { tiposAsignaciones } = initialState;
  
  const TiposAsignacionesReducer = (state = tiposAsignaciones, action) => {
    switch (action.type) {
      case MOSTRAR_TIPOS_ASIGNACIONES:
        // console.log("action.payload",action.payload)
        return {
          ...state,
          tiposAsignaciones: action.payload
        };
      case CREATE_TIPOS_ASIGNACIONES:
        // console.log("action.payload",action.payload)
        return {
          ...state,
          tiposAsignaciones: [...state.tiposAsignaciones,action.payload ] 
        };
        case UPDATE_TIPOS_ASIGNACIONES:
          // console.log("action.payload",action.payload)
          return {
            ...state,
            tiposAsignaciones: [...state.tiposAsignaciones.filter(x=> x.tipoAsignacionId != action.payload.tipoAsignacionId),action.payload ] 
          };
      case DELETE_TIPOS_ASIGNACIONES:
        // console.log("action.payload",action.payload)
        return {
          ...state,
          tiposAsignaciones: action.payload
        };
      case MOSTRAR_TIPO_ASIGNACION:
        // console.log("action.payload",action.payload)
        return {
          ...state,
          tipoAsignacion: action.payload
        };
      case LOADING_TIPOS_ASIGNACIONES:
        return {
          ...state,
          isLoading: true
        };
      case TIPOS_ASIGNACIONES_SUCCESS:
      case TIPOS_ASIGNACIONES_FAILED:
        return {
          ...state,
          isLoading: false
        };
      default:
        return state;
    }
  };
  
  export default TiposAsignacionesReducer;
  