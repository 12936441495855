import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

export const ModalEditarAsignacionItemsProveedor = (props) => {

    const toggle = () => props.activarModalEditarAsignacionItemsProveedor(props.modalEditarAsignacionItemsProveedorStatus);
  
    const { BorrarArticulos, proveedorSeleccionado, modalEditarAsignacionItemsProveedorStatus, actividadesArticuloSeguimiento, proveedores, deleteArticulos, precioAdjudicacionRef, obtenerPreciosAdjudicados, proveedoresInsumosAdjudicados } = props;

    const proveedorSelectEditar = proveedores.find(item => item.proveedorId === proveedorSeleccionado);

    return (
      <Modal isOpen={modalEditarAsignacionItemsProveedorStatus} toggle={toggle} size="lg" backdrop="static">
        <ModalHeader className="block-example border-bottom " toggle={toggle}>
          DESASIGNAR ITEMS DEL PROVEEDOR { undefined !== proveedorSelectEditar ? proveedorSelectEditar.nombre : ''}
        </ModalHeader>
        <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <Table style={{ tableLayout: 'fixed' }}>
                    <thead>
                      <tr>
                        <th>Sub-clase</th>
                        <th>Nombre</th>
                        <th>Precio de referencia</th>
                        <th>Precio de adjudicación</th>
                      </tr>
                    </thead>
                    <tbody>
                    { actividadesArticuloSeguimiento && actividadesArticuloSeguimiento
                    .filter(item => proveedoresInsumosAdjudicados.some(adjudicado => adjudicado.articulo.id === item.articuloId && adjudicado.proveedor.proveedorId === proveedorSeleccionado))
                    .map((items, index) => {
                        return (
                          <tr key={index} >
                          <td>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                id={`articuloId-${items.articuloId}`}
                                type="checkbox"
                                onChange={deleteArticulos}
                                value={items.articuloId}
                                defaultChecked={true}
                              />
                              <label className="custom-control-label"  style={{ paddingTop: '0.2rem' }} htmlFor={`articuloId-${items.articuloId}`}>
                                {items.presupuesto.subclase}
                              </label>
                            </div>
                          </td>
                          <td style={{ whiteSpace: 'unset' }}>{ items.nombreArticulo }</td>
                          <td>
                            <input 
                              className="form-control form-control-sm"
                              type="text"
                              id={`precioReferencia-${items.articuloId}`}
                              disabled
                              value = { items.precioReferencia + ' RD$' }
                            />
                          </td>
                          <td className="d-flex flex-row">
                            <input  
                              className="form-control form-control-sm"
                              type="number"
                              id={`precioAdjudicacion-${items.articuloId}`}
                              ref={precioAdjudicacionRef}
                              disabled
                              value = { items.precioAdjudicacion !== null ? items.precioAdjudicacion : 0 }
                              onChange={obtenerPreciosAdjudicados}
                            />
                            <span className="p-2">RD$</span>
                          </td>
                        </tr> 
                        )
                      })}
                    </tbody>
                </Table>
              </div>
            </div>
        </ModalBody>
        <ModalFooter>
          <div className="row">
            <div className="">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  props.activarModalEditarAsignacionItemsProveedor(props.modalEditarAsignacionItemsProveedor);
                }}
              >
              Cancelar
              </button>
            </div>
            <div className="ml-3">
              <button
                className="btn btn-primary"
                onClick={(e) => BorrarArticulos(e) }
              >
              Guardar
            </button>
            </div>
          </div>
          
        </ModalFooter>
      </Modal>
    );
};
