import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import Loading from "./../../utils/Loading";
import { Authorization } from "./../../../utils/Authorization";
import { getAreasOrganizacionales } from "./../../../redux/actions/areas-actions";
import {
  mostrarPoas,
  getPoaActivo
} from "./../../../redux/actions/poa-actions";

import {
  getPonderacionAreas,
  createPonderacionAreas,
  editPonderacionAreas
} from "./../../../redux/actions/ponderacion-areas-action";
import {
  Table,
  Container
} from "reactstrap";

class PonderacionAreas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ponderacionGeneral: 0,
      loadingPonderacion: true,
      loadingChange: true
    };
  }
  anoSelectedRef = React.createRef();
  componentDidMount() {
    this.setState({ loadingPonderacion: false });
    let total = 0;
    this.props.mostrarPoas();
    this.props.getAreasOrganizacionales();
    this.props.getPoaActivo().then(resultado => {
      this.props.getPonderacionAreas(this.props.poaActivo.poaId).then(() => {
        this.props.ponderacionAreas &&
          this.props.ponderacionAreas.length > 0 &&
          this.props.ponderacionAreas.map(
            ponderacion => (total += ponderacion.ponderacion)
          );
        this.setState({
          ponderacionGeneral: total,
          loadingPonderacion: true
        });
      });
    });
  }

  buscarPonderacion = areaOrganizacionalId => {
    if (this.props.ponderacionAreas.length > 0) {
      for (let i = 0; i < this.props.ponderacionAreas.length; i++) {
        if (
          areaOrganizacionalId ===
          this.props.ponderacionAreas[i].areaOrganizacionalId
        ) {
          return this.props.ponderacionAreas[i].ponderacion;
        }
      }
    } else {
      return;
    }
  };
  editarPonderacionAreas = poaId => {
    let ponderacionesAreas = [];
    for (let i = 0; i < this.props.ponderacionAreas.length; i++) {
      let ponderacionGeneral = {
        ponderacionAreaPoaId: this.props.ponderacionAreas[i]
          .ponderacionAreaPoaId,
        areaOrganizacionalId: this.props.ponderacionAreas[i].areaOrganizacional
          .areaOrganizacionalId,
        poaId: this.props.ponderacionAreas[i].poa.poaId,
        ponderacion:
          document.getElementById(this.props.areasOrganizacionales[i].nombre)
            .value !== ""
            ? document.getElementById(
                this.props.areasOrganizacionales[i].nombre
              ).value
            : 0
      };

      ponderacionesAreas.push(ponderacionGeneral);
    }
    let ponderacionGeneral = {
      ponderacionAreas: ponderacionesAreas
    };
    this.props.editPonderacionAreas(ponderacionGeneral);
  };
  guardarPonderacionAreas = poaId => {
    let ponderacionesAreas = [];
    for (let i = 0; i < this.props.areasOrganizacionales.length; i++) {
      let ponderacionGeneral = {
        areaOrganizacionalId: this.props.areasOrganizacionales[i]
          .areaOrganizacionalId,
        poaId: poaId,
        ponderacion:
          document.getElementById(this.props.areasOrganizacionales[i].nombre)
            .value !== ""
            ? document.getElementById(
                this.props.areasOrganizacionales[i].nombre
              ).value
            : 0
      };

      ponderacionesAreas.push(ponderacionGeneral);
    }

    let PonderacionGeneral = {
      ponderacionAreas: ponderacionesAreas
    };
    this.props.createPonderacionAreas(PonderacionGeneral);
  };

  calcularPonderacionGeneral = () => {
    let ponderacionGeneral = 0;
    this.props.areasOrganizacionales.map(
      areas =>
        (ponderacionGeneral += parseFloat(
          document.getElementById(areas.nombre).value !== ""
            ? document.getElementById(areas.nombre).value
            : 0
        ))
    );
    this.setState({
      ponderacionGeneral
    });
    if (ponderacionGeneral > 100) {
      // alert("La ponderación general excede el 100% permitido");
      swal("Ponderación Áreas", "La ponderación general excede el 100% permitido","error");

    }
  };

  validarOpcionesGuardar = () => {
    if (
      (this.state.loadingPonderacion &&
        this.anoSelectedRef.current != null &&
        parseInt(this.props.poaActivo.poaId) !==
          parseInt(this.anoSelectedRef.current.value)) ||
      this.props.poaActivo.nivelAprobacionId !== 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          <div className="pb-6 pt-5 pt-md-7">
            {/* <h1 className="mt-3">Ponderación Áreas </h1> */}

            {this.props.poas &&
            this.props.poas.length > 0 &&
            this.state.loadingPonderacion ? (
              <div className="container bg-white border">
                <div className="form-group col-md-6 mt-3">
                  <label>Año del Poa</label>
                  <select
                    className="form-control"
                    defaultValue={this.props.poaActivo.poaId}
                    id="anoSelected"
                    ref={this.anoSelectedRef}
                    onChange={e => {
                      e.preventDefault();
                      this.setState({ loadingChange: false });
                      let total = 0;
                      this.props
                        .getPonderacionAreas(
                          document.getElementById("anoSelected").value
                        )
                        .then(resultado => {
                          this.props.ponderacionAreas.length > 0
                            ? this.props.ponderacionAreas.map(
                                ponderacion =>
                                  (total += ponderacion.ponderacion)
                              )
                            : this.props.areasOrganizacionales.map(area =>
                                this.buscarPonderacion(
                                  area.areaOrganizacionalId
                                )
                              );
                          this.setState({
                            ponderacionGeneral: total,
                            loadingChange: true
                          });
                        });
                    }}
                  >
                    {this.props.poas.map(poa => (
                      <option key={poa.poaId} value={poa.poaId}>
                        {poa.ano}
                      </option>
                    ))}
                  </select>
                </div>
                {this.state.loadingChange ? (
                  <React.Fragment>
                    <div className="col-md-12">
                      <Table
                        style={{ backgroundColor: "#fff", color: "black" }}
                        responsive
                        className="table-striped"
                      >
                        <thead>
                          <tr>
                            <th>Área</th>
                            <th>Ponderación</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.areasOrganizacionales.map(areas => (
                            <tr key={areas.areaOrganizacionalId}>
                              <td>{areas.nombre}</td>
                              <td>
                                <input
                                  type="number"
                                  id={areas.nombre}
                                  readOnly={this.validarOpcionesGuardar()}
                                  defaultValue={this.buscarPonderacion(
                                    areas.areaOrganizacionalId
                                  )}
                                  className="form-control form-control-sm"
                                  onKeyUp={() =>
                                    this.calcularPonderacionGeneral()
                                  }
                                ></input>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Total</td>
                            <td>
                              {Math.round(this.state.ponderacionGeneral)}%
                            </td>
                          </tr>
                        </tfoot>
                      </Table>
                    </div>
                    <div className="col-md-12 mb-3">
                      {this.props.ponderacionAreas &&
                      this.props.ponderacionAreas.length > 0 ? (
                        <button
                          className="btn btn-info"
                          onClick={() => this.editarPonderacionAreas()}
                          disabled={this.validarOpcionesGuardar()}
                        >
                          Editar
                        </button>
                      ) : (
                        <button
                          className="btn btn-info"
                          disabled={this.validarOpcionesGuardar()}
                          onClick={e => {
                            e.preventDefault();
                            this.guardarPonderacionAreas(
                              document.getElementById("anoSelected").value
                            );
                          }}
                        >
                          Guardar
                        </button>
                      )}
                    </div>
                  </React.Fragment>
                ) : (
                  <Loading></Loading>
                )}
              </div>
            ) : (
              <div className="container bg-white border py-3">
              <Loading></Loading>
              </div>
            )}
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const stateMapToProps = state => ({
  areasOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
  poaActivo: state.poas.poaActivo,
  ponderacionAreas: state.ponderacionAreas.ponderacionAreas,
  poas: state.poas.poas,
  user: state.auth.signed
});

const mapDispatchToProps = dispatch => ({
  createPonderacionAreas: ponderacionAreas =>
    dispatch(createPonderacionAreas(ponderacionAreas)),
  editPonderacionAreas: ponderacionAreas =>
    dispatch(editPonderacionAreas(ponderacionAreas)),
  getPonderacionAreas: poaId => dispatch(getPonderacionAreas(poaId)),
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
  mostrarPoas: () => dispatch(mostrarPoas()),
  getPoaActivo: () => dispatch(getPoaActivo())
});
export default connect(stateMapToProps, mapDispatchToProps)( Authorization(PonderacionAreas, [
  "Administrador general",
  "Analista del POA",
  "Gestor del POA",
  "MAE"
]));
