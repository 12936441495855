import React, { Component } from "react";
import { connect } from "react-redux";
import { mostrarPoas, crearPoa } from "./../../redux/actions/poa-actions";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";
import { DateTimePicker } from "react-widgets";
import "react-widgets/dist/css/react-widgets.css";
import { Authorization } from "./../../utils/Authorization";
import Loading from "../utils/Loading";
import swal from "sweetalert";

import {
  Container
} from "reactstrap";
import moment from "moment";

class CrearPoa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poa: {},
      ano: "",
      fechaCierre: new Date(new Date().getFullYear() + 2, 0, 1),
      nombre: "",
      divisa: "",
      loading: false,
      loadingButton: false,
    };
  }
  nombreRef = React.createRef();
  divisaRef = React.createRef();

  crearPoa = async (e) => {
    e.preventDefault();
    let nuevoPoa = {
      nombre: this.nombreRef.current.value,
      ano: Moment(this.state.ano).year(),
      fechaCierre: this.state.fechaCierre,
      divisa:
        this.divisaRef.current !== null && this.divisaRef.current !== undefined
          ? this.divisaRef.current.value
          : 0.0,
    };

    this.setState({
      poa: nuevoPoa,
      loadingButton: true,
      nombre: nuevoPoa.nombre,
      divisa: nuevoPoa.divisa,
    });

    if (await this.validarNivelPoas()) {
      if (nuevoPoa.nombre.length > 0 && nuevoPoa.ano) {
        this.props.crearPoa(nuevoPoa);

        this.setState({ loading: true });
        //redirecciona a la raiz
        setTimeout(() => {
          this.setState({
            loading: false,
            loadingButton: false,
          });
          this.props.history.push("/admin/index");
        }, 3000);
      } else {
        swal("Poa","Debe ingresar los valores a crear","error");
      }
    }
  };

  validarNivelPoas = async () => {
    await this.props.mostrarPoas();

    let resultado = this.props.poas.filter(
      (poa) => poa.nivelAprobacion.nivelAprobacionId === 1
    );
    let anoPoa = this.props.poas.filter(
      (poa) => poa.ano === moment(this.state.ano).year()
    );

    if (Object.keys(resultado).length > 0) {
      this.setState({
        loadingButton: false,
      });

      swal("Poa",`El poa ${resultado[0].ano} se encuentra en estado de elaboración debe ser aprobado antes de poder abrir un nuevo POA`,"error");
      return false;
    }

    if (Object.keys(anoPoa).length > 0) {
      this.setState({
        loadingButton: false,
      });

      swal("Poa",`El poa ${anoPoa[0].ano} ya existe, favor validar el año del POA`,"error");

      return false;
    }

    return true;
  };

  render() {
    const { loading } = this.state;
    Moment.locale("en");
    momentLocalizer();
    let anoRef = (e) => {
      this.setState({
        ano: e,
      });
    };
    let fechaCierreRef = (e) => this.setState({ fechaCierre: e });
    let datePickerAno = (
      <DateTimePicker
        footer={false}
        max={new Date(new Date().getFullYear() + 1, 0, 1)}
        time={false}
        defaultView="decade"
        onChange={anoRef}
        ref={this.anoRef}
      />
    );
    let datePickerFechaCierre = (
      <DateTimePicker
        footer={false}
        min={new Date(new Date().getFullYear() + 2, 0, 1)}
        defaultValue={new Date(new Date().getFullYear() + 2, 0, 1)}
        time={false}
        onChange={fechaCierreRef}
        ref={this.fechaCierreRef}
      />
    );

    return (
      <>
        {!loading ? (
          <div className="pb-6 pt-5 pt-md-7">
            <Container>
              <div className="container bg-white border">
                <form className="p-3" onSubmit={this.crearPoa}>
                  <div className="form-group">
                    <label htmlFor="descripción">Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      id="descripción"
                      placeholder="Nombre de la meta del año"
                      ref={this.nombreRef}
                    />
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6 ">
                      <label htmlFor="ano">Año</label>
                      <DateTimePicker
                        footer={false}
                        max={new Date(new Date().getFullYear() + 1, 0, 1)}
                        time={false}
                        defaultView="decade"
                        ref={this.anoRef}
                        onChange={anoRef}
                      />
                    </div>
                    <div className="form-group col-md-6 ">
                      <label htmlFor="fechaCierre">Fecha Cierre</label>
                      <DateTimePicker
                        footer={false}
                        defaultValue={
                          new Date(new Date().getFullYear() + 2, 0, 1)
                        }
                        time={false}
                        onChange={fechaCierreRef}
                        ref={this.fechaCierreRef}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6 ">
                      <label htmlFor="diivisa">Divisa</label>
                      <input
                        type="text"
                        className="form-control text-dark"
                        id="divisa"
                        placeholder="Divisa del POA"
                        defaultValue={
                          Object.keys(this.state.divisa) > 0
                            ? this.state.divisa
                            : ""
                        }
                        ref={this.divisaRef}
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    disabled={this.state.loadingButton}
                    className="btn btn-primary"
                  >
                    Guardar
                  </button>
                </form>
              </div>
            </Container>
          </div>
        ) : (
          <Loading></Loading>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    poas: state.poas.poas,
    user: state.auth.signed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  crearPoa: (data) => dispatch(crearPoa(data)),
  mostrarPoas: () => dispatch(mostrarPoas()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps //{ Login }
)(Authorization(CrearPoa, ["Administrador general", "Gestor del POA", "MAE"]));
