import React, { Component } from "react";
import {
  Table
} from "reactstrap";
import Moment from "react-moment";

const AreaOrganziacional = (props) => {
  const { areaOrganizacional, poaMonitoreo } = props.areaPoaMonitoreo;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-8">
          <h3>{areaOrganizacional.nombre}</h3>
        </div>
        <div className="col-md-4">
          <button
            className="btn btn-success btn-sm float-right"
            onClick={(e) => {
              e.preventDefault();
              let ano = new Date(poaMonitoreo[0].fechaFin)
              props.reporteMonitoreo(
                areaOrganizacional.areaOrganizacionalId,
                poaMonitoreo[0].poaId,
                areaOrganizacional.nombre,
                ano.getFullYear()
              );
            }}
          >
            Reporte Monitoreo
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Table className="align-items-center table-flush" responsive>
            <thead>
              <tr>
                <th>Trimestre</th>
                <th>Fecha Inicio</th>
                <th>Fecha Fin</th>
                <th>Estatus</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {poaMonitoreo.map((trimestre) => (
                <tr key={trimestre.trimestreId}>
                  <td>{trimestre.trimestreId}</td>
                  <td>
                    <Moment
                      format="DD/MM/YYYY"
                      date={trimestre.fechaInicio}
                    ></Moment>
                  </td>
                  <td>
                    <Moment
                      format="DD/MM/YYYY"
                      date={trimestre.fechaFin}
                    ></Moment>
                  </td>
                  <td>{trimestre.status === true ? "Abierto" : "Cerrado"}</td>
                  <td>
                    {trimestre.status !== true ? (
                      <button
                        className="btn btn-sm btn-success"
                        onClick={(e) => {
                          e.preventDefault();
                          props.habilitarTrimestreArea(
                            trimestre,
                            areaOrganizacional
                          );
                        }}
                      >
                        Abrir
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={(e) => {
                          e.preventDefault();
                          props.cerrarTrimestreArea(
                            trimestre,
                            areaOrganizacional
                          );
                        }}
                      >
                        Cerrar
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AreaOrganziacional;
