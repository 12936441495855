import {
    MOSTRAR_EJES_ESTRATEGICOS_GENERAL,
    MOSTRAR_ESTRATEGIAS_GENERAL,
    MOSTRAR_OBJETIVOS_ESTRATEGICOS_GENERAL
} from "../types";

import initialState from "./../initial-state";

const { planesEstrategicosgeneral } = initialState;

const PlanesEstrategicosGeneralReducer = (state = planesEstrategicosgeneral, action) => {
    switch (action.type) {
        case MOSTRAR_EJES_ESTRATEGICOS_GENERAL:
            return {
                ...state,
                ejesEstrategicos: action.payload
            };
        case MOSTRAR_OBJETIVOS_ESTRATEGICOS_GENERAL:
            return {
                ...state,
                objetivosEstrategicos: action.payload
            };
        case MOSTRAR_ESTRATEGIAS_GENERAL:
            return {...state, estrategias: action.payload };
        default:
            return state;
    }
};

export default PlanesEstrategicosGeneralReducer;
