import React, { Component } from "react";

import {
  Container,
} from "reactstrap";
import swal from "sweetalert";
import { connect } from "react-redux";
import { Authorization } from "./../../../utils/Authorization";
import {
  getAreasPoaMonitoreo,
  cerrarTodasAreasPoaMonitoreo,
  habilitarTodasAreasPoaMonitoreo,
  updateAreasPoaMonitoreo,
} from "./../../../redux/actions/area_poa_monitoreo_actions";
import AreaOrganizacional from "./areaOrganizaional";
import Loading from "./../../utils/Loading";
import { getReporteMonitoreo } from "../../../redux/actions/monitoreo-actividad-actions";
import { ModalLoading } from "./../../utils/ModalLoading";
import { getTrimestres } from "../../../redux/actions/trimestres-actions";

class AdministracionMonitoreo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaPoaMonitoreo: [],
      isloadingState: true,
      modal: false,
      mensaje: "",
    };
  }
  filtroAreaRef = new React.createRef();
  trimestreRef = new React.createRef();
  componentDidMount() {
    this.cargarDatos();
  }
  cargarDatos = async () => {
    const { poaId } = this.props.poaActivo;
    await this.props.getAreasPoaMonitoreo(poaId);
    await this.props.getTrimestres();
    this.setState({
      areaPoaMonitoreo: this.props.areaPoaMonitoreo,
      isloadingState: false,
    });
  };
  filtroAreaOrganizacional = () => {
    if (
      this.filtroAreaRef.current === null ||
      this.filtroAreaRef.current.value === null ||
      this.filtroAreaRef.current.value === ""
    ) {
      this.setState({ areaPoaMonitoreo: this.props.areaPoaMonitoreo });
    } else {
      let filtroAreaPoaMonitoreo = this.props.areaPoaMonitoreo.filter((area) =>
        area.areaOrganizacional.nombre
          .toUpperCase()
          .includes(this.filtroAreaRef.current.value.toUpperCase())
      );
      this.setState({ areaPoaMonitoreo: filtroAreaPoaMonitoreo });
    }
  };

  habilitarTrimestreTodasAreas = async () => {
    if (
      this.trimestreRef.current !== null &&
      this.trimestreRef.current.value !== "--Elegir Trimestre--"
    ) {
      let trimestre = this.trimestreRef.current.value;
      swal({
        title: `Esta seguro de querer abrir el trimestre ${trimestre}?`,
        text: "Este proceso abrirá el trimestre para todas las áreas",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then(async (willDelete) => {
        if (willDelete) {
             this.setState({ isloadingState: true });
           await this.props.habilitarTodasAreasPoaMonitoreo(
             this.props.poaActivo.poaId,
             trimestre
           );
           this.setState({
             isloadingState: false,
             areaPoaMonitoreo: this.props.areaPoaMonitoreo,
           });
        }
      });

    } else {
      swal("Administración de Trimestre", "Debe elegir el trimestre que desea habilitar","error");
    }
  };
  cerrarTrimestreTodasAreas = async () => {
    if (
      this.trimestreRef.current !== null &&
      this.trimestreRef.current.value !== "--Elegir Trimestre--"
    ) {
      let trimestre = this.trimestreRef.current.value;
      swal({
        title: `Esta seguro de querer cerrar el trimestre ${trimestre}`,
        text: "Este proceso cerrara el trimestre para todas las áreas!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then( async (willDelete) => {
        if (willDelete) {
          this.setState({ isloadingState: true });
          await this.props.cerrarTodasAreasPoaMonitoreo(
            this.props.poaActivo.poaId,
            trimestre
          );
          this.setState({
            isloadingState: false,
            areaPoaMonitoreo: this.props.areaPoaMonitoreo,
          });
        } 
      });
    } else {
      swal("Administración de Trimestre", "Debe elegir el trimestre que desea cerrar","error");
    }
  };

  habilitarTrimestreArea = async (poaMonitoreo, areaOrganizacional) => {

    swal({
      title:  `Esta seguro de querer abrir el trimestre ${poaMonitoreo.trimestreId}?`,
      text: `Este proceso abrirá el trimestre para el área ${areaOrganizacional.nombre}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then( async (willDelete) => {
      if (willDelete) {
        let obj = {
          poaMonitoreoId: poaMonitoreo.poaMonitoreoId,
          areaOrganizacionalId: areaOrganizacional.areaOrganizacionalId,
          status: true,
          trimestreId: poaMonitoreo.trimestreId,
          poaId: poaMonitoreo.poaId,
        };
        this.setState({ isloadingState: true });
        await this.props.updateAreasPoaMonitoreo(obj);
        this.setState({
          isloadingState: false,
          areaPoaMonitoreo: this.props.areaPoaMonitoreo,
        });
      }
    });
  };
  cerrarTrimestreArea = async (poaMonitoreo, areaOrganizacional) => {
    swal({
      title: `Esta seguro de querer cerrar el trimestre ${poaMonitoreo.trimestreId}?`,
      text: `Este proceso cerrara el trimestre para el área ${areaOrganizacional.nombre}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (willDelete) => {
      if (willDelete) {
        let obj = {
          poaMonitoreoId: poaMonitoreo.poaMonitoreoId,
          areaOrganizacionalId: areaOrganizacional.areaOrganizacionalId,
          status: false,
          trimestreId: poaMonitoreo.trimestreId,
          poaId: poaMonitoreo.poaId,
        };
        this.setState({ isloadingState: true });
        await this.props.updateAreasPoaMonitoreo(obj);
        this.setState({
          isloadingState: false,
          areaPoaMonitoreo: this.props.areaPoaMonitoreo,
        });
      }
    });
  };

  reporteMonitoreo = async (areaOrganizacionalId, poaId,areaOrganizacional,ano) => {
    let monitoreo = {
      areaOrganizacionalId: areaOrganizacionalId,
      poaId: poaId,
      areaOrganizacional:areaOrganizacional,
      ano,
      trimestre:0
    };

    this.setState({ modal: true, mensaje: "Elaborando Reporte de Monitoreo" });
    await this.props.getReporteMonitoreo(monitoreo);

    this.setState({ mensaje: "Reporte descargado" });

    setTimeout(() => {
      this.setState({ modal: false });
    }, 1500);
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          <div className="pb-6 pt-5 pt-md-7">
            <div className="container bg-white border">
              {this.state.isloadingState ? (
                <Loading></Loading>
              ) : (
                <React.Fragment>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mt-3 ">
                        <label htmlFor="filtro">
                          Filtro Área Organizacional
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm text-dark"
                          id="filtro"
                          placeholder="Área organizacional"
                          onKeyUp={(e) => {
                            e.preventDefault();
                            this.filtroAreaOrganizacional();
                          }}
                          ref={this.filtroAreaRef}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mt-3">
                        <label>Elegir Trimestre</label>
                        <select
                          ref={this.trimestreRef}
                          className=" form-control form-control-sm"
                        >
                          <option>--Elegir Trimestre--</option>
                          {this.props.trimestres.map((trimestre) => (
                            <option
                              key={trimestre.alias}
                              value={trimestre.alias}
                            >
                              {trimestre.alias}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className=" from-group mt-3">
                        <label>Acción</label>
                        <div>
                          <button
                            className="btn btn-sm btn-success"
                            disabled={
                              this.state.areaPoaMonitoreo[0].poaMonitoreo
                                .length < 1
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              this.habilitarTrimestreTodasAreas();
                            }}
                          >
                            Abrir todas
                          </button>
                          <button
                            className="btn btn-sm btn-danger"
                            disabled={
                              this.state.areaPoaMonitoreo[0].poaMonitoreo
                                .length < 1
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              this.cerrarTrimestreTodasAreas();
                            }}
                          >
                            Cerrar todas
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    {this.state.areaPoaMonitoreo[0].poaMonitoreo.length > 0 ? (
                      this.state.areaPoaMonitoreo.map((area) => (
                        <AreaOrganizacional
                          key={area.areaOrganizacional.areaOrganizacionalId}
                          areaPoaMonitoreo={area}
                          habilitarTrimestreArea={this.habilitarTrimestreArea}
                          cerrarTrimestreArea={this.cerrarTrimestreArea}
                          reporteMonitoreo={this.reporteMonitoreo}
                        ></AreaOrganizacional>
                      ))
                    ) : (
                      <h2 className="text-center">
                        No existen monitoreos creados para este poa
                      </h2>
                    )}
                    <ModalLoading
                      modal={this.state.modal}
                      mensaje={this.state.mensaje}
                    ></ModalLoading>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  poas: state.poas.poas,
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  trimestres: state.trimestres.trimestres,
  programacionMonitoreos: state.programacionMonitoreos.programacionMonitoreos,
  areaPoaMonitoreo: state.areaPoaMonitoreo.areaPoaMonitoreo,
});

const mapDispatchToProps = (dispatch) => ({
  getAreasPoaMonitoreo: (poaId) => dispatch(getAreasPoaMonitoreo(poaId)),
  cerrarTodasAreasPoaMonitoreo: (poaId, trimestreId) =>
    dispatch(cerrarTodasAreasPoaMonitoreo(poaId, trimestreId)),
  habilitarTodasAreasPoaMonitoreo: (poaId, trimestreId) =>
    dispatch(habilitarTodasAreasPoaMonitoreo(poaId, trimestreId)),
  updateAreasPoaMonitoreo: (areaPoaMonitoreo) =>
    dispatch(updateAreasPoaMonitoreo(areaPoaMonitoreo)),
  getReporteMonitoreo: (monitoreo) => dispatch(getReporteMonitoreo(monitoreo)),
  getTrimestres: () => dispatch(getTrimestres()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(AdministracionMonitoreo, [
    "Administrador general",
    "Analista del POA",
    "Gestor del POA",
    "MAE",
  ])
);
