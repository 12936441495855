import {
  MOSTRAR_COMPRAS_DOCUMENTACIONES,
  COMPRAS_DOCUMENTACIONES_FAILED,
  LOADING_COMPRAS_DOCUMENTACIONES,
  COMPRAS_DOCUMENTACIONES_SUCCESS,
} from "../types";
import initialState from "../initial-state.js";
const { comprasDocumentaciones } = initialState;

const comprasDocumentacionesReducer = (state = comprasDocumentaciones, action) => {
  switch (action.type) {
    case MOSTRAR_COMPRAS_DOCUMENTACIONES:
      return {
        ...state,
        comprasDocumentaciones: action.payload
      };
    case LOADING_COMPRAS_DOCUMENTACIONES:
      return {
        ...state,
        isLoading: true
      };
    case COMPRAS_DOCUMENTACIONES_SUCCESS:
    case COMPRAS_DOCUMENTACIONES_FAILED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default comprasDocumentacionesReducer;
