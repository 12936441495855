import initialState from "./../initial-state";
import {
    MOSTRAR_AREA_POA_MONITOREO,
    EDITAR_AREA_POA_MONITOREO,
    LOADING_AREA_POA_MONITOREO,
    AREA_POA_MONITOREO_FAILED,
    AREA_POA_MONITOREO_SUCCESS,
    TOTAL_PONDERACION_AREA_ACTIVIDADES_NOPOA
} from "./../types";

const { areaPoaMonitoreo } = initialState;

const AreaPoaMonitoreo = (state = areaPoaMonitoreo, action) => {

    switch (action.type) {

        case LOADING_AREA_POA_MONITOREO:
            return {
                ...state,
                isLoading: true
            }
        case MOSTRAR_AREA_POA_MONITOREO:
            return {
                ...state,
                areaPoaMonitoreo: action.payload,
                isLoading: false
            }
        case AREA_POA_MONITOREO_FAILED:
        case AREA_POA_MONITOREO_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case EDITAR_AREA_POA_MONITOREO:
            return {
                ...state,
                areaPoaMonitoreo: action.payload

            };
        case TOTAL_PONDERACION_AREA_ACTIVIDADES_NOPOA:
            return {
                ...state,
                totalPonderacionActividadesNopoa: action.payload
            };
        default:
            return state;
    }
}

export default AreaPoaMonitoreo;
