import React, { Component } from "react";
import { connect } from "react-redux";
import { getListaUsuarios } from "./../../redux/actions/usuarios-actions";
import Loading from "./../utils/Loading.jsx";
import { Link } from "react-router-dom";
import Usuario from "./Usuario.jsx";
import { Authorization } from "./../../utils/Authorization";
import {
  Table,
  Container,
} from "reactstrap";
import Pagination from "react-js-pagination";

class ListaUsuarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      listaUsuariosState: [],
      listaUsuariosPageState: [],
      activePage: 1,
      totalPages: 0,
      itemPerPage: 10,
    };
  }

  componentDidMount() {
    this.cargarDatos();
  }

  componentWillReceiveProps(next_props, nextState){
    this.setState({ listaUsuariosState: this.props.usuarios }, () =>
    this.validarPaginacion());
  }

  cargarDatos = async () => {
    await this.props.getListaUsuarios();
    this.setState({ listaUsuariosState: this.props.usuarios }, () =>
      this.validarPaginacion()
    );

    this.setState({ isLoading: false });
  };

  filtroUsuario = (email) => {
    let filtroUsuarios = [];
    if (email.length > 0) {
      filtroUsuarios = this.props.usuarios.filter((usuario) =>
      this.removeAccents(usuario.email).toUpperCase().includes( this.removeAccents(email).toUpperCase()) ||  this.removeAccents(usuario.name).toUpperCase().includes(this.removeAccents(email).toUpperCase()) ||  this.removeAccents(usuario.areaOrganizacional.nombre).toUpperCase().includes( this.removeAccents(email).toUpperCase() )
      );
    } else {
      filtroUsuarios = this.props.usuarios;
    }

    this.setState({ listaUsuariosState: filtroUsuarios }, () => {
      this.validarPaginacion();
    });
  };

  validarPaginacion = (page = 1, elementos = 10) => {
    let index = elementos * page - elementos;
    let listaUsuarios = [];

    for (let i = 0; i < elementos; i++) {
      listaUsuarios.push(this.state.listaUsuariosState[index]);

      index++;
    }

    let totalPages = Math.ceil(
      this.state.listaUsuariosState.length / elementos
    );
    this.setState({
      listaUsuariosPageState: listaUsuarios,
      activePage: page,
      totalPages,
      itemPerPage: elementos,
    });
  };

   removeAccents(value) {
    return value
        .replace(/á/g, 'a')            
        .replace(/é/g, 'e')
        .replace(/í/g, 'i')
        .replace(/ó/g, 'o')
        .replace(/ú/g, 'u');
}

  render() {
    const { isLoading, usuarios } = this.props;
    const {
      listaUsuariosState,
      listaUsuariosPageState,
      activePage,
      itemPerPage,
    } = this.state;
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <div className="container bg-white border py-3">
            {isLoading ? (
              <Loading></Loading>
            ) : (
              <div className="header-body">
                <div className="row">
                  <div className="form-group  col-md-6 ">
                    <label htmlFor="filtro">Filtro Usuario</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="filtro"
                      placeholder="Email"
                      onKeyUp={(e) => {
                        e.preventDefault();
                        this.filtroUsuario(e.currentTarget.value);
                      }}
                      ref={this.filtroAreaRef}
                    />
                  </div>
                  <div className="col-md-6">
                    <Link
                      className="btn btn-lg btn-info float-right mb-3"
                      to="/admin/usuarios/nuevo"
                    >
                      Agregar Usuario
                    </Link>
                  </div>
                </div>
                <Table
                  style={{ backgroundColor: "#fff", color: "black" }}
                  responsive
                  className="table-striped"
                >
                  <thead>
                    <tr className="text-left">
                      <th>Email</th>
                      <th>Nombre</th>
                      <th>Area</th>
                      <th>Estado</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody className="text-left">
                    {listaUsuariosPageState.map(
                      (usuario) =>
                        usuario !== null &&
                        usuario !== undefined && (
                          <Usuario
                            usuario={usuario}
                            key={usuario.email}
                          ></Usuario>
                        )
                    )}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-center">
                  <Pagination
                    prevPageText="ant"
                    nextPageText="sig"
                    firstPageText="prime"
                    lastPageText="ultimo"
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={activePage}
                    itemsCountPerPage={itemPerPage}
                    totalItemsCount={listaUsuariosState.length}
                    //pageRangeDisplayed={5}
                    onChange={this.validarPaginacion.bind(this)}
                  />
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  usuarios: state.usuarios.usuarios,
  isLoading: state.usuarios.isLoading,
  user: state.auth.signed,
});

const mapDispacthToProps = (dispatch) => ({
  getListaUsuarios: () => {
    dispatch(getListaUsuarios());
  },
});
export default connect(
  mapStateToProps,
  mapDispacthToProps
)(
  Authorization(ListaUsuarios, [
    "Administrador general",
    "Gestor del POA",
    "Analista del POA",
    "MAE",
  ])
);
