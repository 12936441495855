import React, { Component } from "react";
import Select from "react-select";
import Async from "react-select/async";
import Loading from "./../utils/Loading";
import NumberFormat from "react-number-format";
import { Multiselect } from "react-widgets";
import { meses } from "./../../utils/mesesByTrimestre"

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import swal from "sweetalert";

export const ModalGuardarRequerimiento = props => {
  let  justificacion = React.createRef();
  let  numeroTransdoc = React.createRef();
    const toggle = () => props.activarModalGuardarRequerimiento();

    const handleCuenta = e => {
        e.preventDefault();


    };


    const handleInsumo = (newValue, e) => {
        // e.preventDefault();

        //   clearTimeout(timer);
        //   timer = setTimeout(() => {
        //     // const inputValue = newValue.replace(/\W/g, "");
        //     const inputValue = newValue;

        //     if (inputValue !== "") {
        //       props.consultarInsumosActividad(
        //         props.state.cuentaFiltro,
        //         inputValue,
        //         props.state.operacionFiltro
        //       );
        //     }
        //   }, 2000);

    };




    const { modalGuardarRequerimiento } = props;

    return (
        <Modal isOpen={modalGuardarRequerimiento} toggle={toggle} size="lg" backdrop="static">
            <ModalHeader className="block-example border-bottom " toggle={toggle}>
                Guardar Requerimiento
            </ModalHeader>
            <ModalBody className="block-example border-bottom ">
                <form>
                    <div className="col-12">
                        <div className="form-group ">
                            <label htmlFor="justificacion">Numeración Transdoc</label>
                            {/* <textarea rows={2} className="form-control  text-dark" id="justificacion" placeholder="Justificación de la compra" ref={justificacion}></textarea> */}
                            <input  className="form-control  text-dark" id="numeroTransdoc" placeholder="Numeración Transdoc" ref={numeroTransdoc}></input>
                        </div>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Cargar especificaciones técnicas(PDF)</label>
                        <input
                            id="inputpdf"
                            className="form-control text-dark"
                            type="file" accept=".pdf"
                            name="file[]"
                            onChange={
                                (e) => {
                                    e.preventDefault();
                                    ////////////////////////////////////////////////////////////
                                    var fileInput = document.getElementById('inputpdf');
                                    var filePath = fileInput.value;
                                    var allowedExtensions = /(\.pdf|\.PDF)$/i;
                                    if (!allowedExtensions.exec(filePath)) {
                                        swal("Error de tipo de archivo", "Debe cargar archivos en formato PDF", "error");
                                        fileInput.value = '';
                                        return false;
                                    }
                                    ///////////////////////////////////////////////
                                     props.cargarEspecificacionesTecnicas(e);
                                }
                            }
                        />
                    </div>
                </form>
            </ModalBody>
            <ModalFooter className="block-example border-bottom ">
                <button
                    className="btn btn-primary"
                    onClick={e => {
                        e.preventDefault();
                        if(numeroTransdoc && numeroTransdoc.current.value.length>0){
                           let requerimientoProps= {
                            justificacion:"",
                            numeroTransdoc: numeroTransdoc.current.value
                           }
                            props.guardarRequerimiento(requerimientoProps);
                        }else{
                            swal("Advertencia",
                                "Debe especificar la numeración de transdoc para crear el requerimiento de compra",
                                "error"
                             )
                        }
                    }}
                >
                    Guardar
                </button>
                <button
                    className="btn btn-danger"
                    onClick={e => {
                        e.preventDefault();
                        props.activarModalGuardarRequerimiento();
                    }}
                >
                    Cerrar
                </button>
            </ModalFooter>
        </Modal>
    );
};
