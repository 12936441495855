import {
    MOSTRAR_TIPO_ASIGNACION,
    MOSTRAR_TIPOS_ASIGNACIONES,
    TIPOS_ASIGNACIONES_FAILED,
    LOADING_TIPOS_ASIGNACIONES,
    TIPOS_ASIGNACIONES_SUCCESS,
    CREATE_TIPOS_ASIGNACIONES,
    DELETE_TIPOS_ASIGNACIONES,
    UPDATE_TIPOS_ASIGNACIONES
  } from "../types";
import { api_base } from "../../config";
import { getData } from "../../utils/api-client";

export const getListaTiposAsignaciones = () => async dispatch => {
    let url = api_base + "api/v1/tipoAsignacionesPresupuesto";
    dispatch({ type: LOADING_TIPOS_ASIGNACIONES });
    await getData
        .get(url)
        .then(resultado => {
            // console.log("resultado.data",resultado.data)
            dispatch({ type: MOSTRAR_TIPOS_ASIGNACIONES, payload: resultado.data });
            dispatch({ type: TIPOS_ASIGNACIONES_SUCCESS});
        })
        .catch(error => {
                dispatch({ type: TIPOS_ASIGNACIONES_FAILED });
        });
};
export const getTipoAsignacion = (tipoAsignacionId) => async dispatch => {
    let url = api_base + "api/v1/tipoAsignacionesPresupuesto/"+tipoAsignacionId;
    dispatch({ type: LOADING_TIPOS_ASIGNACIONES });
    await getData
        .get(url)
        .then(resultado => {
            // console.log("resultado.data",resultado.data)
            dispatch({ type: MOSTRAR_TIPO_ASIGNACION, payload: resultado.data });
            dispatch({ type: TIPOS_ASIGNACIONES_SUCCESS});
        })
        .catch(error => {
                dispatch({ type: TIPOS_ASIGNACIONES_FAILED });
        });
};
export const updateTipoAsignacion = (tipoAsignacion) => async dispatch => {
    let url = api_base + "api/v1/tipoAsignacionesPresupuesto/actualizar";
    dispatch({ type: LOADING_TIPOS_ASIGNACIONES });
    await getData
        .post(url,tipoAsignacion)
        .then(resultado => {
            // console.log("resultado.data",resultado.data)
            dispatch({ type: UPDATE_TIPOS_ASIGNACIONES, payload: resultado.data });
            dispatch({ type: TIPOS_ASIGNACIONES_SUCCESS});
        })
        .catch(error => {
                dispatch({ type: TIPOS_ASIGNACIONES_FAILED });
        });
};
export const setTipoAsignacion = (tipoAsignacion) => async dispatch => {
    let url = api_base + "api/v1/tipoAsignacionesPresupuesto/crear";
    dispatch({ type: LOADING_TIPOS_ASIGNACIONES });
    await getData
        .post(url,tipoAsignacion)
        .then(resultado => {
            // console.log("resultado.data",resultado.data)
            dispatch({ type: CREATE_TIPOS_ASIGNACIONES, payload: resultado.data });
            dispatch({ type: TIPOS_ASIGNACIONES_SUCCESS});
        })
        .catch(error => {
                dispatch({ type: TIPOS_ASIGNACIONES_FAILED });
        });
};

export const deleteTipoAsignacion = (tipoAsignacion) => async dispatch => {
    let url = api_base + "api/v1/tipoAsignacionesPresupuesto/eliminar";
    dispatch({ type: LOADING_TIPOS_ASIGNACIONES });

    await getData
        .post(url,tipoAsignacion)
        .then(resultado => {
            // console.log("resultado.data",resultado.data)
            dispatch({ type: DELETE_TIPOS_ASIGNACIONES, payload: resultado.data });
            dispatch({ type: TIPOS_ASIGNACIONES_SUCCESS});
        })
        .catch(error => {
                dispatch({ type: TIPOS_ASIGNACIONES_FAILED });
        });
};
