 export const  meses= {
    t1:[
        {id:1,value:"Enero"},
        {id:2,value:"Febrero"},
        {id:3,value:"Marzo"}
      ],
      t2:[
        {id:4,value:"Abril"},
        {id:5,value:"Mayo"},
        {id:6,value:"Junio"},
      ],
      t3:[
        {id:7,value:"Julio"},
        {id:8,value:"Agosto"},
        {id:9,value:"Septiembre"},
      ],
      t4:[
        {id:10,value:"Octubre"},
        {id:11,value:"Noviembre"},
        {id:12,value:"Diciembre"},
      ]
}

