import React from "react";

const UnidadMedida = props => {
  const { nombre, unidadMedidaId } = props.unidadMedida;
  return (
    <option key={nombre} value={nombre}>
      {nombre}
    </option>
  );
};

export default UnidadMedida;
