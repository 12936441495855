import React, { useRef } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

export const ModalCrearTipoCompra = (props) => {
    const toggle = () => props.activarModalTipoCompra(props.modalTipoCompraStatus);
    const { modalTipoCompraStatus, guardarTipoDeCompra } = props;

    const tipoCompraRef = useRef("");

    return (
        <Modal isOpen={modalTipoCompraStatus} toggle={toggle} size="lg" backdrop="static">
          <ModalHeader className="block-example border-bottom " toggle={toggle}>
            Crear tipo de compra
          </ModalHeader>
          <ModalBody>
            <div>
              <label>Tipo de compra</label>
              <input
                type="text"
                className="form-control text-dark"
                id="nombre"
                ref={tipoCompraRef}
                required
                placeholder="Digite el tipo de compra"
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="row">
              <div className="">
                <button
                  className="btn btn-danger"
                  onClick={(e) => {
                    e.preventDefault();
                    props.activarModalTipoCompra(props.modalTipoCompraStatus);
                  }}
                >
                  Cancelar
                </button>
              </div>
              <div className="ml-3">
                  <button
                  className="btn btn-primary"
                  onClick={(e) => {
                      e.preventDefault();
                      guardarTipoDeCompra(tipoCompraRef.current.value);
                  }}
                  >
                  Guardar
                  </button>
              </div>
            </div>
      
          </ModalFooter>
        </Modal>
      );
      
};
