import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  Table,
  Container,
} from "reactstrap";

import { Authorization } from "../../utils/Authorization";
import Loading from "../utils/Loading";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";

import { ModalRecepcionBienesServicios } from "../utils/ModalRecepcionBienesServicios";
import { getTrimestres } from "../../redux/actions/trimestres-actions";
import {
  createInformeTrimestral,
  updateInformeTrimestral,
  getInformesTrimestral,
  deleteInformeTrimestral,
} from "../../redux/actions/informe_trimestral_actions";

import { getAreasOrganizacionales } from "../../redux/actions/areas-actions";
import { getRequerimientos } from "../../redux/actions/requerimientos-actions";

import SolicitudCompras from "./SolicitudCompras.jsx";

class SolicitudesCompras extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      trimestres: [],
      trimestresEntity: [],
      modalInformeSolicitudesComprasStatus: false,
      selectedFile: null,
      file: [],
      fecha: "",
      documento: [],
      trimestreId: "",
      estatusEnviar: false,
      areaid2: 0,
      areaSelected:0,
      listaSolicitudesState: [],
      listaSolicitudesPageState: [],
      activePage: 1,
      totalPages: 0,
      itemPerPage: 10,
    };
  }

  componentDidMount() {
    this.cargarSolicitudes();
    this.props.getAreasOrganizacionales();
    this.setState({ areaid2: this.props.user.areaOrganizacional.areaOrganizacionalId })
  }

  cargarSolicitudes = async () => {
    let areaConsulta = this.state.areaSelected==0?this.props.user.areaOrganizacional.areaOrganizacionalId:this.state.areaSelected;
    await this.props.getRequerimientos(areaConsulta);
    // console.log(this.props.requerimientos);
    this.setState({ listaSolicitudesState: this.props.requerimientos, isLoadingState: false }, () =>
      this.validarPaginacion()
    );
  }

  cargarSolicitudCompra = (informe) => {
    let files = [];
    let documentos = [];
    if (informe.target.files.length > 0) {
      for (let i = 0; i < informe.target.files.length; i++) {
        files.push(informe.target.files[i]);
        documentos.push(informe.target.files[i].name);
      }
      this.setState({
        file: files,
        documento: documentos,
      });
    }
    else {
      this.setState({
        file: "",
        documento: "",
      });
    }
  };

  activarModalRecepcionBienesServicios = () => {
    this.setState({
      modalInformeTrimestralStatus: !this.state.modalInformeTrimestralStatus,
    });
  };

  validarPaginacion = (page = 1, elementos = 10) => {
    let index = elementos * page - elementos;
    let listaSolicitudes = [];

    for (let i = 0; i < elementos; i++) {
      listaSolicitudes.push(this.state.listaSolicitudesState[index]);

      index++;
    }

    let totalPages = Math.ceil(
      this.state.listaSolicitudesState.length / elementos
    );

    this.setState({
      listaSolicitudesPageState: listaSolicitudes,
      activePage: page,
      totalPages,
      itemPerPage: elementos,
    });
  };

  validar;

  render() {
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (
                <div className="row">

                  <div className="col-md-5 text-left" >
                    <label>Filtro Área</label>
                    <select
                      className="form-control"
                      // defaultValue={this.state.areaid2}
                      value={this.state.areaSelected>0?this.state.areaSelected:this.state.areaid2}
                      id="areaSelected"
                      onChange={(e) => {
                        e.preventDefault();

                        this.setState({ isLoadingState: true });
                        this.setState({ areaSelected: document.getElementById("areaSelected").value });
                        this.cargarSolicitudes();
                      }}
                    >
                      <option key={0} value={0}>
                          {"--Seleccione Área Organizacional--"}
                        </option>
                      {this.props.areaOrganizacionales.map((area) => (
                        <option key={area.areaOrganizacionalId} value={area.areaOrganizacionalId}>
                          {area.nombre}
                        </option>

                      ))}
                    </select>

                    {/* <label>Filtro Área</label>
                    <select
                      className="form-control"
                      defaultValue={this.state.areaid2}

                      id="areaSelected"
                      onChange={(e) => {
                        e.preventDefault();

                        this.setState({ isLoadingState: true });
                        this.setState({ areaid2: document.getElementById("areaSelected").value });
                        this.cargarDatos(document.getElementById("areaSelected").value);
                      }}
                    >
                      {this.props.areaOrganizacionales.map((area) => (
                        <option key={area.areaOrganizacionalId} value={area.areaOrganizacionalId}>
                          {area.nombre}
                        </option>

                      ))}
                    </select> */}
                  </div>
                  <div className="col-md-12 text-right">
                    {/* <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        this.activarModalRecepcionBienesServicios();
                      }}
                    >
                      Solicitar Requerimiento
                    </button> */}
                  </div>
                  <div className="col-md-12 mt-3">
                    <Table  responsive>
                      <thead>
                        <tr>
                          <th>Número Solicitud</th>
                          <th>Estado</th>
                          <th>Área Actual</th>
                          <th>Fecha de Entrada</th>
                          <th>Tipo de Compra</th>
                          <th>Analista Asignado</th>
                          <th>Proceso Plurianual</th>
                          <th>SIGEF</th>
                          <th>PACC</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listaSolicitudesPageState.map(
                          (solicitud) =>
                            solicitud != null &&
                            solicitud != undefined && (
                              <SolicitudCompras
                                key={solicitud.requerimientoId}
                                requerimiento={solicitud}
                                activarModalRecepcionBienesServicios={this.activarModalRecepcionBienesServicios}
                                user={this.props.user}
                              />
                            )
                        )}
                      </tbody>
                    </Table>
                    <div className="d-flex justify-content-center">
                      <Pagination
                        prevPageText="ant"
                        nextPageText="sig"
                        firstPageText="prime"
                        lastPageText="ultimo"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemPerPage}
                        totalItemsCount={this.state.listaSolicitudesState.length}
                        onChange={this.validarPaginacion.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {this.state.modalInformeTrimestralStatus && (
              <ModalRecepcionBienesServicios
                modalInformeTrimestralStatus={
                  this.state.modalInformeTrimestralStatus
                }
                cargarSolicitudCompra={this.cargarSolicitudCompra}
                activarModalRecepcionBienesServicios={this.activarModalRecepcionBienesServicios}
              ></ModalRecepcionBienesServicios>
            )}
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  trimestres: state.trimestres.trimestres,
  informesTrimestrales: state.informesTrimestrales.informesTrimestrales,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
  requerimientos: state.requerimientos.requerimientos,
});

const mapDispatchToProps = (dispatch) => ({
 
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
  getRequerimientos: (areaId) => dispatch(getRequerimientos(areaId))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(SolicitudesCompras, [
    "Administrador general",
    "Responsables de compras",
    "Analista Compras",
    "Encargado Administrativo"
  ])
);
