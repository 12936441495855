import React, { Component } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";
import { DateTimePicker } from "react-widgets";
import { connect } from "react-redux";
import { Authorization } from "./../../../utils/Authorization";
import {
  getPoaActivo,
  mostrarPoas,
} from "./../../../redux/actions/poa-actions";
import { getTrimestres } from "../../../redux/actions/trimestres-actions";

import {
  getProgramacionMonitoreos,
  createProgramacionMonitoreos,
  updateProgramacionMonitoreos,
} from "./../../../redux/actions/programacion-monitoreo-actions";
import {
  Container,
} from "reactstrap";
import swal from "sweetalert";
import Loading from "./../../utils/Loading";

class ProgramacionMonitoreo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloadingState: true,
      fechaT1Inicio: new Date(),
      fechaT2Inicio: new Date(),
      fechaT3Inicio: new Date(),
      fechaT4Inicio: new Date(),
      fechaT1Fin: "",
      fechaT2Fin: "",
      fechaT3Fin: "",
      fechaT4Fin: "",
    };
  }

  anoSelectedRef = React.createRef();
  componentDidMount() {
    this.cargarDatos();
  }

  cargarDatos = async () => {
    await this.props.getPoaActivo();
    await this.props.mostrarPoas();
    await this.props.getTrimestres();
    await this.props.getProgramacionMonitoreos(this.props.poaActivo.poaId);
    if (
      this.props.programacionMonitoreos !== null &&
      this.props.programacionMonitoreos.length > 0
    ) {
      this.setState({
        fechaT1Inicio: this.props.programacionMonitoreos[0].fechaInicio,
        fechaT1Fin: this.props.programacionMonitoreos[0].fechaFin,
        fechaT2Inicio: this.props.programacionMonitoreos[1].fechaInicio,
        fechaT2Fin: this.props.programacionMonitoreos[1].fechaFin,
        fechaT3Inicio: this.props.programacionMonitoreos[2].fechaInicio,
        fechaT3Fin: this.props.programacionMonitoreos[2].fechaFin,
        fechaT4Inicio: this.props.programacionMonitoreos[3].fechaInicio,
        fechaT4Fin: this.props.programacionMonitoreos[3].fechaFin,
      });
    }
    this.setState({
      isloadingState: false,
      poaActivo: this.props.poaActivo.poaId,
    });
  };

  cambiarPoaSeleccionado = async () => {
    this.setState({
      poaActivo: this.anoSelectedRef.current.value,
      isloadingState: true,
    });
    await this.props.getProgramacionMonitoreos(
      this.anoSelectedRef.current.value
    );
    if (
      this.props.programacionMonitoreos !== null &&
      this.props.programacionMonitoreos.length > 0
    ) {
      this.setState({
        fechaT1Inicio: this.props.programacionMonitoreos[0].fechaInicio,
        fechaT1Fin: this.props.programacionMonitoreos[0].fechaFin,
        fechaT2Inicio: this.props.programacionMonitoreos[1].fechaInicio,
        fechaT2Fin: this.props.programacionMonitoreos[1].fechaFin,
        fechaT3Inicio: this.props.programacionMonitoreos[2].fechaInicio,
        fechaT3Fin: this.props.programacionMonitoreos[2].fechaFin,
        fechaT4Inicio: this.props.programacionMonitoreos[3].fechaInicio,
        fechaT4Fin: this.props.programacionMonitoreos[3].fechaFin,
      });
    } else {
      this.setState({
        fechaT1Inicio: null,
        fechaT1Fin: null,
        fechaT2Inicio: null,
        fechaT2Fin: null,
        fechaT3Inicio: null,
        fechaT3Fin: null,
        fechaT4Inicio: null,
        fechaT4Fin: null,
      });
    }
    // console.log(this.props.programacionMonitoreos);
    this.setState({ isloadingState: false });
  };

  crearProgramacionMonitoreo = async () => {
    let poaMonitoreo = [];
    let fecha = new Date();

    if (this.validarProgramacionMonitoreo()) {
      this.setState({ isloadingState: true });
      for (let i = 0; i < this.props.trimestres.length; i++) {
        let programacionMonitoreo = {
          trimestreId: this.props.trimestres[i].trimestreId,
          poaId: this.state.poaActivo,
          fechaInicio:
            this.props.trimestres[i].trimestreId === "T1"
              ? this.state.fechaT1Inicio
              : this.props.trimestres[i].trimestreId === "T2"
              ? this.state.fechaT2Inicio
              : this.props.trimestres[i].trimestreId === "T3"
              ? this.state.fechaT3Inicio
              : this.props.trimestres[i].trimestreId === "T4"
              ? this.state.fechaT4Inicio
              : null,
          fechaFin:
            this.props.trimestres[i].trimestreId === "T1"
              ? this.state.fechaT1Fin
              : this.props.trimestres[i].trimestreId === "T2"
              ? this.state.fechaT2Fin
              : this.props.trimestres[i].trimestreId === "T3"
              ? this.state.fechaT3Fin
              : this.props.trimestres[i].trimestreId === "T4"
              ? this.state.fechaT4Fin
              : null,
          fechaCreacion: fecha,
        };
        poaMonitoreo.push(programacionMonitoreo);
      }

      await this.props.createProgramacionMonitoreos(
        this.state.poaActivo,
        poaMonitoreo
      );
      this.setState({ isloadingState: false });
    }
  };

  actualizarProgramacionMonitoreo = async () => {
    let poaMonitoreo = [];
    let fecha = new Date();

    if (this.validarProgramacionMonitoreo()) {
      this.setState({ isloadingState: true });

      for (let i = 0; i < this.props.trimestres.length; i++) {
        let programacionMonitoreo = {
          trimestreId: this.props.trimestres[i].trimestreId,
          poaId: this.state.poaActivo,
          fechaInicio:
            this.props.trimestres[i].trimestreId === "T1"
              ? this.state.fechaT1Inicio
              : this.props.trimestres[i].trimestreId === "T2"
              ? this.state.fechaT2Inicio
              : this.props.trimestres[i].trimestreId === "T3"
              ? this.state.fechaT3Inicio
              : this.props.trimestres[i].trimestreId === "T4"
              ? this.state.fechaT4Inicio
              : null,
          fechaFin:
            this.props.trimestres[i].trimestreId === "T1"
              ? this.state.fechaT1Fin
              : this.props.trimestres[i].trimestreId === "T2"
              ? this.state.fechaT2Fin
              : this.props.trimestres[i].trimestreId === "T3"
              ? this.state.fechaT3Fin
              : this.props.trimestres[i].trimestreId === "T4"
              ? this.state.fechaT4Fin
              : null,
          fechaActualizacion: fecha,
        };
        poaMonitoreo.push(programacionMonitoreo);
      }

      await this.props.updateProgramacionMonitoreos(
        this.state.poaActivo,
        poaMonitoreo
      );
      this.setState({ isloadingState: false });
    }
  };

  validarProgramacionMonitoreo = () => {
    if (
      this.state.fechaT1Inicio === null ||
      this.state.fechaT1Inicio === "" ||
      this.state.fechaT1Fin === null ||
      this.state.fechaT1Fin === ""
    ) {
      swal("Monitoreos", "Debe especificar las fechas del primer trimestre","error");

      return false;
    }
    if (
      this.state.fechaT2Inicio === null ||
      this.state.fechaT2Inicio === "" ||
      this.state.fechaT2Fin === null ||
      this.state.fechaT2Fin === ""
    ) {
      swal("Monitoreos", "Debe especificar las fechas del segundo trimestre","error");

      return false;
    }
    if (
      this.state.fechaT3Inicio === null ||
      this.state.fechaT3Inicio === "" ||
      this.state.fechaT3Fin === null ||
      this.state.fechaT3Fin === ""
    ) {
      swal("Monitoreos", "Debe especificar las fechas del tercer trimestre","error");

      return false;
    }
    if (
      this.state.fechaT4Inicio === null ||
      this.state.fechaT4Inicio === "" ||
      this.state.fechaT4Fin === null ||
      this.state.fechaT4Fin === ""
    ) {
      swal("Monitoreos", "Debe especificar las fechas del cuarto trimestre","error");

      return false;
    }
    return true;
  };

  render() {
    Moment.locale("en");
    momentLocalizer();

    let fechaT1InicioRef = (e) => this.setState({ fechaT1Inicio: e });
    let fechaT1FinRef = (e) => this.setState({ fechaT1Fin: e });

    let fechaT2InicioRef = (e) => this.setState({ fechaT2Inicio: e });
    let fechaT2FinRef = (e) => this.setState({ fechaT2Fin: e });

    let fechaT3InicioRef = (e) => this.setState({ fechaT3Inicio: e });
    let fechaT3FinRef = (e) => this.setState({ fechaT3Fin: e });

    let fechaT4InicioRef = (e) => this.setState({ fechaT4Inicio: e });
    let fechaT4FinRef = (e) => this.setState({ fechaT4Fin: e });
    return (
      <React.Fragment>
        <Container fluid>
          <div className="pb-6 pt-5 pt-md-7">
            {this.state.isloadingState ? (
              <div className="container bg-white border py-3">
                <Loading></Loading>
              </div>
            ) : (
              <div className="container bg-white border">
                <div className="form-group col-md-6 mt-3">
                  <label>Año del Poa</label>
                  <select
                    className="form-control"
                    defaultValue={this.state.poaActivo}
                    id="anoSelected"
                    ref={this.anoSelectedRef}
                    onChange={(e) => {
                      e.preventDefault();
                      this.cambiarPoaSeleccionado();
                    }}
                  >
                    {this.props.poas.map((poa) => (
                      <option key={poa.poaId} value={poa.poaId}>
                        {poa.ano}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-12">
                  <div
                    className="col-md-12 form-inline pl-0 "
                    style={{
                      paddingBottom: "5px",
                      borderTop: "3px solid #e9ecef",
                      borderBottom: "3px solid #e9ecef",
                      backgroundColor: "#fff",
                      color: "black",
                    }}
                  >
                    <div className="col-md-4 text-left">Trimestre</div>
                    <div className="col-md-4 text-left">Fecha Inicio</div>
                    <div className="col-md-4 text-left">Fecha Fin</div>
                  </div>

                  <div
                    className="col-md-12 form-inline pl-0 pb-2"
                    style={{
                      borderBottom: "3px solid #e9ecef",
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <div className="col-md-4 text-left">
                      {this.props.trimestres[0].alias}
                    </div>
                    <div className="col-md-4 text-left">
                      <DateTimePicker
                        placeholder="mm/dd/yyyy"
                        footer={false}
                        defaultValue={
                          this.props.programacionMonitoreos !== null &&
                          this.props.programacionMonitoreos.length > 0
                            ? new Date(
                                this.props.programacionMonitoreos[0].fechaInicio
                              )
                            : new Date()
                        }
                        time={false}
                        onChange={fechaT1InicioRef}
                        ref={this.fechaT1InicioRef}
                      />
                    </div>
                    <div className="col-md-4 text-left">
                      <DateTimePicker
                        placeholder="mm/dd/yyyy"
                        footer={false}
                        min={
                          typeof this.state.fechaT1Inicio === "string"
                            ? new Date(this.state.fechaT1Inicio)
                            : this.state.fechaT1Inicio
                        }
                        defaultValue={
                          this.props.programacionMonitoreos !== null &&
                          this.props.programacionMonitoreos.length > 0
                            ? new Date(
                                this.props.programacionMonitoreos[0].fechaFin
                              )
                            : null
                        }
                        time={false}
                        onChange={fechaT1FinRef}
                        ref={this.fechaT1FinRef}
                      />
                    </div>
                  </div>

                  <div
                    className="col-md-12 form-inline pl-0 pb-2"
                    style={{ borderBottom: "3px solid #e9ecef" }}
                  >
                    <div className="col-md-4 text-left">
                      {this.props.trimestres[1].alias}
                    </div>
                    <div className="col-md-4 text-left">
                      <DateTimePicker
                        placeholder="mm/dd/yyyy"
                        footer={false}
                        defaultValue={
                          this.props.programacionMonitoreos !== null &&
                          this.props.programacionMonitoreos.length > 0
                            ? new Date(
                                this.props.programacionMonitoreos[1].fechaInicio
                              )
                            : new Date()
                        }
                        time={false}
                        onChange={fechaT2InicioRef}
                        ref={this.fechaT2InicioRef}
                      />
                    </div>
                    <div className="col-md-4 text-left">
                      <DateTimePicker
                        placeholder="mm/dd/yyyy"
                        footer={false}
                        min={
                          typeof this.state.fechaT1Inicio === "string"
                            ? new Date(this.state.fechaT1Inicio)
                            : this.state.fechaT1Inicio
                        }
                        defaultValue={
                          this.props.programacionMonitoreos !== null &&
                          this.props.programacionMonitoreos.length > 0
                            ? new Date(
                                this.props.programacionMonitoreos[1].fechaFin
                              )
                            : null
                        }
                        time={false}
                        onChange={fechaT2FinRef}
                        ref={this.fechaT2FinRef}
                      />
                    </div>
                  </div>

                  <div
                    className="col-md-12 form-inline pl-0 pb-2"
                    style={{
                      borderBottom: "3px solid #e9ecef",
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <div className="col-md-4 text-left">
                      {this.props.trimestres[2].alias}
                    </div>
                    <div className="col-md-4 text-left">
                      <DateTimePicker
                        placeholder="mm/dd/yyyy"
                        footer={false}
                        defaultValue={
                          this.props.programacionMonitoreos !== null &&
                          this.props.programacionMonitoreos.length > 0
                            ? new Date(
                                this.props.programacionMonitoreos[2].fechaInicio
                              )
                            : new Date()
                        }
                        time={false}
                        onChange={fechaT3InicioRef}
                        ref={this.fechaT3InicioRef}
                      />
                    </div>
                    <div className="col-md-4 text-left">
                      <DateTimePicker
                        placeholder="mm/dd/yyyy"
                        footer={false}
                        min={
                          typeof this.state.fechaT1Inicio === "string"
                            ? new Date(this.state.fechaT1Inicio)
                            : this.state.fechaT1Inicio
                        }
                        defaultValue={
                          this.props.programacionMonitoreos !== null &&
                          this.props.programacionMonitoreos.length > 0
                            ? new Date(
                                this.props.programacionMonitoreos[2].fechaFin
                              )
                            : null
                        }
                        time={false}
                        onChange={fechaT3FinRef}
                        ref={this.fechaT3FinRef}
                      />
                    </div>
                  </div>

                  <div
                    className="col-md-12 form-inline pl-0 pb-2"
                    style={{ borderBottom: "3px solid #e9ecef" }}
                  >
                    <div className="col-md-4 text-left">
                      {this.props.trimestres[3].alias}
                    </div>
                    <div className="col-md-4 text-left">
                      <DateTimePicker
                        footer={false}
                        placeholder="mm/dd/yyyy"
                        defaultValue={
                          this.props.programacionMonitoreos !== null &&
                          this.props.programacionMonitoreos.length > 0
                            ? new Date(
                                this.props.programacionMonitoreos[3].fechaInicio
                              )
                            : new Date()
                        }
                        time={false}
                        onChange={fechaT4InicioRef}
                        ref={this.fechaT4InicioRef}
                      />
                    </div>
                    <div className="col-md-4 text-left">
                      <DateTimePicker
                        placeholder="mm/dd/yyyy"
                        footer={false}
                        min={
                          typeof this.state.fechaT1Inicio === "string"
                            ? new Date(this.state.fechaT1Inicio)
                            : this.state.fechaT1Inicio
                        }
                        defaultValue={
                          this.props.programacionMonitoreos !== null &&
                          this.props.programacionMonitoreos.length > 0
                            ? new Date(
                                this.props.programacionMonitoreos[3].fechaFin
                              )
                            : null
                        }
                        time={false}
                        onChange={fechaT4FinRef}
                        ref={this.fechaT4FinRef}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 mt-3 pl-0">
                    {/* <button className="btn btn-info">Guardar</button> */}
                    {this.props.programacionMonitoreos !== null &&
                    this.props.programacionMonitoreos.length > 0 ? (
                      <button
                        className="btn btn-info"
                        onClick={(e) => {
                          e.preventDefault();
                          this.actualizarProgramacionMonitoreo();
                        }}
                      >
                        Editar
                      </button>
                    ) : (
                      <button
                        className="btn btn-info"
                        onClick={(e) => {
                          e.preventDefault();
                          this.crearProgramacionMonitoreo();
                        }}
                      >
                        Guardar
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  poas: state.poas.poas,
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  trimestres: state.trimestres.trimestres,
  programacionMonitoreos: state.programacionMonitoreos.programacionMonitoreos,
});

const mapDispatchToProps = (dispatch) => ({
  mostrarPoas: () => dispatch(mostrarPoas()),
  getPoaActivo: () => dispatch(getPoaActivo()),
  getTrimestres: () => dispatch(getTrimestres()),
  getProgramacionMonitoreos: (poaId) =>
    dispatch(getProgramacionMonitoreos(poaId)),
  createProgramacionMonitoreos: (poaId, poaMonitoreo) =>
    dispatch(createProgramacionMonitoreos(poaId, poaMonitoreo)),
  updateProgramacionMonitoreos: (poaId, poaMonitoreo) =>
    dispatch(updateProgramacionMonitoreos(poaId, poaMonitoreo)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(ProgramacionMonitoreo, [
    "Administrador general",
    "Analista del POA",
    "Gestor del POA",
    "MAE",
  ])
);
