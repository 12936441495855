import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "./../../utils/Loading.jsx";

import {
  ModalBody,
  ModalFooter
} from "reactstrap";

import { agregarNotaProducto } from "../../../redux/actions/nota-producto-actions";

class NotaProducto extends Component {
  constructor(props) {
    super(props);
    this.resultadoRef = React.createRef();
    this.descripcionRef = React.createRef();
    this.indicadorRef = React.createRef();
    this.ponderacionAreaRef = React.createRef();
    this.unidadResponsableRef = React.createRef();
    this.comentarioRef = React.createRef();
    this.involucradoRef = React.createRef();
    this.cooperacionRef = React.createRef();
    this.metaRef = React.createRef();
    this.accionMitigacionRef = React.createRef();
    this.riesgoAsociadoRef = React.createRef();

  }

  componentDidMount() {
    if (
      Object.keys(this.props.notaProducto).length !== 0 &&
      this.props.productoId === this.props.notaProducto.productoId
    ) {
      const {
        descripcion,
        indicador,
        ponderacionArea,
        resultado,
        comentario,
        unidadResponsable,
        meta,
        involucrados,
        accionMitigacion,
        riesgoAsociado
      } = this.props.notaProducto;

      this.resultadoRef.current.value = resultado;
      this.descripcionRef.current.value = descripcion;
      this.indicadorRef.current.value = indicador;
      this.ponderacionAreaRef.current.value = ponderacionArea;
      this.unidadResponsableRef.current.value = unidadResponsable;
      this.comentarioRef.current.value = comentario;
      this.metaRef.current.value = meta ? meta : null;
      this.involucradoRef.current.value = involucrados ? involucrados : null;
      this.accionMitigacionRef.current.value = accionMitigacion ? accionMitigacion : null;
      this.riesgoAsociadoRef.current.value = riesgoAsociado ? riesgoAsociado : null;
    }
  }

  agregarNota = () => {
    let notaProducto = {
      producto: this.props.producto,
      resultado:
        this.resultadoRef.current.value !== ""
          ? this.resultadoRef.current.value
          : null,
      descripcion:
        this.descripcionRef.current.value !== ""
          ? this.descripcionRef.current.value
          : null,
      indicador:
        this.indicadorRef.current.value !== ""
          ? this.indicadorRef.current.value
          : null,
      ponderacionArea:
        this.ponderacionAreaRef.current.value !== ""
          ? this.ponderacionAreaRef.current.value
          : null,
      unidadResponsable:
        this.unidadResponsableRef.current.value !== ""
          ? this.unidadResponsableRef.current.value
          : null,
      comentario:
        this.comentarioRef.current.value !== ""
          ? this.comentarioRef.current.value
          : null,
      meta:
        this.metaRef.current.value !== "" ? this.metaRef.current.value : null,
      involucrados:
        this.involucradoRef.current.value !== "" ? this.involucradoRef.current.value : null,
      cooperacion:
        this.cooperacionRef.current.value !== "" ? this.cooperacionRef.current.value : null,
      accionMitigacion:
        this.accionMitigacionRef.current.value !== "" ? this.accionMitigacionRef.current.value : null,
      riesgoAsociado:
        this.riesgoAsociadoRef.current.value !== "" ? this.riesgoAsociadoRef.current.value : null
    };
    if (!this.validarNota(notaProducto)) {
      notaProducto = {};
    }

    this.setState({ notaProducto });
    this.props.agregarNota(notaProducto);
  };

  validarNota = notaProducto => {
    if (
      notaProducto.meta === null &&
      notaProducto.comentario === null &&
      notaProducto.unidadResponsable === null &&
      notaProducto.ponderacionArea === null &&
      notaProducto.indicador === null &&
      notaProducto.descripcion === null &&
      notaProducto.resultado === null &&
      notaProducto.involucrados ===null &&
      notaProducto.cooperacion ===null &&
      notaProducto.riesgoAsociado ===null &&
      notaProducto.accionMitigacion ===null 
    ) {
      return false;
    } else {
      return true;
    }
  };

  limpiarCampos = () => {
    this.metaRef.current.value = null;
    this.comentarioRef.current.value = null;
    this.unidadResponsableRef.current.value = null;
    this.ponderacionAreaRef.current.value = null;
    this.indicadorRef.current.value = null;
    this.descripcionRef.current.value = null;
    this.resultadoRef.current.value = null;
    this.involucradoRef.current.value = null;
    this.cooperacionRef.current.value = null;
    this.accionMitigacionRef.current.value = null;
    this.riesgoAsociadoRef.current.value = null;
  };

  render() {
    return (
      <React.Fragment>
        <ModalBody>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group ">
                <label htmlFor="descripcion">Producto</label>
                <textarea
                  type="text"
                  className="form-control form-control-sm text-dark"
                  id="descripcion"
                  placeholder="Nota nombre del producto"
                  ref={this.descripcionRef}
                />
              </div>
              <div className="form-group ">
                <label htmlFor="riesgoAsociado">Riesgo Asociado</label>
                <textarea
                  type="text"
                  className="form-control form-control-sm text-dark"
                  id="riesgoAsociado"
                  placeholder="Nota riesgo asociado"
                  ref={this.riesgoAsociadoRef}
                />
              </div>
              <div className="form-group ">
                <label htmlFor="indicador">Indicador</label>
                <textarea
                  type="text"
                  className="form-control form-control-sm text-dark"
                  id="indicador"
                  placeholder="Nota indicador del producto"
                  ref={this.indicadorRef}
                />
              </div>
              <div className="form-group ">
                <label htmlFor="unidadResponsable">Unidad Responsable</label>
                <textarea
                  type="text"
                  className="form-control form-control-sm text-dark"
                  id="unidadResponsable"
                  placeholder="Nota de la unidad responsable"
                  ref={this.unidadResponsableRef}
                />
              </div>
              <div className="form-group ">
              <label htmlFor="involucrados">Involucrados</label>
              <textarea
                  type="text"
                  className="form-control form-control-sm text-dark"
                  id="involucrados"
                  placeholder="Involucrados"
                  ref={this.involucradoRef}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label htmlFor="resultado">Resultado</label>
                <textarea
                  type="text"
                  className="form-control form-control-sm text-dark"
                  id="resultado"
                  placeholder="Nota resultado del producto"
                  ref={this.resultadoRef}
                />
              </div>
              <div className="form-group ">
                <label htmlFor="accionMitigacion">Accion de la Mitigación</label>
                <textarea
                  type="text"
                  className="form-control form-control-sm text-dark"
                  id="accionMitigacion"
                  placeholder="Nota accion de la mitigación"
                  ref={this.accionMitigacionRef}
                />
              </div>

              <div className="form-group ">
                <label htmlFor="ponderacionArea">
                  Ponderación del Producto
                </label>
                <textarea
                  type="number"
                  className="form-control form-control-sm text-dark"
                  id="ponderacionArea"
                  placeholder="Nota de la ponderación del área"
                  ref={this.ponderacionAreaRef}
                />
              </div>
              <div className="form-group ">
                <label htmlFor="meta">Meta</label>
                <textarea
                  type="text"
                  className="form-control form-control-sm text-dark"
                  id="meta"
                  placeholder="Meta"
                  ref={this.metaRef}
                />
              </div>
              <div className="form-group ">
              <label htmlFor="cooperacion">Cooperación Externa</label>
              <textarea
                  type="text"
                  className="form-control form-control-sm text-dark"
                  id="cooperacion"
                  placeholder="Cooperación Externa"
                  ref={this.cooperacionRef}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group ">
                <label htmlFor="comentario">Comentario</label>
                <textarea
                  type="text"
                  className="form-control form-control-sm text-dark"
                  id="comentario"
                  placeholder="comentario"
                  ref={this.comentarioRef}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            onClick={() => {
              this.agregarNota();
              this.props.toggle();
            }}
          >
            Guardar
          </button>
          <button
            className="btn btn-warning"
            onClick={e => {
              e.preventDefault();
              this.limpiarCampos();
            }}
          >
            Limpiar
          </button>
          <button
            className="btn btn-danger"
            onClick={() => this.props.toggle()}
          >
            Cancelar
          </button>
        </ModalFooter>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = () => dispatch => ({
  agregarNotaProducto: notaProducto =>
    dispatch(agregarNotaProducto(notaProducto))
});
const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NotaProducto);
