import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";

class Contratos extends Component {
  state = {};

  render() {
    const { requerimientoId, numeroSolicitud, fechaCreacion,analista, requerimiento } = this.props.requerimiento;
    const { nombreDocumento = '' } = this.props.contrato || {};

    return (
      <tr>
        <td>-</td>
        <td>{(analista && analista.name !== null) ? analista.name : '-' }</td>
        <td>{numeroSolicitud}</td>
        <td>{requerimiento[requerimiento.length-1].area.nombre}</td>
        <td> 
          <Moment
              format="DD/MM/YYYY"
              date={fechaCreacion}
            >
            </Moment>
        </td>
        <td align="center">
          {nombreDocumento ? 
           <a 
            className="p-2" 
            title={nombreDocumento} 
            onClick={async () => {
              this.props.descargarContrato(this.props.requerimiento)
            }}
            >
            <i className="fas fa-download text-primary"></i>
          </a> :
          <a className="p-2">
            <i className="fas fa-download text-muted"></i>
          </a> 
          }
        </td>
        <td align="center">
          <a
            className=""
            onClick={(e) => {
              e.preventDefault();
              this.props.activarModalCertificacionContrato(requerimientoId, numeroSolicitud);
            }} >
            <i className="fas fa-cloud-upload-alt text-primary"></i>
          </a>
        </td>
      </tr>
    );
  }
}

export default connect(null, {})(Contratos);
