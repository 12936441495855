import {
  CREAR_PRODUCTOS_NO_POA,
  MOSTRAR_PRODUCTOS_NO_POA,
  MOSTRAR_PRODUCTO_NO_POA,
  EDITAR_PRODUCTO_NO_POA,
  ELIMINAR_PRODUCTO_NO_POA,
  LOADING_PRODUCTO_NO_POA,
  PRODUCTO_NO_POA_SUCCEEDED,
  PRODUCTO_NO_POA_FAILED,
  MOSTRAR_PRODUCTOS_NO_POA_ADMINISTRACION,
  TOTAL_PONDERACION_AREA_PRODUCTOS_NO_POA,
  TOTAL_PONDERACION_GENERAL_PRODUCTOS_NO_POA,
  TOTAL_PONDERACION_PRODUCTO_NO_POA_ACTIVIDADES,
  MOSTRAR_TOTAL_PRODUCTOS_NO_POA_ADMINISTRACION,
  CLEAN_PRODUCTOS_NO_POA_ADMINISTRACION,
  CREAR_ACTIVIDADES_PRODUCTOS_NOPOA,
  MOSTRAR_PRODUCTOS_NO_POA_INSUMOS
} from "../types";

import initialState from "../initial-state";

const { productosNoPoa } = initialState;

const productosNoPoaReducer = (state = productosNoPoa, action) => {
  switch (action.type) {
    //NO POA
    case LOADING_PRODUCTO_NO_POA:
      return {
        ...state,
        isLoading: true,
      };
    case PRODUCTO_NO_POA_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
      };
    case MOSTRAR_PRODUCTO_NO_POA:
      return {
        ...state,
        productoNopoa: action.payload,
      };
    case MOSTRAR_PRODUCTOS_NO_POA:
      return {
        ...state,
        productosNoPoa: action.payload,
      };
    case MOSTRAR_TOTAL_PRODUCTOS_NO_POA_ADMINISTRACION:
      return {
        ...state,
        totalProductosAdministracionNoPoa: action.payload,
      };
    case CREAR_PRODUCTOS_NO_POA:
      return {
        ...state,
        productosNopoa: [...state.productosNopoa, action.payload],
      };
    case EDITAR_PRODUCTO_NO_POA: {
      return {
        ...state,
        productosNoPoa: state.productosNoPoa.map((productoNopoa) =>
          productoNopoa.id === action.payload.id
            ? (productoNopoa = action.payload)
            : productoNopoa
        ),
      };
    }
    case TOTAL_PONDERACION_PRODUCTO_NO_POA_ACTIVIDADES: {
      return {
        ...state,
        productosNopoa: state.productosNopoa.map((productoNopoa) =>
          productoNopoa.productoId === action.payload.productoId
            ? {
                ...productoNopoa,
                totalPonderacionActividadesNopoa:
                  action.payload.totalPonderacionActividadesNopoa,
              }
            : productoNopoa
        ),
      };
    }
    case ELIMINAR_PRODUCTO_NO_POA:
      return {
        ...state,
        productosNopoa: [
          ...state.productosNopoa.filter(
            (productoNopoa) => productoNopoa.productoId !== action.payload
          ),
        ],
      };
    case MOSTRAR_PRODUCTOS_NO_POA_ADMINISTRACION:
      return {
        ...state,
        productosAdministracionNoPoa: action.payload,
      };
    case CLEAN_PRODUCTOS_NO_POA_ADMINISTRACION:
      return {
        ...state,
        productosAdministracionNoPoa: [],
      };
    case TOTAL_PONDERACION_AREA_PRODUCTOS_NO_POA:
      return {
        ...state,
        totalPonderacionProductosNopoa: action.payload,
      };
    case TOTAL_PONDERACION_GENERAL_PRODUCTOS_NO_POA:
      return {
        ...state,
        totalPonderacionGeneralProductosNopoa: action.payload.totalPoderacion,
      };
    case CREAR_ACTIVIDADES_PRODUCTOS_NOPOA:
        return {
          ...state,
          productoNopoa: {
            ...state.productoNopoa,
            actividades: [...state.productoNopoa.actividades, action.payload],
          },
        };
      case MOSTRAR_PRODUCTOS_NO_POA_INSUMOS:
        return {
          ...state,
          productosActividadInsumosNoPoa: action.payload,
        };

    default:
      return state;
  }
};

export default productosNoPoaReducer;
