import { MOSTRAR_TRIMESTRES, LOADING_TRIMESTRES, TRIMESTRES_FAILED, TRIMESTRES_SUCCESS } from "../types";
import { getData } from "../../utils/api-client"
import { api_base } from "../../config";

export const getTrimestres = () => async dispatch => {
    dispatch({ type: LOADING_TRIMESTRES });
    let url = `${api_base}api/v1/trimestres`;

    await getData.get(url)
    .then(resultado => {
        dispatch({ type: TRIMESTRES_SUCCESS });
        dispatch({ type: MOSTRAR_TRIMESTRES, payload: resultado.data });
    }).catch(error => {
        dispatch({ type: TRIMESTRES_FAILED });
    });
}