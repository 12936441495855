import {
  MOSTRAR_PROVEEDORES,
  MOSTRAR_PROVEEDOR,
  CREAR_PROVEEDOR,
  CREAR_PROVEEDOR_POR_REQUERIMIENTO,
  EDITAR_PROVEEDOR,
  ELIMINAR_PROVEEDOR,
  LOADING_PROVEEDOR,
  PROVEEDOR_SUCCEEDED,
  PROVEEDOR_FAILED,
  MOSTRAR_PROVEEDORES_INSUMOS_ADJUDICADOS,
  LOADING_PROVEEDOR_REQUERIMIENTO_COMPRA,
  MOSTRAR_PROVEEDORES_REQUERIMIENTOS_COMPRAS,
  PROVEEDOR_REQUERIMIENTO_COMPRA_SUCCEEDED,
  PROVEEDOR_REQUERIMIENTO_COMPRA_FAILED,
  ELIMINAR_PROVEEDOR_REQUERIMIENTO_COMPRA,
  CREAR_PROVEEDOR_REQUERIMIENTO_COMPRA
} from "../types";

import initialState from "../initial-state";

const { proveedores } = initialState;

const ProveedoresReducer = (state = proveedores, action) => {
  switch (action.type) {
    case MOSTRAR_PROVEEDORES:
      return {
        ...state,
        proveedores: action.payload
      };
    case CREAR_PROVEEDOR:
      return {
        ...state,
        proveedores: [...state.proveedores, action.payload]
      };
    case CREAR_PROVEEDOR_POR_REQUERIMIENTO:
      return {
        ...state,
        proveedores: [...state.proveedores_por_requerimiento, action.payload]
      };
    case MOSTRAR_PROVEEDOR:
      return {
        ...state,
        proveedor: action.payload
      };
    case MOSTRAR_PROVEEDORES_INSUMOS_ADJUDICADOS:
      return {
        ...state,
        proveedoresInsumosAdjudicados: action.payload
      };
    case MOSTRAR_PROVEEDORES_REQUERIMIENTOS_COMPRAS:
      return {
        ...state,
        proveedorRequerimientoCompra: action.payload
      };
    case ELIMINAR_PROVEEDOR_REQUERIMIENTO_COMPRA:
      return {
        ...state,
        proveedorRequerimientoCompra: [
          ...state.proveedorRequerimientoCompra.filter(
            (proveedorRequerimientoCompra) => proveedorRequerimientoCompra.proveedorRequerimientoCompraId !== action.payload
          ),
        ],
      };
    case CREAR_PROVEEDOR_REQUERIMIENTO_COMPRA:
      return {
        ...state,
        proveedorRequerimientoCompra: [...state.proveedores_por_requerimiento, action.payload]
      };
    default:
      return state;
  }
};
export default ProveedoresReducer;
