import React, { Component } from "react";
import {} from "react-router-dom";
import { connect } from "react-redux";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";
import { DateTimePicker } from "react-widgets";
import "react-widgets/dist/css/react-widgets.css";
import { getAreasPoaMonitoreo } from "../../redux/actions/area_poa_monitoreo_actions";
import { Authorization } from "../../utils/Authorization";
import swal from "sweetalert";

import { Container } from "reactstrap";
import Loading from "../utils/Loading";
import {
  getMonitoreosActividad,
  createMonitoreoActividad,
} from "../../redux/actions/monitoreo-actividad-actions";
import {
  getActividad,
  getActividadLocal,
} from "../../redux/actions/actividades-actions";

class MonitoreoActividadNoPoa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      selectedFile: null,
      file: [],
      fileEvidencias: [],
      fecha: "",
      documento: [],
      documentoEvidencias: [],
      trimestres: [
        { id: "T1", descripcion: "Primer Trimestre" },
        { id: "T2", descripcion: "Segundo Trimestre" },
        { id: "T3", descripcion: "Tercer Trimestre" },
        { id: "T4", descripcion: "Cuarto Trimestre" },
      ],
      trimestresEntity: [],
      poaMonitoreo: [],
      trimestreActivo: false,
      totalSizeFile: 0,
      totalSizeFileEvidencias: 0
    };
  }

  fechaRef = React.createRef();
  trimestreRef = React.createRef();
  ejecucionRef = React.createRef();
  comentarioRef = React.createRef();
  accionesRef = React.createRef();
  // nivelEjecucionTrimestreRef = React.createRef();

  componentDidMount() {
    const { actividadId } = this.props.match.params;
    this.cargarData(actividadId);
  }
  onChangeHandler = (event) => {
    this.setState(
      {
        selectedFile: event.target.files[0],
        loaded: 0,
      },
      () => {
        this.onClickHandler();
      }
    );
  };
  onClickHandler = () => {
    const data = new FormData();
    data.append("file", this.state.selectedFile);
    this.setState({ file: data }, () => {
      // console.log(data);
    });
  };

  createMonitoreo = async (e) => {
    e.preventDefault();

    const { producto, actividad } = this.props;
    if (
      this.validarMonitoreo(
        this.trimestreRef.current.value,
        this.ejecucionRef.current.value
      )
    ) {
      if (this.state.file.length <= 20 && this.state.totalSizeFile <= 30) {
        this.setState({ isLoading: true });
        let fecha =
          this.state.fecha !== ""
            ? new Date(this.state.fecha).toDateString()
            : "";

        const monitoreoActividad = {
          productoId: producto.productoId,
          actividadId: actividad.actividadId,
          fecha: fecha,
          comentario: this.comentarioRef.current.value,
          accionesMedidas: this.accionesRef.current.value,
          trimestre: this.trimestreRef.current.value,
          ejecucion: this.ejecucionRef.current.value,
          documento: this.state.documento !== "" ? this.state.documento : null,
          documentoEvidencias: this.state.documentoEvidencias !== "" ? this.state.documentoEvidencias : null
        };

        // console.log(monitoreoActividad);

        const fd = new FormData();
        // console.log("Enviando",this.state.file);
        if (this.state.file.length > 0) {
          for (let i = 0; i < this.state.file.length; i++) {
            fd.append(
              `imageFile[]`,
              this.state.file[i],
              this.state.documento[i]
            );
          }
        }

        if (this.state.fileEvidencias.length > 0) {
          for (let i = 0; i < this.state.fileEvidencias.length; i++) {
            fd.append(
              `imageFileEvidencias[]`,
              this.state.fileEvidencias[i],
              this.state.documentoEvidencias[i]
            );
          }
        }

        Object.keys(monitoreoActividad).forEach(function (item) {
          fd.append(item, monitoreoActividad[item]);
        });

        // console.log(monitoreoActividad)

        await this.props.createMonitoreoActividad(fd);
        this.setState({ isLoading: false });
        this.props.history.goBack();
      } else {
        swal(
          "Error de archivos",
          "La cantidad maxima de archivos permitidos son 20 o su equivalente a 30MB",
          "error"
        );
      }
    } else {
      swal(
        "Validar Campos",
        "Los campos del trimestre y ejecución son valores requeridos",
        "error"
      );
    }
  };

  validarMonitoreo = (trimestre, ejecucion) => {
    if (trimestre === "--Seleccionar Trimestre--" || ejecucion === "") {
      return false;
    }

    return true;
  };

  cargarData = async (actividadId) => {
    await this.props.getActividad(actividadId);
    const { poaId } = this.props.poaActivo;
    await this.props.getAreasPoaMonitoreo(poaId);
    let areaPoaMonitoreo = await this.props.areaPoaMonitoreo.filter(
      (area) =>
        area.areaOrganizacional.areaOrganizacionalId ===
          this.props.user.areaOrganizacional.areaOrganizacionalId && area
    );

    this.setState({
      poaMonitoreo:
        areaPoaMonitoreo.length > 0 ? areaPoaMonitoreo[0].poaMonitoreo : [],
    });

    await this.CargarTrimestre();
    this.setState({ isLoading: false });
  };

  CargarTrimestre = () => {
    let trimestreState = [...this.state.trimestres];
    for (let i = 0; i < trimestreState.length; i++) {
      for (let m = 0; m < this.props.actividad.monitoreo.length; m++) {
        if (
          this.props.actividad.monitoreo[m].trimestre === trimestreState[i].id
        ) {
          trimestreState.splice(i, 1);
          this.setState({ trimestresEntity: trimestreState });
          i = -1;
          break;
        }
      }
    }

    if (
      this.state.trimestresEntity.length === 0 &&
      this.props.actividad.monitoreo.length === 0
    ) {
      this.setState({ trimestresEntity: this.state.trimestres });
    }
  };

  render() {
    Moment.locale("en");
    momentLocalizer();

    let fechaRef = (e) => {
      // console.log(e);
      this.setState({ fecha: e });
    };
    const { isLoading, actividad } = this.state;
    const {
      descripcion,
      medioVerificacion,
      ponderacionActividad,
      t1,
      t2,
      t3,
      t4,
      monitoreo,
      unidadMedida,
    } = this.props.actividad;

    // console.log(this.state)
    return (
      <div className="pb-6 pt-5 pt-md-7">
        {!isLoading ? (
          <Container>
            <div className="container bg-white border">
              <form
                className="needs-validation p-3 "
                onSubmit={this.createMonitoreo}
                noValidate
              >
                <div className="form-group">
                  <label>Actividad</label>
                  <input
                    className="form-control text-dark form-control-sm"
                    type="text"
                    readOnly
                    defaultValue={descripcion}
                  ></input>
                </div>
                <div className="form-group">
                  <label>Medio Verificación</label>
                  <input
                    className="form-control text-dark form-control-sm"
                    type="text"
                    readOnly
                    defaultValue={medioVerificacion.descripcion}
                  ></input>
                </div>
                <div className="form-group">
                  <h3>Ejecución Programada</h3>
                  <div className="row">
                    <div className="col-md-3 text-center">
                      <label>E-M</label>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="text-dark">{unidadMedida}:</label>
                        </div>
                        <div className="col-md-8 input-group">
                          <input
                            className="form-control text-dark form-control-sm"
                            type="text"
                            readOnly
                            defaultValue={t1 !== null ? t1 : 0}
                          ></input>
                          {unidadMedida !== "Cantidad" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text text-dark form-control-sm "
                                style={{
                                  backgroundColor: "#e9ecef",
                                  borderLeft: "2px solid  #bebaba",
                                }}
                              >
                                %
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 text-center">
                      <label>A-J</label>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="text-dark">{unidadMedida}:</label>
                        </div>
                        <div className="col-md-8 input-group">
                          <input
                            className="form-control text-dark form-control-sm"
                            type="text"
                            readOnly
                            defaultValue={t2 !== null ? t2 : 0}
                          ></input>
                          {unidadMedida !== "Cantidad" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text text-dark form-control-sm "
                                style={{
                                  backgroundColor: "#e9ecef",
                                  borderLeft: "2px solid  #bebaba",
                                }}
                              >
                                %
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 text-center">
                      <label>J-S</label>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="text-dark">{unidadMedida}:</label>
                        </div>
                        <div className="col-md-8 input-group">
                          <input
                            className="form-control text-dark form-control-sm"
                            type="text"
                            readOnly
                            defaultValue={t3 !== null ? t3 : 0}
                          ></input>
                          {unidadMedida !== "Cantidad" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text text-dark form-control-sm "
                                style={{
                                  backgroundColor: "#e9ecef",
                                  borderLeft: "2px solid  #bebaba",
                                }}
                              >
                                %
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 text-center">
                      <label>O-D</label>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="text-dark">{unidadMedida}:</label>
                        </div>
                        <div className="col-md-8 input-group">
                          <input
                            className="form-control text-dark form-control-sm"
                            type="text"
                            readOnly
                            defaultValue={t4 !== null ? t4 : 0}
                          ></input>
                          {unidadMedida !== "Cantidad" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text text-dark form-control-sm "
                                style={{
                                  backgroundColor: "#e9ecef",
                                  borderLeft: "2px solid  #bebaba",
                                }}
                              >
                                %
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <h2 className="mb-0">Nivel Ejecución</h2>
                  <hr className="mt-0"></hr>
                  <div className="row">
                    <div className="col-md-4">
                      <label>Trimestre</label>
                      <select
                        className="form-control text-dark "
                        ref={this.trimestreRef}
                        onChange={(e) => {
                          e.preventDefault();

                          if (
                            this.state.poaMonitoreo.find((monitoreo) =>
                              monitoreo.trimestreId ===
                              this.trimestreRef.current.value
                                ? !monitoreo.status
                                : false
                            )
                          ) {
                            this.setState({ trimestreActivo: true });
                            swal(
                              "Monitoreos",
                              `El trimestre ${this.trimestreRef.current.value}, está cerrado favor comunicarse al Departamento de Formulación, Monitoreo y Evaluación de Planes, Programas y Proyectos`,
                              "error"
                            );
                          } else {
                            this.setState({ trimestreActivo: false });
                          }
                        }}
                      >
                        <option defaultValue="">
                          --Seleccionar Trimestre--
                        </option>
                        {this.state.trimestresEntity.length > 0
                          ? this.state.trimestresEntity.map((t) => (
                              <option key={t.id} value={t.id}>
                                {t.descripcion}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label>Ejecución</label>
                      <input
                        className="form-control text-dark "
                        type="text"
                        ref={this.ejecucionRef}
                        placeholder="Ejecución Trimestre"
                      ></input>
                    </div>
                    <div className="col-md-4">
                      <label>Fecha</label>
                      <DateTimePicker
                        footer={false}
                        time={false}
                        format={"DD/MM/YYYY"}
                        placeholder="Fecha Ejecución"
                        ref={this.fechaRef}
                        onChange={fechaRef}
                        className="form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Nivel de avance</label>
                  <textarea
                    className="form-control text-dark form-control-sm"
                    type="text"
                    placeholder="Nivel de avance"
                    ref={this.comentarioRef}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label>Justificación de no cumplimiento</label>
                  <textarea
                    className="form-control text-dark"
                    type="text"
                    placeholder="Justificación de no cumplimiento"
                    ref={this.accionesRef}
                  ></textarea>
                </div>
                {/* <div className="form-group">
                  <label>Nivel de Ejecución Trimestre</label>
                  <textarea
                    className="form-control text-dark"
                    type="text"
                    placeholder="Nivel de Ejecución Trimestre"
                    ref={this.nivelEjecucionTrimestreRef}
                  ></textarea>
                </div> */}

                <div className="form-group">
                  <label>Cargar Medios de Verificación (PDF)</label>
                  <input
                    id="inputpdf"
                    className="form-control text-dark"
                    type="file"
                    accept=".pdf"
                    name="file[]"
                    multiple
                    onChange={(e) => {
                      e.preventDefault();

                      ////////////////////////////////////////////////////////////
                      var fileInput = document.getElementById("inputpdf");
                      var filePath = fileInput.value;
                      var allowedExtensions = /(\.pdf|\.PDF)$/i;
                      if (!allowedExtensions.exec(filePath)) {
                        swal(
                          "Error de tipo de archivo",
                          "Debe cargar archivos en formato PDF",
                          "error"
                        );
                        fileInput.value = "";
                        return false;
                      }
                      ///////////////////////////////////////////////

                      let totalSize = 0;
                      let files = [];
                      let documentos = [];
                      // console.log(e.target.files);
                      if (e.target.files.length > 0) {
                        // console.log(e.target);
                        for (let i = 0; i < e.target.files.length; i++) {
                          // console.log(e.target.files[i]);
                          totalSize += e.target.files[i].size;
                          files.push(e.target.files[i]);
                          documentos.push(e.target.files[i].name);
                        }

                        if (
                          totalSize / 1024 / 1024 > 30 ||
                          e.target.files.length > 20
                        ) {
                          swal(
                            "Error de archivos",
                            "La cantidad maxima de archivos permitidos son 20 o su equivalente a 30MB",
                            "error"
                          );
                        }
                        this.setState({
                          file: files,
                          documento: documentos,
                          totalSizeFile: totalSize / 1024 / 1024,
                        });
                      } else {
                        this.setState({
                          file: "",
                          documento: "",
                          totalSizeFile: 0,
                        });
                      }
                    }}
                  />
                </div>

                <div className="form-group">
                  <label>Evidencias de la Justificación de no Cumplimiento (PDF)</label>
                  <input
                    id="inputpdfEvidencias"
                    className="form-control text-dark"
                    type="file"
                    accept=".pdf"
                    name="fileEvidencias[]"
                    multiple
                    onChange={(e) => {
                      e.preventDefault();

                      ////////////////////////////////////////////////////////////
                      var fileInput = document.getElementById("inputpdfEvidencias");
                      var filePath = fileInput.value;
                      var allowedExtensions = /(\.pdf|\.PDF)$/i;
                      if (!allowedExtensions.exec(filePath)) {
                        swal(
                          "Error de tipo de archivo",
                          "Debe cargar archivos en formato PDF",
                          "error"
                        );
                        fileInput.value = "";
                        return false;
                      }
                      ///////////////////////////////////////////////

                      let totalSize = 0;
                      let files = [];
                      let documentos = [];
                      // console.log(e.target.files);
                      if (e.target.files.length > 0) {
                        // console.log(e.target);
                        for (let i = 0; i < e.target.files.length; i++) {
                          // console.log(e.target.files[i]);
                          totalSize += e.target.files[i].size;
                          files.push(e.target.files[i]);
                          documentos.push(e.target.files[i].name);
                        }

                        if (
                          totalSize / 1024 / 1024 > 30 ||
                          e.target.files.length > 20
                        ) {
                          swal(
                            "Error de archivos",
                            "La cantidad maxima de archivos permitidos son 20 o su equivalente a 30MB",
                            "error"
                          );
                        }
                        this.setState({
                          fileEvidencias: files,
                          documentoEvidencias: documentos,
                          totalSizeFileEvidencias: totalSize / 1024 / 1024,
                        });
                      } else {
                        this.setState({
                          fileEvidencias: "",
                          documentoEvidencias: "",
                          totalSizeFileEvidencias: 0,
                        });
                      }
                    }}
                  />
                </div>


                <button
                  className="btn btn-info"
                  type="submit"
                  disabled={this.state.trimestreActivo}
                >
                  Guardar
                </button>
              </form>
            </div>
          </Container>
        ) : (
          <Loading></Loading>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  actividad: state.actividades.actividad,
  producto: state.productos.producto,
  poaActivo: state.poas.poaActivo,
  isLoading: state.monitoreosActividad.isLoading,
  user: state.auth.signed,
  areaPoaMonitoreo: state.areaPoaMonitoreo.areaPoaMonitoreo,
});
const mapDispatchToProps = (dispatch) => ({
  getActividadLocal: (actividadId) => dispatch(getActividadLocal(actividadId)),
  getMonitoreosActividad: (actividadId) =>
    dispatch(getMonitoreosActividad(actividadId)),
  getActividad: (actividadId) => dispatch(getActividad(actividadId)),
  createMonitoreoActividad: (monitoreosActividad) =>
    dispatch(createMonitoreoActividad(monitoreosActividad)),
  getAreasPoaMonitoreo: (poaId) => dispatch(getAreasPoaMonitoreo(poaId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(MonitoreoActividadNoPoa, [
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Analista del POA",
    "MAE",
  ])
);
