import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "./../../utils/Loading.jsx";

import {
  ModalFooter,
  ModalBody
} from "reactstrap";

class NotaActividad extends Component {
  constructor(props) {
    super(props);
    this.descripcionRef = React.createRef();
    this.actividadRef = React.createRef();
    this.medioVerificacionRef = React.createRef();
    this.t1Ref = React.createRef();
    this.t2Ref = React.createRef();
    this.t3Ref = React.createRef();
    this.t4Ref = React.createRef();
    this.ponderacionActividadRef = React.createRef();
  }
  componentDidMount() {
    if (this.props.notaActividad) {
      const {
        descripcion,
        medioVerificacion,
        t1,
        t2,
        t3,
        t4,
        ponderacionActividad
      } = this.props.notaActividad;

      this.descripcionRef.current.value =
        descripcion != null ? descripcion : "";

      this.medioVerificacionRef.current.value =
        medioVerificacion != null ? medioVerificacion : "";
      this.t1Ref.current.value = t1 != null ? t1 : "";
      this.t2Ref.current.value = t2 != null ? t2 : "";
      this.t3Ref.current.value = t3 != null ? t3 : "";
      this.t4Ref.current.value = t4 != null ? t4 : "";
      this.ponderacionActividadRef.current.value =
        ponderacionActividad != null ? ponderacionActividad : "";
    }
  }

  agregarNota = e => {
    e.preventDefault();
    let actividad = {
      descripcion:
        this.descripcionRef.current.value !== ""
          ? this.descripcionRef.current.value
          : null,
      actividad: this.props.actividad,

      medioVerificacion:
        this.medioVerificacionRef.current.value !== ""
          ? this.medioVerificacionRef.current.value
          : null,
      t1: this.t1Ref.current.value !== "" ? this.t1Ref.current.value : null,
      t2: this.t2Ref.current.value !== "" ? this.t2Ref.current.value : null,
      t3: this.t3Ref.current.value !== "" ? this.t3Ref.current.value : null,
      t4: this.t4Ref.current.value !== "" ? this.t4Ref.current.value : null,
      ponderacionActividad:
        this.ponderacionActividadRef.current.value !== ""
          ? this.ponderacionActividadRef.current.value
          : null
    };
    this.props.agregarNotaActividad(actividad);
    this.props.activarModalActividad(actividad);
  };

  render() {
    const { actividad, isLoading } = this.props;

    return (
      <React.Fragment>
        <ModalBody>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group ">
                <label htmlFor="descripcion">Actividad</label>
                <textarea
                  type="text"
                  className="form-control form-control-sm text-dark"
                  id="descripcion"
                  placeholder="Descripcion de la Actividad"
                  ref={this.descripcionRef}
                />
              </div>
              <div className="form-group ">
                <label htmlFor="t1">Primer Trimestre</label>
                <textarea
                  type="text"
                  className="form-control form-control-sm text-dark"
                  id="t1"
                  ref={this.t1Ref}
                  placeholder="Primer Trimestre"
                />
              </div>
              <div className="form-group ">
                <label htmlFor="t3">Tercer Trimestre</label>
                <textarea
                  type="text"
                  className="form-control form-control-sm text-dark"
                  id="t3"
                  ref={this.t3Ref}
                  placeholder="Tercer Trimestre"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label htmlFor="medioVerificacion">Medio Verificación</label>
                <textarea
                  type="text"
                  className="form-control form-control-sm text-dark"
                  id="medioVerificacion"
                  ref={this.medioVerificacionRef}
                  placeholder="Medio de verificación"
                />
              </div>
              <div className="form-group ">
                <label htmlFor="t2">Segundo Trimestre</label>
                <textarea
                  type="text"
                  className="form-control form-control-sm text-dark"
                  id="t2"
                  ref={this.t2Ref}
                  placeholder="segundo Trimestre"
                />
              </div>
              <div className="form-group ">
                <label htmlFor="t4">Cuarto Trimestre</label>
                <textarea
                  type="text"
                  className="form-control form-control-sm text-dark"
                  id="t4"
                  ref={this.t4Ref}
                  placeholder="Cuarto Trimestre"
                />
              </div>
            </div>
          </div>
          <div className="form-group ">
            <label htmlFor="ponderacionActividad">Ponderación Actividad</label>
            <textarea
              type="number"
              className="form-control form-control-sm text-dark"
              id="ponderacionActividad"
              ref={this.ponderacionActividadRef}
              placeholder="Ponderación Actividad"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            onClick={e => this.agregarNota(e)}
          >
            Guardar
          </button>
          <button
            className="btn btn-warning"
            onClick={() => this.props.activarModalActividad()}
          >
            Cancelar
          </button>
        </ModalFooter>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = () => {};

export default connect(null, null)(NotaActividad);
