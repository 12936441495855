import { MOSTRAR_ESTADOS, LOADING_ESTADOS, ESTADOS_FAILED } from "../types";
import { api_base } from "../../config";
import { getData } from "../../utils/api-client";

export const getListaEstados = () => async dispatch => {

    let url = api_base + "api/v1/estado";

    await getData
        .get(url)
        .then(resultado => {
            dispatch({ type: LOADING_ESTADOS });
            dispatch({ type: MOSTRAR_ESTADOS, payload: resultado.data });
        })
        .catch(error => {
            if (error) {
                dispatch({ type: ESTADOS_FAILED });
            } else {
                window.location.reload();
            }
        });
};
