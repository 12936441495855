import {
    MOSTRAR_POA,
    MOSTRAR_POA_ACTIVO,
    MOSTRAR_POAS,
    CREAR_POA,
    EDITAR_POA,
    ELIMINAR_POA,
    MOSTRAR_DETALLE_POA,
    MOSTRAR_POA_ELABORACION
} from "../types";

import initialState from "./../initial-state";

const { poas } = initialState;

const poaReducer = (state = poas, action) => {
    switch (action.type) {
        case MOSTRAR_POAS:
            return {
                ...state,
                poas: action.payload
            };
        case CREAR_POA:
            return {
                ...state,
                poas: [...state.poas, action.payload]
            };
        case MOSTRAR_POA:
            return {
                ...state,
                poa: action.payload
            };
        case MOSTRAR_POA_ACTIVO:
            return {
                ...state,
                poaActivo: action.payload
            };
        case MOSTRAR_POA_ELABORACION:
            return {
                ...state,
                poaElaboracion: action.payload
            };
        case EDITAR_POA:
            return {
                ...state,
                poas: state.poas.map(poa =>
                    poa.poaId === action.payload.poaId ? (poa = action.payload) : poa
                )
            };
        case ELIMINAR_POA:
            return {
                ...state,
                poas: state.poas.filter(poa => poa.poaId !== action.payload)
            };
        case MOSTRAR_DETALLE_POA:
            return {
                ...state,
                detallePoa: action.payload
            };
        default:
            return state;
    }
};

export default poaReducer;
