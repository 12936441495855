import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

import NumericFormat  from 'react-number-format';

export const ModalAsignacionItemsProveedor = (props) => {
    const toggle = () => props.activarModalAsignacionItemsProveedor(props.modalAsignacionItemsProveedorStatus);
  
    const {desabilitarboton,requerimiento, proveedorRequerimientoCompra, requerimientoId, modalAsignacionItemsProveedorStatus, actividadesArticuloSeguimiento, proveedores, proveedoresRef, obtenerProveedores, obtenerArticulos, precioAdjudicacionRef, obtenerPreciosAdjudicados, proveedoresInsumosAdjudicados } = props;

    const proveedorIds = proveedoresInsumosAdjudicados.map(item => item.proveedor.proveedorId);
    const proveedorRequerimientoIds = proveedorRequerimientoCompra.map(item => item.proveedor.proveedorId);
    const articulosSeguimientos = [];
    let datosDisponiblesPrinted = false;

    const cuerpo = (items) => {
      // console.log("items",items)
      let presupuesto ={}
      if(!requerimiento["requerimiento"].requerimientoActividad[0].noPoa){
         presupuesto = requerimiento['presupuesto'].find(x=> x.presupuestoId ==items.articuloId);
      }else{
        presupuesto = requerimiento['presupuestoNoPoa'].find(x=> x.presupuestoNoPoaId ==items.articuloId);
      }
  
      if (!articulosSeguimientos.some(item => item === items)) {
        articulosSeguimientos.push(items);
        return (
          <tr key={items.articuloId}>
            <td>
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id={`articuloId-${items.articuloId}`}
                        type="checkbox"
                        onChange={obtenerArticulos}
                        value={items.articuloId}
                    />
                    <label className="custom-control-label" style={{ paddingTop: '0.2rem' }} htmlFor={`articuloId-${items.articuloId}`}>
                        {presupuesto.subclase}
                    </label>
                </div>
            </td>
            <td style={{ whiteSpace: 'unset' }}>{items.nombreArticulo}</td>
            <td>
                <NumericFormat 
                  prefix={'RD$ '}
                  id={`precioReferencia-${items.articuloId}`}
                  className="form-control form-control-sm" 
                  defaultValue={items.precioReferencia !== null ? items.precioReferencia : '0'} 
                  allowLeadingZeros 
                  thousandSeparator="," 
                  disabled
              />
            </td>
            <td className="d-flex flex-row">
            <NumericFormat 
              prefix={'RD$ '}
              id={`precioAdjudicacion-${items.articuloId}`} 
              className="form-control form-control-sm" 
              defaultValue={items.precioAdjudicacion !== null ? items.precioAdjudicacion : '0'} 
              allowLeadingZeros 
              thousandSeparator="," 
              disabled
              onChange={(event) => obtenerPreciosAdjudicados(event, items.articuloId) }
            />
            </td>
        </tr>
        )
      }
    }

    return (
        <Modal isOpen={modalAsignacionItemsProveedorStatus} toggle={toggle} size="lg" backdrop="static">
          <ModalHeader className="block-example border-bottom " toggle={toggle}>
            ASIGNACIÓN DE ITEMS DEL PROVEEDOR
          </ModalHeader>
          <ModalBody>
            {proveedores.filter((prov) => !proveedorRequerimientoIds.includes(prov.proveedorId)).length === 0 && (
              <div className="col-md-6 mb-3">
                <p>No hay proveedores disponibles</p>
              </div>
            )}
            {proveedores.filter((prov) => !proveedorRequerimientoIds.includes(prov.proveedorId)).length !== 0 && (
              <>
                <div className="col-md-4 mb-3">
                  <label>Seleccione a un proveedor</label>
                  <select
                    id="proveedor"
                    className="form-control select form-control-sm text-capitalize"
                    name="proveedores"
                    ref={proveedoresRef}
                    onChange={obtenerProveedores}
                  >
                    {proveedores.map((prov) => (
                      !proveedorRequerimientoIds.includes(prov.proveedorId) && (
                        <option
                          key={prov.proveedorId}
                          value={prov.proveedorId}
                          defaultValue={proveedorIds.includes(prov.proveedorId)}
                        >
                          {prov.nombre}
                        </option>
                      )
                    ))}
                  </select>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Table style={{ tableLayout: 'fixed' }}>
                      <thead>
                        <tr>
                          <th>Sub-clase</th>
                          <th>Nombre</th>
                          <th>Precio de referencia</th>
                          <th>Precio de adjudicación</th>
                        </tr>
                      </thead>
                      <tbody>
                      {proveedores.map((proveedor) => {
                        if (!proveedorRequerimientoIds.includes(proveedor.proveedorId)) {
                              const filteredItems = actividadesArticuloSeguimiento.filter(item => !proveedoresInsumosAdjudicados.some(adjudicado => adjudicado.requerimiento.requerimientoId === item.requerimientoId));

                              if (filteredItems.length === 0 && !datosDisponiblesPrinted) {
                                  datosDisponiblesPrinted = true;
                                  return <td className="text-danger text-center h1" colspan="4">No hay datos disponibles.</td>;
                              } else {
                                  return filteredItems.map((item) => (
                                      cuerpo(item)
                                  ));
                              }
                          }
                          return null;
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="row">
              <div className="">
                <button
                  className="btn btn-danger"
                  disabled={desabilitarboton}
                  onClick={(e) => {
                    e.preventDefault();
                    props.activarModalAsignacionItemsProveedor(props.modalAsignacionItemsProveedor);
                  }}
                >
                  Cancelar
                </button>
              </div>
              {proveedores.filter((prov) => !proveedorRequerimientoIds.includes(prov.proveedorId)).length !== 0 && (
                <div className="ml-3">
                    <button
                    className="btn btn-primary"
                    disabled={desabilitarboton}
                    onClick={(e) => {
                        e.preventDefault();
                        props.guardarProveedorRequerimientoCompra(requerimientoId);
                    }}
                    >
                    Guardar
                    </button>
                </div>
                )}
            </div>
      
          </ModalFooter>
        </Modal>
      );
      
};
