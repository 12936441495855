import {
    MOSTRAR_MONITOREOS_ACTIVIDAD,
    MOSTRAR_MONITOREO_ACTIVIDAD,
    MOSTRAR_ADMIN_MONITOREO_ACTIVIDAD,
    CREAR_MONITOREO_ACTIVIDAD,
    ELIMINAR_MONITOREO_ACTIVIDAD,
    EDITAR_MONITOREO_ACTIVIDAD,
    MOSTRAR_REPORTE_MONITOREO_DETALLE,
    LOADING_MONITOREO_ACTIVIDAD

} from "./../types";
import initialState from "./../initial-state";
const { monitoreosActividad } = initialState;
const MonitoreoActividadReducer = (state = monitoreosActividad, action) => {
    switch (action.type) {
        case MOSTRAR_MONITOREOS_ACTIVIDAD:
            return {
                ...state,
                monitoreosActividad: action.payload
            };
        case MOSTRAR_ADMIN_MONITOREO_ACTIVIDAD:
            return {
                ...state,
                adminMonitoreoActividad: action.payload
            };
        case MOSTRAR_REPORTE_MONITOREO_DETALLE:
            return {
                ...state,
                reporteMonitoreoDetalle: action.payload[0]
            }

        case MOSTRAR_MONITOREO_ACTIVIDAD:
            return {
                ...state,
                monitoreoActividad: action.payload
            };
        case CREAR_MONITOREO_ACTIVIDAD:
            return {
                ...state,
                monitoreosActividad: [...state, action.payload]
            };
        case EDITAR_MONITOREO_ACTIVIDAD:
            return {
                ...state,
                adminMonitoreoActividad:state.adminMonitoreoActividad.length>0? state.adminMonitoreoActividad.filter(monitoreo=> monitoreo.monitoreoId !== action.payload.monitoreoId):[]
            };
        case ELIMINAR_MONITOREO_ACTIVIDAD:
            return {
                ...state,
                monitoreosActividad: [
                    ...state.monitoreosActividad.filter(
                        monitoreo => monitoreo.monitoreoId !== action.payload
                    )
                ]
            };
        case LOADING_MONITOREO_ACTIVIDAD:
            return{
                ...state,
                isLoading :action.payload
            }
        default:
            return state;
    }
};

export default MonitoreoActividadReducer;