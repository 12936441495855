import { MOSTRAR_AREAS, LOADING_AREAS, AREAS_FAILED } from "./../types";
import { api_base } from "../../config";
import { getData } from "./../../utils/api-client";

export const getAreasOrganizacionales = () => async dispatch => {

    let url = api_base + "api/v1/areas";

    await getData
        .get(url)
        .then(resultado => {
            dispatch({ type: LOADING_AREAS });
            dispatch({ type: MOSTRAR_AREAS, payload: resultado.data });
            // //console.log(resultado.data);
        })
        .catch(error => {
            if (error) {
                dispatch({ type: AREAS_FAILED });
            } else {
                window.location.reload();
            }
        });
};

export const getAreasOrganizacionalesResponsables = () => async dispatch => {

    let url = api_base + "api/v1/areasResponsables";

    await getData
    .get(url)
    .then(resultado => {
        dispatch({ type: LOADING_AREAS });
        dispatch({ type: MOSTRAR_AREAS, payload: resultado.data });
    })
    .catch(error => {
        if (error.response) {
            dispatch({ type: AREAS_FAILED });
        } else {
            window.location.reload();
        }
    });
};