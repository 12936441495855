import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import swal from "sweetalert";
import {
  eliminarPoa,
  aprobarPoa,
  reAbrirPoa,
  cerrarPoa,
} from "./../../redux/actions/poa-actions";
class Poa extends Component {
  state = {
  
  };

  validarAprobarPoa=()=>{
    let resultado = true;
    this.props.poas.map(poa=>{
      poa.nivelAprobacion.nivelAprobacionId===7&&(resultado=false) 
    });
    return resultado;
  }

  eliminarPoa =  (poaId) => {
    swal({
      title: "Está seguro de eliminar el Poa?",
      text: "Una vez eliminado no podrá ser recuperado!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async(confirm) => {
      if (confirm) {
        this.props.setLoading()
        await  this.props.eliminarPoa(poaId);
        this.props.setLoading()
      }
    });
  };

  aprobarPoa = (poaId) => {
    if (this.validarAprobarPoa()) {
      swal({
        title: "Esta seguro de aprobar el Poa?",
        text: "Una vez aprobado no podrá ser editado!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then(async (confirm) => {
        if (confirm) {
          this.props.setLoading()
          await this.props.aprobarPoa(poaId);
          this.props.setLoading()
        }
      });
    } else {
      swal({
        title: "Aprobar POA",
        text: "Ya existe un POA en proceso, debe cerrar el anterior para poder aprobar este!",
        icon: "error",
        buttons: true,
        dangerMode: true,
      })
      .then((confirm) => {
        if (confirm) {
          // TODO: lógica para despues de confirmar
        } else {
          // TODO: lógica para despues de confirmar
        }
      });
    }
  };

  cerrarPoa = (poaId) => {
    swal({
      title: "Esta seguro de cerrar el Poa?",
      text: "Una vez cerrado, no podrá ser actualizado!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (confirm) => {
      if (confirm) {
        this.props.setLoading()
       await this.props.cerarPoa(poaId);
        this.props.setLoading()
      } else {
       
      }
    });
  };

  reAbrirPoa = (poaId) => {
    swal({
      title: "Esta seguro de re-abrir el Poa?",
      text: "Permitira agregar nuevos productos y modificar los que sean devueltos!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (confirm) => {
      if (confirm) {
        this.props.setLoading()
       await this.props.reAbrirPoa(poaId);
        this.props.setLoading()
      } else {
       
      }
    });
  };

  render() {
    const { poaId, ano, nombre, fechaCierre, nivelAprobacion } = this.props.poa;
    return (
      <tr className="text-left">
        <td>{poaId}</td>
        <td>{ano}</td>
        <td>{nombre}</td>
        <td>{moment(fechaCierre).format("DD/MM/YYYY")}</td>
        <td>
          <Link
            to={{
              pathname: `/admin/poa/detalle/${ano}/${poaId}`,
              state: "/admin/poa/detalle/:ano/:poaId",
            }}
            className="btn btn-sm btn-warning"
          >
            Detalle
          </Link>
          {this.props.opcionAdmin?(
            <>
         { nivelAprobacion.nivelAprobacionId === 1 && (
           
            <button
              onClick={(e) => {
                e.preventDefault();
                this.aprobarPoa(poaId);
              }}
              className="btn btn-sm btn-info"
            >
              Aprobar
            </button>
            
          )}
          {nivelAprobacion.nivelAprobacionId === 7 && (
            <React.Fragment>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.cerrarPoa(poaId);
                }}
                className="btn btn-sm btn-info "
              >
                Cerrar
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.reAbrirPoa(poaId);
                }}
                className="btn btn-sm btn-primary "
              >
                Re-Abrir
              </button>
            </React.Fragment>
          )}
         { nivelAprobacion.nivelAprobacionId === 6 && (
            <button
              onClick={(e) => e.preventDefault()}
              className="btn btn-sm btn-info opacity"
            >
              Cerrado
            </button>
          )}
          <Link
            to={{
              pathname: `/admin/poa/editar/${poaId}`,
              state: "/admin/poa/editar/:poaId",
            }}
            className="btn btn-success btn-sm"
          >
            Editar
          </Link>
          <button
            onClick={() => this.eliminarPoa(poaId)}
            className="btn btn-danger btn-sm"
          >
            Eliminar
          </button>
          </>
          ):(
            <>
               { nivelAprobacion.nivelAprobacionId === 1 && (
                <button
                  onClick={(e) => e.preventDefault()}
                  className="btn btn-sm btn-info "
                >
                   Elaboración
                </button>
              )}
             { nivelAprobacion.nivelAprobacionId === 7 && (
                <React.Fragment>
                <button
                    onClick={(e) => e.preventDefault()}
                    className="btn btn-sm btn-default "
                >
                  Proceso
                </button>
            </React.Fragment>
              )}
              {nivelAprobacion.nivelAprobacionId === 6 && (
                <button
              onClick={(e) => e.preventDefault()}
              className="btn btn-sm btn-success "
            >
              Cerrado
            </button>
              )}
              </>
          )}
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state)=>({
  user: state.auth.signed
})
const mapDispatchToProps = (dispatch) => ({
  eliminarPoa: (poaId) => dispatch(eliminarPoa(poaId)),
  aprobarPoa: (poaId) => dispatch(aprobarPoa(poaId)),
  cerarPoa: (poaId) => dispatch(cerrarPoa(poaId)),
  reAbrirPoa: (poaId) => dispatch(reAbrirPoa(poaId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Poa);
