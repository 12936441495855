import React, { Component } from "react";
import Loading from "./Loading";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import swal from "sweetalert";

export  const ModalDevolucionInformesTrimestralesTodasAreas = (props) => {
  const toggle = () => props.activarModalDevolucionInformeTrimestralTodasAreas();
  let comentarioRef = React.createRef();
  const { modalInformeTrimestralTodasAreas } = props;
  return (
    <Modal isOpen={modalInformeTrimestralTodasAreas} toggle={toggle} size="lg" backdrop="static">
      <ModalHeader className="block-example border-bottom " toggle={toggle}>
        Razón Devolución Informe Trimestral
      </ModalHeader>
      <ModalBody>
        <textarea
          placeholder="Comentario de la devolución"
          ref={comentarioRef}
          className="form-control"
          rows="3"
        ></textarea>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-danger btn-sm"
          onClick={(e) => {
            e.preventDefault();

            if (
              comentarioRef.current != null &&
              comentarioRef.current.value !== ""
            ) {
              props.rechazarInformesTrimestralesTodasAreas(
                comentarioRef.current.value
              );
            } else {
                swal("Informes Trimestrales","El comentario es un valor requerido para rechazar el informe trimestral","error");
            }
          }}
        >
          Devolver
        </button>
        <button
          className="btn btn-info btn-sm"
          onClick={(e) => {
            e.preventDefault();
            toggle();
          }}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  );
};

export  const ModalDevolucionInformeTrimestral = (props) => {
  const toggle = () => props.activarModalDevolucionInformeTrimestral();
  let comentarioRef = React.createRef();
  const { modalInformeTrimestral } = props;
  return (
    <Modal isOpen={modalInformeTrimestral} toggle={toggle} size="lg" backdrop="static">
      <ModalHeader className="block-example border-bottom " toggle={toggle}>
        Razón Devolución Informe Trimestral
      </ModalHeader>
      <ModalBody>
        <textarea
          placeholder="Comentario de la devolución"
          ref={comentarioRef}
          className="form-control"
          rows="3"
        ></textarea>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-danger btn-sm"
          onClick={(e) => {
            e.preventDefault();

            if (
              comentarioRef.current != null &&
              comentarioRef.current.value !== ""
            ) {
              props.rechazarInformeTrimestral(
                props.informeTrimestral,
                comentarioRef.current.value
              );
            } else {
                swal("Informes Trimestrales","El comentario es un valor requerido para rechazar el informe trimestral","error");
            }
          }}
        >
          Devolver
        </button>
        <button
          className="btn btn-info btn-sm"
          onClick={(e) => {
            e.preventDefault();
            toggle();
          }}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  );
};
