import { MOSTRAR_TIPOS_COMPRAS, LOADING_TIPOS_COMPRAS, TIPOS_COMPRAS_FAILED, ELIMINAR_TIPO_COMPRA, TIPOS_COMPRAS_SUCCESS, EDITAR_TIPO_COMPRA, CREAR_TIPO_COMPRA } from "../types";
import { api_base } from "../../config";
import { getData } from "../../utils/api-client";

export const getListaTipoCompras = () => async dispatch => {

    let url = api_base + "api/v1/tipocompras";

    await getData
        .get(url)
        .then(resultado => {
            dispatch({ type: LOADING_TIPOS_COMPRAS });
            dispatch({ type: MOSTRAR_TIPOS_COMPRAS, payload: resultado.data });
        })
        .catch(error => {
            
                dispatch({ type: TIPOS_COMPRAS_FAILED });
             
        });
};

export const getTipoCompra = (tipoCompraId) => async (dispatch) => {
    let url = api_base + `api/v1/tipocompras/${tipoCompraId}`;
    dispatch({ type: LOADING_TIPOS_COMPRAS });
    await getData
      .get(url)
      .then((resultado) => {
        dispatch({ type: MOSTRAR_TIPOS_COMPRAS, payload: resultado.data });
        dispatch({ type: TIPOS_COMPRAS_SUCCESS });
      })
      .catch((error) => {
        if (error) {
          dispatch({ type: TIPOS_COMPRAS_FAILED });
        }
      });
  };

  export const crearTipoCompra = (nombre) => async (dispatch) => {
    let url = api_base + "api/v1/tipocompra/crear";
  
    dispatch({ type: LOADING_TIPOS_COMPRAS });
    await getData
      .post(url, {nombre: nombre})
      .then((resultado) => {
        dispatch({
          type: CREAR_TIPO_COMPRA,
          payload: resultado.data,
        });
        dispatch({ type: TIPOS_COMPRAS_SUCCESS });
      })
      .catch((error) => {
        if (error) {
          dispatch({ type: TIPOS_COMPRAS_FAILED });
        }
      });
  };

  export const actualizarTiposCompras = (compra) => async (dispatch) => {
    let url = api_base + "api/v1/tipocompra/editar";

    dispatch({ type: LOADING_TIPOS_COMPRAS });
    await getData
      .post(url, JSON.stringify(compra))
      .then((resultado) => {
        dispatch({
          type: EDITAR_TIPO_COMPRA,
          payload: resultado.data,
        });
        dispatch({ type: TIPOS_COMPRAS_SUCCESS });
      })
      .catch((error) => {
        if (error) {
          dispatch({ type: TIPOS_COMPRAS_FAILED });
        }
      });
  };

  export const eliminarTipoCompra = (tipoCompraId) => async (dispatch) => {
    let url = api_base + "api/v1/tipocompra/eliminar";

    dispatch({ type: LOADING_TIPOS_COMPRAS });
    await getData
      .post(url, {tipoCompraId: tipoCompraId})
      .then((resultado) => {
        dispatch({
          type: ELIMINAR_TIPO_COMPRA,
          payload: tipoCompraId,
        });
        dispatch({ type: TIPOS_COMPRAS_SUCCESS });
      })
      .catch((error) => {
        if (error) {
          dispatch({ type: TIPOS_COMPRAS_FAILED });
        }
      });
  };
