import React from "react";
import initialState from "./../initial-state";

import { MOSTRAR_TRIMESTRES, LOADING_TRIMESTRES, TRIMESTRES_FAILED, TRIMESTRES_SUCCESS } from "./../types";


const { trimestres } = initialState;

const TrimestresReducer = (state = trimestres, action) => {
    switch (action.type) {
        case MOSTRAR_TRIMESTRES:
            return {
                ...state,
                trimestres: action.payload
            }
        case LOADING_TRIMESTRES:
            return {
                ...state,
                isLoading: true
            }
        case TRIMESTRES_FAILED:
        case TRIMESTRES_SUCCESS:

            return {
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
}

export default TrimestresReducer;
