import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  Container,
} from "reactstrap";
import Select from 'react-select'

import { Authorization } from "../../../utils/Authorization";
import Loading from "../../utils/Loading";

import { getTrimestres } from "../../../redux/actions/trimestres-actions";
import { api_download_informes } from "../../../config";
import {
  createInformeTrimestral,
  updateInformeTrimestral,
  getInformesTrimestral,
  deleteInformeTrimestral,
} from "../../../redux/actions/informe_trimestral_actions";

import { getAreasOrganizacionales } from "../../../redux/actions/areas-actions";

import { getRequerimiento } from "../../../redux/actions/requerimientos-actions";
import { createRequerimientos, guardarRequerimientoHistorico } from "../../../redux/actions/historicos-requerimientos-actions";

import { habilitar } from "../../../utils/habilitarCampos"
class CrearRecepcionRemision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      trimestres: [],
      trimestresEntity: [],
      modalInformeTrimestralStatus: false,
      selectedFile: null,
      file: [],
      fecha: "",
      documento: [],
      trimestreId: "",
      estatusEnviar: false,
      areaOrganizacionalUser: {},
      areaOrganizacionalSelected: {},
      selectedOption: "Remision"
    };
  }

  // Variables Ref
  fechaRecepcionRef = React.createRef();
  horaRecepcionRef = React.createRef();
  fechaRemisionRef = React.createRef();
  horaRemisionRef = React.createRef();
  areaRef = React.createRef();
  comentarioRef = React.createRef();
  aplicaDevolucionRef = React.createRef();
  fechaDevolucionRef = React.createRef();
  horaDevolucionRef = React.createRef();
  conceptoRef = React.createRef();
  razonesRef = React.createRef();

  componentDidMount() {
    this.setState({ isLoadingState: true })
    this.cargarDatos();
    this.props.getAreasOrganizacionales();
    this.setState({
      areaOrganizacionalUser: {
        value: this.props.user.areaOrganizacional.areaOrganizacionalId,
        label: this.props.user.areaOrganizacional.nombre
      }
    })
    // document.getElementById("fechaRecepcion").value = new Date();
  }

  irAtras = () => {
    this.props.history.goBack();
  }

  cargarDatos = async () => {
    await this.props.getRequerimiento(this.props.match.params.solicitudCompraId);
    
    this.setState({ isLoadingState: false });
  };

  GuardarHistoricoRequerimiento = async () => {
    if (
      this.validarHistoricoRequerimiento()
    ) {
      let date = new Date();
      let hora =`${date.getHours()}:${date.getMinutes()}`;
      let historicoRequerimientos = {
        estadoId: this.props.requerimiento.requerimiento.estado.estadoId == 2 && this.state.selectedOption == "Remision" && this.state.areaOrganizacionalSelected.value == 29 ? 4 : document.getElementById("aplicaDevolucion").checked ? 8 : this.props.requerimiento['requerimiento'].requerimiento.estado.estadoId,
        requerimientoId: this.props.match.params.solicitudCompraId,
        fechaRecepcion: this.fechaRecepcionRef ? this.fechaRecepcionRef.current?.value : null,
        horaRecepcion: hora,
        fechaRemision: this.fechaRemisionRef ? this.fechaRemisionRef.current?.value : null,
        horaRemision: hora,
        areaId: this.state.selectedOption == "Recepcion" ? this.state.areaOrganizacionalUser.value : this.state.areaOrganizacionalSelected.value,
        comentario: this.comentarioRef ? this.comentarioRef.current?.value : null,
        aplicaDevolucion: document.getElementById("aplicaDevolucion").checked,
        fechaDevolucion: this.fechaDevolucionRef ? this.fechaDevolucionRef.current?.value : null,
        horaDevolucion: hora,
        concepto:null, //this.conceptoRef ? this.conceptoRef.current?.value : null,
        razones: this.razonesRef ? this.razonesRef.current?.value : null,
      };

      // llamar al api para guardar el historico
       await this.props.guardarRequerimientoHistorico(historicoRequerimientos);
      // redirigir a algun lugar, ejemplo debajo
      //  this.props.history.push(`/admin/solicitud-compra/departamento-administrativo/index`);
       this.irAtras();
    }
  }

  validarHistoricoRequerimiento = () => {
    let aplicaDevolucion = document.getElementById("aplicaDevolucion").checked;

    if (this.state.selectedOption == "Recepcion" && !aplicaDevolucion) {
      if (this.fechaRecepcionRef && this.fechaRecepcionRef.current.value == "") {
        swal("Crear Historico de Requerimiento", "El campo fecha de recepción es un valor requerido", "error");
        return false;
      }

      // if (this.horaRecepcionRef && this.horaRecepcionRef.current.value == "") {
      //   swal("Crear Historico de Requerimiento", "El campo hora de recepción es un valor requerido", "error");
      //   return false;
      // }
    }
    if (this.state.selectedOption == "Remision" && !aplicaDevolucion) {

      if (this.fechaRemisionRef && this.fechaRemisionRef.current.value == "") {
        swal("Crear Historico de Requerimiento", "El campo fecha de remisión es un valor requerido", "error");
        return false;
      }

      // if (this.horaRemisionRef && this.horaRemisionRef.current.value == "") {
      //   swal("Crear Historico de Requerimiento", "El campo hora de remisión es un valor requerido", "error");
      //   return false;
      // }

      if (Object.keys(this.state.areaOrganizacionalSelected) == 0) {
        swal("Crear Historico de Requerimiento", "El campo de área es un valor requerido", "error");
        return false;
      }
    }
    // if (comentario === "") {
    //   swal("Crear Historico de Requerimiento", "El campo comentario es un valor requerido", "error");
    //   return false;
    // }

    if (aplicaDevolucion) {
      if (this.fechaDevolucionRef && this.fechaDevolucionRef.current.value === "") {
        swal("Crear Historico de Requerimiento", "El campo fecha de devolución es un valor requerido", "error");
        return false;
      }

      // if (this.horaDevolucionRef && this.horaDevolucionRef.current.value === "") {
      //   swal("Crear Historico de Requerimiento", "El campo hora de devolución es un valor requerido", "error");
      //   return false;
      // }

      // if (this.conceptoRef && this.conceptoRef.current.value === "") {
      //   swal("Crear Historico de Requerimiento", "El campo concepto es un valor requerido", "error");
      //   return false;
      // }

      if (this.razonesRef && this.razonesRef.current.value === "") {
        swal("Crear Historico de Requerimiento", "El campo razones es un valor requerido", "error");
        return false;

      }
      if (Object.keys(this.state.areaOrganizacionalSelected) == 0) {
        swal("Crear Historico de Requerimiento", "El campo de área es un valor requerido", "error");
        return false;
      }
    }
    return true;
  };

  handleOptionChange = (changeEvent) => {

    if (changeEvent.target.value == "Recepcion") {
      // console.log(this.state.areaOrganizacionalUser.value);
      this.areaRef.current.value = this.state.areaOrganizacionalUser.value;
    } else if (Object.keys(this.state.areaOrganizacionalSelected).length > 0) {
      this.areaRef.current.value = this.state.areaOrganizacionalSelected.value;
    }

    this.setState({
      selectedOption: changeEvent.target.value
    });
  }

  render() {
   // console.log(new Date().getTime().toString());
    //console.log(this.horaRecepcionRef.current?this.horaRecepcionRef.current.value:'');
    const options = this.props.areaOrganizacionales.map(area => ({
      value: area.areaOrganizacionalId,
      label: area.nombre
    }));
// console.log("this.props.requerimiento",this.props);
    const { numeroSolicitud } = this.props.requerimiento.requerimiento;

    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (

                <div className="row">

                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-6 pb-4 border-right">
                        <label className="text-left" htmlFor="">No. Requerimiento</label>
                        <input
                          type="text"
                          className="form-control text-dark"
                          id="requerimiento"
                          required
                          disabled={true}
                          value={numeroSolicitud}
                        />
                        <div className="form-check form-check-inline mt-3 ">
                          <div className="mr-3">

                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Remision" checked={this.state.selectedOption === 'Remision'} onChange={this.handleOptionChange} />
                            <label className="form-check-label" htmlFor="inlineRadio1">Remisión</label>

                          </div>
                          <div className="">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Recepcion" checked={this.state.selectedOption === 'Recepcion'} onChange={this.handleOptionChange} />
                            <label className="form-check-label" htmlFor="inlineRadio2">Recepción</label>
                          </div>
                        </div>

                        {this.state.selectedOption === "Recepcion" ? (
                          <>
                            <div className="mt-4">
                              <h3>Recepción</h3>
                              <hr style={{ marginTop: '0rem' }} />
                            </div>

                            <div className="mt-4">
                              <label className="text-left" htmlFor="">Fecha</label>
                              <input
                                type="date"
                                className="form-control text-dark"
                                id="fechaRecepcion"
                                ref={this.fechaRecepcionRef}
                                defaultValue={new Date().toISOString().substr(0, 10)}
                                required
                              />
                            </div>
                            {/* <div className="mt-4">
                              <label className="text-left" htmlFor="">Hora</label>
                              <input
                                type="time"
                                className="form-control text-dark"
                                ref={this.horaRecepcionRef}
                                defaultValue={new Date().getTime().toString()}
                                required
                              />
                            </div> */}
                          </>
                        ) : (
                          <>
                            <div className="mt-4">
                              <h3>Remisión</h3>
                              <hr style={{ marginTop: '0rem' }} />
                            </div>

                            <div className="mt-4">
                              <label className="text-left" htmlFor="">Fecha</label>
                              <input
                                type="date"
                                className="form-control text-dark"
                                defaultValue={new Date().toISOString().substr(0, 10)}
                                ref={this.fechaRemisionRef}
                                required
                              />
                            </div>
                            {/* <div className="mt-4">
                              <label className="text-left" htmlFor="">Hora-12</label>
                              <input
                                type="time"
                                className="form-control text-dark"
                                id="requerimiento"
                                ref={this.horaRemisionRef}
                                defaultValue={new Date().toTimeString()}
                                required
                              />
                            </div> */}
                            <div className="mt-4">
                              <label className="text-left" htmlFor="">Área</label>
                              {/* <select
                                id="area"
                                className="form-control"
                                >
                                 {this.props.areaOrganizacionales.map((area) => (
                                  <option
                                    key={area.areaOrganizacionalId}
                                    value={area.areaOrganizacionalId}
                                  >
                                    {area.nombre}
                                  </option>
                                ))}
                              </select> */}
                              <Select
                                inputId="select-input"
                                ref={this.areaRef}
                                data={options}
                                options={options}
                                value={this.state.selectedOption == "Recepcion" ? this.state.areaOrganizacionalUser : this.state.areaOrganizacionalSelected}
                                onFocus={() => {
                                  document.querySelector("#select-input").select();
                                }}
                                onChange={e => {
                                  this.areaRef.current.value = e.value;
                                  this.setState({ areaOrganizacionalSelected: e })
                                }}
                              />
                            </div>
                          </>
                        )}



                        <div className="mt-4">
                          <label className="text-left" htmlFor="">Comentario</label>
                          <textarea
                            type="text"
                            className="form-control form-control-sm text-dark"
                            placeholder="Escribe el comentario"
                            ref={this.comentarioRef}
                          />
                        </div>
                      </div>
                      <div className="col-6">

                        <div className="custom-control custom-checkbox mt-4">
                          <input type="checkbox" className="custom-control-input" id="aplicaDevolucion" ref={this.aplicaDevolucionRef} onChange={() => habilitar()} />
                          <label className="custom-control-label" htmlFor="aplicaDevolucion">¿Aplica la devolución?</label>
                        </div>

                        <div className="devolucion aplicaDevolucion">
                          <h3 className="mt-5">Devolución</h3>
                          <hr style={{ marginTop: '0rem' }} />
                          <label className="text-left" htmlFor="fechaDevolucion">Fecha</label>
                          <input
                            type="date"
                            className="form-control text-dark"
                            id="fechaDevolucion"
                            ref={this.fechaDevolucionRef}
                            defaultValue={new Date().toISOString().substr(0, 10)}
                            required
                          />

                          {/* <div className="mt-4">
                            <label className="text-left" htmlFor="horaDeveolucion">Hora</label>
                            <input
                              type="time"
                              className="form-control text-dark"
                              id="horaDeveolucion"
                              ref={this.horaDevolucionRef}
                              required
                            />
                          </div> */}

                          {/* <div className="mt-4">
                            <label className="text-left" htmlFor="concepto">Concepto</label>
                            <textarea
                              type="text"
                              className="form-control form-control-sm text-dark"
                              id="concepto"
                              placeholder="Escribe el concepto"
                              ref={this.conceptoRef}
                            />
                          </div> */}

                          <div className="mt-4">
                            <label className="text-left" htmlFor="razones">Razones</label>
                            <textarea
                              type="text"
                              className="form-control form-control-sm text-dark"
                              id="razones"
                              placeholder="Escribe las razones"
                              ref={this.razonesRef}
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-5">
                    <div className="row pb-3 pt-5">
                      <div className="col-4 m-auto">
                        <button
                          onClick={() => this.irAtras()}
                          className="btn btn-danger"
                        >
                          CANCELAR
                        </button>
                        <button
                          onClick={() => this.GuardarHistoricoRequerimiento()}
                          className="btn btn-primary"
                        >
                          GUARDAR
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  trimestres: state.trimestres.trimestres,
  informesTrimestrales: state.informesTrimestrales.informesTrimestrales,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,//me
  requerimiento: state.requerimientos.requerimiento,
});

const mapDispatchToProps = (dispatch) => ({
  getTrimestres: () => dispatch(getTrimestres()),
  createInformeTrimestral: (informeTrimestral) =>
    dispatch(createInformeTrimestral(informeTrimestral)),
  getInformesTrimestral: (poaId, areaOrganizacionalId) =>
    dispatch(getInformesTrimestral(poaId, areaOrganizacionalId)),
  deleteInformeTrimestral: (informeTrimestralId) =>
    dispatch(deleteInformeTrimestral(informeTrimestralId)),
  updateInformeTrimestral: (informeTrimestral) =>
    dispatch(updateInformeTrimestral(informeTrimestral)),
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
  getRequerimiento: requerimientoId => dispatch(getRequerimiento(requerimientoId)),
  createRequerimientos: historicoRequerimientos => dispatch(createRequerimientos(historicoRequerimientos)),
  guardarRequerimientoHistorico: historicoRequerimientos => dispatch(guardarRequerimientoHistorico(historicoRequerimientos))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(CrearRecepcionRemision, [
    "Administrador general",
    "Responsables de compras",
    "Analista Compras"
  ])
);