import { api_base, homePages } from "./../../config";
import { getData, instance as api } from "./../../utils/api-client";
import axios from "axios";

import {
  SIGNIN_REQUEST_SENT,
  SIGNIN_REQUEST_SUCCEEDED,
  SIGNIN_REQUEST_FAILED,
  SIGNOUT_REQUEST_SENT,
  SIGNOUT_REQUEST_SUCCEEDED,
  SIGNOUT_REQUEST_FAILED,
  VERIFY_USER_SIGNED,
  USER_SIGNED_SUCCESS,
  USER_SIGNED_FAILED,
} from "../types";

import { client, endpoints } from "../../config";
export const signInRequestSent = () => ({
  type: SIGNIN_REQUEST_SENT,
});

export const signInRequestSucceeded = (authAttributes) => ({
  type: SIGNIN_REQUEST_SUCCEEDED,
  payload: {
    token: authAttributes,
  },
});

export const signInRequestFailed = () => ({
  type: SIGNIN_REQUEST_FAILED,
});
///////////////////
export const signOutRequestSent = () => ({
  type: SIGNOUT_REQUEST_SENT,
});

export const signOutRequestSucceeded = () => ({
  type: SIGNOUT_REQUEST_SUCCEEDED,
});

export const signOutRequestFailed = () => ({
  type: SIGNOUT_REQUEST_FAILED,
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Async Redux Thunk actions:
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const testAlgo = () => (dispatch) => {
  dispatch(signInRequestSent());
};

export const signInUser = (userSignInCredentials) => async (dispatch) => {
  localStorage.clear();
  dispatch(signInRequestSent());

  const { username, password, grant_type } = userSignInCredentials;

  try {
    await api
      .post(
        endpoints.login,
        {
          client_id: client.id,
          client_secret: client.secret,
          username,
          password,
          grant_type,
          scope: "*",
        },
        {
          timeout: 30000,
        }
      )
      .then((res) => {
        res.data.expires_at = new Date().getTime() + res.data.expires_in * 1000; //  res.data.expires_in * 1000; /
        localStorage.clear();
        localStorage.setItem("usuario", JSON.stringify(username));
        localStorage.setItem("jwt", JSON.stringify(res.data));
        dispatch(signInRequestSucceeded(res.data));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(signInRequestFailed());
      });
  } catch (error) {
    dispatch(signInRequestFailed());
    throw error;
  }
};

export const signRefreshTokenUser = (auth) => async (dispatch) => {
  dispatch(signInRequestSent());

  try {
    await api
      .post(
        endpoints.login,
        {
          client_id: client.id,
          client_secret: client.secret,
          scope: "*",
          grant_type: "refresh_token",
          refresh_token: auth.token.refresh_token,
        },
        {
          timeout: 300000,
        }
      )
      .then((res) => {
        res.data.expires_at = new Date().getTime() + res.data.expires_in * 1000; //  res.data.expires_in * 1000; /
        localStorage.setItem("jwt", JSON.stringify(res.data));
        dispatch(signInRequestSucceeded(res.data));
      })
      .catch((error) => {
        if (error) {
          dispatch(signInRequestFailed());
        }
      });
  } catch (error) {
    dispatch(signInRequestFailed());
    throw error;
  }
};

export const signOutUser = (userSignInCredentials) => async (dispatch) => {
  localStorage.clear();
  dispatch(signOutRequestSent());
  dispatch(signOutRequestSucceeded());
  localStorage.setItem("token_valid", false);
};

export const searchUser = (username, token) => async (dispatch) => {
  let infoUsuario = JSON.parse(localStorage.getItem("jwt"));

  dispatch({ type: VERIFY_USER_SIGNED });
  let url = api_base + `api/v1/usuarios/search`;

  axios
    .post(
      url,
      { username },
      {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          Authorization: `Bearer ${
            infoUsuario ? infoUsuario.access_token : ""
          }`,
        },
      }
    )
    .then((res) => {
      dispatch({ type: USER_SIGNED_SUCCESS, payload: JSON.parse(res.data) });
      let user = JSON.parse(res.data);
      user.roles.map((rol) =>
        rol === "Gestor del POA"
          ? window.location.replace(homePages.poa)
          : rol === "MAE"
          ? window.location.replace(homePages.poa)
          : rol === "Responsables de areas"
          ? window.location.replace(homePages.productos)
          : rol === "Responsables de productos"
          ? window.location.replace(homePages.productos)
          : rol === "Administrador general"
          ? window.location.replace(homePages.productos)
          : window.location.replace(homePages.productos)
      );
    })
    .catch((error) => {
      // console.log(error);
      dispatch({ type: USER_SIGNED_FAILED });
    });
};
