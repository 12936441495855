import { MOSTRAR_COMPRAS_DOCUMENTACIONES, LOADING_COMPRAS_DOCUMENTACIONES, COMPRAS_DOCUMENTACIONES_FAILED } from "../types";
import { api_base } from "../../config";
import { getData } from "../../utils/api-client";

export const getListaComprasDocumentaciones = (tipoCompraId) => async dispatch => {

    let url = api_base + `api/v1/comprasdocumentaciones/documentaciones_por_compra/${tipoCompraId}`;

    await getData
    .get(url)
    .then(resultado => {
        dispatch({ type: LOADING_COMPRAS_DOCUMENTACIONES });
        dispatch({ type: MOSTRAR_COMPRAS_DOCUMENTACIONES, payload: resultado.data });
    })
    .catch(error => {
        if (error) {
            dispatch({ type: COMPRAS_DOCUMENTACIONES_FAILED });
        } else {
            window.location.reload();
        }
    });
};
