import {
  PONDERACION_AREA_FAILED,
  PONDERACION_AREA_SUCCEEDED,
  LOADING_PONDERACION_AREA,
  MOSTRAR_PONDERACION_AREA,
  MOSTRAR_PONDERACION_AREAS,

  // NOPOA
  LOADING_PONDERACION_AREA_NOPOA,
  MOSTRAR_PONDERACION_AREA_NOPOA,
  PONDERACION_AREA_SUCCEEDED_NOPOA,
  PONDERACION_AREA_FAILED_NOPOA
  
} from "./../types";

import { getData } from "./../../utils/api-client";
import { api_base } from "./../../config";

export const getPonderacionAreas = (poaId) => async (dispatch) => {
  let url = `${api_base}api/v1/ponderacionareas/${poaId}`;
  dispatch({ type: LOADING_PONDERACION_AREA });
  dispatch({ type: MOSTRAR_PONDERACION_AREAS, payload: [] });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_PONDERACION_AREAS, payload: resultado.data });
      dispatch({ type: PONDERACION_AREA_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: PONDERACION_AREA_FAILED });
      }
    });
};

export const getPonderacionArea = (poaId, areaOrganizacionalId) => async (
  dispatch
) => {
  let url = `${api_base}api/v1/ponderacionarea/${poaId}/${areaOrganizacionalId}`;
  dispatch({ type: LOADING_PONDERACION_AREA });
  dispatch({ type: MOSTRAR_PONDERACION_AREA, payload: {} });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_PONDERACION_AREA, payload: resultado.data });
      dispatch({ type: PONDERACION_AREA_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: PONDERACION_AREA_FAILED });
      }
    });
};

export const getPonderacionAreaNopoa = (areaOrganizacionalId) => async (
  dispatch
) => {
  let url = `${api_base}api/v1/ponderacionarea/${areaOrganizacionalId}`;
  dispatch({ type: LOADING_PONDERACION_AREA_NOPOA });
  dispatch({ type: MOSTRAR_PONDERACION_AREA_NOPOA, payload: {} });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_PONDERACION_AREA_NOPOA, payload: resultado.data });
      dispatch({ type: PONDERACION_AREA_SUCCEEDED_NOPOA });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: PONDERACION_AREA_FAILED_NOPOA });
      }
    });
};

export const createPonderacionAreas = (ponderacionArea) => async (dispatch) => {
  let url = `${api_base}api/v1/ponderacionareas/create`;
  dispatch({ type: LOADING_PONDERACION_AREA });

  getData
    .post(url, ponderacionArea)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_PONDERACION_AREA, payload: resultado.data });
      dispatch({ type: PONDERACION_AREA_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: PONDERACION_AREA_FAILED });
      }
    });
};

export const editPonderacionAreas = (ponderacionArea) => async (dispatch) => {
  let url = `${api_base}api/v1/ponderacionareas/edit`;
  dispatch({ type: LOADING_PONDERACION_AREA });

  getData
    .post(url, ponderacionArea)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_PONDERACION_AREA, payload: resultado.data });
      dispatch({ type: PONDERACION_AREA_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: PONDERACION_AREA_FAILED });
      }
    });
};
