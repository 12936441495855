import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

import swal from "sweetalert";

export const ModalRecepcionBienesServicios = (props) => {
    const toggle = () =>
      props.activarModalRecepcionBienesServicios(props.modalInformeTrimestral);
  
    const {  modalInformeTrimestralStatus} = props;
    return (
      <Modal isOpen={modalInformeTrimestralStatus} toggle={toggle} size="lg" backdrop="static">
        <ModalHeader className="block-example border-bottom " toggle={toggle}>
          RECEPCIÓN DE BIENES Y SERVICIOS
        </ModalHeader>
  
        <ModalBody>
            <div className="row">
              <div className="form-group col-md-6">
                <label>No. Requerimiento</label>
                <input 
                    className="form-control" 
                    value='INT-MEM-2023-158'
                    disabled
                  />
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-12 mt-5">
                <h3 className="text-center">ITEMS</h3>
                <Table>
                    <thead>
                      <tr>
                        <th>
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="customCheck1"
                              type="checkbox"
                            />
                            <label className="custom-control-label"  style={{ fontSize: '0.65rem', paddingTop: '0.4rem' }} htmlFor="customCheck1">
                              Sub-clase
                            </label>
                          </div>
                        </th>
                        <th>Nombre</th>
                        <th>Precio</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="customCheck2"
                              type="checkbox"
                            />
                            <label className="custom-control-label"  style={{ paddingTop: '0.2rem' }} htmlFor="customCheck2">
                              D-24510
                            </label>
                          </div>
                        </td>
                        <td>Disco duro SSD</td>
                        <td>5000 $RD</td>
                      </tr>
                      <tr>
                      <td>
                        <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="customCheck3"
                              type="checkbox"
                            />
                            <label className="custom-control-label"  style={{ paddingTop: '0.2rem' }} htmlFor="customCheck3">
                              D-24510
                            </label>
                          </div>
                        </td>
                        <td>Disco duro SSD</td>
                        <td>5000 $RD</td>
                      </tr>
                    </tbody>
                </Table>
              </div>
            </div>

            <div className="row">
              <div className="form-group pt-4 p-2">
                  <label>PDF de Acuse de Evidencias</label>
                  <input
                  id="inputpdf"
                    className="form-control text-dark"
                    type="file" accept=".pdf"
                    name="file[]"
                    title="Adjuntar archivo"
                    onChange= {
                      (e) => {
                          e.preventDefault();
                          ////////////////////////////////////////////////////////////
                          var fileInput = document.getElementById('inputpdf');
                          var filePath = fileInput.value;
                          var allowedExtensions = /(\.pdf|\.PDF)$/i;
                          if (!allowedExtensions.exec(filePath)) {
                            swal("Error de tipo de archivo","Debe cargar archivos en formato PDF","error");
                              fileInput.value = '';
                              return false;
                          } 
                          ///////////////////////////////////////////////
                          props.cargarInformeTrimestral(e);
                      }
                    }
                  />
                </div>
            </div>
              
         </ModalBody>
        <ModalFooter>
          <div className="row">
            <div className="">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  props.activarModalRecepcionBienesServicios(props.modalInformeTrimestral);
                }}
              >
              Cancelar
              </button>
            </div>
            <div className="ml-3">
              <button
                className="btn btn-primary"
                onClick={(e)=>{
                    e.preventDefault();
                    props.guardarSolicitudCompra();
                }}
              >
              Guardar
            </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
};