import {
    CREAR_POA,
    MOSTRAR_POA,
    MOSTRAR_POAS,
    EDITAR_POA,
    ELIMINAR_POA,
    LOADING_POA,
    POA_SUCCEEDED,
    POA_FAILED,
    MOSTRAR_POA_ACTIVO,
    MOSTRAR_DETALLE_POA,
    MOSTRAR_POA_ELABORACION
} from "./../types";

import { getData } from "./../../utils/api-client";
import { api_base, api_download_reportes } from "./../../config";
import FileSaver, { saveAs } from "file-saver";

export const crearPoa = (data) => async(dispatch) => {
    let url = api_base + "api/v1/poa/nuevo";

    await getData
    .post(url, JSON.stringify(data))
    .then((resultado) => {
        dispatch({
            type: CREAR_POA,
            payload: resultado.data,
        });
    })
    .catch((error) => {
        if (error) {
            dispatch({ type: POA_FAILED });
        }
    });
};
export const editarPoa = (poa) => async(dispatch) => {
    let url = api_base + "api/v1/poa/editar";

    await getData
    .post(url, JSON.stringify(poa))
    .then((resultado) => {
        dispatch({
            type: EDITAR_POA,
            payload: resultado.data,
        });
    })
    .catch((error) => {
        if (error) {
            dispatch({ type: POA_FAILED });
        }
    });
};
export const eliminarPoa = (poaId) => async(dispatch) => {
    let url = api_base + "api/v1/poa/eliminar";
    let poa = { poaId: poaId };
    await getData.post(url, JSON.stringify(poa)).catch((error) => {
        if (error) {
            dispatch({
                type: ELIMINAR_POA,
                payload: poaId,
            });
            window.location.reload();
        }
    });
};

export const mostrarPoas = () => async(dispatch) => {
    let url = api_base + "api/v1/poa/";

    await getData
    .get(url)
    .then((resultado) => {
        dispatch({
            type: MOSTRAR_POAS,
            payload: resultado.data,
        });
    })
    .catch((error) => {
        if (error) {
            dispatch({ type: POA_FAILED });
        }
    });
};

export const mostrarPoa = (poaId,area=0) => async(dispatch) => {
    let url = api_base + `api/v1/poa/${poaId}`;
    if(area){url =api_base + `api/v1/poa/${poaId}/${area}`;}

    await getData
    .get(url)
    .then((resultado) => {
        dispatch({
            type: MOSTRAR_POA,
            payload: resultado.data,
        });
        dispatch({ type: MOSTRAR_POA_ACTIVO, payload: resultado.data });
        dispatch({ type: MOSTRAR_POA_ELABORACION, payload: resultado.data });
        dispatch({ type: POA_SUCCEEDED });
    })
    .catch((error) => {
        if (error) {
            dispatch({ type: POA_FAILED });
        }
    });
};

export const getPoaActivo = () => async(dispatch) => {
    let url = api_base + `api/v1/poa/activo`;

    dispatch({ type: LOADING_POA });
    await getData
    .get(url)
    .then((resultado) => {
        dispatch({ type: MOSTRAR_POA_ACTIVO, payload: resultado.data });
        dispatch({ type: POA_SUCCEEDED });
    })
    .catch((error) => {
        if(error) {
            dispatch({ type: POA_FAILED });
        }
    });
};

export const getPoaElaboracion= () => async(dispatch) => {
    let url = api_base + `api/v1/poa/elaboracion`;

    dispatch({ type: LOADING_POA });
    await getData
    .get(url)
    .then((resultado) => {
        dispatch({ type: MOSTRAR_POA_ELABORACION, payload: resultado.data });
        dispatch({ type: POA_SUCCEEDED });
    })
    .catch((error) => {
        if(error) {
            dispatch({ type: POA_FAILED });
        }
    });
};

export const aprobarPoa = (poaId) => async(dispatch) => {
    let url = api_base + `api/v1/poa/aporbacion`;

    let data = {
        poaId: poaId,
        nivelAprobacionId: 7,
    };

    await getData
    .post(url, JSON.stringify(data))
    .then((resultado) => {
        dispatch({
            type: EDITAR_POA,
            payload: resultado.data,
        });
    })
    .catch((error) => {
        if (error) {
            dispatch({ type: POA_FAILED });
        }
    });
};
export const cerrarPoa = (poaId) => async(dispatch) => {
    let url = api_base + `api/v1/poa/aporbacion`;

    let data = {
        poaId: poaId,
        nivelAprobacionId: 6,
    };

    await getData
    .post(url, JSON.stringify(data))
    .then((resultado) => {
        dispatch({
            type: EDITAR_POA,
            payload: resultado.data,
        });
    })
    .catch((error) => {
        if (error) {
            dispatch({ type: POA_FAILED });
        }
    });
};
export const reAbrirPoa = (poaId) => async(dispatch) => {
    let url = api_base + `api/v1/poa/aporbacion`;

    let data = {
        poaId: poaId,
        nivelAprobacionId: 1,
    };

    await getData
    .post(url, JSON.stringify(data))
    .then((resultado) => {
        dispatch({
            type: EDITAR_POA,
            payload: resultado.data,
        });
    })
    .catch((error) => {
        if (error) {
            dispatch({ type: POA_FAILED });
        }
    });
};

export const mostrarDetallePoa = (poaId) => async(dispatch) => {
    let url = api_base + `api/v1/reportes/poa/${poaId}`;

    await getData
    .get(url)
    .then((resultado) => {
        dispatch({
            type: MOSTRAR_DETALLE_POA,
            payload: resultado.data,
        });
    })
    .catch((error) => {
        if (error) {
            dispatch({ type: POA_FAILED });
        }
    });
};

export const getReportePoa = (poaId) => async(dispatch) => {
    let url = api_base + `api/v1/reportes/exportreport/poaId/${poaId}`;
    await getData
    .get(url)
    .then((resultado) => {
        let ruta = `${api_download_reportes}${resultado.data.ruta}`;
        saveAs(ruta, resultado.data.ruta);
    })
    .catch((error) => {
        if (error) {
            dispatch({ type: POA_FAILED });
        }
    });
};