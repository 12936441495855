import {
  MOSTRAR_PONDERACION_AREA,
  MOSTRAR_PONDERACION_AREAS,
  PONDERACION_AREA_FAILED,
  LOADING_PONDERACION_AREA,
  PONDERACION_AREA_SUCCEEDED,
  CREAR_PONDERACION_AREA,
  EDITAR_PONDERACION_AREA,

  //NOPOA
  MOSTRAR_PONDERACION_AREA_NOPOA,
  MOSTRAR_PONDERACION_AREAS_NOPOA,
  PONDERACION_AREA_FAILED_NOPOA,
  LOADING_PONDERACION_AREA_NOPOA,
  PONDERACION_AREA_SUCCEEDED_NOPOA,
  CREAR_PONDERACION_AREA_NOPOA,
  EDITAR_PONDERACION_AREA_NOPOA
} from "./../types";
import initialState from "./../initial-state";

const { ponderacionAreas } = initialState;

const PonderacionAreasReducer = (state = ponderacionAreas, action) => {
  switch (action.type) {
    case LOADING_PONDERACION_AREA:
      return {
        ...state,
        isLoading: true
      };
    case PONDERACION_AREA_SUCCEEDED:
    case PONDERACION_AREA_FAILED:
      return {
        ...state,
        isLoading: false
      };
    case MOSTRAR_PONDERACION_AREAS:
      return {
        ...state,
        ponderacionAreas: action.payload
      };
    case MOSTRAR_PONDERACION_AREA:
      return {
        ...state,
        ponderacionArea: action.payload
      };
    case CREAR_PONDERACION_AREA:
      return {
        ...state,
        ponderacionAreas: action.payload
      };
    case EDITAR_PONDERACION_AREA:
      return {
        ...state,
        ponderacionAreas: state.ponderacionAreas.map(area =>
          area.ponderacionAreaPoaId === action.payload.ponderacionAreaPoaId
            ? (area = action.payload)
            : area
        )
      };

      // NOPOA
      case LOADING_PONDERACION_AREA_NOPOA:
      return {
        ...state,
        isLoading: true
      };
    case PONDERACION_AREA_SUCCEEDED_NOPOA:
    case PONDERACION_AREA_FAILED_NOPOA:
      return {
        ...state,
        isLoading: false
      };
    case MOSTRAR_PONDERACION_AREAS_NOPOA:
      return {
        ...state,
        ponderacionAreas: action.payload
      };
    case MOSTRAR_PONDERACION_AREA_NOPOA:
      return {
        ...state,
        ponderacionArea: action.payload
      };
    case CREAR_PONDERACION_AREA_NOPOA:
      return {
        ...state,
        ponderacionAreas: action.payload
      };
    case EDITAR_PONDERACION_AREA_NOPOA:
      return {
        ...state,
        ponderacionAreas: state.ponderacionAreas.map(area =>
          area.ponderacionAreaPoaId === action.payload.ponderacionAreaPoaId
            ? (area = action.payload)
            : area
        )
      };


    default:
      return state;
  }
};

export default PonderacionAreasReducer;
