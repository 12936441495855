import {
    MOSTRAR_EJES_ESTRATEGICOS,
    MOSTRAR_ESTRATEGIAS,
    MOSTRAR_OBJETIVOS_ESTRATEGICOS,
    MOSTRAR_EJES_ESTRATEGICOS_GENERAL,
    MOSTRAR_ESTRATEGIAS_GENERAL,
    MOSTRAR_OBJETIVOS_ESTRATEGICOS_GENERAL,

} from "../types";
import { api_base } from "./../../config";
import { getData } from "./../../utils/api-client";

export const getEjesEstrategicos = () => async dispatch => {
    let url = api_base + "api/v1/ejesestrategicos";
    await getData
        .get(url)
        .then(resultado => {
            dispatch({ type: MOSTRAR_EJES_ESTRATEGICOS, payload: resultado.data });
        })
        .catch(error => {
            //console.log(error);
        });
};

export const getObjetivosEstrategicos = () => async dispatch => {

    let url = api_base + "api/v1/objetivosestrategicos";
    await getData
        .get(url)
        .then(resultado => {
            dispatch({
                type: MOSTRAR_OBJETIVOS_ESTRATEGICOS,
                payload: resultado.data
            });
        })
        .catch(error => {
            //console.log(error);
        });
};
export const getEstrategias = () => async dispatch => {

    let url = api_base + "api/v1/estrategias";
    await getData
    .get(url)
    .then(resultado => {
        dispatch({ type: MOSTRAR_ESTRATEGIAS, payload: resultado.data });
    })
    .catch(error => {
        //console.log(error);
    });
};


export const getEjesEstrategicosGeneral = () => async dispatch => {
    let url = api_base + "api/v1/ejesestrategicosgeneral";
    await getData
    .get(url)
    .then(resultado => {
        dispatch({ type: MOSTRAR_EJES_ESTRATEGICOS_GENERAL, payload: resultado.data });
        // //console.log(resultado.data);
    })
    .catch(error => {
        //console.log(error);
    });
};

export const getObjetivosEstrategicosGeneral = () => async dispatch => {

    let url = api_base + "api/v1/objetivosestrategicos";
    await getData
    .get(url)
    .then(resultado => {
        dispatch({
            type: MOSTRAR_OBJETIVOS_ESTRATEGICOS_GENERAL,
            payload: resultado.data
        });
    })
    .catch(error => {
        //console.log(error);
    });
};
export const getEstrategiasGeneral = () => async dispatch => {

    let url = api_base + "api/v1/estrategias";
    await getData
    .get(url)
    .then(resultado => {
        dispatch({ type: MOSTRAR_ESTRATEGIAS_GENERAL, payload: resultado.data });
    })
    .catch(error => {
        //console.log(error);
    });
};