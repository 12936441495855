import {
  EDITAR_MOSTRAR_PRODUCTO_PROGRAMATICA,
  MOSTRAR_PRODUCTO_PROGRAMATICAS,
  MOSTRAR_PRODUCTO_PROGRAMATICA,
  MOSTRAR_PRODUCTO_PROGRAMATICA_SUCCESS,
  LOADING_MOSTRAR_PRODUCTO_PROGRAMATICA,
  MOSTRAR_PRODUCTO_PROGRAMATICA_FAILED,
} from "./../types";

import { api_base } from "../../config";
import { getData } from "../../utils/api-client";

export const getProductoProgramatica = () => async (dispatch) => {
  let url = api_base + "api/v1/programatica";
  dispatch({ type: LOADING_MOSTRAR_PRODUCTO_PROGRAMATICA });
  await getData
    .get(url)
    .then((resultado) => {
      // console.log("programatica", resultado.data);
      dispatch({
        type: MOSTRAR_PRODUCTO_PROGRAMATICAS,
        payload: resultado.data,
      });
      dispatch({ type: MOSTRAR_PRODUCTO_PROGRAMATICA_SUCCESS });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: MOSTRAR_PRODUCTO_PROGRAMATICA_FAILED });
      }
    });
};
