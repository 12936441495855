import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  Container,
  Table
} from "reactstrap";
import { Authorization } from "../../utils/Authorization";
import Loading from "../utils/Loading";
import Insumos from "./Insumos";
import numbro from "numbro";
import { getListaTipoCompras } from "../../redux/actions/tipos-compras-actions";
import { getListaEstados } from "../../redux/actions/estados-actions";
import { getListaUsuarios, getListaAnalistasAsignados } from "../../redux/actions/usuarios-actions";
import { getRequerimiento, updateRequerimientos, deletePresupuesto } from "../../redux/actions/requerimientos-actions";
import { setActividadesArticuloSeguimientoCotizacion } from "../../redux/actions/actividades-actions";
import { ModalDetalleArticulo } from "./ModalDetalleArticulo";
import { ModalAsignarCotizacionArticulo } from "./ModalAsignarCotizacionArticulo";

class EditarRequerimiento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editarArticulo: {},
      modalDetalleArticulo: false,
      modalAsignarCotizacionArticulo: false,
      presupuesto: {},
      editarForm: false,
      isLoadingState: false,
      analistaId: 0,
      tipoCompraId: 0,
      estadoId: 0,
      procesoPlurianual: false,
      file:[],
      documento:[],
      sigef: ""
    };
  }

  // Ref
  analistaRef = React.createRef();
  tipoCompraRef = React.createRef();
  sigefRef = React.createRef();
  numeroSolicitudRef = React.createRef();
  esProcesoPlurianual = React.createRef();
  estadoRef = React.createRef();

  componentDidMount() {
    this.setState({ isLoadingState: true });
    this.cargarDatos();
    this.validarEditar();
  }

  actualizarRequerimiento = async (e) => {
    e.preventDefault();
    // this.setState({ isLoadingState: true });
   
    let editarRequerimiento = {
      analistaId: this.state.analistaId ? this.state.analistaId : (this.props.requerimiento["requerimiento"].analista  ? this.props.requerimiento["requerimiento"].analista.id : 0),
      estadoId: this.state.estadoId ? this.state.estadoId : this.props.requerimiento["requerimiento"].estado.estadoId,
      sigef: this.state.sigef ? this.state.sigef : this.props.requerimiento["requerimiento"].sigef,
      numeroSolicitud: this.props.requerimiento["requerimiento"].numeroSolicitud,
      tipoCompraId: this.state.tipoCompraId ? this.state.tipoCompraId : (this.props.requerimiento["requerimiento"].tipoCompras !== null ? this.props.requerimiento["requerimiento"].tipoCompras.tipoCompraId : 0) ,
      procesoPlurianual: document.getElementById("procesoPlurianual").checked,
      requerimientoId: this.props.requerimiento["requerimiento"].requerimientoId,
      selectedFile: null,
      nombreDocumento:this.state.documento[0],
    };

    //  console.log(editarRequerimiento);
    const fd = new FormData();
    if (this.state.file.length > 0) {
      for (let i = 0; i < this.state.file.length; i++) {
        fd.append(`documento[]`, this.state.file[i], this.state.documento[i]);
      }
    }
    Object.keys(editarRequerimiento).forEach(function (item) {
      fd.append(item, editarRequerimiento[item]);
    });

    // await this.props.updateRequerimientos(editarRequerimiento);
    await this.props.updateRequerimientos(fd);
    this.irAtras();
    // this.props.updateRequerimientos(editarRequerimiento).then(resultado => {
    //   this.props.history.push("/admin/solicitud-requerimiento/listado");
    // });
  };

  cargarDatos = async () => {
    const { requerimientoId } = this.props.match.params;
    await this.props.getListaEstados();
    await this.props.getListaTipoCompras();
    await this.props.getRequerimiento(requerimientoId);
    await this.props.getListaAnalistasAsignados();
    this.setState({ isLoadingState: false });
  };

  validarButtonEliminar = () => {
    const { requerimiento, estado } = this.props.requerimiento
    // if (estado.estadoId === 8 || requerimiento[0].area.areaOrganizacionalId != this.props.user.areaOrganizacional.areaOrganizacionalId) {
    if (estado.estadoId !== 8 || estado.estadoId !== 2 || requerimiento[0].area.areaOrganizacionalId != this.props.user.areaOrganizacional.areaOrganizacionalId) {
      return false
    } else {
      return true
    }
  }

  toggleModal = (nombreModal, presupuesto, precioCotizacion, articuloId = 0, actividadId = 0) => {
    this.setState(prevState => ({
      [nombreModal]: !prevState[nombreModal],
      presupuesto: presupuesto,
      precioCotizacion: precioCotizacion,
      articuloIdSeleccionado: articuloId,
      actividadIdSeleccionado: actividadId,
    }));
  }
  
  ActivarModalDetalleArticulo = (presupuesto, precioCotizacion) => {
    this.toggleModal('modalDetalleArticulo', presupuesto, precioCotizacion);
  }
  
  ActivarModalAsignarCotizacionArticulo = (presupuesto, precioCotizacion, articuloId, actividadId) => {
    this.toggleModal('modalAsignarCotizacionArticulo', presupuesto, precioCotizacion, articuloId, actividadId);
  }

  guardarPreciocotizacion = async (precio) => {
   
    let data = {
      'articulo_id' : this.state.articuloIdSeleccionado,
      'actividad_id' : this.state.actividadIdSeleccionado,
      'requerimiento_id' : this.props.requerimiento.requerimientoId ,
      'precio_cotizacion' : precio,
    }

    try {
      await this.props.setActividadesArticuloSeguimientoCotizacion(data);
      
      setTimeout(() => {
        window.location.reload();
      }, 800);

      swal("Success", "Se guardó correctamente.", "success");

    } catch (error) {
      swal("Error", "Ocurrió un error al guardar el precio de cotización.", "error");
      return;
    }
  }

  irAtras = () => {
    this.props.history.goBack();
  }


  validarEditar = () => {
    let filter = ['Responsables de compras', 'Analista Compras', 'Administrador general', 'Analista Administrativo'];
    let resultado = this.props.user.roles.some(i => filter.includes(i));
  
    // Additional validation for Analista Administrativo
    if (this.props.user.roles.includes('Analista Administrativo')) {
      resultado = resultado && this.props.user.roles.some(i => ['Analista Administrativo'].includes(i));
    }
  
    this.setState({ editarForm: resultado });
  }

  eliminarPresupuesto = (presupuestoId) => {
    if (this.props.requerimiento.actividadesArticuloSeguimiento.length < 1) {
      swal("Advertencia",
        "El producto no puede ser eliminado si el requerimiento consta de uno solo; se debe eliminar el requerimiento completo.",
        "error"
      )
    } else {

      swal({
        title: "¿Eliminar Insumo?",
        text: "¿Esta seguro de eliminar el insumo, esta accion no es reversible?",
        icon: "warning",
        buttons: [
          'Cancelar',
          'Aceptar'
        ],
        dangerMode: true,
      }).then(async (isConfirm) => {
        if (isConfirm) {
          // console.log(presupuestoId)
          return;
          await this.props.deletePresupuesto(presupuestoId);
          swal("¡Exito!", "Fue actualizado el requerimiento", "success");
          setTimeout(() => { window.location.reload(); }, 1200)
        }
      })
    }
  }

  cargarEspecificacionesTecnicas = (informe) => {
    let files = [];
    let documentos = [];
    if (informe.target.files.length > 0) {
      for (let i = 0; i < informe.target.files.length; i++) {
        files.push(informe.target.files[i]);
        documentos.push(informe.target.files[i].name);
      }
      this.setState({
        file: files,
        documento: documentos,
      });
    }
    else {
      this.setState({
        file: "",
        documento: "",
      });
    }
  };

  cargarCotizaciones = (cotizacion) => {
    let files = [];
    let documentos = [];
    if (cotizacion.target.files.length > 0) {
      for (let i = 0; i < cotizacion.target.files.length; i++) {
        files.push(cotizacion.target.files[i]);
        documentos.push(cotizacion.target.files[i].name);
      }
      this.setState({
        fileCotizacion: files,
        documentoCotizacion: documentos,
      });
    } else {
      this.setState({
        fileCotizacion: "",
        documentoCotizacion: "",
      });
    }
  };

  // CalcularTotalInsumos = (articulos)=>{
  //   if(articulos !== undefined) {
  //     let  articulosCount = articulos.filter(x=> x.estatus);
  //     let totalInsumos = articulosCount.reduce((total,valor)=>total+valor.presupuesto.totalCosto,0);
  //    return `RD$ ${numbro(totalInsumos).format({thousandSeparated:true})}`;
  //   }
  // }
  // CalcularTotalInsumosCotizado = (articulos)=>{
  //   if(articulos !== undefined) {
  //     let  articulosCount = articulos.filter(x=> x.estatus);
  //     let totalInsumos = articulosCount.reduce((total,valor)=>total+valor.precioCotizacion,0);
  //    return `RD$ ${numbro(totalInsumos).format({thousandSeparated:true})}`;
  //   }
  // }
  // CalcularTotalInsumosAdjudicado = (articulos)=>{
  //   if(articulos !== undefined) {
  //     let  articulosCount = articulos.filter(x=> x.estatus);
  //     let totalInsumos = articulosCount.reduce((total,valor)=>total+valor.precioAdjudicacion,0);
  //    return `RD$ ${numbro(totalInsumos).format({thousandSeparated:true})}`;
  //   }
  // }

  CalcularTotalInsumos = (articulos,actividadesArticulos)=>{
    let articulosCount = [];  
    // console.log("articulos",articulos)
    // console.log("actividadesArticulos",actividadesArticulos)
    actividadesArticulos.forEach(x=>{
      articulos.forEach(articulo=>{
        if(x.articuloId==articulo.presupuestoId && x.estatus){
            articulosCount = [...articulosCount,articulo];
        }
      })
    })
    let totalInsumos = articulosCount.reduce((total,valor)=>total+valor.totalCosto,0);
   return `RD$ ${numbro(totalInsumos).format({thousandSeparated:true})}`;
  }

  CalcularTotalInsumosNoPoa = (articulos,actividadesArticulos)=>{
    let articulosCount = [];  
    actividadesArticulos.forEach(x=>{
      articulos.forEach(articulo=>{
        if(x.articuloId==articulo.presupuestoNoPoaId && x.estatus){
            articulosCount = [...articulosCount,articulo];
        }
      })
    })
    let totalInsumos = articulosCount.reduce((total,valor)=>total+valor.totalCosto,0);
   return `RD$ ${numbro(totalInsumos).format({thousandSeparated:true})}`;
  }


  CalcularTotalInsumosCotizado= (articulos)=>{
    if(articulos !== undefined) {
      // console.log("cotizado",articulos)
      let  articulosCount = articulos.filter(x=> x.estatus);
      let totalInsumos = articulosCount.reduce((total,valor)=>total+ (valor.precioCotizacion?valor.precioCotizacion:0),0);
      // console.log(totalInsumos)
     return `RD$ ${numbro(totalInsumos).format({thousandSeparated:true})}`;
    }
  }
  CalcularTotalInsumosAdjudicado= (articulos)=>{
    if(articulos !== undefined) {
      // console.log("adjudicado",articulos)
      let  articulosCount = articulos.filter(x=> x.estatus);
      let totalInsumos = articulosCount.reduce((total,valor)=>total+(valor.precioAdjudicacion?valor.precioAdjudicacion:0),0);
      // console.log(totalInsumos)
     return `RD$ ${numbro(totalInsumos).format({thousandSeparated:true})}`;
    }
  }


  render() {
    const { isLoading, requerimiento } = this.props;
    const { analista, estado, tipoCompras, sigef, numeroSolicitud, actividadesArticuloSeguimiento, requerimientoProcesoPlurianual } = requerimiento['requerimiento'];
    // console.log(this.props);
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (

                <div className="row">
                  <div className="col-md-12 mt-3">
                    <form  >
                      <div className="row">
                        <div className="col-6 pb-4 border-right">
                          <h3>Modificar Requerimiento Compra</h3>
                          <hr style={{ marginTop: '0rem' }} />
                          <div className="form-group mt-4">
                            <label className="text-left" htmlFor="numeroSigef">Número De Solicitud</label>
                            <input
                              type="text"
                              className="form-control text-dark"
                              id="numeroSolicitud"
                              ref={this.numeroSolicitudRef}
                              defaultValue={numeroSolicitud}
                              readOnly
                              // readOnly={this.state.actividadEdit}
                              required
                            />
                          </div>
                          <div className="form-group mt-4">
                            <label htmlFor="analista">Analista asignado</label>
                            {this.state.editarForm ? (
                              <select
                                id="analista"
                                className="form-control"
                                ref={this.analistaRef}
                                defaultValue={analista ? analista.id : 0}
                                onChange={(e) => {
                                  this.setState({ analistaId: e.currentTarget.value })
                                }}
                                disabled={!this.state.editarForm}
                              >
                                <option
                                  key={0}
                                  value={0}
                                >--Seleccionar Analista--</option>
                                {this.props.usuariosAnalistasAsignados && (this.props.usuariosAnalistasAsignados.map((analista) => (
                                  <option
                                    key={analista.id}
                                    value={analista.id}
                                  >
                                    {analista.name}
                                  </option>
                                )))}
                              </select>

                            ) : (
                              <input
                                type="text"
                                className="form-control text-dark"
                                id="analista"
                                ref={this.analistaRef}
                                defaultValue={requerimiento.analista ? requerimiento.analista.name : "-"}
                                readOnly
                              // readOnly={this.state.actividadEdit}
                              />
                            )}

                          </div>

                          <div className="form-group mt-4">
                            <label htmlFor="tipoProcesoCompra">Tipo proceso de compra</label>
                            <select
                              id="tipoProcesoCompra"
                              className="form-control"
                              ref={this.tipoCompraRef}
                              defaultValue={tipoCompras ? tipoCompras.tipoCompraId : 0}
                              onChange={(e) => {
                                this.setState({ tipoCompraId: e.currentTarget.value })
                              }}
                              disabled={!this.state.editarForm}
                            >
                              <option
                                key={0}
                                value={0}
                              >--Seleccionar Tipo de Compra--</option>
                              {this.props.tiposCompras && (this.props.tiposCompras.map((tipoCompra) => (
                                <option
                                  key={tipoCompra.tipoCompraId}
                                  value={tipoCompra.tipoCompraId}
                                >
                                  {tipoCompra.nombre}
                                </option>
                              )))}
                            </select>
                          </div>

                          <div className="form-group mt-4">
                            <label htmlFor="estatusSecundario">Estatus secundario</label>
                            <select
                              id="estado"
                              className="form-control"
                              ref={this.estadoRef}
                              defaultValue={estado ? estado.estadoId : 0}
                              disabled={!this.state.editarForm}
                              onChange={(e) => {
                                this.setState({ estadoId: e.currentTarget.value })
                              }}
                            >
                              <option
                                key={0}
                                value={""}
                              >
                                {"--Seleccionar Estatus"}
                              </option>
                              {this.props.estados && (this.props.estados.map((estado) => (
                                <option
                                  key={estado.estadoId}
                                  value={estado.estadoId}
                                >
                                  {estado.nombre}
                                </option>
                              )))}
                            </select>
                          </div>

                        </div>
                        <div className="col-6 pb-4">
                          <h3>-</h3>
                          <hr style={{ marginTop: '0rem' }} />

                          <div className="form-group mt-4">
                            <label className="text-left" htmlFor="numeroSigef">Número De SIGEF</label>
                            <input
                              type="number"
                              className="form-control text-dark"
                              id="numeroSigef"
                              ref={this.sigefRef}
                              defaultValue={sigef}
                              disabled={!this.state.editarForm}
                              onChange={(e) => {
                                this.setState({ sigef: e.currentTarget.value })
                              }}
                            />
                          </div>


                          <div className="custom-control custom-checkbox mt-4 mb-4">
                            <input type="checkbox" className="custom-control-input" id="procesoPlurianual" disabled={!this.state.editarForm} ref={this.esProcesoPlurianual} defaultChecked={Boolean(requerimientoProcesoPlurianual)} />
                            <label className="custom-control-label" htmlFor="procesoPlurianual">¿Es proceso plurianual?</label>
                          </div>
<br/>
                          <div className="form-group col-md-12">
                            <label>Actualizar especificaciones técnicas(PDF)</label>
                            <input
                              id="inputpdf"
                              className="form-control text-dark"
                              type="file" accept=".pdf"
                              name="file[]"
                              onChange={
                                (e) => {
                                  e.preventDefault();
                                  ////////////////////////////////////////////////////////////
                                  var fileInput = document.getElementById('inputpdf');
                                  var filePath = fileInput.value;
                                  var allowedExtensions = /(\.pdf|\.PDF)$/i;
                                  if (!allowedExtensions.exec(filePath)) {
                                    swal("Error de tipo de archivo", "Debe cargar archivos en formato PDF", "error");
                                    fileInput.value = '';
                                    return false;
                                  }
                                  ///////////////////////////////////////////////
                                   this.cargarEspecificacionesTecnicas(e);
                                }
                              }
                            />
                          </div>

                          { /* Mostrar cuando este aprobado por el dept. administrativo */ }
                          { estado !== undefined && estado.estadoId === 5 && analista !== null && tipoCompras !== null ? 
                          <div className="form-group col-md-12">
                             <label>Documento de cotizaciones</label>
                            <input
                              id="inputCotizacionesPDF"
                              className="form-control text-dark"
                              type="file" accept=".pdf"
                              name="fileCotizacion[]"
                              onChange = {
                                (e) => {
                                  e.preventDefault();
                                  ////////////////////////////////////////////////////////////
                                  var fileInput = document.getElementById('inputCotizacionesPDF');
                                  var filePath = fileInput.value;
                                  var allowedExtensions = /(\.pdf|\.PDF)$/i;
                                  if (!allowedExtensions.exec(filePath)) {
                                    swal("Error de tipo de archivo", "Debe cargar archivos en formato PDF", "error");
                                    fileInput.value = '';
                                    return false;
                                  }
                                  ///////////////////////////////////////////////
                                    this.cargarCotizaciones(e);
                                }
                              }
                              disabled={!this.state.editarForm}
                            />
                          </div> : null
                          }
                        </div>
                      </div>
                      <div className="text-center">
                        <div>
                          <label style={{ fontWeight: "bold", fontSize: 20 }}>Insumos</label>
                        </div>
                        <Table
                          responsive
                          className="table-striped">
                          <thead>
                            <tr>
                              <th>Producto</th>
                              <th>Subclase</th>
                              <th>Cod. Gasto</th>
                              <th>Valor</th>
                              <th>Cotización</th>
                              <th>Adjudicado</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                          !this.props.requerimiento['requerimiento'].requerimientoActividad[0].noPoa ?(
                            
                            
                            requerimiento['presupuesto'] && requerimiento['presupuesto'].length > 0 && (requerimiento['presupuesto'].map(
                              (articulo,index) =>
                                articulo != null &&
                                articulo != undefined && (
                                  <Insumos
                                    key={articulo.articuloId}
                                    eliminarPresupuesto={this.eliminarPresupuesto}
                                    ActividadArticuloSeguimiento={actividadesArticuloSeguimiento[index]}
                                    presupuesto= {articulo}
                                    ActivarModalDetalleArticulo={this.ActivarModalDetalleArticulo}
                                    ActivarModalAsignarCotizacionArticulo= {this.ActivarModalAsignarCotizacionArticulo}
                                    user={this.props.user}
                                    editar={this.validarButtonEliminar}
                                  />
                                )
                            )
                            )
                            ):(
                              requerimiento['presupuestoNoPoa'] && requerimiento['presupuestoNoPoa'].length > 0 && (requerimiento['presupuestoNoPoa'].map(
                                (articulo,index) =>
                                  articulo != null &&
                                  articulo != undefined && (
                                    <Insumos
                                      key={articulo.articuloId}
                                      eliminarPresupuesto={this.eliminarPresupuesto}
                                      ActividadArticuloSeguimiento={actividadesArticuloSeguimiento[index]}
                                      presupuesto= {articulo}
                                      ActivarModalDetalleArticulo={this.ActivarModalDetalleArticulo}
                                      ActivarModalAsignarCotizacionArticulo= {this.ActivarModalAsignarCotizacionArticulo}
                                      user={this.props.user}
                                      editar={this.validarButtonEliminar}
                                    />
                                  )
                              )
                              )
                            )}
                               {!this.props.requerimiento['requerimiento'].requerimientoActividad[0].noPoa ?(
                           <tr>
                               <td className="text-left font-weight-bold">Total</td>
                               <td></td>
                               <td></td>
                               <td className="font-weight-bold">{this.CalcularTotalInsumos(requerimiento['presupuesto'],requerimiento['requerimiento'].actividadesArticuloSeguimiento)}</td>
                               <td className="font-weight-bold">{this.CalcularTotalInsumosCotizado(requerimiento['requerimiento'].actividadesArticuloSeguimiento)}</td>
                               <td className="font-weight-bold">{this.CalcularTotalInsumosAdjudicado(requerimiento['requerimiento'].actividadesArticuloSeguimiento)}</td>
                               <td></td>
                               </tr>
                               ):(
                                <tr>
                               <td className="text-left font-weight-bold">Total</td>
                               <td></td>
                               <td></td>
                               <td className="font-weight-bold">{this.CalcularTotalInsumosNoPoa(requerimiento['presupuestoNoPoa'],requerimiento['requerimiento'].actividadesArticuloSeguimiento)}</td>
                               <td className="font-weight-bold">{this.CalcularTotalInsumosCotizado(requerimiento['requerimiento'].actividadesArticuloSeguimiento)}</td>
                               <td className="font-weight-bold">{this.CalcularTotalInsumosAdjudicado(requerimiento['requerimiento'].actividadesArticuloSeguimiento)}</td>
                               <td></td>
                               </tr>
                               )
                               }
                          </tbody>
                        </Table>
                      </div>
                      <div className="row pb-3 pt-5">
                        <div className="col-4 m-auto">
                          <button
                            onClick={() => this.irAtras()}
                            className="btn btn-danger"
                          >
                            CANCELAR
                          </button>
                          <button
                            onClick={(e) => this.actualizarRequerimiento(e)}
                            className="btn btn-primary"
                            type="submit"
                          >
                            ACTUALIZAR
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {this.state.modalDetalleArticulo && (
                <ModalDetalleArticulo
                  presupuesto={this.state.presupuesto}
                  ActivarModalDetalleArticulo={this.ActivarModalDetalleArticulo}
                  modalDetalleArticulo={this.state.modalDetalleArticulo}
                  precioCotizacion={this.state.precioCotizacion}
                />
              )}

              {this.state.modalAsignarCotizacionArticulo && (
                <ModalAsignarCotizacionArticulo
                  presupuesto={this.state.presupuesto}
                  ActivarModalAsignarCotizacionArticulo={this.ActivarModalAsignarCotizacionArticulo}
                  modalAsignarCotizacionArticulo={this.state.modalAsignarCotizacionArticulo}
                  guardarPreciocotizacion= {this.guardarPreciocotizacion}
                  precioCotizacion={this.state.precioCotizacion}
                />
              )}
            </div>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  tiposCompras: state.tiposCompras.tiposCompras,
  estados: state.estados.estados,
  usuarios: state.usuarios.usuarios,
  requerimiento: state.requerimientos.requerimiento,
  isLoading: state.requerimientos.isLoading,
  usuariosAnalistasAsignados: state.usuarios.usuariosAnalistasAsignados,
});

const mapDispatchToProps = (dispatch) => ({
  getListaTipoCompras: () => dispatch(getListaTipoCompras()),
  getListaEstados: () => dispatch(getListaEstados()),
  getListaUsuarios: () => {
    dispatch(getListaUsuarios());
  },
  updateRequerimientos: requerimiento => dispatch(updateRequerimientos(requerimiento)),
  getRequerimiento: requerimientoId => dispatch(getRequerimiento(requerimientoId)),
  getListaAnalistasAsignados: () => dispatch(getListaAnalistasAsignados()),
  deletePresupuesto: (presupuestoId) => dispatch(deletePresupuesto(presupuestoId)),
  setActividadesArticuloSeguimientoCotizacion: (data) => dispatch(setActividadesArticuloSeguimientoCotizacion(data))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(EditarRequerimiento, [
    "Administrador general",
    "Responsables de compras",
    "Analista Compras",
    "Responsables de areas",
    "Responsables de productos"
  ])
);
