import {
  MOSTRAR_EJES_ESTRATEGICOS,
  MOSTRAR_ESTRATEGIAS,
  MOSTRAR_OBJETIVOS_ESTRATEGICOS
} from "../types";

import initialState from "./../initial-state";

const { planesEstrategicos } = initialState;

const planesEstrategicosReducer = (state = planesEstrategicos, action) => {
  switch (action.type) {
    case MOSTRAR_EJES_ESTRATEGICOS:
      return {
        ...state,
        ejesEstrategicos: action.payload
      };
    case MOSTRAR_OBJETIVOS_ESTRATEGICOS:
      return {
        ...state,
        objetivosEstrategicos: action.payload
      };
    case MOSTRAR_ESTRATEGIAS:
      return { ...state, estrategias: action.payload };
    default:
      return state;
  }
};

export default planesEstrategicosReducer;
