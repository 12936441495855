import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Table,
} from "reactstrap";
import Moment from "react-moment";
import numbro from "numbro";
import { Authorization } from "../../utils/Authorization";
import Loading from "../utils/Loading";

import { getRequerimiento, getEspecificacionesTecnicasMonitoreo } from "../../redux/actions/requerimientos-actions";
import {setListaPaginacionHistorico} from "../../redux/actions/historicos-requerimientos-actions";
import Insumos from "./Insumos";
import { ModalDetalleArticulo } from "./ModalDetalleArticulo";
import swal from "sweetalert";
import {ModalHistoricoRequerimiento} from "./ModalHistoricoRequerimiento";
import InsumosNoPoa from "./InsumosNoPoa";

class MostrarRequerimiento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      requerimientoState: {},
      presupuestoState:{},
      presupuestoNoPoaState:{},
      modalDetalleArticulo: false,
      modalHistoricoRequerimiento: false,
      historicoSelected:{},
      presupuesto: {},
      activePage: 1,
      totalPages: 0,
      itemPerPage: 4,

      //  listaHistoricoRequerimientos:[]
    }
  }

  componentDidMount() {
    this.cargarDatos();
  }

  cargarDatos = async () => {
    const { requerimientoId } = this.props.match.params;

    await this.props.getRequerimiento(requerimientoId)
// console.log("llll",this.props)
      this.setState({ requerimientoState: this.props.requerimiento['requerimiento'],presupuestoState:this.props.requerimiento['presupuesto'],presupuestoNoPoaState:this.props.requerimiento['presupuestoNoPoa'], historicoSelected:this.props.requerimiento['requerimiento'].requerimiento[this.props.requerimiento['requerimiento'].requerimiento.length-1] });
    

    this.setState({ isLoadingState: false })
  };

  ActivarModalDetalleArticulo = (presupuesto) => {
    this.setState({
      modalDetalleArticulo: !this.state.modalDetalleArticulo,
      presupuesto: presupuesto
    });
  }
  ActivarModalHistoricoRequerimiento = () => {
    this.validarPaginacionHistoricoRequerimiento();
    this.setState({
      modalHistoricoRequerimiento: !this.state.modalHistoricoRequerimiento,
    });
  }

  descargarEspecificacionesTecnicas = async (requerimiento) => {
    await this.props.getEspecificacionesTecnicasMonitoreo(requerimiento);
    if (this.props.success == 'false') {
      swal("Descarga Especificaciones Técnicas", "Las especificaciones no pudieron ser descargadas, favor verificar si fueron cargas", "error");
    } else if (this.props.success == 'true') {
      swal("Descarga Especificaciones Técnicas", "Las especificaciones técnicas fueron descargadas", "success");
    }
  }

  changeHistoricoSelected = (historico)=>{
    this.setState({historicoSelected:historico});
  }
  changeListaHistoricoRequerimiento=(listaHistorico)=>{
    // this.setState({listaHistoricoRequerimientos:listaHistorico}); 
    this.props.setListaPaginacionHistorico(listaHistorico)
  // console.log("listaHistorico",listaHistorico);
  }

  validarPaginacionHistoricoRequerimiento = (page = 1, elementos = 4) => {
    // console.log("this.props.requerimiento.requerimiento.toReversed",this.props.requerimiento)
    // console.log("Validando paginacion")
    let index = elementos * page - elementos;
    let listaHistorico = [];
     let reversedListHistorico = this.props.requerimiento['requerimiento'].requerimiento.toReversed();
    for (let i = 0; i < elementos; i++) {
        listaHistorico.push(reversedListHistorico[index]);

      index++;
    }
   // console.log("requerimiento.requerimiento",requerimiento.requerimiento.length)
    let totalPages = Math.ceil(
      this.props.requerimiento.requerimiento.length / elementos
    );

    //("listaHistorico",listaHistorico);
     this.changeListaHistoricoRequerimiento(listaHistorico);
    // activePage= page
    // totalPages =totalPages
    // itemPerPage= elementos
    this.setState({
      // listaSolicitudesPageState: listaSolicitudes,
      activePage: page,
      totalPages,
      itemPerPage: elementos,
    });
  };

  CalcularTotalInsumos = (articulos,actividadesArticulos)=>{
    let articulosCount = [];  
    actividadesArticulos.forEach(x=>{
      articulos.forEach(articulo=>{
        if(x.articuloId==articulo.presupuestoId && x.estatus){
            articulosCount = [...articulosCount,articulo];
        }
      })
    })
    let totalInsumos = articulosCount.reduce((total,valor)=>total+valor.totalCosto,0);
   return `RD$ ${numbro(totalInsumos).format({thousandSeparated:true})}`;
  }

  CalcularTotalInsumosNoPoa = (articulos,actividadesArticulos)=>{
    let articulosCount = [];  
    actividadesArticulos.forEach(x=>{
      articulos.forEach(articulo=>{
        if(x.articuloId==articulo.presupuestoNoPoaId && x.estatus){
            articulosCount = [...articulosCount,articulo];
        }
      })
    })
    let totalInsumos = articulosCount.reduce((total,valor)=>total+valor.totalCosto,0);
   return `RD$ ${numbro(totalInsumos).format({thousandSeparated:true})}`;
  }


  CalcularTotalInsumosCotizado= (articulos)=>{
    if(articulos !== undefined) {
      // console.log("cotizado",articulos)
      let  articulosCount = articulos.filter(x=> x.estatus);
      let totalInsumos = articulosCount.reduce((total,valor)=>total+ (valor.precioCotizacion?valor.precioCotizacion:0),0);
      // console.log(totalInsumos)
     return `RD$ ${numbro(totalInsumos).format({thousandSeparated:true})}`;
    }
  }
  CalcularTotalInsumosAdjudicado= (articulos)=>{
    if(articulos !== undefined) {
      // console.log("adjudicado",articulos)
      let  articulosCount = articulos.filter(x=> x.estatus);
      let totalInsumos = articulosCount.reduce((total,valor)=>total+(valor.precioAdjudicacion?valor.precioAdjudicacion:0),0);
      // console.log(totalInsumos)
     return `RD$ ${numbro(totalInsumos).format({thousandSeparated:true})}`;
    }
  }

  render() {
    const { requerimiento, isLoading } = this.props;
    // const { numeroSolicitud, sigef, analista, tipoCompras, estado, fechaCreacion, actividadesArticuloSeguimiento, requerimientoProcesoPlurianual } = requerimiento['requerimiento'];
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {isLoading || this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (

                <div className="row">
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-6 pb-4 border-right">
                        <h3>Detalles</h3>
                        <hr style={{ marginTop: '0rem' }} />

                        <div className="form-group mt-4">
                          <label className="text-left" htmlFor="numeroSigef">Número De Solicitud</label>
                          <input
                            type="text"
                            className="form-control text-dark"
                            id="numeroSolicitud"
                            required
                            defaultValue={requerimiento["requerimiento"].numeroSolicitud}
                            value={requerimiento["requerimiento"].numeroSolicitud}
                            disabled
                          />
                        </div>
                        <div className="form-group mt-4">
                          <label htmlFor="analistaAsignado">Analista asignado</label>
                          <input
                            type="text"
                            className="form-control text-dark"
                            id="tipoProcesoCompra"
                            value={(requerimiento && requerimiento["requerimiento"].analista && requerimiento["requerimiento"].analista.name !== null) ? requerimiento["requerimiento"].analista.name : '-'}
                            disabled
                          />
                        </div>

                        <div className="form-group mt-4">
                          <label htmlFor="tipoProcesoCompra">Tipo proceso de compra</label>
                          <input
                            type="text"
                            className="form-control text-dark"
                            id="tipoProcesoCompra"
                            value={(requerimiento && requerimiento["requerimiento"].tipoCompras && requerimiento["requerimiento"].tipoCompras.nombre !== null) ? requerimiento["requerimiento"].tipoCompras.nombre : '-'}
                            disabled
                          />
                        </div>

                        <div className="form-group mt-4">
                          <label htmlFor="estatusSecundario">Estatus secundario</label>
                          <input
                            type="text"
                            className="form-control text-dark"
                            id="estatusSecundario"
                            value={requerimiento["requerimiento"].estado.nombre}
                            disabled
                          />
                        </div>

                      </div>
                      <div className="col-6 pb-4">
                        <h3>-</h3>
                        <hr style={{ marginTop: '0rem' }} />

                        <div className="form-group mt-4">
                          <label className="text-left" htmlFor="numeroSigef">Número De SIGEF</label>
                          <input
                            type="number"
                            className="form-control text-dark"
                            id="numeroSigef"
                            required
                            value={requerimiento["requerimiento"].sigef}
                            disabled
                          />
                        </div>

                        <div className="form-group mt-4">
                          <label className="text-left" htmlFor="numeroSigef">Fecha de Creación</label>

                          <br />
                          <Moment
                            format="DD/MM/YYYY"
                            date={requerimiento["requerimiento"].fechaCreacion}
                            className="font-weight-bold"
                          >
                          </Moment>
                        </div>

                        <div className="custom-control custom-checkbox mt-4">
                          <input type="checkbox" className="custom-control-input" id="procesoPlurianual" disabled checked={requerimiento['requerimiento'].requerimientoProcesoPlurianual} />
                          <label className="custom-control-label" htmlFor="procesoPlurianual">¿Es proceso plurianual?</label>
                        </div>
                        <div className="form-group mt-4">
                          <br />
                          <label className="text-left" htmlFor="especificacionesTecnicas">Especificaciones Técnicas</label>
                          <br />
                          <i
                            className=" fas fa-file-pdf fa-2x "
                            style={{marginLeft:30,cursor:"pointer"}}
                            onClick={async () => {
                              this.descargarEspecificacionesTecnicas(requerimiento['requerimiento'])
                            }}
                          ></i>
                        </div>
                        <div className="form-group mt-4">
                          <br />
                          <label className="text-left" htmlFor="especificacionesTecnicas">Notas Requerimiento</label>
                          <br />
                          <i
                            className=" fas fa-clipboard-list fa-2x "
                            style={{marginLeft:30,cursor:"pointer"}}
                            onClick={async () => {
                             this.ActivarModalHistoricoRequerimiento()
                            }}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <div>
                        <label style={{ fontWeight: "bold", fontSize: 20 }}>Insumos</label>
                      </div>
                      <Table
                        responsive
                        className="table-striped">
                        <thead>
                          <tr>
                            <th>Producto</th>
                            <th>Subclase</th>
                            <th>Cod. Gasto</th>
                            <th>Valor</th>
                            <th>Cotización</th>
                            <th>Adjudicado</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                           !this.props.requerimiento['requerimiento'].requerimientoActividad[0].noPoa ?(
                          this.state.presupuestoState.map(
                            (articulo,index) =>
                              articulo != null &&
                              articulo != undefined && (
                              
                                <Insumos
                                  key={articulo.articuloId}
                                  ActividadArticuloSeguimiento={requerimiento['requerimiento'].actividadesArticuloSeguimiento[index]}
                                  presupuesto= {articulo}
                                  user={this.props.user}
                                  editar={false}
                                  ActivarModalDetalleArticulo={this.ActivarModalDetalleArticulo}
                                />
                              )
                          )): this.state.presupuestoNoPoaState.map(
                            (articulo,index) =>
                              articulo != null &&
                              articulo != undefined && (
                              
                                <InsumosNoPoa
                                key={articulo.articuloId}
                                ActividadArticuloSeguimiento={requerimiento['requerimiento'].actividadesArticuloSeguimiento[index]}
                                presupuesto= {articulo}
                                user={this.props.user}
                                editar={false}
                                ActivarModalDetalleArticulo={this.ActivarModalDetalleArticulo}
                              />
                              )
                          )                                                  
                          }
                           {!this.props.requerimiento['requerimiento'].requerimientoActividad[0].noPoa ?(
                           <tr>
                               <td className="text-left font-weight-bold">Total</td>
                               <td></td>
                               <td></td>
                               <td className="font-weight-bold">{this.CalcularTotalInsumos(this.state.presupuestoState,requerimiento['requerimiento'].actividadesArticuloSeguimiento)}</td>
                               <td className="font-weight-bold">{this.CalcularTotalInsumosCotizado(requerimiento['requerimiento'].actividadesArticuloSeguimiento)}</td>
                               <td className="font-weight-bold">{this.CalcularTotalInsumosAdjudicado(requerimiento['requerimiento'].actividadesArticuloSeguimiento)}</td>
                               <td></td>
                               </tr>
                               ):(
                                <tr>
                               <td className="text-left font-weight-bold">Total</td>
                               <td></td>
                               <td></td>
                               <td className="font-weight-bold">{this.CalcularTotalInsumosNoPoa(this.state.presupuestoNoPoaState,requerimiento['requerimiento'].actividadesArticuloSeguimiento)}</td>
                               <td className="font-weight-bold">{this.CalcularTotalInsumosCotizado(requerimiento['requerimiento'].actividadesArticuloSeguimiento)}</td>
                               <td className="font-weight-bold">{this.CalcularTotalInsumosAdjudicado(requerimiento['requerimiento'].actividadesArticuloSeguimiento)}</td>
                               <td></td>
                               </tr>
                               )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              )}
              {this.state.modalDetalleArticulo && (
                <ModalDetalleArticulo
                  presupuesto={this.state.presupuesto}
                  ActivarModalDetalleArticulo={this.ActivarModalDetalleArticulo}
                  modalDetalleArticulo={this.state.modalDetalleArticulo}
                ></ModalDetalleArticulo>
              )}
              {this.state.modalHistoricoRequerimiento && (
                <ModalHistoricoRequerimiento
                  requerimiento={requerimiento}
                  ActivarModalHistoricoRequerimiento={this.ActivarModalHistoricoRequerimiento}
                  modalHistoricoRequerimiento={this.state.modalHistoricoRequerimiento}
                  historicoSelected={this.state.historicoSelected}
                  changeHistoricoSelected = {this.changeHistoricoSelected}
                  listaHistoricoRequerimientos={this.props.listaHistoricoRequerimientos}
                  validarPaginacionHistoricoRequerimiento= {this.validarPaginacionHistoricoRequerimiento}
                  changeListaHistoricoRequerimiento = {this.changeListaHistoricoRequerimiento}
                  activePage = {this.state.activePage}
                  itemPerPage = {this.state.itemPerPage}
                ></ModalHistoricoRequerimiento>
              )}
            </div>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  requerimiento: state.requerimientos.requerimiento,
  success: state.requerimientos.success,
  isLoading: state.requerimientos.isLoading,
   historicoRequerimientos: state.historicoRequerimientos,
   listaHistoricoRequerimientos: state.historicoRequerimientos.listaHistoricoRequerimientos
});

const mapDispatchToProps = (dispatch) => ({
  getRequerimiento: requerimientoId => dispatch(getRequerimiento(requerimientoId)),
  getEspecificacionesTecnicasMonitoreo: requerimiento => dispatch(getEspecificacionesTecnicasMonitoreo(requerimiento)),
  setListaPaginacionHistorico:(listaHistoricoRequerimientos)=>dispatch(setListaPaginacionHistorico(listaHistoricoRequerimientos))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(MostrarRequerimiento, [
    "Administrador general",
    "Responsables de compras",
    "Analista Compras",
    "Responsables de areas",
    "Responsables de productos"
  ])
);
