import React from "react";
import { Table } from "reactstrap";
import numbro from "numbro";

const ListaDetalleInsumosNoPoa = props => {
  return (
    <React.Fragment>
      <Table
        style={{ backgroundColor: "#fff", color: "black" }}
        responsive
        className="table-striped"
      >
        <thead>
          <tr>
            <th>Insumo</th>
            <th>Subclase</th>
            <th>Cuenta</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {props.insumos.map(insumo => (
            <React.Fragment key={insumo.presupuestoNoPoaId}>
              <tr key={insumo.presupuestoId} className="text-dark">
                <td>{insumo.insumo}</td>
                <td>{insumo.subclase}</td>
                <td>{insumo.cuenta}</td>
                <td>{'RD$ '+numbro(insumo.valor*insumo.frecuencia*insumo.cantidad).format({thousandSeparated:true})}</td>
              </tr>
            </React.Fragment>
          ))}
          <tr key={0}>
            <td></td>
            <td></td>
            <td style={{fontWeight:"bold"}}>Total</td>
            <td>{'RD$ '+numbro(props.insumos.reduce((accumulator,obj)=>{
              return accumulator + (obj.valor * obj.frecuencia * obj.cantidad);
            },0)).format({thousandSeparated:true})}</td>
          </tr>
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default ListaDetalleInsumosNoPoa;
