import React, { Component } from "react";
import Loading from "./../utils/Loading";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import swal from "sweetalert";

export const ModalDevolucionMonitoreo = (props) => {
  const toggle = () => props.activarModalDevolucionMonitoreo(props.monitoreo);
  let comentarioRef = React.createRef();
  const { modalDevolucion } = props;
  return (
    <Modal isOpen={modalDevolucion} toggle={toggle} size="lg" backdrop="static">
      <ModalHeader className="block-example border-bottom " toggle={toggle}>
        Razón Devolución Monitoreo
      </ModalHeader>
      <ModalBody>
        <textarea
          placeholder="Comentario de la devolución"
          ref={comentarioRef}
          className="form-control"
          rows="3"
        ></textarea>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-danger btn-sm"
          onClick={(e) => {
            e.preventDefault();

            if (
              comentarioRef.current != null &&
              comentarioRef.current.value !== ""
            ) {
              props.devolverMonitoreo(
                props.monitoreo,
                comentarioRef.current.value
              );
            } else {
              swal("Monitoreos","El comentario es un valor requerido para la devolución del monitoreo","error");
            }
          }}
        >
          Devolver
        </button>
        <button
          className="btn btn-info btn-sm"
          onClick={(e) => {
            e.preventDefault();
            toggle();
          }}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  );
};
