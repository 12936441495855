import React, { Component } from "react";
import Loading from "./../utils/Loading";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import swal from "sweetalert";

export const ModalInformeTrimestral = (props) => {
    const toggle = () =>
      props.activarModalInformeTrimestral(props.modalInformeTrimestral);
  
    const {  modalInformeTrimestralStatus} = props;
    return (
      <Modal isOpen={modalInformeTrimestralStatus} toggle={toggle} size="lg" backdrop="static">
        <ModalHeader className="block-example border-bottom " toggle={toggle}>
          Cargar Informe Trimestral
        </ModalHeader>
  
        <ModalBody>
            <div className="row">
           <div className="form-group col-md-4">
            <label>Trimestre</label>
            <select className="form-control" onChange={(e)=>{
                      e.preventDefault();
                      props.seleccionarTrimestre(e.currentTarget.value);
                      }}>
                <option key="0" value="">--Seleccionar Trimestre--</option>
               {props.trimestres.map(trimestre=>(
                    <option key={trimestre.trimestreId}  value={trimestre.trimestreId}>{trimestre.nombre}</option>
               ))} 
            </select>
           </div>
           <div className="form-group col-md-8">
                  <label>Cargar Informe del Trimestre (PDF)</label>
                  <input
                  id="inputpdf"
                    className="form-control text-dark"
                    type="file" accept=".pdf"
                    name="file[]"
                    onChange= {
                      (e) => {
                          e.preventDefault();
                          ////////////////////////////////////////////////////////////
                          var fileInput = document.getElementById('inputpdf');
                          var filePath = fileInput.value;
                          var allowedExtensions = /(\.pdf|\.PDF)$/i;
                          if (!allowedExtensions.exec(filePath)) {
                            swal("Error de tipo de archivo","Debe cargar archivos en formato PDF","error");
                              fileInput.value = '';
                              return false;
                          } 
                          ///////////////////////////////////////////////
                          props.cargarInformeTrimestral(e);
                      }
                    }
                  />
                </div>
           </div>
         </ModalBody>
        <ModalFooter>
        <button
            className="btn btn-primary"
            onClick={(e)=>{
                e.preventDefault();
                props.guardarInformeTrimestral();
            }}
          >
            Guardar
          </button>
          <button
            className="btn btn-danger"
            onClick={(e) => {
              e.preventDefault();
              props.activarModalInformeTrimestral(props.modalInformeTrimestral);
            }}
          >
            Salir
          </button>
        </ModalFooter>
      </Modal>
    );
  };