import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  Table,
  Container,
} from "reactstrap";

import { Authorization } from "../../../utils/Authorization";
import Loading from "../../utils/Loading";
import { Link } from "react-router-dom";

import { ModalRecepcionBienesServicios } from "../../utils/ModalRecepcionBienesServicios";
import Requerimiento from "../../requerimientos/Requerimiento";

import { api_download_informes } from "../../../config";
import Pagination from "react-js-pagination";

import { getAreasOrganizacionales } from "../../../redux/actions/areas-actions"; //me
import {getRequerimientosAdmin} from "./../../../redux/actions/requerimientos-actions";
import {
  getUsuarioEntity,
} from "../../../redux/actions/usuarios-actions";

class DepartamentoAdministrativo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      
      file: [],
      fecha: "",
      documento: [],
      trimestreId: "",
      estatusEnviar: false,
      areaid2:0,
      activePage: 1,
      totalPages: 0,
      itemPerPage: 10,
      listaRequerimientosPageState:[]
    };
  }

  componentDidMount() {
    // this.props.getAreasOrganizacionales();//me
    this.cargarDatos();
    this.setState({areaid2:this.props.user.areaOrganizacional.areaOrganizacionalId})
  }

  validarPaginacion = (page = 1, elementos = 10) => {
    let index = elementos * page - elementos;
    let listaRequerimientos = [];

    for (let i = 0; i < elementos; i++) {
      listaRequerimientos.push(this.state.listaRequerimientosState[index]);

      index++;
    }

    let totalPages = Math.ceil(
      this.state.listaRequerimientosState.length / elementos
    );

    this.setState({
      listaRequerimientosPageState: listaRequerimientos,
      activePage: page,
      totalPages,
      itemPerPage: elementos,
    });
  };

  cargarDatos = async()=>{
    await this.props.getAreasOrganizacionales();
    this.setState({areaid2:this.props.user.areaOrganizacional.areaOrganizacionalId})
    await this.props.getRequerimientosAdmin(15);
    // console.log("this.props.requerimientosAdmin",this.props.requerimientosAdmin);
    this.setState({ listaRequerimientosState: this.props.requerimientosAdmin,isLoadingState:false })
    this.validarPaginacion();
  }

  obtenerIdUsuario = (usuarioId) => {
    this.props.getUsuarioEntity(usuarioId);
    return this.props.usuarioId ?? 0;
  }

  render() {
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (
                <div className="row">

                  {/* //me */}
                  <div className="col-md-5 text-left" style={{display:"none"}}>
                  <label>Filtro Área</label>
                     <select
                      className="form-control"
                      defaultValue={this.state.areaid2}
                      
                      id="areaSelected"
                      onChange={(e) => {
                        e.preventDefault();
                   
                        this.setState({ isLoadingState: true });
                        this.setState({areaid2:document.getElementById("areaSelected").value});
                        this.cargarDatos(document.getElementById("areaSelected").value);
                      }}
                    >
                      {this.props.areaOrganizacionales.map((area) => (
                        <option key={area.areaOrganizacionalId} value={area.areaOrganizacionalId}>
                          {area.nombre}
                        </option>
                        
                      ))}
                    </select>

                    <label>Filtro Área</label>
                    <select
                        className="form-control"
                        defaultValue={this.state.areaid2}

                        id="areaSelected"
                        onChange={(e) => {
                          e.preventDefault();

                          this.setState({ isLoadingState: true });
                          this.setState({areaid2:document.getElementById("areaSelected").value});
                          this.cargarDatos(document.getElementById("areaSelected").value);
                        }}
                    >
                      {this.props.areaOrganizacionales.map((area) => (
                          <option key={area.areaOrganizacionalId} value={area.areaOrganizacionalId}>
                            {area.nombre}
                          </option>

                      ))}
                    </select>

                  </div>

                  <div className="col-md-12 text-left">
                   {/* <h3>APROBAR SOLICITUDES DE COMPRAS DEPARTAMENTO ADMINISTRATIVO</h3> */}
                  </div>
                  <div className="col-md-12 mt-3">
                  {this.state.listaRequerimientosState && this.state.listaRequerimientosState.length>0?(
                  <Table
                      responsive
                      className="table-striped">
                      <thead>
                        <tr>
                          <th>Número Solicitud</th>
                          <th>Estado</th>
                          <th>Área Actual</th>
                          <th>Fecha de Entrada</th>
                          <th>Tipo de Compra</th>
                          <th>Analista Asignado</th>
                          <th>Proceso Plurianual</th>
                          {/* <th>SIGEF</th> */}
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listaRequerimientosPageState.map(
                          (requerimiento) =>
                            requerimiento != null &&
                            requerimiento != undefined && (
                              <Requerimiento
                                key={requerimiento.requerimientoId}
                                requerimiento={requerimiento}
                                user={this.props.user}
                                // eliminarRequerimiento={this.eliminarRequerimiento}
                                obtenerIdUsuario={this.obtenerIdUsuario}
                              />
                            )
                        )}
                      </tbody>
                      <div className="d-flex justify-content-center">
                        <Pagination
                          prevPageText="ant"
                          nextPageText="sig"
                          firstPageText="prime"
                          lastPageText="ultimo"
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemPerPage}
                          totalItemsCount={this.state.listaRequerimientosState.length}
                          onChange={this.validarPaginacion.bind(this)}
                        />
                      </div>
                    </Table>
                  ):(<h3>No tiene solicitudes de compras pendientes por aprobar</h3>)}
                  </div>
                </div>
              )}
            </div>
            <ModalRecepcionBienesServicios
              guardarInformeTrimestral={this.guardarInformeTrimestral}
              trimestres={this.state.trimestresEntity}
              seleccionarTrimestre={this.seleccionarTrimestre}
              modalInformeTrimestralStatus={
                this.state.modalInformeTrimestralStatus
              }
              cargarInformeTrimestral={this.cargarInformeTrimestral}
              activarModalInformeTrimestral={this.activarModalInformeTrimestral}
            ></ModalRecepcionBienesServicios>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  requerimientosAdmin: state.requerimientos.requerimientosAdmin,
  informesTrimestrales: state.informesTrimestrales.informesTrimestrales,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,//me
});

const mapDispatchToProps = (dispatch) => ({
    getRequerimientosAdmin:(estadoId)=>dispatch(getRequerimientosAdmin(estadoId)),
    getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),//me
    getUsuarioEntity: (usuarioId) => dispatch(getUsuarioEntity(usuarioId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(DepartamentoAdministrativo, [
    "Administrador general",
    "Encargado Presupuesto",
    "Analista Presupuesto",
    
  ])
);


// import React, { Component } from "react";
// import swal from "sweetalert";
// import { connect } from "react-redux";
// import {
//   Table,
//   Container,
// } from "reactstrap";

// import { Authorization } from "../../../utils/Authorization";
// import Loading from "../../utils/Loading";
// import { Link } from "react-router-dom";
// import Pagination from "react-js-pagination";

// import { ModalRecepcionBienesServicios } from "../../utils/ModalRecepcionBienesServicios";
// import { getTrimestres } from "../../../redux/actions/trimestres-actions";
// import { api_download_informes } from "../../../config";
// import {
//   createInformeTrimestral,
//   updateInformeTrimestral,
//   getInformesTrimestral,
//   deleteInformeTrimestral,
// } from "../../../redux/actions/informe_trimestral_actions";

// import { getRequerimientos } from "../../../redux/actions/requerimientos-actions";
// import { getAreasOrganizacionales } from "../../../redux/actions/areas-actions"; 
// import SolicitudComprasPresupuesto from "./SolicitudComprasPresupuesto.jsx";

// class DepartamentoAdministrativo extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isLoadingState: true,
//       trimestres: [],
//       trimestresEntity: [],
//       modalInformeTrimestralStatus: false,
//       selectedFile: null,
//       file: [],
//       fecha: "",
//       documento: [],
//       trimestreId: "",
//       estatusEnviar: false,
//       areaid2:0,

//       listaSolicitudesState: [],
//       listaSolicitudesPageState: [],
//       activePage: 1,
//       totalPages: 0,
//       itemPerPage: 10,
//     };
//   }

//   componentDidMount() {
//     this.cargarDatos();
//     this.props.getAreasOrganizacionales();//me
//     this.setState({areaid2:this.props.user.areaOrganizacional.areaOrganizacionalId})
//   }

//   cargarDatos = async (areafiltro) => {
//     await this.props.getRequerimientos();
//     this.setState({ listaSolicitudesState: this.props.requerimientos }, () =>
//       this.validarPaginacion()
//     );

//     await this.props.getTrimestres();

//     if(areafiltro > 0){
//     await this.props.getInformesTrimestral(
//       this.props.poaActivo.poaId,
//       areafiltro
//     );
//     this.setState({areaid2:areafiltro});
//   } else {
//     await this.props.getInformesTrimestral(
//       this.props.poaActivo.poaId,
//       this.props.user.areaOrganizacional.areaOrganizacionalId
//     );
//   }

//     this.setState({ isLoadingState: false, trimestres: this.props.trimestres });
//     await this.CargarTrimestre();
//   };

//   cargarInformeTrimestral = (informe) => {
//     let files = [];
//     let documentos = [];
//     if (informe.target.files.length > 0) {
//       for (let i = 0; i < informe.target.files.length; i++) {
//         files.push(informe.target.files[i]);
//         documentos.push(informe.target.files[i].name);
//       }
//       this.setState({
//         file: files,
//         documento: documentos,
//       });
//     }
//     else {
//       this.setState({
//         file: "",
//         documento: "",
//       });
//     }
//   };

//   activarModalInformeTrimestral = () => {
//     this.CargarTrimestre();
//     this.setState({
//       modalInformeTrimestralStatus: !this.state.modalInformeTrimestralStatus,
//     });
//   };

//   CargarTrimestre = () => {
//     let informesTrimestral = this.props.informesTrimestrales;
//     let trimestreState = [...this.state.trimestres];
//     for (let i = 0; i < trimestreState.length; i++) {
//       for (let m = 0; m < informesTrimestral.length; m++) {
//         if (
//           informesTrimestral[m].trimestre.trimestreId ===
//           trimestreState[i].trimestreId
//         ) {
//           trimestreState.splice(i, 1);
//           this.setState({ trimestresEntity: trimestreState });
//           i = -1;
//           break;
//         }
//       }
//     }
//     if (
//       this.state.trimestresEntity.length === 0 &&
//       informesTrimestral.length === 0
//     ) {
//       this.setState({ trimestresEntity: this.state.trimestres });
//     }
//   };

//   seleccionarTrimestre = (trimestreId) => {
//     this.setState({ trimestreId: trimestreId });
//   };

//   enviarInforme = async (informe) => {
//     this.setState({ isLoadingState: true });

//     swal({
//       title: "Está seguro de enviar a revisión el informe del trimestre?",
//       text: "",
//       icon: "warning",
//       buttons: true,
//       dangerMode: true,
//     })
//     .then(async (confirm) => {
//       if (confirm) {
//         let informeTrimestral = {
//           informeTrimestralId: informe.informeTrimestralId,
//           nivelAprobacionId: this.props.user.nivelAprobacion.padre,
//           envio: true,
//           comentario: "Enviado para aprobación",
//         };
//         await this.props.updateInformeTrimestral(informeTrimestral);
//         this.setState({ isLoadingState: false });
//       } else {
//         this.setState({ isLoadingState: false });
//       }
//     });
//   };

//   eliminarInformeTrimestral = async (informeTrimestralId) => {

//     swal({
//       title: "Está seguro de querer eliminar este informe del trimestre?",
//       text: "No podrá ser recuperado luego de ser eliminado",
//       icon: "warning",
//       buttons: true,
//       dangerMode: true,
//     })
//     .then(async (confirm) => {
//       if (confirm) {
//          this.setState({ isLoadingState: true });
//          await this.props.deleteInformeTrimestral(informeTrimestralId);
//          this.setState({ isLoadingState: false });
//       }
//     });
//   };

//   devolverInforme = async (informeTrimestralId, comentario) => {
//     let informeTrimestral = {
//       informeTrimestralId: informeTrimestralId,
//       nivelAprobacionId: this.props.user.nivelAprobacion.hijo,
//       envio: true,
//       comentario,
//     };

//     await this.props.updateInformeTrimestral(informeTrimestral);
//   };

//   validarEstatusEnviado = (informeTrimestral) => {
//     let resultado =
//       informeTrimestral.nivelAprobacion.nivelAprobacionId !==
//       this.props.user.nivelAprobacion.nivelAprobacionId
//         ? true
//         : false;
//     return resultado;
//   };
//   validarGuardarInforme = () => {
//     if (this.state.trimestreId.length === 0) {
//       swal("Informe Trimestral", "El campo trimestre es requerido para el informe del trimestre","error");

//       return false;
//     }

//     if (this.state.file.length === 0) {
//       swal("Informe Trimestral", "El campo de archivo es un valor requerido para el informe del trimestre","error");

//       return false;
//     }
//     return true;
//   };

//   guardarInformeTrimestral = async () => {
//     if (this.validarGuardarInforme()) {
//       this.setState({ isLoadingState: true });
//       this.activarModalInformeTrimestral();

//       let informeTrimestral = {
//         trimestreId: this.state.trimestreId,
//         nivelAprobacionId: this.props.user.nivelAprobacion.nivelAprobacionId,
//         nombreImagen: this.state.documento[0],
//         areaOrganizacionalId: this.props.user.areaOrganizacional
//           .areaOrganizacionalId,
//         poaId: this.props.poaActivo.poaId,
//       };

//       const fd = new FormData();
//       if (this.state.file.length > 0) {
//         for (let i = 0; i < this.state.file.length; i++) {
//           fd.append(`documento[]`, this.state.file[i], this.state.documento[i]);
//         }
//       }

//       Object.keys(informeTrimestral).forEach(function (item) {
//         fd.append(item, informeTrimestral[item]);
//       });

//       await this.props.createInformeTrimestral(fd);

//       this.setState({ isLoadingState: false });
//     }
//   };

//   validarPaginacion = (page = 1, elementos = 10) => {
//     let index = elementos * page - elementos;
//     let listaSolicitudes = [];

//     for (let i = 0; i < elementos; i++) {
//       listaSolicitudes.push(this.state.listaSolicitudesState[index]);

//       index++;
//     }

//     let totalPages = Math.ceil(
//       this.state.listaSolicitudesState.length / elementos
//     );

//     this.setState({
//       listaSolicitudesPageState: listaSolicitudes,
//       activePage: page,
//       totalPages,
//       itemPerPage: elementos,
//     });
//   };

//   validar;

//   render() {
//     return (
//       <div className="pb-6 pt-5 pt-md-7">
//         <Container fluid>
//           <React.Fragment>
//             <div className="container-fluid bg-white border py-3">
//               {this.state.isLoadingState ? (
//                 <Loading></Loading>
//               ) : (
//                 <div className="row">

//                   {/* //me */}
//                   <div className="col-md-5 text-left" style={{display:"none"}}>
//                   <label>Filtro Área</label>
//                      <select
//                       className="form-control"
//                       defaultValue={this.state.areaid2}
                      
//                       id="areaSelected"
//                       onChange={(e) => {
//                         e.preventDefault();
                   
//                         this.setState({ isLoadingState: true });
//                         this.setState({areaid2:document.getElementById("areaSelected").value});
//                         this.cargarDatos(document.getElementById("areaSelected").value);
//                       }}
//                     >
//                       {this.props.areaOrganizacionales.map((area) => (
//                         <option key={area.areaOrganizacionalId} value={area.areaOrganizacionalId}>
//                           {area.nombre}
//                         </option>
                        
//                       ))}
//                     </select>

//                     <label>Filtro Área</label>
//                     <select
//                         className="form-control"
//                         defaultValue={this.state.areaid2}

//                         id="areaSelected"
//                         onChange={(e) => {
//                           e.preventDefault();

//                           this.setState({ isLoadingState: true });
//                           this.setState({areaid2:document.getElementById("areaSelected").value});
//                           this.cargarDatos(document.getElementById("areaSelected").value);
//                         }}
//                     >
//                       {this.props.areaOrganizacionales.map((area) => (
//                           <option key={area.areaOrganizacionalId} value={area.areaOrganizacionalId}>
//                             {area.nombre}
//                           </option>

//                       ))}
//                     </select>

//                   </div>

//                   <div className="col-md-12 text-left">
//                    <h3>SOLICITUDES DE COMPRAS PARA VALIDAR FONDOS POR EL DEPARTAMENTO DE PRESUPUESTO</h3>
//                   </div>
//                   <div className="col-md-12 mt-3">
//                     <Table>
//                       <thead>
//                         <tr>
//                           <th>No. Producto</th>
//                           <th>Analista Asignado</th>
//                           <th>No. Requerimiento</th>
//                           <th>Área</th>
//                           <th>Fecha de Entrada</th>
//                           <th>Acciones</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                        <tr>
//                         {/* <td>892960</td>
//                         <td>Miguel Cruz</td>
//                         <td>INT-MEM-2023-158</td>
//                         <td>Viceministerio de Hidrocarburos</td>
//                         <td>10/01/2023</td>
//                         <td className="d-flex flex-row">
//                           <Link
//                             to={{
//                               pathname: `validacion/${1}`,
//                               state: "/admin/solicitud-compra/departamento-presupuesto/validacion/:solicitudCompraId",
//                             }}
//                             className="p-2"
//                           >
//                             <i className="fas fa-eye text-primary"></i>
//                           </Link>
//                         </td> */}
//                         {this.state.listaSolicitudesPageState.map(
//                         (solicitud) =>
//                           solicitud != null &&
//                           solicitud != undefined && (
//                             <SolicitudComprasPresupuesto
//                               key={solicitud.requerimientoId}
//                               requerimiento={solicitud}
//                               user={this.props.user}
//                             />
//                           )
//                         )}
//                         </tr>
//                       </tbody>
//                     </Table>
//                     <div className="d-flex justify-content-center">
//                       <Pagination
//                         prevPageText="ant"
//                         nextPageText="sig"
//                         firstPageText="prime"
//                         lastPageText="ultimo"
//                         itemClass="page-item"
//                         linkClass="page-link"
//                         activePage={this.state.activePage}
//                         itemsCountPerPage={this.state.itemPerPage}
//                         totalItemsCount={this.state.listaSolicitudesState.length}
//                         onChange={this.validarPaginacion.bind(this)}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </div>
//             <ModalRecepcionBienesServicios
//               guardarInformeTrimestral={this.guardarInformeTrimestral}
//               trimestres={this.state.trimestresEntity}
//               seleccionarTrimestre={this.seleccionarTrimestre}
//               modalInformeTrimestralStatus={
//                 this.state.modalInformeTrimestralStatus
//               }
//               cargarInformeTrimestral={this.cargarInformeTrimestral}
//               activarModalInformeTrimestral={this.activarModalInformeTrimestral}
//             ></ModalRecepcionBienesServicios>
//           </React.Fragment>
//         </Container>
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   poaActivo: state.poas.poaActivo,
//   user: state.auth.signed,
//   trimestres: state.trimestres.trimestres,
//   informesTrimestrales: state.informesTrimestrales.informesTrimestrales,
//   areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
//   requerimientos: state.requerimientos.requerimientos,
// });

// const mapDispatchToProps = (dispatch) => ({
//   getTrimestres: () => dispatch(getTrimestres()),
//   createInformeTrimestral: (informeTrimestral) =>
//     dispatch(createInformeTrimestral(informeTrimestral)),
//   getInformesTrimestral: (poaId, areaOrganizacionalId) =>
//     dispatch(getInformesTrimestral(poaId, areaOrganizacionalId)),
//   deleteInformeTrimestral: (informeTrimestralId) =>
//     dispatch(deleteInformeTrimestral(informeTrimestralId)),
//   updateInformeTrimestral: (informeTrimestral) =>
//     dispatch(updateInformeTrimestral(informeTrimestral)),
//     getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
//   getRequerimientos: () => dispatch(getRequerimientos())
// });
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(
//   Authorization(DepartamentoAdministrativo, [
//     "Administrador general",
//     "Responsables de compras"
//   ])
// );
