import {
  MOSTRAR_TIPOS_COMPRAS,
  CREAR_TIPO_COMPRA,
  EDITAR_TIPO_COMPRA,
  ELIMINAR_TIPO_COMPRA,
  TIPOS_COMPRAS_FAILED,
  LOADING_TIPOS_COMPRAS,
  TIPOS_COMPRAS_SUCCESS,
} from "../types";
import initialState from "../initial-state.js";
const { tiposCompras } = initialState;

const tipoComprasReducer = (state = tiposCompras, action) => {
  switch (action.type) {
    case MOSTRAR_TIPOS_COMPRAS:
      return {
        ...state,
        tiposCompras: action.payload
      };
    case LOADING_TIPOS_COMPRAS:
      return {
        ...state,
        isLoading: true
      };
    case CREAR_TIPO_COMPRA:
      return {
        ...state,
        tiposCompras: [...state, action.payload]
      };
    case EDITAR_TIPO_COMPRA:
      return {
        ...state,
        tiposCompras: state.tiposCompras.map(compra =>
          compra.tipoCompraId === action.payload.tipoCompraId
            ? (compra = action.payload)
            : compra
        )
      };
    case ELIMINAR_TIPO_COMPRA:
      return {
        ...state,
        tiposCompras: [
          ...state.tiposCompras.filter(
            (tipoCompra) => tipoCompra.tipoCompraId !== action.payload
          ),
        ],
      };
    case TIPOS_COMPRAS_SUCCESS:
    case TIPOS_COMPRAS_FAILED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default tipoComprasReducer;
