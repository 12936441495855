import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  deleteActividades,
  getActividad,
} from "./../../redux/actions/actividades-actions";
import ReactTooltip from 'react-tooltip';

const Actividad = (props) => {
  const {
    actividadId,
    descripcion,
    medioVerificacion,
    ponderacionActividad,
   notasActividad
  } = props.actividad;

  const cargarActividad = () => async (dispatch) => {
    props.getActividad(props.actividad.actividadId);
  };

  const truncateString = (str) => {
    let num = 50;

    return str && str.length > num ? str.slice(0, num) + "..." : str;
  };
  const eliminarActividad = () => {
    let confirmed = window.confirm("Está seguro de eliminar la actividad");
    if (confirmed) {
      let actividad = {
        actividadId: props.actividad.actividadId,
        productoId: props.productoId,
      };
      props.deleteActividades(actividad).then(() => window.location.reload());
    }
  };

  return (
    <tr>
      <td title={descripcion}>{truncateString(descripcion)}</td>
      <td>{ponderacionActividad}</td>
      <td title={medioVerificacion.descripcion}>
        {truncateString(medioVerificacion.descripcion)}
      </td>

      <td>
        {notasActividad.length>0&&(
            <button type={"button"} data-tip={"Esta actividad tiene notas de devolución"} data-event='click' className="btn btn-sm btn-danger">
               <i className="ni ni-chat-round"></i>
            </button>
         )}
        <ReactTooltip globalEventOff='click' />
        <ReactTooltip/>
        <Link
          to={{
            pathname: `/admin/actividad/detalle/${actividadId}`,
            state: `/admin/actividad/detalle/:actividadId`,
          }}
          className="btn btn-sm btn-primary"
        >
          Detalle
        </Link>

        <Link
          to={{
            pathname: `/admin/monitoreo/actividad/${actividadId}/nuevo`,
            state: "/admin/actividad/detalle/:actividadId/nuevo",
          }}
          onClick={cargarActividad()}
          className="btn btn-sm btn-info"
        >
          Monitoreo
        </Link>
        {props.validarOpcionEditar() ? (
          <Link
            to={{
              pathname: `/admin/actividad/editar/${actividadId}`,
              state: "/admin/actividad/editar/:actividadId",
            }}
            className="btn btn-sm btn-primary"
          >
            Editar
          </Link>
        ) : (
          <button
            title="Producto Enviado"
            className="btn btn-primary btn-sm opacity"
            onClick={(e) => e.preventDefault()}
          >
            Editar
          </button>
        )}

        {props.validarOpcionEditar() ? (
          <button
            onClick={(e) => {
              e.preventDefault();
              eliminarActividad();
            }}
            className="btn btn-sm btn-danger"
          >
            Eliminar
          </button>
        ) : (
          <button
            onClick={(e) => e.preventDefault()}
            className="btn btn-danger btn-sm opacity"
          >
            Eliminar
          </button>
        )}
      </td>
    </tr>
  );
};

export default connect(null, { deleteActividades, getActividad })(Actividad);
