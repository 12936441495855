import React from "react";
import { connect } from "react-redux";
import {
  deleteActividadesNopoa,
  getActividadNopoa
} from "./../../../redux/actions/actividades-actions";
import numbro from "numbro";

const AdminActividadNoPoa = props => {
  const {
    descripcion,
    // medioVerificacion,
    // ponderacionActividad,
    presupuestoNopoa,
    t1,
    t2,
    t3,
    t4
  } = props.actividad;

  const truncateString = (str,num =40) => {
    return str && str.length > num ? str.slice(0, num) + "..." : str;
  };

  return (
    <tr>
      <td title={descripcion}>{truncateString(descripcion)}</td>
      {/* <td>{ponderacionActividad}</td>
      <td title={medioVerificacion?.descripcion || ''}>{truncateString(medioVerificacion?.descripcion || '', 35)}</td> */}
      <td>
      {'RD$ '+numbro(presupuestoNopoa.reduce((value, objPresupuesto)=>{ return value +(objPresupuesto.valor*objPresupuesto.cantidad*objPresupuesto.frecuencia)},0)
      ).format({thousandSeparated:true}) }
      </td>
      <td>{t1?t1:0}</td>
      <td>{t2?t2:0}</td>
      <td>{t3?t3:0}</td>
      <td>{t4?t4:0}</td>
    </tr>
  );
};

export default connect(null, { deleteActividadesNopoa, getActividadNopoa })(
  AdminActividadNoPoa
);
