import {
  MOSTRAR_AREA_POA_MONITOREO,
  EDITAR_AREA_POA_MONITOREO,
  LOADING_AREA_POA_MONITOREO,
  AREA_POA_MONITOREO_FAILED,
  AREA_POA_MONITOREO_SUCCESS,
} from "./../types";

import { api_base } from "../../config";
import { getData } from "./../../utils/api-client";

export const getAreasPoaMonitoreo = (poaId) => async (dispatch) => {
  let url = api_base + `api/v1/areapoamonitoreo/${poaId}`;
  dispatch({ type: LOADING_AREA_POA_MONITOREO });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_AREA_POA_MONITOREO, payload: resultado.data });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: AREA_POA_MONITOREO_FAILED });
      }
    });
};

export const cerrarTodasAreasPoaMonitoreo = (poaId, trimestreId) => async (
  dispatch
) => {
  let url = api_base + "api/v1/areapoamonitoreo/inhabilitar";
  dispatch({ type: LOADING_AREA_POA_MONITOREO });

  let obj = {
    poaId: poaId,
    trimestreId: trimestreId,
  };

  await getData
    .post(url, JSON.stringify(obj))
    .then((resultado) => {
      dispatch({ type: EDITAR_AREA_POA_MONITOREO, payload: resultado.data });
      dispatch({ type: AREA_POA_MONITOREO_SUCCESS });
    })
    .catch((error) => {
      if(error) {
        dispatch({ type: AREA_POA_MONITOREO_FAILED });
      }
    });
};

export const habilitarTodasAreasPoaMonitoreo = (poaId, trimestreId) => async (
  dispatch
) => {
  let url = api_base + "api/v1/areapoamonitoreo/habilitar";
  dispatch({ type: LOADING_AREA_POA_MONITOREO });

  let obj = {
    poaId: poaId,
    trimestreId: trimestreId,
  };

  await getData
    .post(url, JSON.stringify(obj))
    .then((resultado) => {
      dispatch({ type: EDITAR_AREA_POA_MONITOREO, payload: resultado.data });
      dispatch({ type: AREA_POA_MONITOREO_SUCCESS });
    })
    .catch((error) => {
      if(error) {
        dispatch({type: AREA_POA_MONITOREO_FAILED});
      }
    });
};

export const updateAreasPoaMonitoreo = (areapoamonitoreo) => async (
  dispatch
) => {
  let url = api_base + "api/v1/areapoamonitoreo/actualizar";
  dispatch({ type: LOADING_AREA_POA_MONITOREO });

  await getData
    .post(url, JSON.stringify(areapoamonitoreo))
    .then((resultado) => {
      dispatch({ type: EDITAR_AREA_POA_MONITOREO, payload: resultado.data });
      dispatch({ type: AREA_POA_MONITOREO_SUCCESS });
    })
    .catch((error) => {
      if(error) {
        dispatch({ type: AREA_POA_MONITOREO_FAILED });
      }
    });
};
