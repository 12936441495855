import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getProductosNopoa,
  updateProductoNopoa,
  calcularPonderacionAreaNopoa,
  calcularPonderacionGeneralNopoa,
  deleteProductoNopoa,
  getDocumentoDescargar
} from "../../redux/actions/productos-actions.js";
import { getReporteMonitoreo } from "../../redux/actions/monitoreo-actividad-actions.js";
import Producto from "./Producto.jsx";
import Loading from "../utils/Loading.jsx";
import { Authorization } from "../../utils/Authorization.js";
import { ModalLoading } from "../utils/ModalLoading.jsx";

import { getAreasOrganizacionales } from "../../redux/actions/areas-actions.js";
import {
  Table,
  Container,
} from "reactstrap";
import swal from "sweetalert";

class ListaProductosNoPoa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: false,
      modal: false,
      areaidSeleccionada: 0
    };
  }

  areaOrganizacionalRef = {};
  componentDidMount() {
    this.cargarDatos();
    this.setState({ isLoadingState: true });
    // this.props.getAreasOrganizacionales();
    this.setState({ areaidSeleccionada : this.props.user.areaOrganizacional.areaOrganizacionalId });
  }

  cargarDatos = async (area = 0) => {
    await this.props.getProductosNopoa();

    this.setState({ isLoadingState: false });
    if(area > 0){this.setState({ areaidSeleccionada : area });}
  };

  descargarDocumentoNoPoa = async (productoNoPoaId) => {
    await this.props.getDocumentoDescargar(productoNoPoaId);
  }

  aprobarProducto = (productoNoPoa) => {
    if (productoNoPoa.actividadesNopoa.length > 0) {
      swal({
        title: "¿Está seguro de enviar a revisión este producto?",
        text: "Una vez enviado, no podrá ser editado",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willSend) => {
        if (willSend) {
            let productoEditar = {
              productoNoPoaId: productoNoPoa.productoNoPoaId,
              descripcion: productoNoPoa.descripcion,
              resultado: productoNoPoa.resultado,
              indicador: productoNoPoa.indicador,
              meta: productoNoPoa.meta, 
              nivelAprobacionId: this.props.user.nivelAprobacion.padre,
              modoEditar: true,
            };
            this.props.updateProductoNopoa(productoEditar);
        }
      });
    } else {
      swal("Insumos","El producto no puede ser enviado debido a que no tiene actividades asignadas","error");
    }
  };

  devolverProducto = (productoNoPoa) => {
    swal({
      title: "¿Está seguro de devolver el producto?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if (confirm) {
        let productoEditar = {
          prodcutoNoPoaId: productoNoPoa.prodcutoNoPoaId,
          descripcion: productoNoPoa.descripcion,
          resultado: productoNoPoa.resultado,
          indicador: productoNoPoa.indicador,
          meta: productoNoPoa.meta,
          ponderacionArea: productoNoPoa.ponderacionArea,
          ejeEstrategico: productoNoPoa.ejeEstrategico.ejeEstrategicoId,
          objetivoEstrategico: productoNoPoa.objetivoEstrategico.objetivoEstrategicoId,
  
          estrategia: productoNoPoa.estrategia.estrategiaId,
  
          unidadResponsables: productoNoPoa.unidadResponsables,
          involucrados: productoNoPoa.involucradosProductos.involucradoId,
          recursosExterno: productoNoPoa.recursosExterno,
  
          ponderacionGeneral: productoNoPoa.ponderacionGeneralNoPoa,
          nivelAprobacionId: this.props.user.nivelAprobacion.hijo,
          accionMitigacion: productoNoPoa.accionMitigacion,
          riesgoAsociado:productoNoPoa.riesgoAsociado,
          modoEditar: true,
        };
        this.props.updateProductoNopoa(productoEditar);
      }
    });
  };

  render() {
    const { productosNoPoa, isLoading } = this.props;
// console.log("isLoading || this.state.isLoadingState", isLoading," ", this.state.isLoadingState)
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <div className="container bg-white border py-3">
            {isLoading || this.state.isLoadingState ? (
              <Loading></Loading>
            ) : (
              <div className="header-body">
                <div className="row">

                  <div className="form-group col-md-12 " style={{display:"none"}} >
                  <label>Filtro Área</label>
                     <select
                      className="form-control"
                      defaultValue={ this.state.areaidSeleccionada }
                      id="areaSelected"
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({ isLoadingState: true });
                        this.setState({areaidSeleccionada:document.getElementById("areaSelected").value});
                        this.cargarDatos(
                          document.getElementById("anoSelected").value,document.getElementById("areaSelected").value);
                      }}
                    >
                      {this.props.areaOrganizacionales.map((area) => (
                        <option key={area.areaOrganizacionalId} value={area.areaOrganizacionalId}>
                          {area.nombre}
                        </option>
                      ))}
                    </select> 
                  </div>

                  <div className="col-md-12 mt-3">
                      <Link
                        className="btn btn-lg btn-info float-right mb-3"
                        to="/admin/productoNoPoa/nuevo"
                      >
                        Agregar nuevo Producto
                      </Link>
                  </div>
                </div>
                <Table
                  style={{ backgroundColor: "#fff", color: "black" }}
                  responsive
                  className="table-striped"
                >
                  <thead>
                    <tr className="text-left">
                      <th>Producto</th>
                      <th>Meta</th>
                      <th>Indicador</th>
                      <th>Presupuesto</th>
                      {/* <th>Actividades</th> */}
                      <th>Devuelto</th>
                      <th>Documento</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody className="text-left">
                    {productosNoPoa.map((producto) => (
                      <Producto
                        // totalPonderacionProductos={
                        //   this.props.totalPonderacionProductos
                        // }
                        totalPonderacionActividades={producto.totalPonderacionActividad}
                        aprobarProducto={this.aprobarProducto}
                        devolverProducto={this.devolverProducto}
                        productoNoPoa={producto}
                        user={this.props.user}
                        key={producto.productoNoPoaId}
                        descargarDocumentoNoPoa={this.descargarDocumentoNoPoa}
                      ></Producto>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </Container>

        <ModalLoading
          modal={this.state.modal}
          mensaje={this.state.mensaje}
        ></ModalLoading>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  productosNoPoa: state.productosNoPoa.productosNoPoa,
  // totalPonderacionProductos: state.productos.totalPonderacionProductos,
  isLoading: state.productosNoPoa.isLoading,
  user: state.auth.signed,
  // ponderacionGeneral: state.productos.totalPonderacionGeneralProductos,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
});

const mapDispatchToProps = (dispatch) => ({
  getProductosNopoa: () => dispatch(getProductosNopoa()),
  updateProductoNopoa: (producto) => dispatch(updateProductoNopoa(producto)),
  deleteProductoNopoa: (producto) => dispatch(deleteProductoNopoa(producto)),
  calcularPonderacionAreaNopoa: (productos, user) => dispatch(calcularPonderacionAreaNopoa(productos, user)),
  calcularPonderacionGeneralNopoa: (poaId) => dispatch(calcularPonderacionGeneralNopoa(poaId)),
  getReporteMonitoreo: (monitoreo) => dispatch(getReporteMonitoreo(monitoreo)),
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
  getDocumentoDescargar: (productoNoPoaId) => dispatch(getDocumentoDescargar(productoNoPoaId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(ListaProductosNoPoa, [
    "Administrador general",
    "Producto Fuera del POA",
    "Gestor del POA",
    "MAE",
  ])
);
