import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";
import { DateTimePicker } from "react-widgets";
import "react-widgets/dist/css/react-widgets.css";
import {
  Container
} from "reactstrap";
import Loading from "../utils/Loading";
import { Authorization } from "../../utils/Authorization";
import {
  getMonitoreosActividad,
  getMonitoreoActividad,
  updateMonitoreoActividad,
} from "../../redux/actions/monitoreo-actividad-actions";
import { getActividad } from "../../redux/actions/actividades-actions";
import swal from "sweetalert"

class EditarMonitoreoActividadNoPoa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      selectedFile: null,
      file: [],
      fecha: "",
      documento: [],
      totalSizeFile:0
    };
  }

  fechaRef = React.createRef();
  trimestreRef = React.createRef();
  ejecucionRef = React.createRef();
  comentarioRef = React.createRef();
  accionesRef = React.createRef();

  componentDidMount() {
    this.cargarData();
  }
  cargarData = async () => {
    const { actividadId, monitoreoActividadId } = this.props.match.params;
    await this.props.getActividad(actividadId);
    await this.props.getMonitoreosActividad(actividadId);
    await this.props.getMonitoreoActividad(monitoreoActividadId);
    //console.log(this.props);
    this.setState({
      isLoading: false,
      fecha: this.props.monitoreoActividad.fecha,
    });
  };
  componentWillReceiveProps(nextProps, nextState) {}

  buscarMonitoreoActividad = async (monitoreoId) => {
    await this.props.getMonitoreoActividad(monitoreoId);
  };

  actualizarMonitoreoActividad = async (e) => {
    e.preventDefault();

    const { producto, actividad, monitoreoActividad } = this.props;
    if( this.ejecucionRef.current.value!=="") {
        if(this.state.file.length <= 20 && this.state.totalSizeFile <=30) {
        const monitoreoActividadentity = {
          monitoreoActividadId: monitoreoActividad.monitoreoId,
          productoId: this.props.producto.productoId,
          actividadId: actividad.actividadId,
          fecha: new Date(this.state.fecha).toDateString(),
          comentario: this.comentarioRef.current.value,
          accionesMedidas: this.accionesRef.current.value,
          trimestre: this.trimestreRef.current.value,
          ejecucion: this.ejecucionRef.current.value,
          documento: this.state.documento !== "" ? this.state.documento : null,
          envio: false,
        };

        const fd = new FormData();
        if (this.state.file.length > 0) {
          for (let i = 0; i < this.state.file.length; i++) {
            fd.append(`imageFile[]`, this.state.file[i], this.state.documento[i]);
          }
        }

        Object.keys(monitoreoActividadentity).forEach(function (item) {
          fd.append(item, monitoreoActividadentity[item]);
        });

        this.setState({isLoading:true});
        await this.props.updateMonitoreoActividad(fd);
        this.setState({isLoading:false});
        this.props.history.goBack();
      } else {
        swal("Error de archivos","La cantidad maxima de archivos permitidos son 20 o su equivalente a 30MB","error");
      }
    } else {
      swal("Validar datos","La ejecución programada es un valor requerido","error");
    }
  };
  render() {
    Moment.locale("en");
    momentLocalizer();
    const { isLoading, actividad } = this.state;

    const {
      descripcion,
      medioVerificacion,
      t1,
      t2,
      t3,
      t4,
      unidadMedida
    } = this.props.actividad;

    const {
      fecha,
      nivelEjecucion,
      trimestre,
      comentario,
      accionesMedidas,
    } = this.props.monitoreoActividad;

    let fechaRef = (e) => this.setState({ fecha: e });

    return (
      <div className="pb-6 pt-5 pt-md-7">
        {!isLoading || this.props.isLoading ? (
          <Container>
            <div className="container bg-white border">
              <form
                className="needs-validation p-3 "
                onSubmit={this.actualizarMonitoreoActividad}
                noValidate
              >
                <div className="form-group">
                  <label>Actividad</label>
                  <input
                    className="form-control text-dark form-control-sm"
                    type="text"
                    readOnly
                    defaultValue={descripcion}
                  ></input>
                </div>
                <div className="form-group">
                  <label>Medio Verificación</label>
                  <input
                    className="form-control text-dark form-control-sm"
                    type="text"
                    readOnly
                    defaultValue={medioVerificacion.descripcion}
                  ></input>
                </div>
                <div className="form-group">
                  <h3>Ejecución Programada</h3>
                  <div className="row">
                    <div className="col-md-3 text-center">
                      <label>E-M</label>
                       <div className="row">
                        <div className="col-md-4">
                          <label className="text-dark">{unidadMedida}:</label>
                        </div>
                        <div className="col-md-8 input-group">
                          <input
                            className="form-control text-dark form-control-sm"
                            type="text"
                            readOnly
                            defaultValue={t1 !== null ? t1 : 0}
                          ></input>
                          {unidadMedida !== "Cantidad" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text text-dark form-control-sm "
                                style={{
                                  backgroundColor: "#e9ecef",
                                  borderLeft: "2px solid  #bebaba",
                                }}
                              >
                                %
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                    </div>
                    <div className="col-md-3 text-center">
                      <label>A-J</label>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="text-dark">{unidadMedida}:</label>
                        </div>
                        <div className="col-md-8 input-group">
                          <input
                            className="form-control text-dark form-control-sm"
                            type="text"
                            readOnly
                            defaultValue={t2 !== null ? t2 : 0}
                          ></input>
                          {unidadMedida !== "Cantidad" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text text-dark form-control-sm "
                                style={{
                                  backgroundColor: "#e9ecef",
                                  borderLeft: "2px solid  #bebaba",
                                }}
                              >
                                %
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                    </div>
                    <div className="col-md-3 text-center">
                      <label>J-S</label>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="text-dark">{unidadMedida}:</label>
                        </div>
                        <div className="col-md-8 input-group">
                          <input
                            className="form-control text-dark form-control-sm"
                            type="text"
                            readOnly
                            defaultValue={t3 !== null ? t3 : 0}
                          ></input>
                          {unidadMedida !== "Cantidad" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text text-dark form-control-sm "
                                style={{
                                  backgroundColor: "#e9ecef",
                                  borderLeft: "2px solid  #bebaba",
                                }}
                              >
                                %
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                    </div>
                    <div className="col-md-3">
                      <label>O-D</label>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="text-dark">{unidadMedida}:</label>
                        </div>
                        <div className="col-md-8 input-group">
                          <input
                            className="form-control text-dark form-control-sm"
                            type="text"
                            readOnly
                            defaultValue={t4 !== null ? t4 : 0}
                          ></input>
                          {unidadMedida !== "Cantidad" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text text-dark form-control-sm "
                                style={{
                                  backgroundColor: "#e9ecef",
                                  borderLeft: "2px solid  #bebaba",
                                }}
                              >
                                %
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <h3>Nivel Ejecución</h3>
                  <div className="row">
                    <div className="col-md-4">
                      <label>Trimestre</label>
                      <select
                        className="form-control text-dark "
                        ref={this.trimestreRef}
                        defaultValue={trimestre}
                        disabled
                      >
                        <option defaultValue="">
                          --Seleccionar Trimestre--
                        </option>
                        <option value="T1">Primer Trimestre</option>
                        <option value="T2">Segundo Trimestre</option>
                        <option value="T3">Tercer Trimestre</option>
                        <option value="T4">Cuarto Trimestre</option>
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label>Ejecución</label>
                      <input
                        className="form-control text-dark "
                        type="text"
                        ref={this.ejecucionRef}
                        defaultValue={nivelEjecucion}
                        placeholder="Ejecución Trimestre"
                      ></input>
                    </div>
                    <div className="col-md-4">
                      <label>Fecha</label>
                      <DateTimePicker
                        footer={false}
                        time={false}
                        placeholder="Fecha Ejecución"
                        ref={this.fechaRef}
                        onChange={fechaRef}
                        defaultValue={new Date(fecha)}
                        className="form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Nivel de avance</label>
                  <textarea
                    className="form-control text-dark form-control-sm"
                    type="text"
                    placeholder="Nivel de avance"
                    ref={this.comentarioRef}
                    defaultValue={comentario}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label>Justificación de no cumplimiento</label>
                  <textarea
                    className="form-control text-dark"
                    type="text"
                    placeholder="Justificación de no cumplimiento"
                    ref={this.accionesRef}
                    defaultValue={accionesMedidas}
                  ></textarea>
                </div>

                <div className="form-group">
                  <label>Cargar Medios de Verificación (PDF)</label>
                  <input
                  id="inputpdf"
                    className="form-control text-dark"
                    type="file" accept=".pdf"
                    name="file[]"
                    multiple
                    onChange={
                      (e) => {
                        e.preventDefault();

                        ////////////////////////VALIDAR FORMATO////////////////////////////////////
                        var fileInput = 
                        document.getElementById('inputpdf');
                        var filePath = fileInput.value;
                        var allowedExtensions = /(\.pdf|\.PDF)$/i;
                        if (!allowedExtensions.exec(filePath)) {
                        swal("Error de tipo de archivo","Debe cargar archivos en formato PDF","error");
                        fileInput.value = '';
                        return false;
                        } 
                        ///////////////////////////////////////////////

                        let files = [];
                        let documentos = [];
                        let totalSize = 0;
                        if (e.target.files.length > 0) {
                          for (let i = 0; i < e.target.files.length; i++) {
                            totalSize+= e.target.files[i].size;
                            files.push(e.target.files[i]);
                            documentos.push(e.target.files[i].name);
                          }
                          if(((totalSize/1024)/1024)>30 || e.target.files.length > 20){
                            swal("Error de archivos","La cantidad maxima de archivos permitidos son 20 o su equivalente a 30MB","error");
                          }
                          this.setState({
                            file: files,
                            documento: documentos,
                            totalSizeFile:((totalSize/1024)/1024)

                          });
                        } else {
                          this.setState({
                            file: "",
                            documento: "",
                            totalSizeFile:0
                          });
                        }
                      }
                    }
                  />
                </div>

                <button className="btn btn-info" type="submit">
                  Guardar
                </button>
              </form>
            </div>
          </Container>
        ) : (
          <Loading></Loading>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  monitoreoActividad: state.monitoreosActividad.monitoreoActividad,

  producto: state.productos.producto,
  isLoading: state.monitoreosActividad.isLoading,
  actividad: state.actividades.actividad,
  user: state.auth.signed,
});
const mapDispatchToProps = (dispatch) => ({
  getMonitoreosActividad: (actividadId) =>dispatch(getMonitoreosActividad(actividadId)),
  getMonitoreoActividad: (actividadId) =>dispatch(getMonitoreoActividad(actividadId)),
  updateMonitoreoActividad: (monitoreoActividad) => dispatch(updateMonitoreoActividad(monitoreoActividad)),
  getActividad: (actividadId) => dispatch(getActividad(actividadId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(EditarMonitoreoActividadNoPoa, [
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Analista del POA",
    "MAE",
  ])
);
