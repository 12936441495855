import React, { useState } from "react";
import numbro from "numbro";
import NumericFormat  from 'react-number-format';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";

export const ModalAsignarCotizacionArticulo = props => {

    const toggle = () => props.ActivarModalAsignarCotizacionArticulo();

    const { modalAsignarCotizacionArticulo, presupuesto, precioCotizacion } = props;
    const [precio, setPrecio] = useState(precioCotizacion === null ? 0 : precioCotizacion);

    return (
        <Modal isOpen={modalAsignarCotizacionArticulo} toggle={toggle} size="lg" backdrop="static">
            <ModalHeader className="block-example border-bottom " toggle={toggle}>
                Asignar precio de cotización
            </ModalHeader>
            <ModalBody className="block-example border-bottom ">
                <form>
                    <div className="form-row mt-1">
                        <div className="col-12">
                            <label>Insumo</label>
                            <input
                                type="text"
                                className="form-control text-dark"
                                placeholder="Insumo"
                                readOnly
                                value={presupuesto.insumo}
                            />
                        </div>
                    </div>
                    <div className="form-row mt-1">
                        <div className="col-3">
                            <div className="form-group ">
                                <label htmlFor="cantidad">Cantidad</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control  text-dark"
                                        id="cantidad"
                                        placeholder="Cantidad"
                                        readOnly
                                        value={presupuesto.cantidad}
                                    />

                                    <div className="input-group-append" title="Unidad de Medida" >
                                        <span
                                            className="input-group-text text-dark  "
                                            style={{
                                                backgroundColor: "#e9ecef",
                                                borderLeft: "2px solid  #bebaba"
                                            }}
                                        >
                                            {presupuesto.unidadMedidaLabel}
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group ">
                                <label htmlFor="frecuencia">Frecuencia</label>
                                <input
                                    type="text"
                                    className="form-control  text-dark"
                                    id="frecuencia"
                                    placeholder="Frecuencia"
                                    readOnly
                                    value={presupuesto.frecuencia}
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group ">
                                <label htmlFor="frecuencia">Valor</label>
                                <input
                                    type="text"
                                    className="form-control  text-dark"
                                    id="valor"
                                    placeholder="Valor"
                                    readOnly
                                    value={'RD$ '+numbro(presupuesto.valor).format({thousandSeparated:true})}
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group ">
                                <label htmlFor="total">Total</label>{" "}
                                <input
                                    type="text"
                                    className="form-control  text-dark"
                                    id="total"
                                    placeholder="Total"
                                    readOnly
                                    value={'RD$ '+numbro(presupuesto.totalCosto).format({thousandSeparated:true})}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-row mt-1">
                        <div className="col-12">
                            <div className="form-group ">
                                <label htmlFor="precioCotizacion">Precio de cotización</label>
                                <NumericFormat 
                                    prefix={'RD$ '}
                                    id="precioCotizacion"
                                    className="form-control form-control text-dark" 
                                    allowLeadingZeros 
                                    thousandSeparator="," 
                                    placeholder="Precio de cotización"
                                    defaultValue={'RD$ '+numbro(precio).format({thousandSeparated:true})}
                                    onValueChange={ (e) => setPrecio(e.floatValue) }
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter className="block-example border-bottom ">
                <button
                    className="btn btn-danger"
                    onClick={e => {
                        e.preventDefault();
                        props.ActivarModalAsignarCotizacionArticulo();
                    }}
                >
                    Cerrar
                </button> 
                <button
                    className="btn btn-primary"
                    onClick={() => { props.guardarPreciocotizacion(precio) }}
                    >
                    Guardar
                </button>
            </ModalFooter>
        </Modal>
    );
};
