import React from "react";
import { Link } from "react-router-dom";
import { deleteProducto } from "../../redux/actions/productos-actions";
import { MOSTRAR_PRODUCTO } from "./../../redux/types";
import swal from "sweetalert";
import { connect } from "react-redux";
import actividad from "../administracion/actividades/actividad";
import ReactTooltip from 'react-tooltip';

let activarNota= false;
let notas = "";
let actividades = "";
const Producto = (props) => {
  const eliminarProducto = (e) => {
    e.preventDefault();
    const { poaId } = props.producto.poa;
    const { producto } = props;
    let confirmed = window.confirm("Está seguro de eliminar el producto");
    if (confirmed) {
      props.deleteProducto(producto, poaId);
    }
  };

  const truncateString = (str) => {
    let num = 30;

    return str && str.length > num ? str.slice(0, num) + "..." : str;
  };

  const validarOpcionEditar = () => {
    return props.validarOpcionEditar(props.producto);
  };
  const {
    productoId,
    descripcion,
    meta,
    indicador,
    ponderacionArea,
    actividades
  } = props.producto;

 const validarNotasActividades= (actividadesNota) =>{
    let activarActividad = false;
    actividadesNota.forEach(actividadNota => {
      if(actividadNota.notasActividad.length>0){
        activarActividad =true;
      }
    });
    return activarActividad;
 }

  const validarOpcionNotas = ()=>{
   let notaActividad =  validarNotasActividades(props.producto.actividades);

    if(props.producto.notaProductos.length>0 && notaActividad){
      notas = "El producto fue devuelto debido a notas en el producto y las actividades";
      activarNota = true;
    } else if (props.producto.notaProductos.length===0 && notaActividad ){
      notas = "El producto fue devuelto debido a notas en las actividades";
      activarNota = true;
    }else if (props.producto.notaProductos.length>0){
      notas = "El producto fue devuelto debido a notas en el producto";
      activarNota = true;
    } else{
      activarNota = false;
    }
  }

  validarOpcionNotas();

  return (
    <tr>
      <td title={descripcion}>{truncateString(descripcion)}</td>
      <td>{meta}</td>
      <td title={indicador}>{truncateString(indicador)}</td>
      <td>{ponderacionArea}</td>
      <td>{actividades.length}</td>
      <td>
     {activarNota && (
          <button data-tip={notas} data-event='click' className="btn btn-sm btn-danger">
          <i className="ni ni-chat-round"></i>
          </button>
      )}
      <ReactTooltip globalEventOff='click' />
      <ReactTooltip/>
        <Link
          to={{
            pathname: `/admin/producto/detalle/${productoId}`,
            state: `/admin/producto/detalle/:productoId`,
          }}
          className="btn btn-primary btn-sm"
        >
          Detalle
        </Link>
        {validarOpcionEditar() &&
          !(
            props.user.nivelAprobacion.nivelAprobacionId !== 2 &&
            props.producto.creadoPor.name !== props.user.nombre
          ) &&
          (props.totalPonderacionProductos <= 100 ? (
            
            <button
              className="btn btn-info btn-sm "
              onClick={(e) => {
                e.preventDefault();
                if(props.totalPonderacionActividades < 100){
                  swal("Enviar Producto","La ponderación de las actividades es menor a 100%, actualmente tiene "+props.totalPonderacionActividades+"%","error");
                }else if(props.totalPonderacionActividades > 100){
                  swal("Enviar Producto","La ponderación de las actividades es mayor a 100%, actualmente tiene "+props.totalPonderacionActividades+"%","error");
                }else{
                  props.aprobarProducto(props.producto);
                }
                
              }}
            >
              Enviar
            </button>
          ) : (
            
            <button
              onClick={(e) =>{ e.preventDefault()
                swal("Productos","Excede el límite de ponderación permitido para los productos, actualmente tiene "+props.totalPonderacionProductos+"%","error");
              } 
              
              }
              className="btn btn-info btn-sm opacity"
              title="Excede el límite de ponderación permitido para los productos"
            >
              Enviar
            </button>
          ))}

        {validarOpcionEditar() &&
          props.user.nivelAprobacion.nivelAprobacionId !== 2 &&
          props.producto.creadoPor.name !== props.user.nombre && (
            <Link
              to={{
                pathname: `/user/producto/detalle/${productoId}`,
                state: "/user/producto/detalle/:productoId",
              }}
              className="btn btn-warning btn-sm"
            >
              Aprobar
            </Link>
          )}
        {validarOpcionEditar() ? (
          <Link
            to={{
              pathname: `/admin/producto/editar/${productoId}`,
              state: "/admin/producto/editar/:productoId",
            }}
            className="btn btn-success btn-sm"
          >
            Editar
          </Link>
          ) : (
           <button
            title="Producto Enviado"
            className="btn btn-success btn-sm opacity"
          >
            Editar
          </button>
        )}
        {validarOpcionEditar() ? (
          <button
            onClick={(e) => {
              eliminarProducto(e);
            }}
            className="btn btn-danger btn-sm"
          >
            Eliminar
            <span className="fa fa-remove"></span>
          </button>
        ) : (
          <button
            onClick={(e) => e.preventDefault()}
            className="btn btn-danger btn-sm opacity"
          >
            Eliminar
            <span className="fa fa-remove"></span>
          </button>
        )}
      </td>
    </tr>
  );
};

export default connect(null, { deleteProducto })(Producto);
