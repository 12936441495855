import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../utils/Loading.jsx";
import { Authorization } from "../../utils/Authorization.js";
import {
  Container,
  UncontrolledCollapse,
} from "reactstrap";

import { getActividadNopoa } from "../../redux/actions/actividades-actions.js";
import ListaDetalleInsumos from "./ListaDetalleInsumos.jsx";

class DetalleActividadNoPoa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      toggleMonitoreo: false,
      toggleInsumos: false,
      insumos: [],
    };
  }

  componentDidMount() {
    const { actividadNoPoaId } = this.props.match.params;
    this.cargarData(actividadNoPoaId);
  }

  toggleInsumos = () => this.setState({ toggleInsumos: !this.state.toggleInsumos });

  cargarData = async (actividadNoPoaId) => {
    await this.props.getActividadNopoa(actividadNoPoaId);
  };

  render() {
    const { isLoading } = this.props.isLoading;
    const {
      actividadNoPoaId,
      descripcion,
      // medioVerificacion,
      t1,
      t2,
      t3,
      t4,
       presupuesto,
       presupuestoNopoa,
       unidadMedida,
    } = this.props.actividadNopoa;
    return (
      <div className="pb-6 pt-5 pt-md-7">
        {!isLoading ? (
          <Container>
            <div className="container bg-white border">
              <form
                className="needs-validation p-3 "
                noValidate
              >
                <div className="form-group">
                  <label>Actividad</label>
                  <input
                    className="form-control text-dark form-control-sm"
                    type="text"
                    readOnly
                    defaultValue={descripcion}
                  ></input>
                </div>
                {/* <div className="form-group">
                  <label>Medio Verificación</label>
                  <input
                    className="form-control text-dark form-control-sm"
                    type="text"
                    readOnly
                    defaultValue={medioVerificacion.descripcion}
                  ></input>
                </div> */}
                <div className="form-group">
                  <h3>Ejecución Programada</h3>
                  <div className="row">
                    <div className="col-md-3 text-center">
                      <label>E-M</label>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="text-dark">{"Cantidad"}:</label>
                        </div>
                        <div className="col-md-8 input-group">
                          <input
                            className="form-control text-dark form-control-sm"
                            type="text"
                            readOnly
                            defaultValue={t1 !== null ? t1 : 0}
                          ></input>
                          {/* {unidadMedida !== "Cantidad" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text text-dark form-control-sm "
                                style={{
                                  backgroundColor: "#e9ecef",
                                  borderLeft: "2px solid  #bebaba",
                                }}
                              >
                                %
                              </span>
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 text-center">
                      <label>A-J</label>
                      <div className="row">
                        <div className="col-md-4">
                        {/* <label className="text-dark">{unidadMedida}:</label> */}
                          <label className="text-dark">{"Cantidad"}:</label>
                        </div>
                        <div className="col-md-8 input-group">
                          <input
                            className="form-control text-dark form-control-sm"
                            type="text"
                            readOnly
                            defaultValue={t2 !== null ? t2 : 0}
                          ></input>
                          {/* {unidadMedida !== "Cantidad" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text text-dark form-control-sm "
                                style={{
                                  backgroundColor: "#e9ecef",
                                  borderLeft: "2px solid  #bebaba",
                                }}
                              >
                                %
                              </span>
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 text-center">
                      <label>J-S</label>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="text-dark">{"Cantidad"}:</label>
                        </div>
                        <div className="col-md-8 input-group">
                          <input
                            className="form-control text-dark form-control-sm"
                            type="text"
                            readOnly
                            defaultValue={t3 !== null ? t3 : 0}
                          ></input>
                          {/* {unidadMedida !== "Cantidad" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text text-dark form-control-sm "
                                style={{
                                  backgroundColor: "#e9ecef",
                                  borderLeft: "2px solid  #bebaba",
                                }}
                              >
                                %
                              </span>
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 text-center">
                      <label>O-D</label>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="text-dark">{"Cantidad"}:</label>
                        </div>
                        <div className="col-md-8 input-group">
                          <input
                            className="form-control text-dark form-control-sm"
                            type="text"
                            readOnly
                            defaultValue={t4 !== null ? t4 : 0}
                          ></input>
                          {/* {unidadMedida !== "Cantidad" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text text-dark form-control-sm "
                                style={{
                                  backgroundColor: "#e9ecef",
                                  borderLeft: "2px solid  #bebaba",
                                }}
                              >
                                %
                              </span>
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                
                 <div>
                    <div className="form-inline">
                      <h3 className="mt-3" id="toggler">
                        Insumos de la Actividad No Poa
                      </h3>

                      {!this.state.toggleInsumos ? (
                        <i
                          onClick={() => this.toggleInsumos()}
                          style={{
                            fontSize: "20px",
                            marginLeft: "5px",
                            marginTop: "8px",
                          }}
                          className="ni ni-bold-up text-primary"
                          size={25}
                        ></i>
                      ) : (
                        <i
                          onClick={() => this.toggleInsumos()}
                          style={{
                            fontSize: "20px",
                            marginLeft: "5px",
                            marginTop: "8px",
                          }}
                          className="ni ni-bold-down text-primary"
                          size={25}
                        ></i>
                      )}
                    </div>
                    <hr style={{ marginTop: ".1px" }}></hr>

                    <UncontrolledCollapse
                      toggler="#toggler"
                      isOpen={this.state.toggleInsumos}
                    >
                      { presupuestoNopoa && presupuestoNopoa.length > 0 ? (
                        <ListaDetalleInsumos
                          insumos={presupuestoNopoa}
                        ></ListaDetalleInsumos>
                      ) : (
                        <h5 className="text-center">
                          No tienes insumos agregados
                        </h5>
                      )}
                    </UncontrolledCollapse>
                  </div> 
                </div>
              </form>
            </div>
          </Container>
        ) : (
          <Loading></Loading>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  actividadNopoa: state.actividadesNopoa.actividadNopoa,
  isLoading: state.actividadesNopoa.isLoading,
  user: state.auth.signed,
  presupuestoNopoa: state.actividadesNopoa.actividadNopoa.presupuestoNopoa,
});

const mapDispatchToProps = (dispatch) => ({
  getActividadNopoa: (actividadId) => dispatch(getActividadNopoa(actividadId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(DetalleActividadNoPoa, [
    "Administrador general",
    "Producto Fuera del POA",
    "Gestor del POA",
    "MAE",
  ])
);
