import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "./../../utils/Loading.jsx";
import { Link } from "react-router-dom";
import { Authorization } from "./../../../utils/Authorization";
import DataTable, { createTheme } from "react-data-table-component";
import { Table, Container } from "reactstrap";
import {
  getPoaElaboracion,
  mostrarPoas,
  mostrarPoa,
} from "./../../../redux/actions/poa-actions";
import {
  getProductosAdministracion,
  calcularPonderacionGeneral,
  deleteProducto,
  getTotalProductosAdministracion,
  cleanProductosAdministracion,
} from "./../../../redux/actions/productos-actions";
import Producto from "./../../productos/Producto";
import ProductoAdministracion from "../../productos/ProductoAdministracion.jsx";
import Pagination from "react-js-pagination";
import { getAreasOrganizacionales } from "./../../../redux/actions/areas-actions";

class ListaAdminProductos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: false,
      activePage: 1,
      totalPages: 0,
      itemPerPage: 10,
      listaProductosState: [],
      listaProductosPageState: [],
      areaidSeleccionada: 0,
    };
  }

  cargarData = async () => {
    this.setState({ isLoadingState: true });
    let start = 0;
    await this.props.cleanProductosAdministracion();
    await this.props.getPoaElaboracion();
    const { poaId } = this.props.poaElaboracion;
    const area = this.state.areaidSeleccionada;
    await this.props.getTotalProductosAdministracion(poaId, area);

    let paginas =
      this.props.totalProductosAdministracion !== 0
        ? Math.round(this.props.totalProductosAdministracion / 10)
        : 1;

    for (let index = 0; index < paginas; index++) {
      start = index * 10 + 1;
      await this.props.getProductosAdministracion(
        poaId,
        start,
        this.props.user.areaOrganizacional.areaOrganizacionalId
      );
    }

    await this.props.calcularPonderacionGeneral(poaId);
    await this.props.mostrarPoa(poaId);
    this.setState(
      {
        isLoadingState: false,
        listaProductosState: this.props.productosAdministracion,
      },
      () => {
        this.validarPaginacion();
      }
    );
  };

  cargarDatos = async (poaId, area = 0) => {
    if (area > 0) {
      this.setState({ areaidSeleccionada: area });
    } //me
    this.setState({ isLoadingState: true });
    let start = 0;
    await this.props.cleanProductosAdministracion();
    await this.props.getTotalProductosAdministracion(poaId, area);
    let paginas =
      this.props.totalProductosAdministracion !== 0
        ? Math.round(this.props.totalProductosAdministracion / 10)
        : 1;
    for (let index = 0; index < paginas; index++) {
      start = index * 10 + 1;
    }
    await this.props.getProductosAdministracion(poaId, start, area);
    await this.props.calcularPonderacionGeneral(poaId);
    await this.props.mostrarPoa(poaId);

    this.setState(
      {
        isLoadingState: false,
        listaProductosState: this.props.productosAdministracion,
      },
      () => {
        this.validarPaginacion();
      }
    );
  };

  validarPaginacion = async (page = 1, elementos = 10) => {
    const { poaId } = this.props.poaElaboracion;
    // console.log(page, " ", elementos, " ");
    // console.log(this.state.listaProductosState);
    let index = elementos * page - elementos;
    await this.props.getTotalProductosAdministracion(
      poaId,
      this.state.areaidSeleccionada
    );
    let paginas =
      this.props.totalProductosAdministracion !== 0
        ? Math.round(this.props.totalProductosAdministracion / 10)
        : 1;
    await this.props.getProductosAdministracion(
      poaId,
      index,
      this.state.areaidSeleccionada
    );
    let listaProductos = this.props.productosAdministracion;
    let totalPages = paginas;
    this.setState({
      listaProductosPageState: listaProductos,
      activePage: page,
      totalPages,
      itemPerPage: elementos,
    });
  };

  componentDidMount() {
    this.cargarData();
    this.setState({
      areaidSeleccionada: this.props.user.areaOrganizacional
        .areaOrganizacionalId,
    }); //me
  }
  validarOpcionEditar = (producto) => {
    let resultado = true;
    if (this.props.productosAdministracion.length > 0 && producto != null) {
      resultado =
        this.props.user.nivelAprobacion.nivelAprobacionId ===
        producto.nivelAprobacionId;
    }
    return resultado;
  };

  eliminarProducto = (producto) => {
    const { poaId } = producto.poa;

    this.props.deleteProducto(producto, poaId);
  };
  truncateString = (str) => {
    let num = 62;

    return str && str.length > num ? str.slice(0, num) + "..." : str;
  };
  render() {
    const customStyles = {
      rows: {
        color: "black",
        fontSize: "0.8125rem",
        fontFamily: "Open Sans, sans-serif;",
      },
      headCells: {
        style: {
          fontWeight: "bold",
          fontSize: "0.692rem",
          textTransform: "uppercase",
          letterSpacing: "1px",
          fontFamily: "Open Sans, sans-serif;",
        },
      },
    };
    const {
      productosAdministracion,
      isLoading,
      ponderacionGeneral,
      totalProductosAdministracion,
    } = this.props;
    const {
      listaProductosState,
      listaProductosPageState,
      activePage,
      itemPerPage,
    } = this.state;

    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <div className="container bg-white border py-3">
            {isLoading || this.state.isLoadingState ? (
              <Loading></Loading>
            ) : (
              <div className="header-body">
                <div className="row ">
                  <div className="col-md-4">
                    <label>Año del Poa</label>
                    <select
                      className="form-control"
                      defaultValue={this.props.poaElaboracion.poaId}
                      id="anoSelected"
                      ref={this.anoSelectedRef}
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({ isLoadingState: true });
                        this.cargarDatos(
                          document.getElementById("anoSelected").value,
                          document.getElementById("areaSelected").value
                        );
                      }}
                    >
                      {this.props.poas.map((poa) => (
                        <option key={poa.poaId} value={poa.poaId}>
                          {poa.ano}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label>Filtro Área</label>
                    <select
                      className="form-control"
                      defaultValue={this.state.areaidSeleccionada}
                      id="areaSelected"
                      onChange={(e) => {
                        e.preventDefault();

                        this.setState({ isLoadingState: true });
                        this.setState({
                          areaidSeleccionada: document.getElementById(
                            "areaSelected"
                          ).value,
                        });
                        this.cargarDatos(
                          document.getElementById("anoSelected").value,
                          document.getElementById("areaSelected").value
                        );
                      }}
                    >
                      {this.props.areaOrganizacionales.map((area) => (
                        <option
                          key={area.areaOrganizacionalId}
                          value={area.areaOrganizacionalId}
                        >
                          {area.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="mt-2">
                  {productosAdministracion.length > 0 ? (
                    <div>
                      <Table className="table-flush table-striped" responsive>
                        <thead>
                          <tr>
                            <th>Área Organizacional</th>
                            <th>Producto</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listaProductosPageState.map(
                            (producto) =>
                              producto !== null &&
                              producto !== undefined && (
                                <tr key={producto.productoId}>
                                  {/* <td title={producto.areaOrganizacional.nombre}>{this.truncateString(producto.areaOrganizacional.nombre)}</td> */}
                                  <td title={producto.areaOrganizacional}>
                                    {this.truncateString(
                                      producto.areaOrganizacional
                                    )}
                                  </td>
                                  <td title={producto.descripcion}>
                                    {this.truncateString(producto.descripcion)}
                                  </td>
                                  <td>
                                    <Link
                                      to={{
                                        pathname: `/admin/administracion/producto/detalle/${producto.productoId}`,
                                        state:
                                          "/admin/producto/detalle/:productoId",
                                      }}
                                      className="btn btn-primary btn-sm"
                                    >
                                      Detalle
                                    </Link>

                                    {this.validarOpcionEditar(producto) ? (
                                      <Link
                                        to={{
                                          pathname: `/user/producto/detalle/${producto.productoId}`,
                                          state:
                                            "/user/producto/detalle/:productoId",
                                        }}
                                        className="btn btn-info btn-sm"
                                      >
                                        Aprobar
                                      </Link>
                                    ) : (
                                      <>
                                        <button className="btn btn-sm btn-success">
                                          Aprobado
                                        </button>
                                        <Link
                                          to={{
                                            pathname: `/user/producto/detalle/${producto.productoId}`,
                                            state:
                                              "/user/producto/detalle/:productoId",
                                          }}
                                          className="btn btn-danger btn-sm"
                                        >
                                          Devolver
                                        </Link>
                                      </>
                                    )}
                                    <Link
                                      to={{
                                        pathname: `/admin/producto/editar/${
                                          producto.productoId
                                        }/${1}`,
                                        state:
                                          "/admin/producto/editar/:productoId",
                                      }}
                                      className="btn btn-primary btn-sm"
                                    >
                                      Editar
                                    </Link>
                                  </td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </Table>
                      <div className="d-flex justify-content-center">
                        <Pagination
                          prevPageText="ant"
                          nextPageText="sig"
                          firstPageText="prime"
                          lastPageText="ultimo"
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={activePage}
                          itemsCountPerPage={itemPerPage}
                          totalItemsCount={totalProductosAdministracion}
                          // totalItemsCount={listaProductosState.length}
                          onChange={this.validarPaginacion.bind(this)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="row d-flex justify-content-center mb-3">
                      <h3>No existen productos agregadas</h3>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.productos.isLoading,
  productosAdministracion: state.productos.productosAdministracion,
  poaElaboracion: state.poas.poaElaboracion,
  totalProductosAdministracion: state.productos.totalProductosAdministracion,
  poas: state.poas.poas,
  user: state.auth.signed,
  ponderacionGeneral: state.productos.totalPonderacionGeneralProductos,
  productos: state.productos.productos,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales, //me
});
const mapDispatchToProps = (dispatch) => ({
  getProductosAdministracion: (poaId, start, area) =>
    dispatch(getProductosAdministracion(poaId, start, area)),
  calcularPonderacionGeneral: (poaId) =>
    dispatch(calcularPonderacionGeneral(poaId)),
  getPoaElaboracion: () => dispatch(getPoaElaboracion()),
  mostrarPoas: () => dispatch(mostrarPoas()),
  mostrarPoa: (poaId) => dispatch(mostrarPoa(poaId)),
  getTotalProductosAdministracion: (poaId, areaid) =>
    dispatch(getTotalProductosAdministracion(poaId, areaid)),
  cleanProductosAdministracion: () => dispatch(cleanProductosAdministracion()),
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()), //me
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(ListaAdminProductos, [
    "Administrador general",
    "Analista del POA",
    "Gestor del POA",
    "MAE",
  ])
);
