/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import { connect } from "react-redux";

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    return (
      <NavItem>
        <NavLink
          to={"/admin/index"}
          tag={NavLinkRRD}
          //onClick={this.closeCollapse}
          activeClassName="active"
        >
          <i className="ni ni-tv-2 text-primary" />
          POAs
        </NavLink>
        <NavLink
          to={"/admin/productos/index"}
          tag={NavLinkRRD}
          //onClick={this.closeCollapse}
          activeClassName="active"
        >
          <i className="ni ni-bullet-list-67 text-primary" />
          Productos
        </NavLink>

        <NavLink
          to={"/admin/productosNoPoa/index"}
          tag={NavLinkRRD}
          activeClassName="active"
        >
          <i className="ni ni-bullet-list-67 text-primary" />
          Productos No POA
        </NavLink>

        <NavLink
          to={"/admin/involucrado/index"}
          tag={NavLinkRRD}
          //onClick={this.closeCollapse}
          activeClassName="active"
        >
          <i className="ni ni-single-02 text-primary" />
          Involucrados
        </NavLink>
        <NavLink
          to={"/admin/mediosverificacion/index"}
          tag={NavLinkRRD}
          //onClick={this.closeCollapse}
          activeClassName="active"
        >
          <i className="ni ni-archive-2 text-primary" />
          Medios de verificación
        </NavLink>
        {/* <NavLink
          to={"/admin/informe-trimestral/index"}
          tag={NavLinkRRD}
          //onClick={this.closeCollapse}
          activeClassName="active"
        >
          <i className="ni ni-archive-2 text-primary" />
          Informe Trimestral
        </NavLink> */}
        <NavLink
          to={"/admin/reporte-monitoreo/index"}
          tag={NavLinkRRD}
          //onClick={this.closeCollapse}
          activeClassName="active"
        >
          <i className="ni ni-collection text-primary" />
          Reporte monitoreo
        </NavLink>

        {/* <Nav className="mb-md-3" navbar> */}

        <NavLink
          to={"/admin/solicitud-requerimiento/listado"}
          tag={NavLinkRRD}
          activeClassName="active"
        >
          <i className="ni ni-book-bookmark text-primary" />
          Requerimientos
        </NavLink>
        {/* <NavLink
          to={"/admin/solicitud-requerimiento/index"}
          tag={NavLinkRRD}
          activeClassName="active"
        >
          <i className="ni ni-book-bookmark text-primary" />
          Solicitudes de Requerimiento
        </NavLink> */}

        {/* <NavLink
          to={"/admin/expedientes-fisicos/index"}
          tag={NavLinkRRD}
          //onClick={this.closeCollapse}
          activeClassName="active"
        >
          <i className="far fa-file-archive text-primary" />
          Expedientes Fisicos
        </NavLink> */}
        {/* <NavLink
          to={"/admin/gestion-bienes-servicios/index"}
          tag={NavLinkRRD}
          //onClick={this.closeCollapse}
          activeClassName="active"
        >
          <i className="fas fa-hands-helping text-primary" />
          Gestión de Bienes y Servicios
        </NavLink> */}
      </NavItem>
    );
  };
  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className="pt-0 pb-0" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
          {/* User */}
          {/* <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav className="nav-link-icon">
                <i className="ni ni-bell-55" />
              </DropdownToggle>
              <DropdownMenu
                aria-labelledby="navbar-default_dropdown_1"
                className="dropdown-menu-arrow"
                right
              >
                <DropdownItem>Action</DropdownItem>
                <DropdownItem>Another action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Something else here</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={
                        "https://lh5.googleusercontent.com/-b0-k99FZlyE/AAAAAAAAAAI/AAAAAAAAAAA/eu7opA4byxI/photo.jpg?sz=120"
                      }
                    />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Cuenta</h6>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav> */}
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            {/* <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div> */}
            {/* Form */}
            {/* <Form className="mt-4 mb-3 d-md-none">
              <InputGroup className="input-group-rounded input-group-merge">
                <Input
                  aria-label="Search"
                  className="form-control-rounded form-control-prepended"
                  placeholder="Search"
                  type="search"
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <span className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Form> */}
            {/* Navigation */}
            <Nav navbar>{this.createLinks(routes)}</Nav>
           {this.props.user.roles.some(x=> x=="Administrador general" || x=="Encargado Presupuesto" ||x=="Analista Presupuesto")&&(
            <React.Fragment>
              <hr className="my-2" />
              <h6 className="navbar-heading text-muted">Presupuesto</h6>
              {/* Navigation */}
              <Nav className="mb-md-3" navbar>
                <NavLink
                  to={"/admin/solicitud-compra/departamento-presupuesto/index"}
                  tag={NavLinkRRD}
                  activeClassName="active"
                >
                  <i className="fas fa-shopping-cart text-primary" />
                  Solicitudes de Compras - Departamento de Presupuesto
                </NavLink>
                <NavLink
                  to={"/admin/presupuestosAsignadosInstitucion/index"}
                  tag={NavLinkRRD}
                  //onClick={this.closeCollapse}
                  activeClassName="active"
                >
                  <i className="ni ni-circle-08 text-primary" />
                  Presupuesto Institución
                </NavLink>
                <NavLink
                  to={"/admin/usuarios/index"}
                  tag={NavLinkRRD}
                  //onClick={this.closeCollapse}
                  activeClassName="active"
                >
                  <i className="ni ni-circle-08 text-primary" />
                  Asignación Presupuesto
                </NavLink>
                <NavLink
                  to={"/admin/tiposAsignaciones/index"}
                  tag={NavLinkRRD}
                  //onClick={this.closeCollapse}
                  activeClassName="active"
                >
                  <i className="ni ni-circle-08 text-primary" />
                  Tipos Asignaciones
                </NavLink>
              </Nav>
            </React.Fragment>
            )}
             {this.props.user.roles.some(x=> x=="Administrador general" || x=="Analista Compras" || x=="Responsables de Compras" || x=="Encargado Administrativo")&&(
            <React.Fragment>
              <hr className="my-2" />
              <h6 className="navbar-heading text-muted">Compras</h6>
              {/* Navigation */}
              <Nav className="mb-md-3" navbar>
                <NavLink
                  to={"/admin/solicitud-compra/index"}
                  tag={NavLinkRRD}
                  activeClassName="active"
                >
                  <i className="ni ni-cart text-primary" />
                  Solicitudes de Compras
                </NavLink>

                <NavLink
                  to={
                    "/admin/solicitud-compra/departamento-administrativo/index"
                  }
                  tag={NavLinkRRD}
                  activeClassName="active"
                >
                  <i className="fas fa-shopping-cart text-primary" />
                  Solicitudes de Compras - Departamento Administrativo
                </NavLink>
                <NavLink
                  to={"/admin/contratos/index"}
                  tag={NavLinkRRD}
                  //onClick={this.closeCollapse}
                  activeClassName="active"
                >
                  <i className="far fa-file-alt text-primary" />
                  Contratos
                </NavLink>
              </Nav>
            </React.Fragment>
             )}
            {/* Divider */}
            <hr className="my-2" />
            {/* Heading */}
            {this.props.user.nivelAprobacion != null &&
              this.props.user.nivelAprobacion.nivelAprobacionId >= 3 && (
                <React.Fragment>
                  <h6 className="navbar-heading text-muted">Administración</h6>
                  {/* Navigation */}
                  <Nav className="mb-md-3" navbar>
                    <NavLink
                      to={"/admin/usuarios/index"}
                      tag={NavLinkRRD}
                      //onClick={this.closeCollapse}
                      activeClassName="active"
                    >
                      <i className="ni ni-circle-08 text-primary" />
                      Usuarios
                    </NavLink>
                    <NavLink
                      to={"/admin/administracion/productos"}
                      tag={NavLinkRRD}
                      //onClick={this.closeCollapse}
                      activeClassName="active"
                    >
                      <i className="ni ni-bullet-list-67 text-primary" />
                      Productos
                    </NavLink>

                    <NavLink
                      to={"/admin/administracion/productosNopoa"}
                      tag={NavLinkRRD}
                      activeClassName="active"
                    >
                      <i className="ni ni-bullet-list-67 text-primary" />
                      Productos No Poa
                    </NavLink>

                    <NavLink
                      to={"/admin/administracion/areamonitoreo"}
                      tag={NavLinkRRD}
                      //onClick={this.closeCollapse}
                      activeClassName="active"
                    >
                      <i className="ni ni-bullet-list-67 text-primary" />
                      Monitoreo
                    </NavLink>

                    <NavLink
                      to={"/admin/administracion/ponderacionareas"}
                      tag={NavLinkRRD}
                      //onClick={this.closeCollapse}
                      activeClassName="active"
                    >
                      <i className="ni ni-bullet-list-67 text-primary" />
                      Ponderación área
                    </NavLink>
                    <NavLink
                      to={"/admin/administracion/monitoreo"}
                      tag={NavLinkRRD}
                      //onClick={this.closeCollapse}
                      activeClassName="active"
                    >
                      <i className="ni ni-bullet-list-67 text-primary" />
                      Programación del monitoreo
                    </NavLink>
                    <NavLink
                      to={"/admin/administracion/adminmonitoreo"}
                      tag={NavLinkRRD}
                      //onClick={this.closeCollapse}
                      activeClassName="active"
                    >
                      <i className="ni ni-bullet-list-67 text-primary" />
                      Administración de los trimestres
                    </NavLink>
                    {/* <NavLink
                      to={"/admin/adminitracion/informes-trimestrales/index"}
                      tag={NavLinkRRD}
                      //onClick={this.closeCollapse}
                      activeClassName="active"
                    >
                      <i className="ni ni-archive-2 text-primary" />
                      Informes Trimestrales
                    </NavLink> */}
                    <NavLink
                      to={"/admin/administracion/tipos-compras/index"}
                      tag={NavLinkRRD}
                      activeClassName="active"
                    >
                      <i className="fas fa-store-alt text-primary" />
                      Tipos de compras
                    </NavLink>
                  </Nav>
                </React.Fragment>
              )}
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

const stateToProps = (state) => ({
  user: state.auth.signed,
});
export default connect(stateToProps, null)(Sidebar);
