import {
  MOSTRAR_HISTORICO_REQUERIMIENTOS,
  MOSTRAR_HISTORICO_REQUERIMIENTO,
  CREAR_HISTORICO_REQUERIMIENTO,
  LISTA_HISTORICO_PAGINACION,
  EDITAR_HISTORICO_REQUERIMIENTO,
  ELIMINAR_HISTORICO_REQUERIMIENTO,
  LOADING_HISTORICO_REQUERIMIENTO,
  HISTORICO_REQUERIMIENTO_SUCCEEDED,
  HISTORICO_REQUERIMIENTO_FAILED,
  LOADING_GUARDAR_HISTORICO_REQUERIMIENTO,
  GUARDAR_HISTORICO_REQUERIMIENTO,
  GUARDAR_HISTORICO_REQUERIMIENTO_SUCCEEDED,
  GUARDAR_HISTORICO_REQUERIMIENTO_FAILED,
} from "../types";
import { api_base } from "../../config";
import { getData } from "../../utils/api-client";

export const getHistoricoRequerimientos = () => async (dispatch) => {
  let url = api_base + "api/v1/requerimientos/2024";
  dispatch({ type: LOADING_HISTORICO_REQUERIMIENTO });

  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_HISTORICO_REQUERIMIENTOS, payload: resultado.data });
      dispatch({ type:   HISTORICO_REQUERIMIENTO_SUCCEEDED,});
    })
    .catch((error) => {
      if(error) {
        dispatch({ type: HISTORICO_REQUERIMIENTO_FAILED });
      }
    });
};

export const getRequerimiento = (requerimientoId) => async (dispatch) => {
  let url = api_base + `api/v1/requerimiento/${requerimientoId}`;
  dispatch({ type: LOADING_HISTORICO_REQUERIMIENTO });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_HISTORICO_REQUERIMIENTO, payload: resultado.data });
      dispatch({ type: HISTORICO_REQUERIMIENTO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: HISTORICO_REQUERIMIENTO_FAILED });
      }
    });
};

export const createRequerimientos = (requerimiento) => async (dispatch) => {
  let url = api_base + "api/v1/requerimientos/crear";
  dispatch({ type: LOADING_HISTORICO_REQUERIMIENTO });
  await getData
    .post(url, JSON.stringify(requerimiento))
    .then((resultado) => {
      dispatch({
        type: CREAR_HISTORICO_REQUERIMIENTO,
        payload: resultado.data,
      });
      dispatch({ type: HISTORICO_REQUERIMIENTO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: HISTORICO_REQUERIMIENTO_FAILED });
      }
    });
};

export const updateRequerimientos = (requerimiento) => async (dispatch) => {
  let url = api_base + "api/v1/requerimientos/editar";

  dispatch({ type: LOADING_HISTORICO_REQUERIMIENTO });
  await getData
    .post(url, JSON.stringify(requerimiento))
    .then((resultado) => {
      dispatch({
        type: EDITAR_HISTORICO_REQUERIMIENTO,
        payload: resultado.data,
      });
      dispatch({ type: HISTORICO_REQUERIMIENTO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: HISTORICO_REQUERIMIENTO_FAILED });
      }
    });
};

export const deleteRequerimiento = (requerimiento) => async (dispatch) => {
  let url = api_base + "api/v1/requerimientos/eliminar";

  dispatch({ type: LOADING_HISTORICO_REQUERIMIENTO });
  await getData
    .post(url, JSON.stringify(requerimiento))
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_HISTORICO_REQUERIMIENTO,
        payload: requerimiento.requerimientoId,
      });
      dispatch({ type: HISTORICO_REQUERIMIENTO_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: HISTORICO_REQUERIMIENTO_FAILED });
      }
    });
};

export const guardarRequerimientoHistorico = (requerimientoData) => async (dispatch) => {
    let url = api_base + "api/v1/historialrequerimientos/crear";
    dispatch({ type: LOADING_GUARDAR_HISTORICO_REQUERIMIENTO });
    await getData
      .post(url, JSON.stringify(requerimientoData))
      .then((resultado) => {
        dispatch({
          type: GUARDAR_HISTORICO_REQUERIMIENTO,
          payload: resultado.data,
        });
        dispatch({ type: GUARDAR_HISTORICO_REQUERIMIENTO_SUCCEEDED });
      })
      .catch((error) => {
        if (error) {
          dispatch({ type: GUARDAR_HISTORICO_REQUERIMIENTO_FAILED });
        }
      });
};

export const setListaPaginacionHistorico = (listaHistoricoRequerimiento) =>async (dispatch)=>{

  dispatch({type:LISTA_HISTORICO_PAGINACION, payload:listaHistoricoRequerimiento})
}