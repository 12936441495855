import React, { Component } from "react";
import { connect } from "react-redux";
import {
  deletePresupuestoNopoa,
  addPresupuestoNopoa,
  updateActividadesNopoa,
  getActividadNopoa,
  guardarInsumosNopoa
} from "../../redux/actions/actividades-actions.js";
import { ModalInsumosNoPoa } from "../utils/ModalInsumosNoPoa.jsx";
import { ModalInsumosNoPoaEditar } from "../utils/ModalInsumosNoPoaEdit.jsx";
import { getMediosVerificacion } from "../../redux/actions/medios-verificacion-actions.js";
import { getUnidadesMedida } from "../../redux/actions/unidades-medida-actions.js";
import Loading from "../utils/Loading.jsx";
import { Authorization } from "../../utils/Authorization.js";
import {
  getMaestroInsumos,
  getMaestroOperaciones,
  agregarInsumosTemporales,
  agregarInsumosTemporalesNopoa,
  limpiarInsumosTemporalesNopoa,
} from "../../redux/actions/maestro-Insumos-actions.js";
import ListaInsumosNoPoa from "./ListaInsumosNoPoa.jsx";
import { Container } from "reactstrap";
import swal from "sweetalert";

class EditarActividadNoPoa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      actividadNoPoa: {},
      modalInsumos: false,
      ModalInsumosNoPoaEditar: false,
      isLoadingInsumos: false,
      cuentaFiltro: "null",
      insumoFiltro: "null",
      operacionFiltro: "null",
      insumos: [],
      insumoEditar: {},
      actividadEdit: true,
      mediosVerificacionEdit: true,
      primerTrimestreEdit: true,
      segundoTrimestreEdit: true,
      tercerTrimestreEdit: true,
      cuartoTrimestreEdit: true,
      ponderacionActividadEdit: true,
      validarTotalPonderacion: false,
      unidadMedidaInsumo: "",
      mesesHabilesCalendar:[]
    };
  }

  actividadRef = React.createRef();
  primerTrimestreRef = React.createRef();
  segundoTrimestreRef = React.createRef();
  tercerTrimestreRef = React.createRef();
  cuartoTrimestreRef = React.createRef();

  componentDidMount() {
    const { actividadNoPoaId } = this.props.match.params;
     this.props.limpiarInsumosTemporalesNopoa();
    this.cargarData(actividadNoPoaId);

    this.setState({ ...this.state, actividadNopoaId: actividadNoPoaId});

    this.validarInput();
  }

  componentWillReceiveProps(nextProps, nextState) {
    const { actividadNopoa } = nextProps;
    this.setState(
      {
        actividadNopoa,
        insumos: actividadNopoa.presupuestoNopoa,
      },
    );
  }

  editarActividad = (e) => {
    e.preventDefault();
    if (
      this.validarActividad(
        this.actividadRef.current.value,
        this.primerTrimestreRef.current.value,
        this.segundoTrimestreRef.current.value,
        this.tercerTrimestreRef.current.value,
        this.cuartoTrimestreRef.current.value
      )
    ) {
      let actividad = {
        descripcion: this.actividadRef.current.value,
        t1: this.primerTrimestreRef.current.value,
        t2: this.segundoTrimestreRef.current.value,
        t3: this.tercerTrimestreRef.current.value,
        t4: this.cuartoTrimestreRef.current.value,
        productoNoPOAId: this.props.productoNopoa.productoNoPoaId,
        actividadNoPoaId: this.props.actividadNopoa.actividadNoPoaId,
        insumos: this.props.maestroInsumos.insumosTemporalesNopoa,
      };

      // console.log(actividad); return

      this.props
        .updateActividadesNopoa(actividad)
        .then((data) => {
          this.props.history.push(
            `/admin/productoNoPoa/detalle/${this.props.productoNopoa.productoNoPoaId}`
          );
        })
        .catch((error) => {
          //console.log(error);
        });
    }
  };

  cargarData = async (actividadId) => {
    await this.props.getActividadNopoa(actividadId);
    await this.props.getMediosVerificacion();
    await this.props.getUnidadesMedida("actividades");
    await this.props.agregarInsumosTemporalesNopoa(this.props.actividadNopoa.presupuestoNopoa);
    this.setState(
      {
        isLoading: false
      },
      () =>{
         this.ActivarModoEditarActividades(); 
        this.validarMesesCompraInsumos();
      } 
    );
  };

  validarMesesCompraInsumos = () => {
   const objectRef = {
    t1:"primerTrimestreRef",
    t2:"segundoTrimestreRef",
    t3:"tercerTrimestreRef",
    t4:"cuartoTrimestreRef",
   }
    let mesesHabiles = []

   for (let index = 1; index <=4; index++) {
    if (this.props.actividadNopoa[`t${index}`] > 0 || (this[`${objectRef[`t${index}`]}`].current && this[`${objectRef[`t${index}`]}`].current.value > 0 )){
      mesesHabiles = [...mesesHabiles,`t${index}`]
     }
   }
    this.setState({mesesHabilesCalendar:mesesHabiles})
  }

  validarInput = () => {
    window.addEventListener(
      "load",
      function () {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName("needs-validation");
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
          form.addEventListener(
            "submit",
            function (event) {
              if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
              }
              form.classList.add("was-validated");
            },
            false
          );
        });
      },
      false
    );
  };

  editarProducto = (e) => {
    e.preventDefault();
    const { productoNoPoaId } = this.props.match.params;

    let producto = {
      productoNoPoaId: productoNoPoaId,
      descripcion: this.productoRef.current.value,
      resultado: this.resultadoRef.current.value,
      indicador: this.indicadorRef.current.value,
      meta: this.metaRef.current.value,
      unidadResponsables: this.unidadResponsableRef,
      involucrados: this.involucradosRef,
      usuarioId: 2,
    };

    this.props
      .updateActividadesNopoa(producto)
      .then((data) => {
        this.limpiarFiltros();
        this.props.history.push("/admin/productosNoPoa/index");
      })
      .catch((error) => {});
  };

  validarActividad = (
    actividad,
    t1,
    t2,
    t3,
    t4
  ) => {
    if (actividad === "") {
      swal(
        "Editar actividades",
        "El campo actividad es un valor requerido",
        "error"
      );
      return false;
    }
    if (t1 === "" && t2 === "" && t3 === "" && t4 === "") {
      swal(
        "Editar actividades",
        "Debe especificar la distribución de las actividades en los diferentes trimestres",
        "error"
      );
      return false;
    }
    return true;
  };

  setStateUnidadMedidaInsumo = (unidadMedida) => {
    this.setState({ unidadMedidaInsumo: unidadMedida });
  };

  consultarInsumosActividad = (
    cuenta = this.state.cuentaFiltro,
    insumo = this.state.insumoFiltro,
    operacion = this.state.operacionFiltro
  ) => {
    this.setState(
      {
        cuentaFiltro: cuenta,
        insumoFiltro: insumo,
        operacionFiltro: operacion,
        isLoadingInsumos: true,
      },
      () => {
        this.props.getMaestroInsumos(cuenta, insumo, operacion).then(() => {
          this.setState({ isLoadingInsumos: false });
        });
      }
    );
  };

  guardarInsumos = (insumo) => {
    this.setState({
      modalInsumos: !this.state.modalInsumos,
      cuentaFiltro: "null",
      insumoFiltro: "null",
      operacionFiltro: "null",
    },()=>{
     this.props.agregarInsumosTemporalesNopoa([...this.props.maestroInsumos.insumosTemporalesNopoa, insumo])
    });
    this.limpiarFiltros();
  };

  editarInsumos = (presupuestoNopoa) => {
    const { maestroInsumos, agregarInsumosTemporalesNopoa } = this.props;
    const insumoTemporalesFilter = maestroInsumos.insumosTemporalesNopoa.map((insumo) =>
    insumo.presupuestoNoPoaId === presupuestoNopoa.presupuestoNoPoaId ? presupuestoNopoa : insumo
    );

    agregarInsumosTemporalesNopoa(insumoTemporalesFilter);
  
    this.setState((prevState) => ({
      modalInsumosEditar: !prevState.modalInsumosEditar,
    }));
  };
  
  ActivarModalInsumos = () => {
    this.setState({ modalInsumos: !this.state.modalInsumos });
    this.limpiarFiltros();
  };
  
  ActivarModalInsumosEditar = () => {
    this.setState({ modalInsumosEditar: !this.state.modalInsumosEditar });
    this.limpiarFiltros();
  };

  insumoEditar = (insumo) => {
    this.setState({
      insumoEditar: insumo,
      modalInsumosEditar: !this.state.modalInsumosEditar,
    });
  };

  eliminarPresupuesto = (e, insumo) => {
    e.preventDefault();
    let respuesta = window.confirm(
      "¿Está seguro de eliminar el insumo de la actividad No Poa?"
    );

    if (respuesta) {
      if (insumo.presupuestoNoPoaId > 0) {
        this.props.deletePresupuestoNopoa(insumo.presupuestoNoPoaId).then(() => window.location.reload())
      } else {
        let presupuesto = this.props.maestroInsumos.insumosTemporalesNopoa.filter(
          (presupuesto) => presupuesto !== insumo
        );

        this.props.agregarInsumosTemporales(presupuesto)
      }
    }
  };

  limpiarFiltros = () => {
    this.setState({
      cuentaFiltro: "null",
      insumoFiltro: "null",
      operacionFiltro: "null",
      unidadMedidaInsumo: "",
    });
  };

  ActivarModoEditarActividades = () => {
    let actividadEdit = true;
    let mediosVerificacionEdit = true;
    let primerTrimestreEdit = true;
    let segundoTrimestreEdit = true;
    let tercerTrimestreEdit = true;
    let cuartoTrimestreEdit = true;
    let ponderacionActividadEdit = true;
    if (this.props.productoNopoa.modoEditar) {
      let notasActividad = this.props.actividad.notasActividad;
      let indice = notasActividad.length - 1;
      if (
        notasActividad.length > 0 &&
        notasActividad[indice].descripcion !== null
      ) {
        actividadEdit = false;
      }
      if (
        notasActividad.length > 0 &&
        notasActividad[indice].medioVerificacion !== null
      ) {
        mediosVerificacionEdit = false;
      }
      if (notasActividad.length > 0 && notasActividad[indice].t1 !== null) {
        primerTrimestreEdit = false;
      }
      if (notasActividad.length > 0 && notasActividad[indice].t2 !== null) {
        segundoTrimestreEdit = false;
      }
      if (notasActividad.length > 0 && notasActividad[indice].t3 !== null) {
        tercerTrimestreEdit = false;
      }
      if (notasActividad.length > 0 && notasActividad[indice].t4 !== null) {
        cuartoTrimestreEdit = false;
      }
      if (
        notasActividad.length > 0 &&
        notasActividad[indice].ponderacionActividad !== null
      ) {
        ponderacionActividadEdit = false;
      }

      this.setState({
        actividadEdit,
        mediosVerificacionEdit,
        primerTrimestreEdit,
        segundoTrimestreEdit,
        tercerTrimestreEdit,
        cuartoTrimestreEdit,
        ponderacionActividadEdit,
      });
    } else {
      this.setState({
        actividadEdit: false,
        mediosVerificacionEdit: false,
        primerTrimestreEdit: false,
        segundoTrimestreEdit: false,
        tercerTrimestreEdit: false,
        cuartoTrimestreEdit: false,
        ponderacionActividadEdit: false,
      });
    }
  };

  // calcularPonderacionTotalProductos = () => {
  //   if (
  //     this.props.totalPonderacionActividades &&
  //     this.props.actividadNopoa.ponderacionActividad
  //   ) {
  //     let ponderacionAreaProps =
  //      parseFloat(this.props.totalPonderacionActividades) - parseFloat(
  //       this.props.actividadNopoa.ponderacionActividad);
  //     let ponderacionArea =
  //       this.ponderacionActividadRef.current !== null &&
  //       this.ponderacionActividadRef.current.value !== ""
  //         ? this.ponderacionActividadRef.current.value
  //         : 0;
  //     let total =
  //       parseFloat(ponderacionAreaProps) + parseFloat(ponderacionArea);
  //     if (total <= 100) {
  //       this.setState({ validarTotalPonderacion: true });
  //     } else {
  //       this.setState({ validarTotalPonderacion: false });
  //       swal(
  //         "Editar Actividades",
  //         "Excede el límite de ponderación permitido para las actividades, actualmente tiene " +
  //           total +
  //           "%",
  //         "error"
  //       );
  //     }
  //   } else {
  //     this.setState({ validarTotalPonderacion: true });
  //   }
  // };

  render() {
    const { mediosVerificacion, unidadesMedida } = this.props;
    const {
      descripcion,
      t1,
      t2,
      t3,
      t4,
      ponderacionActividad,
      unidadMedida,
      medioVerificacion,
      notasActividad,
    } = this.props.actividadNopoa;

    return (
      <div className="pb-6 pt-5 pt-md-7">
        {this.state.isLoading ? (
          <Loading></Loading>
        ) : (
          <Container>
            <div className="container bg-white border">
              <form
                className="needs-validation p-3 "
                onSubmit={this.editarActividad}
                noValidate
              >
                <div className="form-group ">
                  <label htmlFor="actividad">Actividad</label>
                  <input
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="actividad"
                    placeholder="Nombre de la actividad"
                    ref={this.actividadRef}
                    defaultValue={descripcion}
                    readOnly={this.state.actividadEdit}
                    required
                  />
                  <div className="invalid-feedback">
                    Este es un campo obligatorio
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-3 ">
                    <label htmlFor="primerTrimestre">Primer Trimestre</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="primerTrimestre"
                      placeholder="Primer Trimestre"
                      ref={this.primerTrimestreRef}
                      defaultValue={t1}
                      readOnly={this.state.primerTrimestreEdit}
                      onChange={()=>{this.validarMesesCompraInsumos()}}
                      required
                    />
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>
                  <div className="form-group col-md-3 ">
                    <label htmlFor="segundoTrimestre">Segundo Trimestre</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="segundoTrimestre"
                      placeholder="Segundo Trimestre"
                      ref={this.segundoTrimestreRef}
                      defaultValue={t2}
                      readOnly={this.state.segundoTrimestreEdit}
                      onChange={()=>{this.validarMesesCompraInsumos()}}
                      required
                    />
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>
                  <div className="form-group col-md-3 ">
                    <label htmlFor="tercerTrimestre">Tercer Trimestre</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="tercerTrimestre"
                      placeholder="Tercer Trimestre"
                      ref={this.tercerTrimestreRef}
                      defaultValue={t3}
                      readOnly={this.state.tercerTrimestreEdit}
                      onChange={()=>{this.validarMesesCompraInsumos()}}
                      required
                    />
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>
                  <div className="form-group col-md-3 ">
                    <label htmlFor="cuartoTrimestre">Cuarto Trimestre</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="cuartoTrimestre"
                      placeholder="Cuarto Trimestre"
                      ref={this.cuartoTrimestreRef}
                      defaultValue={t4}
                      readOnly={this.state.cuartoTrimestreEdit}
                      onChange={()=>{this.validarMesesCompraInsumos()}}
                      required
                    />
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h2>Insumos de la Actividad No Poa</h2>
                  </div>
                  <div className="col-md-12">
                    <button
                      className="btn  btn-info float-right mb-3"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          modalInsumos: !this.state.modalInsumos,
                        });
                      }}
                    >
                      Agregar Insumo
                    </button>
                    <ListaInsumosNoPoa
                      insumos={this.props.actividadNopoa.presupuestoNopoa}
                      eliminarPresupuesto={this.eliminarPresupuesto}
                      insumoEditar={this.insumoEditar}
                      ActivarModalInsumos={this.modalInsumosEditar}
                    ></ListaInsumosNoPoa>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  Guardar
                </button>
              </form>
            </div>
            {this.state.modalInsumos &&(
            <ModalInsumosNoPoa
              modalInsumos={this.state.modalInsumos}
              ActivarModalInsumos={this.ActivarModalInsumos}
              consultarInsumosActividad={this.consultarInsumosActividad}
              state={this.state}
              maestroInsumos={this.props.maestroInsumos.maestroInsumos}
              operaciones={this.props.maestroInsumos.operaciones}
              guardarInsumos={this.guardarInsumos}
              setStateUnidadMedidaInsumo={this.setStateUnidadMedidaInsumo}
            ></ModalInsumosNoPoa>
            )}
             {this.state.modalInsumosEditar &&(
            <ModalInsumosNoPoaEditar
              modalInsumosEditar={this.state.modalInsumosEditar}
              ActivarModalInsumos={this.ActivarModalInsumosEditar}
              consultarInsumosActividad={this.consultarInsumosActividad}
              state={this.state}
              maestroInsumos={this.props.maestroInsumos.maestroInsumos}
              operaciones={this.props.maestroInsumos.operaciones}
              editarInsumos={this.editarInsumos}
              setStateUnidadMedidaInsumo={this.setStateUnidadMedidaInsumo}
            ></ModalInsumosNoPoaEditar>
            )}
          </Container>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log(state.auth);
  return {
    actividadesNopoa: state.actividadesNopoa.actividadesNopoa,
    actividadNopoa: state.actividadesNopoa.actividadNopoa,

    isLoading: state.actividades.isLoading,
    unidadesMedida: state.unidadesMedida.unidadesMedida,
    mediosVerificacion: state.mediosVerificacion.mediosVerificacion,
    isLoadingMedio: state.mediosVerificacion.isLoading,
    isLoadingUnidadMedida: state.unidadesMedida.isLoading,
    productoNopoa: state.productosNoPoa.productoNopoa,
    user: state.auth.signed,
    maestroInsumos: state.maestroInsumos,
    presupuesto: state.actividades.actividad.presupuesto,
    totalPonderacionActividades: state.actividades.totalPonderacionActividades,
  };
};
const mapDispatchToProps = () => (dispatch) => ({
  getActividadNopoa: (actividadId) => dispatch(getActividadNopoa(actividadId)),
  updateActividadesNopoa: (actividades) => dispatch(updateActividadesNopoa(actividades)),
  getMediosVerificacion: () => dispatch(getMediosVerificacion()),
  getUnidadesMedida: (modulo) => dispatch(getUnidadesMedida(modulo)),
  getMaestroInsumos: (cuenta, insumo, operacion) => dispatch(getMaestroInsumos(cuenta, insumo, operacion)),
  getMaestroOperaciones: () => dispatch(getMaestroOperaciones()),
  deletePresupuestoNopoa: (presupuesto) => dispatch(deletePresupuestoNopoa(presupuesto)),
  addPresupuestoNopoa: (presupuesto) => dispatch(addPresupuestoNopoa(presupuesto)),
  agregarInsumosTemporales:(insumos) => dispatch(agregarInsumosTemporales(insumos)),
  agregarInsumosTemporalesNopoa:(insumos) => dispatch(agregarInsumosTemporalesNopoa(insumos)),
  limpiarInsumosTemporalesNopoa:() => dispatch(limpiarInsumosTemporalesNopoa()),
  guardarInsumosNopoa: (insumo) => dispatch(guardarInsumosNopoa(insumo))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(EditarActividadNoPoa, [
    "Administrador general",
    "Producto Fuera del POA",
    "Gestor del POA",
    "MAE",
  ])
);
