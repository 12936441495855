import {
  MOSTRAR_AREA_INFORMES_TRIMESTRALES,
  EDITAR_AREA_INFORMES_TRIMESTRALES,
  LOADING_AREA_INFORMES_TRIMESTRALES,
  AREA_INFORMES_TRIMESTRALES_FAILED,
  AREA_INFORMES_TRIMESTRALES_SUCCESS,
} from "./../types";

import { api_base } from "../../config";
import { getData } from "./../../utils/api-client";

export const getAreasInformesTrimestrales = (poaId) => async (dispatch) => {
  let url = api_base + `api/v1/areasinformestrimestrales/${poaId}`;
  dispatch({ type: LOADING_AREA_INFORMES_TRIMESTRALES });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({
        type: MOSTRAR_AREA_INFORMES_TRIMESTRALES,
        payload: resultado.data,
      });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: AREA_INFORMES_TRIMESTRALES_FAILED });
      }
    });
};

export const updateAreaInformeTrimestral = (areaInformeTrimestral) => async (
  dispatch
) => {
  let url = api_base + "api/v1/areasinformestrimestrales/actualizar";
  dispatch({ type: LOADING_AREA_INFORMES_TRIMESTRALES });

  await getData
    .post(url, JSON.stringify(areaInformeTrimestral))
    .then((resultado) => {
      dispatch({
        type: EDITAR_AREA_INFORMES_TRIMESTRALES,
        payload: resultado.data,
      });
      dispatch({ type: AREA_INFORMES_TRIMESTRALES_SUCCESS });
    })
    .catch((error) => {
      if(error) {
        dispatch({ type: AREA_INFORMES_TRIMESTRALES_FAILED });
      }
    });
};

export const updateAllInformesTrimestrales = (infomeTrimestral) => async (
  dispatch
) => {
  let url = api_base + `api/v1/areasinformestrimestrales/actualizar/all`;

  dispatch({ type: LOADING_AREA_INFORMES_TRIMESTRALES });
  await getData
    .post(url, JSON.stringify(infomeTrimestral))
    .then((resultado) => {
      dispatch({
        type: MOSTRAR_AREA_INFORMES_TRIMESTRALES,
        payload: resultado.data,
      });
      dispatch({ type: AREA_INFORMES_TRIMESTRALES_SUCCESS });
    })
    .catch((error) => {
      if (error) {
        dispatch({type: AREA_INFORMES_TRIMESTRALES_FAILED});
      }
    });
};
