import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";

class BienesServiciosLista extends Component {
  state = {};

  render() {
    const {requerimientoId, numeroSolicitud, fechaCreacion, analista, requerimiento } = this.props.requerimiento;

    return (
        <tr>
            <td>-</td>
            <td>{(analista && analista.name !== null) ? analista.name : '-' }</td>
            <td>{numeroSolicitud}</td>
            <td>{requerimiento[requerimiento.length-1].area.nombre}</td>
            <td>
              <Moment
                format="DD/MM/YYYY"
                date={fechaCreacion}
              >
              </Moment>
            </td>
            <td className="d-flex flex-row">
            <Link
                to={{
                pathname: `crear/${requerimientoId}`,
                state: "/admin/expedientes-fisicos/crear/:expedienteFisicoId",
                }}
                className="p-2"
            >
                <i className="fas fa-cloud-upload-alt text-primary"></i>
            </Link>
            </td>
        </tr>
    );
  }
}

export default connect(null, {})(BienesServiciosLista);
