import React, { Component } from "react";
import { connect } from "react-redux";
import { mostrarPoa, editarPoa } from "./../../redux/actions/poa-actions";
import Loading from "./../utils/Loading.jsx";
import { Authorization } from "./../../utils/Authorization";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";
import { DateTimePicker } from "react-widgets";
import "react-widgets/dist/css/react-widgets.css";
import {
  Container
} from "reactstrap";

class EditarPOA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      poa: {},
      nombre: "",
      ano: "",
      fechaCierre: "",
      poaId: "",
      divisa:0.00
    };
  }
  componentDidMount() {
    const { poaId } = this.props.match.params;
    this.props.mostrarPoa(poaId);
  }
  componentWillReceiveProps(nextProps, nextState) {
    const { poaId, ano, fechaCierre, nombre,divisa } = nextProps.poa;
    this.setState({
      ano,
      nombre,
      fechaCierre,
      poaId,
      divisa
    });
  }
  nombreRef = React.createRef();
  divisaRef = React.createRef();

  actualizarPOA = e => {
    e.preventDefault();
    let editarPOA = {
      nombre: this.nombreRef.current.value,
      ano: this.state.ano,
      fechaCierre: this.state.fechaCierre,
      poaId: this.state.poaId,
      divisa:this.divisaRef.current!=null &&this.divisaRef.current!=undefined?this.divisaRef.current.value:0.00
    };
    this.setState({
      poa: editarPOA
    });

    this.props.editarPoa(editarPOA);
    //redirecciona a la raiz
    this.setState({
      loading: true
    });
    setTimeout(() => {
      this.setState({
        loading: false
      });
      this.props.history.push("/admin/index");
    }, 3000);
  };

  render() {
    Moment.locale("en");
    momentLocalizer();
    let anoRef = e => {
      //  //console.log(e);
      this.setState({
        ano: e
      });
    };
    let fechaCierreRef = e =>
      this.setState({ fechaCierre: e });
    const { ano, nombre, fechaCierre, loading,divisa } = this.state;
    let datePickerAno = 0;
    let datePickerfechacierre;
    if (ano) {
      datePickerAno = (
        <DateTimePicker
          footer={false}
          defaultValue={new Date(ano, 0, 1)}
          min={new Date()}
          max={new Date(new Date().getFullYear() + 1, 0, 1)}
          time={false}
          defaultView="decade"
          onChange={anoRef}
          ref={this.anoRef}
        />
      );

      datePickerfechacierre = (
        <DateTimePicker
          defaultValue={
            new Date(
              Moment(fechaCierre).year(),
              Moment(fechaCierre).month(),
              Moment(fechaCierre).date()
            )
          }
          footer={false}
          min={new Date(new Date().getFullYear() + 2, 0, 1)}
          time={false}
          onChange={fechaCierreRef}
          ref={this.fechaCierreRef}
        />
      );
    } else {
      datePickerAno = <h1>date</h1>;
    }

    return (
      <>
        {fechaCierre && !loading ? (
          <div className="pb-6 pt-5 pt-md-7">
            <Container>
              <div className="container bg-white border">
                <form className="p-3" onSubmit={this.actualizarPOA}>
                  <div className="form-group">
                    <label htmlFor="descripción">Nombre</label>
                    <input
                      type="text"
                      className="form-control text-dark"
                      id="descripción"
                      placeholder="Nombre de la meta del año"
                      ref={this.nombreRef}
                      defaultValue={nombre}
                    />
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6 ">
                      <label htmlFor="ano">Año</label>
                      {datePickerAno}
                    </div>
                    <div className="form-group col-md-6 ">
                      <label htmlFor="fechaCierre">Fecha Cierre</label>
                      {datePickerfechacierre}
                    </div>
                  </div>
                  <div className="row">
                  <div className="form-group col-md-6 ">
                      <label htmlFor="divisa">Divisa</label>
                      <input
                      type="text"
                      className="form-control text-dark"
                      id="divisa"
                      placeholder="Divisa del POA"
                      defaultValue={divisa}
                      ref={this.divisaRef}
                    />
                    </div>

                  </div>

                  <button type="submit" className="btn btn-primary">
                    Editar
                  </button>
                </form>
              </div>
            </Container>
          </div>
        ) : (
          <Loading></Loading>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    poa: state.poas.poa,
    user: state.auth.signed
  };
};

const mapDispatchToProps = dispatch => ({
  editarPoa: poa => dispatch(editarPoa(poa)),
  mostrarPoa: poaId => dispatch(mostrarPoa(poaId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps //{ Login }
)(Authorization(EditarPOA, ["Administrador general", "Gestor del POA", "MAE"]));
