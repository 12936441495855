import React, { Component } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Table,
  Container
} from "reactstrap";
import Pagination from "react-js-pagination";

import { getListaTiposAsignaciones } from "../../../redux/actions/tipos_asignaciones_action";
import Loading from "../../utils/Loading";
import { Authorization } from "./../../../utils/Authorization";
import TipoAsignacion from "./TipoAsignacion";

class ListadoTiposAsignaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaTipoAsignacionesState: [],
      listaTipoAsignacionesPageState: [],
      activePage: 1,
      totalPages: 0,
      itemPerPage: 10,
    };
  }

  cargarTiposAsignaciones =async ()=>{
   await this.props.getListaTiposAsignaciones();
    this.setState(
      { listaTipoAsignacionesState: this.props.tiposAsignaciones },
      () => this.validarPaginacion()
    );
  }

 
  componentDidMount() {
    this.cargarTiposAsignaciones();
  }

  filtroTipoAsignaciones = (tipoAsignacion) => {
    let filtroTipoAsignaciones = [];
    if (tipoAsignacion.length > 0) {
      filtroTipoAsignaciones = this.props.tiposAsignaciones.filter((tipo) =>
        tipo.descripcion
          .toUpperCase()
          .includes(tipoAsignacion.toUpperCase())
      );
    } else {
      filtroTipoAsignaciones = this.props.tiposAsignaciones;
    }

    this.setState(
      { listaTipoAsignacionesState: filtroTipoAsignaciones },
      () => {
        this.validarPaginacion();
      }
    );
  };

  validarPaginacion = (page = 1, elementos = 10) => {
    let index = elementos * page - elementos;
    let listaTiposAsignaciones = [];

    for (let i = 0; i < elementos; i++) {
      listaTiposAsignaciones.push(
        this.state.listaTipoAsignacionesState[index]
      );

      index++;
    }

    let totalPages = Math.ceil(
      this.state.listaTipoAsignacionesState.length / elementos
    );
   // console.log(totalPages);
    this.setState({
      //detallePoaState: listaProductos,
      listaTipoAsignacionesPageState: listaTiposAsignaciones,
      activePage: page,
      totalPages,
      itemPerPage: elementos,
    });
  };

  render() {
    const { isLoading, tiposAsignaciones } = this.props;
    return (
      <div className="pb-6 pt-5 pt-md-7">
        {!isLoading ? (
          <Container fluid>
            <div className="container bg-white border py-3">
              <div className="header-body">
                <div className="row">
                  <div className="form-group  col-md-6 ">
                    <label htmlFor="filtro">
                      Filtro Tipos Asignaciones
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="filtro"
                      placeholder="Tipos Asignaciones"
                      onKeyUp={(e) => {
                        e.preventDefault();
                        this.filtroTipoAsignaciones(e.currentTarget.value);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <Link
                      className="btn btn-lg btn-info float-right mb-3"
                      to="/admin/tipoAsignacion/crear"
                    >
                      Agregar Tipo Asignación
                    </Link>
                  </div>
                </div>

                <Table
                  style={{ backgroundColor: "#fff", color: "black" }}
                  responsive
                  className="table-striped"
                >
                  <thead>
                    <tr className="text-left">
                      <th>Descripción</th>
                      <th>Estado</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.listaTipoAsignacionesPageState.map(
                      (tipoAsignacion) =>
                      tipoAsignacion !== null &&
                      tipoAsignacion !== undefined && (
                          <TipoAsignacion
                            key={tipoAsignacion.tipoAsignacionId}
                            tipoAsignacion={tipoAsignacion}
                            user={this.props.user}
                            cargarTiposAsignaciones={this.cargarTiposAsignaciones}
                          />
                        )
                    )}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-center">
                  <Pagination
                    prevPageText="ant"
                    nextPageText="sig"
                    firstPageText="prime"
                    lastPageText="ultimo"
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemPerPage}
                    totalItemsCount={
                      this.state.listaTipoAsignacionesState.length
                    }
                    //pageRangeDisplayed={5}
                    onChange={this.validarPaginacion.bind(this)}
                  />
                </div>
              </div>
            </div>
          </Container>
        ) : (
          <Loading></Loading>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    tiposAsignaciones: state.tiposAsignaciones.tiposAsignaciones,
    isLoading: state.tiposAsignaciones.isLoading,
    user: state.auth.signed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getListaTiposAsignaciones:()=>(dispatch(getListaTiposAsignaciones())) ,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(ListadoTiposAsignaciones, [
    "Encargado Presupuesto",
    "Analista Presupuesto",
    "Administrador general",
    "Analista del POA",
    "MAE",
  ])
);
