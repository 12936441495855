import React, { Component } from "react";
import { connect } from "react-redux";
import { getActividadesNopoa } from "../../redux/actions/actividades-actions.js";
import Actividad from "../actividadesNoPoa/Actividad.jsx";
import { Link } from "react-router-dom";
import { Authorization } from "../../utils/Authorization.js";
import {
  Table,
} from "reactstrap";

class ListaActividadesNoPoa extends Component {

  componentDidMount() {
    this.props.getActividadesNopoa(this.props.productoNoPoaId);
  }

  render() {
    const { actividadesNopoa } = this.props;

    return (
      <React.Fragment>
        <h3 className="mt-3" >Listado de Actividades No Poa</h3>

        {this.props.validarOpcionEditar() ? (
          <Link
            className="btn  btn-info float-right mb-3"
            to="/admin/actividadNoPoa/nueva"
          >
            Agregar Actividades No Poa
          </Link>
        ) : (
          <button
            title="Producto Enviado"
            className="btn  btn-info float-right mb-3 opacity"
            onClick={e => e.preventDefault()}
          >
            Agregar Actividades No Poa
          </button>
        )}
        {actividadesNopoa && actividadesNopoa.length > 0 ? (
          <Table
            style={{ backgroundColor: "#fff", color: "black" }}
            responsive
            className="table-striped"
          >
            <thead>
              <tr className="">
                <th>Actividad</th>
                <th>No. Insumos</th>
                <th>Presupuesto</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody className="text-left">
              {actividadesNopoa.map(actividad => (
                <Actividad
                  productoNoPoaId={this.props.productoNoPoaId}
                  validarOpcionEditar={this.props.validarOpcionEditar}
                  key={actividad.actividadNoPoaId}
                  actividadNopoa={actividad}
                ></Actividad>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="row d-flex justify-content-center mb-3">
            <h5>No tiene actividades agregadas</h5>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  actividadesNopoa: state.actividadesNopoa.actividadesNopoa,
  isLoading: state.actividades.isLoading,
  productoNoPoaId: state.productosNoPoa.productoNopoa.productoNoPoaId,
  user: state.auth.signed
});

const mapDispatchToProps = dispatch => ({
  getActividadesNopoa: productoNoPoaId => dispatch(getActividadesNopoa(productoNoPoaId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(ListaActividadesNoPoa, [
    "Administrador general",
    "Producto Fuera del POA",
    "Gestor del POA",
    "MAE",
  ])
);
