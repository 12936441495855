import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Loading from "./../../utils/Loading.jsx";
import TipoCompra from "./tipoCompra.jsx";
import { getListaTipoCompras, actualizarTiposCompras, crearTipoCompra, eliminarTipoCompra } from "../../../redux/actions/tipos-compras-actions";
import { Authorization } from "./../../../utils/Authorization";
import { ModalCrearTipoCompra } from "../../utils/ModalCrearTipoCompra";
import { ModalEditarTipoCompra } from "../../utils/ModalEditarTipoCompra";

import {
    Table,
    Container,
  } from "reactstrap";
import swal from "sweetalert";

class TiposCompras extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isLoading: true,
          listaTiposComprasState: [],
          listaTiposComprasPageState: [],
          activePage: 1,
          totalPages: 0,
          itemPerPage: 10,
          modalTipoCompraStatus: false,
        };
    }

    componentDidMount() {
        this.cargarDatos();
    }

    filtroTipoCompra = (nombre) => {
      let filtroTipocompra = [];
      if (nombre.length > 0) {
        filtroTipocompra = this.props.tiposCompras.filter((tipoCompra) =>
        tipoCompra.nombre
            .toUpperCase()
            .includes(nombre.toUpperCase())
        );
      } else {
        filtroTipocompra = this.props.tiposCompras;
      }
  
      this.setState({ listaTiposComprasState: filtroTipocompra }, () => {
        this.validarPaginacion();
      });
    };

    validarPaginacion = (page = 1, elementos = 10) => {
        let index = elementos * page - elementos;
        let listaTiposCompras = [];
    
        for (let i = 0; i < elementos; i++) {
            listaTiposCompras.push(this.state.listaTiposComprasState[index]);
    
          index++;
        }
    
        let totalPages = Math.ceil(
          this.state.listaTiposComprasState.length / elementos
        );
    
        this.setState({
          listaTiposComprasPageState: listaTiposCompras,
          activePage: page,
          totalPages,
          itemPerPage: elementos,
        });
    };

    cargarDatos = async () => {
        await this.props.getListaTipoCompras();
        this.setState({ listaTiposComprasState: this.props.tiposCompras }, () =>
          this.validarPaginacion()
        );
    
        this.setState({ isLoading: false });
    };

    editarTipoCompra = (compra) => {
      alert(compra)
    }

    eliminarTipoCompra = (tipoCompraId) => {
      // TODO: validar que si existe requermiento con tipo de compra, no pueda eliminarse
      // y debe de mostrar mensaje.
      swal({
        title: "¿Está seguro de eliminar este tipo de compra?",
        text: "¡Una vez eliminado no podrá ser recuperado!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then(async(confirm) => {
        if (confirm) {
          await  this.props.eliminarTipoCompra(tipoCompraId);
          window.location.reload();
        }
      });
    }

    activarModalTipoCompra = () => {
      this.setState({
        modalTipoCompraStatus: !this.state.modalTipoCompraStatus,
      });
    };

    activarModalEditarTipoCompra = (tipoCompra) => {
      this.setState({
        modalEditarTipoCompraStatus: !this.state.modalEditarTipoCompraStatus,
        tipoCompraEditar: tipoCompra
      });
    };
    
    guardarTipoDeCompra = (compra) => {
      if (compra === null || compra === "") {
        swal("Tipo de Compra", "Debe de completar el campo nombre", "error");
  
        return false;
      }

      this.props.crearTipoCompra(compra)
        .then(() => {
          window.location.reload();
        })
      .catch((error) => {
        swal("Tipo de Compra", "Ha ocurrido un error al intentar guardar", "error");
      });
    }

    actualizarTiposCompras = (tipoCompraId, nombre) => {
      if (nombre === null || nombre === "") {
        swal("Tipo de Compra", "Debe de completar el campo nombre", "error");
  
        return false;
      }

      let tipoCompras = {
        tipoCompraId,
        nombre
      }

      this.props.actualizarTiposCompras(tipoCompras)
        .then(() => {
          window.location.reload();
        })
      .catch((error) => {
        swal("Tipo de Compra", "Ha ocurrido un error al intentar actualizar", "error");
      });
    }

    render() {
        const {
            listaTiposComprasState,
            listaTiposComprasPageState,
            activePage,
            itemPerPage,
            isLoading
        } = this.state;
        return (
            <div className="pb-6 pt-5 pt-md-7">
            <Container fluid>
            <div className="container bg-white border py-3">
                {isLoading ? (
                <Loading></Loading>
                ) : (
                <div className="header-body">
                    <div className="row">
                    <div className="form-group  col-md-6 ">
                        <label htmlFor="filtro">Filtro Compras</label>
                        <input
                        type="text"
                        className="form-control form-control-sm text-dark"
                        id="filtro"
                        placeholder="Tipo de compra"
                        onKeyUp={(e) => {
                            e.preventDefault();
                            this.filtroTipoCompra(e.currentTarget.value);
                        }}
                        ref={this.filtroTipoCompraRef}
                        />
                    </div>
                    <div className="col-md-6">
                        <a
                          className="btn btn-lg btn-info text-white float-right mb-3"
                          onClick={(e) => {
                            e.preventDefault();
                            this.activarModalTipoCompra();
                          }} >
                          Agregar Tipo de Compra
                        </a>
                    </div>
                    </div>
                    <Table
                    style={{ backgroundColor: "#fff", color: "black" }}
                    responsive
                    className="table-striped"
                    >
                    <thead>
                        <tr className="text-left">
                          <th>#</th>
                          <th>Nombre</th>
                        </tr>
                    </thead>
                    <tbody className="text-left">
                        
                        {listaTiposComprasPageState.map(
                        (compra) =>
                            compra !== null &&
                            compra !== undefined && (
                            <TipoCompra
                                compra={compra}
                                key={compra.tipoCompraId}
                                eliminarTipoCompra = {this.eliminarTipoCompra}
                                editarTipoCompra = {this.editarTipoCompra}
                                modalEditarTipoCompraStatus = { this.modalEditarTipoCompraStatus}
                                ModalEditarTipoCompra = { this.modalTipoCompraStatus }
                                activarModalEditarTipoCompra = { this.activarModalEditarTipoCompra}
                            />
                            )
                        )}
                    </tbody>
                    </Table>
                    <div className="d-flex justify-content-center">
                    <Pagination
                        prevPageText="ant"
                        nextPageText="sig"
                        firstPageText="prime"
                        lastPageText="ultimo"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={activePage}
                        itemsCountPerPage={itemPerPage}
                        totalItemsCount={listaTiposComprasState.length}
                        onChange={this.validarPaginacion.bind(this)}
                    />
                    </div>
                </div>
                )}
            </div>
            <ModalCrearTipoCompra
              modalTipoCompraStatus = { this.state.modalTipoCompraStatus }
              activarModalTipoCompra = {this.activarModalTipoCompra}
              guardarTipoDeCompra = { this.guardarTipoDeCompra }
            />
            <ModalEditarTipoCompra
              modalEditarTipoCompraStatus = { this.state.modalEditarTipoCompraStatus }
              activarModalEditarTipoCompra = {this.activarModalEditarTipoCompra}
              actualizarTiposCompras = { this.actualizarTiposCompras }
              tipoCompras = { this.state.tipoCompraEditar }
            />
            </Container>
        </div>
        );
  }
}

const mapStateToProps = (state) => ({
    user: state.auth.signed,
    tiposCompras: state.tiposCompras.tiposCompras,
    tipoCompra: state.tiposCompras.tipoCompra
  });
  
  const mapDispacthToProps = (dispatch) => ({
    getListaTipoCompras: () => dispatch(getListaTipoCompras()),
    eliminarTipoCompra: (tipoCompraId) => dispatch(eliminarTipoCompra(tipoCompraId)),
    actualizarTiposCompras: (compra) => dispatch(actualizarTiposCompras(compra)),
    crearTipoCompra: (compra) => dispatch(crearTipoCompra(compra))
  });
  export default connect(
    mapStateToProps,
    mapDispacthToProps
  )(
    Authorization(TiposCompras, [
      "Administrador general",
      "Analista Compras",
      "Responsables de compras"
    ])
  );