import {
  MOSTRAR_MEDIOS_VERIFICACION,
  LOADING_MEDIOS_VERIFICACION,
  MEDIOS_VERIFICACION_SUCCEEDED,
  CREAR_MEDIOS_VERIFICACION,
  EDITAR_MEDIOS_VERIFICACION,
  MEDIOS_VERIFICACION_FAILED,
  ELIMINAR_MEDIOS_VERIFICACION,
  MOSTRAR_MEDIO_VERIFICACION,
} from "./../types";
import { api_base } from "./../../config";
import { getData } from "./../../utils/api-client";
export const getMediosVerificacion = () => async (dispatch) => {
  let url = api_base + `api/v1/mediosverificacion`;
  dispatch({ type: LOADING_MEDIOS_VERIFICACION });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_MEDIOS_VERIFICACION, payload: resultado.data });
      dispatch({ type: MEDIOS_VERIFICACION_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: MEDIOS_VERIFICACION_FAILED });
      }
    });
};

export const getMedioVerificacion = (medioVerificacionId) => async (
  dispatch
) => {
  let url = api_base + `api/v1/medioverificacion/${medioVerificacionId}`;
  dispatch({ type: LOADING_MEDIOS_VERIFICACION });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_MEDIO_VERIFICACION, payload: resultado.data });
      dispatch({ type: MEDIOS_VERIFICACION_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: MEDIOS_VERIFICACION_FAILED });
      }
    });
};
export const createMedioVerificacion = (medioVerificacion) => async (
  dispatch
) => {
  let url = api_base + "api/v1/medioverificacion/nuevo";

  dispatch({ type: LOADING_MEDIOS_VERIFICACION });
  await getData
    .post(url, JSON.stringify(medioVerificacion))
    .then((resultado) => {
      dispatch({
        type: CREAR_MEDIOS_VERIFICACION,
        payload: resultado.data,
      });
      dispatch({ type: MEDIOS_VERIFICACION_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: MEDIOS_VERIFICACION_FAILED });
      }
    });
};
export const updateMedioVerificacion = (medioVerificacion) => async (
  dispatch
) => {
  let url = api_base + "api/v1/medioverificacion/editar";

  dispatch({ type: LOADING_MEDIOS_VERIFICACION });
  await getData
    .post(url, JSON.stringify(medioVerificacion))
    .then((resultado) => {
      dispatch({
        type: EDITAR_MEDIOS_VERIFICACION,
        payload: resultado.data,
      });
      dispatch({ type: MEDIOS_VERIFICACION_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: MEDIOS_VERIFICACION_FAILED });
      }
    });
};
export const deleteMedioVerificacion = (medioVerificacion) => async (
  dispatch
) => {
  let url = api_base + "api/v1/medioverificacion/eliminar";

  dispatch({ type: LOADING_MEDIOS_VERIFICACION });
  await getData
    .post(url, JSON.stringify(medioVerificacion))
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_MEDIOS_VERIFICACION,
        payload: medioVerificacion.medioVerificacionId,
      });
      dispatch({ type: MEDIOS_VERIFICACION_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: MEDIOS_VERIFICACION_FAILED });
      }
    });
};
