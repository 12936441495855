import {
  MOSTRAR_ROLES,
  LOADING_ROLES,
  ROLES_SUCCEEDED,
  ROLES_FAILD
} from "./../types";

import initialState from "./../initial-state";

const { roles } = initialState;
const RolesReducer = (state = roles, action) => {
  switch (action.type) {
    case MOSTRAR_ROLES:
      return {
        ...state,
        roles: action.payload
      };
    case LOADING_ROLES:
      return {
        ...state,
        isLoading: true
      };
    case ROLES_SUCCEEDED:
    case ROLES_FAILD:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default RolesReducer;
