import {
    LOADING_INFORME_TRIMESTRAL,
    MOSTRAR_INFORMES_TRIMESTRALES,
    MOSTRAR_INFORME_TRIMESTRAL,
    CARGAR_INFORME_TRIMESTRAL,
    EDITAR_INFORME_TRIMESTRAL,
    ELIMINAR_INFORME_TRIMESTRAL,
    INFORME_TRIMESTRAL_SUCCEEDED,
    INFORME_TRIMESTRAL_FAILED,
} from "./../types";

import initialState from "./../initial-state";

const { informesTrimestrales } = initialState;

const InformesTrimestralReducer = (state = informesTrimestrales, action) => {
    switch (action.type) {
        case LOADING_INFORME_TRIMESTRAL:
            return {
                ...state,
                isLoading: true,
            };
        case MOSTRAR_INFORMES_TRIMESTRALES:
        case CARGAR_INFORME_TRIMESTRAL:
            return {
                ...state,
                informesTrimestrales: action.payload,
            };
        case MOSTRAR_INFORME_TRIMESTRAL:
            return {
                ...state,
                informeTrimestral: action.payload,
            };
        case INFORME_TRIMESTRAL_SUCCEEDED:
            return {
                ...state,
                isLoading: false,
            };
        case INFORME_TRIMESTRAL_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case EDITAR_INFORME_TRIMESTRAL:
            return {
                ...state,
                informesTrimestrales: state.informesTrimestrales.map((informe) =>
                    informe.informeTrimestralId === action.payload.informeTrimestralId ?
                    action.payload :
                    informe
                )

            };
        case ELIMINAR_INFORME_TRIMESTRAL:
            return {
                ...state,
                informesTrimestrales: [
                    ...state.informesTrimestrales.filter(
                        (informe) => informe.informeTrimestralId !== action.payload
                    ),
                ],
            };

        default:
            return state;
    }
};

export default InformesTrimestralReducer;