import React from "react";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  deleteActividades,
  getActividad
} from "./../../../redux/actions/actividades-actions";

import swal from "sweetalert";

const AdminActividad = props => {
  const {
    descripcion,
    medioVerificacion,
    ponderacionActividad,
    t1,
    t2,
    t3,
    t4
  } = props.actividad;

  const eliminarNotaActividad = actividad => {
      swal({
      title: "Está seguro de eliminar la nota de la actividad?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        if (props.notaActividades) {
          props.notaActividades.map(
            (nota, index) =>
              nota.actividad.actividadId === actividad.actividadId &&
              props.notaActividades.splice(index, 1)
          );
        } 
  
        props.EliminarNotaActividad(props.notaActividades);
        activarEliminar(actividad);
      }
    });
  };

  const buscarEditarNota = actividad => {
    let prueba = "";
    props.notaActividades.map((nota, index) =>
      nota.actividad.actividadId === actividad.actividadId
        ? (prueba = index)
        : prueba
    );
    return prueba;
  };
  const activarEliminar = actividad => {
    for (let i = 0; i < props.notaActividades.length; i++) {
      if (
        props.notaActividades[i].actividad.actividadId === actividad.actividadId
      ) {
        return true;
      }
    }
    return false;
  };

 const truncateString = (str,num =40) => {
    return str && str.length > num ? str.slice(0, num) + "..." : str;
  };

  return (
    <tr>
      <td title={descripcion}>{truncateString(descripcion)}</td>
      <td>{ponderacionActividad}</td>
      <td title={medioVerificacion.descripcion}>{truncateString(medioVerificacion.descripcion,35)}</td>
      <td>{t1 ?t1:0}</td>
      <td>{t2?t2:0}</td>
      <td>{t3?t3:0}</td>
      <td>{t4?t4:0}</td>
      <td>
        <button
          className="btn btn-info btn-sm"
          onClick={e => {
            e.preventDefault();

            props.activarModalActividad(
              props.actividad,
              buscarEditarNota(props.actividad)
            );
          }}
        >
          Agregar nota
        </button>
        {props.notaActividades.length > 0 &&
        activarEliminar(props.actividad) ? (
          <button
            className="btn btn-danger btn-sm"
            onClick={e => {
              e.preventDefault();
              eliminarNotaActividad(props.actividad);
            }}
          >
            Eliminar nota
          </button>
        ) : (
          false
        )}
      </td>
    </tr>
  );
};

export default connect(null, { deleteActividades, getActividad })(
  AdminActividad
);
